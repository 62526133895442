import React, { useEffect, useState, memo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { ANIMATION_DURATION } from 'constants/common';

import * as S from './UploaderDropzone.styled';
import { useEvent } from 'react-use';

const PADDING = 8;

const UploaderDropzone: React.FC<{
  options: DropzoneOptions;
  position?: 'absolute' | 'fixed';
  children?: React.ReactNode;
}> = ({ children, options, position = 'absolute' }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [hintPosition, setHintPosition] = useState({});
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone(options);

  useEffect(() => {
    isDragActive ? setIsDragging(true) : setTimeout(() => setIsDragging(false), ANIMATION_DURATION);
  }, [isDragActive]);

  useEvent('uploader:open', () => {
    console.log('openDropsone');
    setTimeout(open, 0);
  });

  const rootProps = getRootProps();

  useEffect(() => {
    const rect = rootProps.ref.current.getBoundingClientRect();

    setHintPosition(
      position === 'fixed'
        ? {
            left: rect.left + PADDING,
            top: PADDING,
            bottom: PADDING,
            right: PADDING,
          }
        : {}
    );
  }, [position]);

  return (
    <S.UploaderDropzone {...rootProps}>
      {children}

      <input {...getInputProps()} />

      {isDragging && <S.Hint $isDragging={isDragActive} style={hintPosition} $position={position} />}
    </S.UploaderDropzone>
  );
};

export default memo(UploaderDropzone);
