import { proxyApi } from 'services/http';

export type FaceMapping = Partial<{
  [face_id: string]: {
    user_face_id: string;
  };
}>;

export type AudioMapping = Partial<{
  [face_id: string]: { audio_track_id: string };
}>;

export type MotionsMapping = Partial<{
  [face_id: string]: { user_face_id: string };
}>;

export type SwapMappingOptions<M extends Record<string, unknown> = any> = {
  meta: M;
  source?: string;
  facemapping: FaceMapping;
  audiomapping: AudioMapping;
  motionmapping: MotionsMapping;
};

type SwapContentPayload<T extends Record<string, unknown> = Record<string, unknown>> = T &
  SwapMappingOptions & {
    model_version?: string;
    has_watermark?: boolean;
    intensity?: number;
    // watermark_name: string;
    operation_key?: string;
  };

type RequestOptions = 'model_version' | 'intensity' | 'audiomapping' | 'has_watermark' | 'operation_key';

type SwapVideoOptions = Partial<Pick<SwapContentPayload, RequestOptions>>;

type SwapImageOptions = Partial<
  Pick<SwapContentPayload<{ interpolate: boolean }>, RequestOptions | 'interpolate' | 'motionmapping'>
>;

export type SwapVideoPayload = {
  object_id: string;
  mapping: Pick<SwapMappingOptions, 'facemapping' | 'audiomapping' | 'meta'>;
  options: Omit<SwapVideoOptions, 'facemapping' | 'audiomapping'>;
};

export type SwapContentResponse = {
  swap_id: string;
  message: string | null;
  object_id: string;
  status: number;
  success: boolean;
};

export const swapVideo = async ({ object_id, mapping: { meta = {}, ...mapping }, options }: SwapVideoPayload) => {
  const response = await proxyApi.post<SwapContentResponse>(`swap-video`, {
    object_id,
    model_version: options.model_version || 'v2',
    has_watermark: !!options.has_watermark,
    watermark_name: 'reface',
    operation_key: options.operation_key,
    intensity: options.intensity,
    meta,
    ...mapping,
  });

  return response.data;
};

export type SwapImagePayload = {
  object_id: string;
  mapping: Pick<SwapMappingOptions, 'facemapping' | 'audiomapping' | 'motionmapping' | 'meta'>;
  options: Omit<SwapImageOptions, 'facemapping' | 'audiomapping' | 'motionmapping'>;
};

export const swapImage = async ({ object_id, mapping: { meta = {}, ...mapping }, options = {} }: SwapImagePayload) => {
  const response = await proxyApi.post<SwapContentResponse>(`swap-image`, {
    object_id,
    model_version: options.model_version || 'v2',
    has_watermark: !!options.has_watermark,
    watermark_name: 'reface',
    operation_key: options.operation_key,
    interpolate: !!options.interpolate,
    meta,
    ...mapping,
  });

  return response.data;
};

type SwapVideoContentPayload = {
  content_type: 'video';
  options: Omit<SwapVideoOptions, 'facemapping' | 'audiomapping'>;
};

type SwapImageContentPayload = {
  content_type: 'image';
  options: Omit<SwapImageOptions, 'facemapping' | 'audiomapping' | 'motionmapping'>;
};

export type SwapContentRecordPayload = {
  content_type: 'video' | 'image';
  object_id: string;
  mapping: Pick<SwapMappingOptions, 'facemapping' | 'audiomapping' | 'motionmapping' | 'meta'>;
} & (SwapVideoContentPayload | SwapImageContentPayload);

export const swapContent = async ({ content_type, ...rest }: SwapContentRecordPayload) => {
  if (!['video', 'image'].includes(content_type)) {
    throw new Error('Unsupported content type');
  }

  const swapRequestMethod = content_type === 'video' ? swapVideo : swapImage;

  return swapRequestMethod(rest);
};

type CheckSwapStatusResponse = {
  data: {
    [swap_id: string]: {
      message: string | null;
      object_id: string;
      swap_path: string;
      status: 0 | 1 | 2;
      swap_id: string;
    };
  };
  error?: string;
};

export const checkSwapStatus = async (swap_ids: string[]) => {
  const response = await proxyApi.post<CheckSwapStatusResponse>(`swap/checkstatus`, {
    swap_ids,
  });

  return response.data;
};
