import MediaInfo, { Result } from 'mediainfo.js';

const MAX_RESOLUTION = 1920;

export const getFileMediaInfo = async (file: File) => {
  const getSize = () => file.size;

  const readChunk = (chunkSize: number, offset: number): Promise<Uint8Array> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.error) {
          reject(event.target?.error);
        }
        resolve(new Uint8Array(event.target?.result as ArrayBuffer));
      };
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });
  try {
    const mediainfo = await MediaInfo({});

    return mediainfo.analyzeData(getSize, readChunk);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const checkVideoResolution = async (result: Result | null) => {
  if (!result || typeof result === 'string') {
    return true;
  }

  const videoInfo = result.media.track.find((row: any) => row['@type'] === 'Video');

  if (!videoInfo) {
    return true;
  }

  return !(+videoInfo.Width > MAX_RESOLUTION || +videoInfo.Height > MAX_RESOLUTION);
};

export const checkVideoFormat = (result: Result | void) => {
  if (!result || typeof result === 'string') {
    return false;
  }

  const videoInfo = result.media.track.find((row: any) => row['@type'] === 'Video');

  if (!videoInfo) {
    return false;
  }

  return videoInfo.Format === 'AVC';
};
