import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputText from 'components/InputText';
import { useDebounce } from 'react-use';
import { useDispatch } from 'react-redux';
import { applySearch } from '../../../store/pages/content/searchSlice';
import { useLocation } from 'react-router';

const StyledInputText = styled(InputText)`
  margin-right: 32px;
`;

const SearchInput = () => {
  const location = useLocation();

  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useDebounce(
    () => {
      dispatch(applySearch(value));
    },
    300,
    [value]
  );

  useEffect(() => {
    setValue('');
  }, [location.pathname]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  return <StyledInputText value={value} onChange={handleChange} placeholder={'Search'} isClearable />;
};

export default SearchInput;
