import React, { useCallback, useEffect, useState } from 'react';
import ButtonMenu from 'components/ButtonMenu';
import { IconPreferences } from '@reface/icons/20px';
import { ButtonVariant } from '@reface/ui/Button';
import Switch from '@reface/ui/Switch';
import * as S from './SwapSettingsMenu.styled';
import { Divider } from 'components/LayoutTools';
import Multiselect, { MultiselectItem, MultiselectMode } from 'components/Multiselect';

export const modelOptions: MultiselectItem[] = [
  { value: 'v2', title: 'Current release' },
  { value: 'headshape', title: 'HeadShape' },
  // { value: 'IOS_28_09', title: 'v1' },
];

export const DEFAULT_SWAP_SETTINGS = {
  model_version: 'v2',
  has_watermark: false,
  interpolate: false,
};

type SwapSettingsOptions = {
  model_version: string;
  has_watermark: boolean;
  interpolate: boolean;
};

type SwapSettingsMenuProps = {
  onChange?: (settings: SwapSettingsOptions) => void;
  initialSettings?: SwapSettingsOptions;
  hasVideoOnlyOptions?: boolean;
  hasImageOnlyOptions?: boolean;
};

const SwapSettingsMenu: React.FC<SwapSettingsMenuProps> = ({
  hasImageOnlyOptions = false,
  onChange,
  initialSettings = DEFAULT_SWAP_SETTINGS,
}) => {
  const [value, setValue] = useState([modelOptions[0]]);
  const [settings, setSettings] = useState<SwapSettingsOptions>(initialSettings);

  const handleModelChange = (option: MultiselectItem) => {
    setValue([option]);
    setSettings((prev) => ({
      ...prev,
      model_version: String(option.value),
    }));
  };

  const handleToggle = useCallback((field: keyof typeof settings) => {
    setSettings((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  }, []);

  useEffect(() => {
    onChange && onChange(settings);
  }, [settings]);

  return (
    <ButtonMenu variant={ButtonVariant.SECONDARY} icon={<IconPreferences />}>
      <S.SettingWrapper>
        <S.InputLabel variant={'subtitle1'}>Preferences</S.InputLabel>
        <Multiselect
          placeholder="Swapper model"
          items={modelOptions}
          selectedItems={value}
          onSelect={handleModelChange}
          mode={MultiselectMode.SELECT}
        />
      </S.SettingWrapper>
      <S.SettingWrapper>
        <Switch
          label="With watermark"
          checked={settings.has_watermark}
          onChange={() => handleToggle('has_watermark')}
        />
      </S.SettingWrapper>

      {hasImageOnlyOptions && (
        <>
          <Divider />
          <S.SettingWrapper>
            <Switch label="Interpolate" checked={settings.interpolate} onChange={() => handleToggle('interpolate')} />
          </S.SettingWrapper>
        </>
      )}
    </ButtonMenu>
  );
};

export default SwapSettingsMenu;
