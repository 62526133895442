import styled from 'styled-components';

export const CategoryCover = styled.div`
  display: flex;
  max-height: 100%;
  margin: 0 auto;
`;

export const Preview = styled.div`
  width: 80%;
  margin: 0 auto;
  max-height: 100%;

  video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
