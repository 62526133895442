import { fabric } from 'fabric';
import { extendMethod, extension } from '../utils';
import { IBaseFilter, IToSVGOptions } from 'fabric/fabric-impl';
import alphaBlendFragmentSource from '!!raw-loader!./alphaBlendFragmentSource.glsl';

const toFixed = fabric.util.toFixed;

const simpleSortCallback = function (a: number, b: number) {
  return a - b;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function trimCanvas(canvas: HTMLCanvasElement) {
  let w = canvas.width,
    h = canvas.height;
  console.log('trim:size:prev', { w, h });

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  const pix: { x: number[]; y: number[] } = { x: [], y: [] },
    imageData = ctx.getImageData(0, 0, w, h);

  for (let y = 0; y < h; y++) {
    for (let x = 0; x < w; x++) {
      if (imageData.data[(y * w + x) * 4 + 3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      }
    }
  }
  pix.x.sort(simpleSortCallback);
  pix.y.sort(simpleSortCallback);

  const n = pix.x.length - 1;

  w = pix.x[n] - pix.x[0];
  h = pix.y[n] - pix.y[0];
  const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
  console.log('trim:size:after', { w, h });

  canvas.width = w;
  canvas.height = h;
  ctx.putImageData(cut, 0, 0);
}

export default extension('canvas.exports', (fabric) => {
  fabric.util.object.extend(fabric.Object.prototype, {
    getAbsoluteDimensions() {
      return {
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
        left: this.left,
        top: this.top,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
      };
    },
    getMainPosition() {
      const dimensions = this.getAbsoluteDimensions();
      return {
        left: this.left - dimensions.width / 2,
        top: this.top - dimensions.height / 2,
      };
    },
    exportPNG() {
      const bound = this.getBoundingRect(),
        json = JSON.stringify(this),
        shadowCanvasNode = fabric.util.createCanvasElement();

      shadowCanvasNode.width = bound.width;
      shadowCanvasNode.height = bound.height;
      const shadowCanvas = new fabric.Canvas(shadowCanvasNode, { enableRetinaScaling: false });

      return new Promise((resolve) => {
        fabric.util.enlivenObjects(
          [JSON.parse(json)],
          function (objects: fabric.Object[]) {
            objects.forEach(function (o) {
              if (o.top && o.left) {
                o.set({
                  top: o.top - bound.top,
                  left: o.left - bound.left,
                  angle: 0,
                });
              }

              shadowCanvas.add(o);
            });

            shadowCanvas.renderAll();

            const canvas = shadowCanvas.getElement();
            trimCanvas(canvas);

            // Render debugger

            // document.getElementById('preview')?.appendChild(canvas);
            /* const url = canvas.toDataURL('image/png'),
                          img = new Image();
                        img.width = canvas.width;
                        img.height = canvas.height;
                        img.src = url;

                        document.getElementById('preview')?.appendChild(img);*/

            canvas.toBlob(resolve, 'image/png');
          },
          ''
        );
      });
    },
  });

  fabric.util.createVideo = function () {
    return fabric.document.createElement('video');
  };

  fabric.util.loadVideo = function (url, callback, context, crossOrigin) {
    if (!url) {
      callback && callback.call(context);
      return;
    }

    let video: HTMLVideoElement | null = fabric.util.createVideo();

    /** @ignore */
    const onLoadCallback = function (e: any) {
      console.log('video:onLoadCallback', e);
      if (!video) {
        return;
      }

      const target = e.target as HTMLVideoElement;
      target.setAttribute('width', `${target.videoWidth}`);
      target.setAttribute('height', `${target.videoHeight}`);

      callback && callback.call(context, video, false);
      video = video.onloadeddata = video.onerror = null;
    };

    video.onloadeddata = onLoadCallback;
    /** @ignore */
    video.onerror = function () {
      if (!video) {
        return;
      }
      fabric.log('Error loading ' + video.src);
      callback && callback.call(context, undefined, true);
      video = video.onload = video.onerror = null;
    };

    // data-urls appear to be buggy with crossOrigin
    // https://github.com/kangax/fabric.js/commit/d0abb90f1cd5c5ef9d2a94d3fb21a22330da3e0a#commitcomment-4513767
    // see https://code.google.com/p/chromium/issues/detail?id=315152
    //     https://bugzilla.mozilla.org/show_bug.cgi?id=935069
    // crossOrigin null is the same as not set.
    if (url.indexOf('data') !== 0 && crossOrigin !== undefined && crossOrigin !== null) {
      video.crossOrigin = crossOrigin;
    }

    video.preload = 'auto';
    video.loop = true;

    video.src = url;
  };

  fabric.util.object.extend(fabric.Image.prototype, {
    getOriginalElement() {
      return this._originalElement;
    },
    getBlendImageElements() {
      return this.filters
        .filter((f: IBaseFilter) => f.type === 'BlendImage')
        .map((f: any) => f.image.getOriginalElement());
    },
  });

  fabric.util.object.extend(fabric.Image.filters.BlendImage.prototype, {
    fragmentSource: {
      multiply:
        'precision highp float;\n' +
        'uniform sampler2D uTexture;\n' +
        'uniform sampler2D uImage;\n' +
        'uniform vec4 uColor;\n' +
        'varying vec2 vTexCoord;\n' +
        'varying vec2 vTexCoord2;\n' +
        'void main() {\n' +
        'vec4 color = texture2D(uTexture, vTexCoord);\n' +
        'vec4 color2 = texture2D(uImage, vTexCoord2);\n' +
        'color.rgba *= color2.rgba;\n' +
        'gl_FragColor = mix(color2,color,0.5);\n' +
        '}',
      mask:
        'precision highp float;\n' +
        'uniform sampler2D uTexture;\n' +
        'uniform sampler2D uImage;\n' +
        'uniform vec4 uColor;\n' +
        'varying vec2 vTexCoord;\n' +
        'varying vec2 vTexCoord2;\n' +
        'void main() {\n' +
        'vec4 color = texture2D(uTexture, vTexCoord);\n' +
        'vec4 color2 = texture2D(uImage, vTexCoord2);\n' +
        'color.a = color2.a;\n' +
        'gl_FragColor = color;\n' +
        '}',
      alpha: alphaBlendFragmentSource,
      color: '#fff',
    },
    getOriginalElement() {
      return this._originalElement;
    },
    toObject() {
      console.log(this.image);
      return {
        type: this.type,
        image: this.image && this.image.toObject(),
        mode: this.mode,
        alpha: this.alpha,
      };
    },
  });

  fabric.util.object.extend(fabric.Image.filters.BlendImage, {
    fromObject(object: any, callback: any) {
      console.log('override:BlendImage.fromObject', object);
      fabric.MaskedMedia.fromObject(object.image, function (image: fabric.MaskedMedia) {
        const options = fabric.util.object.clone(object);
        options.image = image;
        callback(new fabric.Image.filters.BlendImage(options));
      });
    },
  });

  fabric.util.object.extend(fabric.Canvas.prototype, {
    initialize: extendMethod(fabric.Canvas, 'initialize', function () {
      // initCenteringGuidelines(this);
    }),
    getObjectsForExport(omit: fabric.Object[] = []) {
      return this.getObjects().filter((obj: fabric.Object) => !obj.excludeFromExport && !omit.includes(obj));
    },
    _setPartialSVGHeader: function (markup: string[], options: IToSVGOptions) {
      const width = options.width || this.width,
        height = options.height || this.height,
        NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS as number;
      let vpt,
        viewBox = 'viewBox="0 0 ' + this.width + ' ' + this.height + '" ';

      if (options.viewBox) {
        viewBox =
          'viewBox="' +
          options.viewBox.x +
          ' ' +
          options.viewBox.y +
          ' ' +
          options.viewBox.width +
          ' ' +
          options.viewBox.height +
          '" ';
      } else {
        if (this.svgViewportTransformation) {
          vpt = this.viewportTransform;
          viewBox =
            'viewBox="' +
            toFixed(-vpt[4] / vpt[0], NUM_FRACTION_DIGITS) +
            ' ' +
            toFixed(-vpt[5] / vpt[3], NUM_FRACTION_DIGITS) +
            ' ' +
            toFixed(this.width / vpt[0], NUM_FRACTION_DIGITS) +
            ' ' +
            toFixed(this.height / vpt[3], NUM_FRACTION_DIGITS) +
            '" ';
        }
      }

      markup.push(
        '<svg ',
        'xmlns="http://www.w3.org/2000/svg" ',
        'xmlns:xlink="http://www.w3.org/1999/xlink" ',
        'version="1.1" ',
        'width="',
        width,
        '" ',
        'height="',
        height,
        '" ',
        viewBox,
        'xml:space="preserve">\n'
      );
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
    exportToSvg: function (object: fabric.Object, options: IToSVGOptions = {}, reviver?: (obj: any) => string) {
      this.toSVG();
      const markup: string[] = [];

      this._setSVGPreamble(markup, { suppressPreamble: true });
      const dimensions = object.getAbsoluteDimensions();
      const position = object.getMainPosition();

      const headerOptions = {
        width: dimensions.width,
        height: dimensions.height,
        viewBox: {
          x: position.left,
          y: position.top,
          width: dimensions.width,
          height: dimensions.height,
        },
        ...options,
      };
      this._setPartialSVGHeader(markup, headerOptions);

      this._setSVGObject(markup, object, reviver);

      markup.push('</svg>');

      return markup.join('');
    },
  });
});
