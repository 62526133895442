import { proxyApi as api } from 'services/http';
import qs from 'qs';

export type AvailableContentType = 'video' | 'image' | 'audio';

export type AvailableAssetType = 'video_motion' | 'image_face' | null;

export enum ContentRecordStatus {
  SUCCESS,
  PROCESSING,
  ERROR,
}

export type MediaRecord = {
  object_id: string;
  content_type: AvailableContentType;
  asset_type: AvailableAssetType;
  created_date: string;
  data: any;
  error: any;
  ffprobe: any;
  label: any;
  origin_path: string;
  parent_id: string | null;
  preview_path: string | null;
  status: ContentRecordStatus;
  tags: string[];
  title: string;
  visible: boolean;
};

export type ContentRecord = Omit<MediaRecord, 'content_type'> & {
  content_type: 'folder' | AvailableContentType;
};

export type ContentModifiablePayload = Partial<
  Pick<ContentRecord, 'title' | 'label' | 'tags' | 'preview_path' | 'visible' | 'parent_id'>
>;

type CreateContentPayload = ContentModifiablePayload & {
  path: string;
};

export const createContentRecord = async (data: CreateContentPayload) => {
  const response = await api.post<MediaRecord>(`media`, data);

  return response.data;
};

export type CreateContentFolderPayload = Pick<ContentRecord, 'title' | 'parent_id'> & {
  asset_type: AvailableAssetType | 'content';
};

export const createContentFolderRecord = async ({ parent_id, asset_type, ...data }: CreateContentFolderPayload) => {
  const response = await api.post<MediaRecord>(
    parent_id ? `files/${parent_id}` : 'files',
    asset_type === 'content'
      ? data
      : {
          ...data,
          asset_type,
        }
  );

  return response.data;
};

type DeleteContentRecordResponse = {
  object_ids: string[];
};

export const archiveContentRecords = async (object_ids: string[]) => {
  const response = await api.delete<DeleteContentRecordResponse>('files', {
    data: {
      object_ids,
    },
  });

  return response.data;
};

export const fetchContentArchive = () =>
  api.get<{
    data: ContentRecord[];
  }>(`api/files/archive?limit=500`);

export const restoreContentRecords = async (object_ids: string[]) => {
  const response = await api.post<string[]>('files/archive', {
    object_ids,
  });

  return response.data;
};

export const removeContentForever = async (object_ids: string[]) => {
  const response = await api.delete<{ data: string[] }>(`files/archive`, {
    data: {
      object_ids,
    },
  });

  return response.data;
};

export const updateContentRecord = async (object_id: string, patch: ContentModifiablePayload) => {
  const response = await api.patch(`files/${object_id}`, patch);

  return response.data;
};

export const updateContentFolder = async (object_id: string, patch: ContentModifiablePayload) => {
  const response = await api.patch(`files/${object_id}`, patch);

  return response.data;
};

export const fetchContentRecord = async (object_id: string) => {
  const response = await api.get<ContentRecord>(`media/${object_id}`);

  return response.data;
};

export type FolderContentResponse = {
  content: ContentRecord[];
  content_type: 'folder';
  asset_type: string;
  created_date: string;
  label: string | null;
  object_id: string;
  parent_id: string | null;
  preview_path: string | null;
  tags: string[] | null;
  title: string;
};

export type FetchContentFilters = {
  status?: number | number[];
  content_type?: AvailableContentType | 'folder';
  asset_type?: string | string[];
  source_id?: string;
  object_ids?: string[];
};

export const fetchFolderContent = async (folder = '', params?: FetchContentFilters) => {
  let folderPath = `files`;

  if (folder && folder !== 'root') {
    folderPath += `/${folder}`;
  }

  const searchString = params
    ? qs.stringify(params, {
        arrayFormat: 'comma',
        encode: false,
      })
    : '';

  const response = await api.get<FolderContentResponse>(folderPath + (searchString && `?${searchString}`));

  return response.data;
};

export const fetchMediaContentSearch = async (params: FetchContentFilters) => {
  const searchString = qs.stringify(params, {
    arrayFormat: 'comma',
    encode: false,
  });
  const response = await api.get<FolderContentResponse>(`files/search?${searchString}`);

  return response.data;
};
