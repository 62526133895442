import styled, { css } from 'styled-components';
import SelectionArea from '../../components/SelectionArea';
import { TableCell, TableRow } from '../../components/Table/Table.styled';
import { IconSuccessFilled } from '@reface/icons/20px';
import ButtonGroup from '@reface/ui/ButtonGroup';

type ViewMode = 'small' | 'large';

export const RowActionsGroup = styled(ButtonGroup)`
  visibility: hidden;
`;

export const TableWrapper = styled(SelectionArea)`
  display: flex;
  flex-direction: column;
  flex: 1;

  & ${TableCell} {
    display: inline-flex;

    align-items: center;
  }

  & ${TableRow}:hover {
    background-color: ${({ theme }) => theme.colors.gray[15]};
    border: 2px solid ${({ theme }) => theme.colors.blue[80]};

    & ${RowActionsGroup} {
      visibility: visible;
    }
  }
`;

// align-items: ${({ $viewMode }) => ($viewMode === 'large' ? 'flex-start' : 'center')};

export const TotalCell = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const RecordPreviewImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const ContentPreview = styled.img<{ $viewMode: ViewMode }>`
  flex-grow: 1;
  //max-width: 52px;
  //max-height: 32px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;

  ${({ $viewMode }) =>
    $viewMode === 'small' &&
    css`
      width: 52px;
      height: 32px;
    `}

  ${({ $viewMode }) =>
    $viewMode === 'large' &&
    css`
      width: 260px;
      height: 160px;
    `}
`;
