import { Meta } from 'App/components/Uploader/types';
import { AssetType, createAssetRecord, processContentRecord } from '../studio/processing';
import { getAssetTypeAliasOverride, getAssetTypeByRouteAlias } from '../../pages/Files/utils';
import { MediaRecord } from '../studio/content';

export type MediaType = Pick<MediaRecord, 'object_id' | 'content_type' | 'title'> & Partial<MediaRecord>;

const processMediaRecord = async (media: MediaType, meta: Meta) => {
  switch (meta.asset_type) {
    case 'image_segmentation':
    case 'video_segmentation': {
      return createAssetRecord('segmentation', media);
    }

    case 'faces':
    case 'motions':
    case 'audios': {
      const asset_type = getAssetTypeByRouteAlias(getAssetTypeAliasOverride(meta.asset_type, media.content_type));
      return createAssetRecord(asset_type as AssetType, { ...media, visible: true });
    }

    default: {
      return processContentRecord(media.object_id, media.content_type);
    }
  }
};

export default processMediaRecord;
