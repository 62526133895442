import { useDropzone } from 'react-dropzone';
import useToast from './useToast';
import { ToastError, ToastType } from 'types/toast';

export const AVAILABLE_MIME_GROUP = {
  image: ['image/jpeg', 'image/png', 'image/webp'],
  video: ['video/mp4', 'video/quicktime', 'video/webm'],
  audio: ['audio/mpeg', 'audio/mp3', 'audio/wav'],
};

export const AVAILABLE_MIME_TYPES = [];

const FILE_MAX_SIZE = 35000000; // 3.5 Mb

export type UseContentDropzoneOptions = {
  disabled?: boolean;
  multiple?: boolean;
  maxFiles?: number;
};

const useContentDropzone = (callback?: any, options: UseContentDropzoneOptions = {}) => {
  const { add: addToast } = useToast();
  const availableAssetsType = Object.values(AVAILABLE_MIME_GROUP).flat();

  return useDropzone({
    accept: availableAssetsType,
    maxSize: FILE_MAX_SIZE,
    maxFiles: options.maxFiles,
    multiple: options.multiple,
    disabled: options.disabled,
    noClick: true,
    validator: (file) => {
      if (file.size > FILE_MAX_SIZE) {
        addToast({
          type: ToastType.ERROR,
          message: 'File is larger than 35 Mb',
        } as ToastError);
      }

      return null;
    },
    onDrop: (files) => {
      callback && callback(files);
    },
  });
};

export default useContentDropzone;
