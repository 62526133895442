import { archiveContentThunk, bulkContentMoveThunk } from '../pages/content/contentActions';
import { restoreContentThunk } from '../pages/archive/archvieActions';

const historyActions = {
  [bulkContentMoveThunk.pending.toString()]: bulkContentMoveThunk,
  [archiveContentThunk.pending.toString()]: restoreContentThunk,
  // [restoreContentThunk.pending.toString()]: archiveContentThunk,
};

export type RegisteredAction = keyof typeof historyActions;

export default historyActions;
