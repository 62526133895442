import React from 'react';
import { CustomPicker } from 'react-color';
import * as S from './BackgroundColorPicker.styled';

const ColorSwatchItem: React.FC<{ color: string; onClick: any }> = ({ color, onClick }) => {
  const handleKeyDown = (e: any) => {
    onClick(color, e);
  };
  return <S.ColorSwatch color={color} onClick={handleKeyDown} />;
};

const BackgroundColorPicker: React.FC<{ colors: string[]; onChange: any }> = ({ onChange, colors }) => (
  <S.ColorPickerWrapper>
    {colors.map((color, index) => (
      <ColorSwatchItem color={color} key={index} onClick={onChange} />
    ))}
  </S.ColorPickerWrapper>
);

export default CustomPicker(BackgroundColorPicker);
