import styled from 'styled-components';

export const ColorPickerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 16px);
  gap: 8px;
  padding: 8px 16px;
`;

export const ColorSwatch = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;

  border-radius: 4px;
  background: ${({ color }) => color};
`;
