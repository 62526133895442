// NOTE: based on `react-toastify`s `TypeOptions`
export enum ToastType {
  ERROR = 'error',
  SUCCESS = 'success',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ToastGeneric<T extends ToastType, D = {}> = {
  id?: number | string;
  type: T;
} & D;

export type ToastError = ToastGeneric<
  ToastType.ERROR,
  {
    url?: string;
    error?: string;
    message: string;
  }
>;

export type ToastSuccess = ToastGeneric<
  ToastType.SUCCESS,
  {
    url?: string;
    message: string;
  }
>;

export type Toast = ToastError | ToastSuccess;

export const isToastError = (toast: Toast): toast is ToastError => toast.type === ToastType.ERROR;

export const isToastSuccess = (toast: Toast): toast is ToastSuccess => toast.type === ToastType.SUCCESS;
