import React, { memo, useCallback, useEffect, useState } from 'react';
import PageLayout from 'App/components/PageLayout';
import { RouteComponentProps, useHistory } from 'react-router-dom';
// import ContentEmpty from '../../components/ContentEmpty/ContentEmpty';
import Breadcrumbs from 'components/Breadcrumbs';

import useSwapBreadcrumbs from './hooks/useSwapBreadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDetailedMediaRecordThunk, selectDetailedMediaRecord } from 'store/pages/content/detailedActions';
import AddSwapEffectModal from './components/AddSwapEffectModal';
import { swapContentThunk } from 'store/pages/swap/swapRequestSlice';
import { prepareSwapMapping } from './utils';
import { SWAP_EVENTS, useSwapQueueContext } from 'App/components/SwapQueue';
import { SwapContentResponse } from 'api/studio/swapProcessing';
import { AppDispatch } from 'store';
import SwapImageContent from './components/SwapImageContent';
import SwapVideoContent from './components/SwapVideoContent/SwapVideoContent';
import SwapHistoryMenu from './components/SwapHistoryMenu';
import useContentSwapHistory from './hooks/useContentSwapHistory';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconReface } from '@reface/icons/20px';
import SwapSettingsMenu, { DEFAULT_SWAP_SETTINGS } from './components/SwapSettingsMenu';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Spinner from '@reface/ui/Spinner';
import DownloadCurrentSwapButton from './components/DownloadCurrentSwapButton';
import { useEvent } from 'react-use';
import ChangeContentTitleModal from '../Files/components/ChangeContentTitleModal';
import CreateVideoProductionCopyModal from '../Files/components/CreateVideoProductionCopyModal';
import DeleteContentConfirmationModal from '../Files/components/DeleteContentConfirmationModal';
import { getRouteAliasByAssetType } from '../Files/utils';
import BulkContentMoveModal from '../Files/components/BulkContentMoveModal';

const AWAITING_SWAP_ID = '00000000-0000-0000-0000-000000000000';

const Swap: React.FC<RouteComponentProps<{ object_id: string }>> = ({ match: { params } }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { add: addSwapInQueue } = useSwapQueueContext();

  const [awaitingSwapId, setAwaitingSwapId] = useState<string | null>(null);
  const detailedMedia = useSelector(selectDetailedMediaRecord(params.object_id));
  const breadcrumbs = useSwapBreadcrumbs(detailedMedia);
  const [swapOptions, setSwapOptions] = useState(DEFAULT_SWAP_SETTINGS);

  const { swapHistoryEntries, swapHistoryPreview, appliedMapping, setAppliedMapping } = useContentSwapHistory(
    params.object_id
  );

  useEffect(() => {
    dispatch(fetchDetailedMediaRecordThunk(params.object_id));
  }, [params.object_id]);

  const handleSwapsCompleted = useCallback(
    (e: any) => {
      const completedSwap = (e.detail as any[]).find(({ swap_id }) => swap_id === awaitingSwapId);

      if (!completedSwap) {
        return;
      }

      setAwaitingSwapId(null);

      history.push({
        search: `swap_id=${awaitingSwapId}`,
      });
    },
    [awaitingSwapId]
  );

  useEvent(SWAP_EVENTS.SWAP_COMPLETED, handleSwapsCompleted);

  const dispatchSwapThunk = async (payload: any) => {
    setAwaitingSwapId(AWAITING_SWAP_ID);
    try {
      const swapCompletedAction = await dispatch(swapContentThunk(payload));
      const { swap_id } = swapCompletedAction.payload as SwapContentResponse;

      setAwaitingSwapId(swap_id);
      addSwapInQueue(swap_id);
    } catch (e) {
      setAwaitingSwapId(null);
    }
  };

  const handleRefaceClick = () => {
    const mapping = prepareSwapMapping(appliedMapping);

    dispatchSwapThunk({
      content_type: detailedMedia!.content_type,
      object_id: detailedMedia!.object_id,
      mapping: mapping,
      options: swapOptions,
    });
  };

  const handleAddSwapEffectConfirm = (appliedMappingDraft: any, triggerReface: boolean) => {
    const newAppliedMapping = {
      ...appliedMapping,
      ...appliedMappingDraft,
    };

    setAppliedMapping(newAppliedMapping);

    const mapping = prepareSwapMapping(newAppliedMapping);

    if (triggerReface) {
      dispatchSwapThunk({
        content_type: detailedMedia!.content_type,
        object_id: detailedMedia!.object_id,
        mapping: mapping,
        options: swapOptions,
      });
    }
  };

  const onContentRemoved = () => {
    history.push(`/files/${getRouteAliasByAssetType(detailedMedia.asset_type)}/${detailedMedia.parent_id ?? ''}`);
  };

  return (
    <PageLayout
      header={<Breadcrumbs items={breadcrumbs} />}
      controls={
        <ButtonGroup>
          <SwapHistoryMenu entities={swapHistoryEntries} object_id={params.object_id} />

          {detailedMedia && (
            <>
              <DownloadCurrentSwapButton detailedMedia={detailedMedia} swapHistoryPreview={swapHistoryPreview} />

              <SwapSettingsMenu
                onChange={setSwapOptions}
                hasVideoOnlyOptions={detailedMedia.content_type === 'video'}
                hasImageOnlyOptions={detailedMedia.content_type === 'image'}
              />
            </>
          )}

          <Button variant={ButtonVariant.PRIMARY} disabled>
            Export
          </Button>
          <Button
            variant={ButtonVariant.ACTION}
            onClick={handleRefaceClick}
            disabled={!!awaitingSwapId}
            icon={awaitingSwapId ? <Spinner /> : <IconReface />}
          >
            Reface
          </Button>
        </ButtonGroup>
      }
    >
      {detailedMedia && detailedMedia.content_type === 'image' && (
        <SwapImageContent
          detailedMedia={detailedMedia}
          appliedMapping={appliedMapping}
          swapHistoryPreview={swapHistoryPreview}
        />
      )}

      {detailedMedia && detailedMedia.content_type === 'video' && (
        <SwapVideoContent
          detailedMedia={detailedMedia}
          appliedMapping={appliedMapping}
          swapHistoryPreview={swapHistoryPreview}
        />
      )}

      <AddSwapEffectModal onConfirm={handleAddSwapEffectConfirm} />
      <ChangeContentTitleModal />
      <CreateVideoProductionCopyModal />
      <DeleteContentConfirmationModal onConfirm={onContentRemoved} />
      <BulkContentMoveModal />
    </PageLayout>
  );
};

export default memo(Swap);
