import styled, { css } from 'styled-components';
import { Button } from '@reface/ui/Button/Button.styled';

export const TextStylesWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 16px;
`;

export const ActiveButton = styled.div<{ $isActive: boolean }>`
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      ${Button} {
        background-color: ${theme.colors.blue[80]};
      }
    `}
`;
