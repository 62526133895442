import { fabric } from 'fabric';
import { MethodsName } from '../../global';

/**
 * Extend an exiting method.
 *
 * @param shape
 * @param methodName
 * @param callback
 */
export function extendMethod<
  U extends T,
  T extends
    | typeof fabric.StaticCanvas
    | typeof fabric.Object
    | typeof fabric.Text
    | typeof fabric.Circle
    | typeof fabric.Group
    | typeof fabric.Image
    | typeof fabric.Polyline,
  M extends MethodsName<T['prototype']>
>(shape: T, methodName: M, callback: (this: InstanceType<U>, ...args: any) => any) {
  const prototype = shape.prototype as T['prototype'];
  // eslint-disable-next-line @typescript-eslint/ban-types
  const original = prototype[methodName] as unknown as Function;
  return function (this: InstanceType<T>) {
    // eslint-disable-next-line prefer-rest-params
    const result = original.apply(this, arguments);
    // eslint-disable-next-line prefer-rest-params
    callback.apply(this, [result, ...Array.from(arguments)]);
    return result;
  };
}

/**
 * Create an extension.
 * @param callback
 */
export function extension(name: string, callback: (instance: typeof fabric) => void) {
  return function (instance: typeof fabric) {
    instance.util.installedExtensions = instance.util.installedExtensions || {};

    if (!instance.util.installedExtensions[name]) {
      callback(instance);
      instance.util.installedExtensions[name] = true;
    }
  };
}
