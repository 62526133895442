import { createContext, createRef, RefObject } from 'react';

type ModalManagerContextValue = {
  openModal: <P extends any = any>(name: string, payload: P) => void;
  closeModal: (name: string) => void;
  modals: RefObject<Map<string, any>>;
  openedModals: string[];
};
const defaultModals = createRef<Map<string, any>>();

const ModalManagerContext = createContext<ModalManagerContextValue>({
  openModal: (name: string, context: any) => {
    console.log('open', { name, context });
  },
  closeModal: (name: string) => {
    console.log('close', name);
  },
  modals: defaultModals,
  openedModals: [],
});

export const ModalManagerProvider = ModalManagerContext.Provider;

export default ModalManagerContext;
