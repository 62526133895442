import { useSelector } from 'react-redux';
import { selectFolderBreadcrumbs } from 'store/pages/content/breadcrumbsSelectors';
import { mediaDetailsSelector } from 'store/pages/files/media/mediaSelectors';

const useTreeViewRecordActiveKey = (object_id: string, asset_type: string) => {
  const selectedRecord = useSelector(mediaDetailsSelector(object_id));

  const folders = useSelector(selectFolderBreadcrumbs(selectedRecord?.parent_id || 'root', asset_type));

  const treePath = folders.map(({ object_id }) => object_id);
  treePath.push(treePath.length ? `${treePath[treePath.length - 1]}_content` : 'root_content');

  treePath.push(object_id);

  const initialActiveKey = treePath.filter((x) => x).join('/');
  const initialOpenNodes = folders
    .map(({ object_id }) => object_id)
    .reduce((prev, parent_id) => [...prev, [prev[prev.length - 1], parent_id].filter(Boolean).join('/')], []);

  return [initialActiveKey, initialOpenNodes] as const;
};

export default useTreeViewRecordActiveKey;
