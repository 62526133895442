import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import {
  AutoSizer,
  InfiniteLoader,
  List,
  OverscanIndicesGetterParams,
  WindowScroller,
  CellMeasurerCache,
  InfiniteLoaderProps,
  ListProps,
} from 'react-virtualized';
import { useEvent } from 'react-use';

type GridContainerProps = {
  children: ListProps['rowRenderer'];
  isRowLoaded: InfiniteLoaderProps['isRowLoaded'];
  threshold?: number;
  hasMore: boolean;
  rowCount: number;
  loadMoreRows: InfiniteLoaderProps['loadMoreRows'];
};

export type GridContainerElement = { cache: CellMeasurerCache; list: List; refresh: () => void };

const GridContainer = forwardRef<GridContainerElement, GridContainerProps>(
  ({ children, threshold, hasMore, loadMoreRows, rowCount, isRowLoaded }, ref) => {
    const cacheRef = useRef<CellMeasurerCache>(
      new CellMeasurerCache({
        fixedWidth: true,
        fixedHeight: false,
      })
    );
    const listRef = useRef<List | null>(null!);

    // NOTE: recalculate row heights when grid is resized
    const handleResize = useCallback(() => {
      cacheRef.current?.clearAll();
      listRef.current?.recomputeRowHeights();
    }, []);

    useImperativeHandle(ref, () => ({
      cache: cacheRef.current as CellMeasurerCache,
      list: listRef.current as List,
      refresh: handleResize,
    }));

    useEvent('trigger:resize', handleResize);

    const handleScroll = (e) => {
      // console.log(e);
    };

    return (
      <InfiniteLoader
        minimumBatchSize={1}
        threshold={threshold}
        rowCount={hasMore ? rowCount + 1 : rowCount}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller onResize={handleResize} onScroll={handleScroll}>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    ref={(element) => {
                      listRef.current = element;
                      registerChild(element);
                    }}
                    deferredMeasurementCache={cacheRef.current}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    // NOTE: N = (stopIndex - startIndex + 1) – number of rows that fit on the screen
                    //       so, leave in DOM up to 2 * N rows each side
                    overscanIndicesGetter={({ cellCount, startIndex, stopIndex }: OverscanIndicesGetterParams) => ({
                      overscanStartIndex: Math.max(0, startIndex - 2 * (stopIndex - startIndex + 1)),
                      overscanStopIndex: Math.min(cellCount - 1, stopIndex + 2 * (stopIndex - startIndex + 1)),
                    })}
                    autoHeight
                    height={height}
                    width={width}
                    rowHeight={cacheRef.current.rowHeight}
                    rowCount={rowCount}
                    onRowsRendered={onRowsRendered}
                    rowRenderer={children}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    );
  }
);

export default GridContainer;
