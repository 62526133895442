import { combineReducers, createSelector } from '@reduxjs/toolkit';

import contentSlice from './contentSlice';
import detailedSlice from './detailedSlice';
import searchSlice from './searchSlice';
import foldersSlice from './foldersSlice';
import { RootState } from '../../index';
import { SORT_VARIANTS } from './constants';
import { AvailableContentType, ContentRecord } from '../../../api/studio/content';

const orderFolderContent = (content: ContentRecord[], sortType: SORT_VARIANTS) => {
  const sortHandler = (a: ContentRecord, b: ContentRecord) => {
    if (sortType === SORT_VARIANTS.NAME) {
      if (a.title === b.title) {
        return 0;
      }
      return a.title < b.title ? -1 : 1;
    }

    return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
  };

  const foldersList = content.filter(({ content_type }) => content_type === 'folder').sort(sortHandler);

  const restContent = content.filter(({ content_type }) => content_type !== 'folder').sort(sortHandler);

  return [...foldersList, ...restContent];
};

export const contentSelector = createSelector(
  (state: RootState) => state.pages.content.content,
  (state: RootState) => state.pages.content.search.search,
  (state: RootState) => state.pages.content.search.sort.type,
  ({ data, loading }, search, sortType) => {
    const totalItems = data.content.length;
    const content = data.content.filter(({ title }) => title.toLowerCase().includes(search));
    const visibleItems = content.length;
    const sortedEntries = orderFolderContent(content, sortType);

    const isLoading = loading === 'pending';

    if (sortType === SORT_VARIANTS.TYPE) {
      const response: Record<AvailableContentType | 'folder', ContentRecord[]> = {
        folder: [],
        video: [],
        image: [],
        audio: [],
      };

      for (const entry of sortedEntries) {
        response[entry.content_type].push(entry);
      }

      return [response, totalItems, visibleItems, isLoading] as const;
    }

    return [sortedEntries, totalItems, visibleItems, isLoading] as const;
  }
);

export default combineReducers({
  content: contentSlice.reducer,
  detailed: detailedSlice.reducer,
  search: searchSlice.reducer,
  folders: foldersSlice.reducer,
});
