import React from 'react';

import Menu from '@reface/ui/Menu';
import Popover, { PopoverRenderOptions } from '@reface/ui/Popover';

type ContextMenuProps = {
  renderAnchor?: (options: PopoverRenderOptions) => React.ReactNode;
  children?: React.ReactNode | ((options: PopoverRenderOptions) => React.ReactNode);
};

const ContextMenu: React.FC<ContextMenuProps> = ({ children, renderAnchor }) => (
  <Popover popover={(options) => <Menu>{typeof children === 'function' ? children(options) : children}</Menu>}>
    {(options) => renderAnchor && renderAnchor(options)}
  </Popover>
);

export default ContextMenu;
