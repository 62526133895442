import { fabric } from 'fabric';
import PinAnchor from './pinDotCircle';
import { IObjectOptions } from 'fabric/fabric-impl';

const DEFAULT_TRACKING_PADDING = 25;

const createPinnedDotObject = (obj: fabric.Object) => {
  const position = {
    left: obj.left,
    top: obj.top,
  };

  const pinAnchor: PinAnchor = new PinAnchor({
    radius: 12,
    fill: '#CC0000',
    strokeWidth: 0,
    originX: 'center',
    originY: 'center',
    // selectable: false,
    ...position,
    excludeFromExport: true,
    innerOptions: {
      radius: 4,
      fill: 'rgba(255, 255, 255, 0.4)',
      strokeWidth: 0,
    },
    tagName: 'PIN',
    noObjectGuidelines: true,
    hasBorders: false,
    cornerColor: 'rgba(255, 255, 255, 0.8)',
    uniformScaling: false,
  });

  const pinLine = new fabric.Line([position.left || 0, position.top || 0, position.left || 0, position.top || 0], {
    strokeDashArray: [5, 5],
    stroke: '#CC0000',
    selectable: false,
    excludeFromExport: true,
  });

  pinAnchor.set({
    anchorLine: pinLine,
  });

  const trackingBBox = new fabric.Rect({
    ...position,
    width: 2 * DEFAULT_TRACKING_PADDING,
    height: 2 * DEFAULT_TRACKING_PADDING,
    objectCaching: false,
    selectable: false,
    evented: false,
    stroke: 'rgba(38, 110, 254, 0.3)',
    fill: 'transparent',
    strokeWidth: 0.5,
    // opacity: 0.2,
    originX: 'center',
    originY: 'center',
    centeredScaling: true,
    excludeFromExport: true,
    // noObjectGuidelines: true,
  });

  // trackingBBox.perPixelTargetFind = true;

  trackingBBox.setControlsVisibility({
    mtr: false,
  });

  const findPointerTarget = (object: fabric.Object, e: Event, omit: fabric.Object[] = []) => {
    const canvas = object.canvas as fabric.Canvas;

    const pointer = canvas.getPointer(e, true);

    const objects = canvas.getObjectsForExport(omit);

    let i = objects.length;

    while (i--) {
      if (canvas._checkTarget(pointer, objects[i], pointer)) {
        return objects[i];
      }
    }
  };

  pinAnchor.set({
    objectPosition: {
      top: obj.top,
      left: obj.left,
    },
    attachments: [trackingBBox],
  });

  pinAnchor.setControlsVisibility({
    mt: false, // middle top disable
    mb: false, // midle bottom
    ml: false, // middle left
    mr: false, // I think you get it
    mtr: false,
    bl: false,
    br: false,
    tl: false,
    tr: false,
  });

  obj.on('moved', () => {
    console.log('moved');
    pinAnchor.set({
      objectPosition: {
        top: obj.top,
        left: obj.left,
      },
    });
  });

  pinAnchor.on('moved:anchor-source', ({ position, movePin, moveBox }: any) => {
    movePin && pinAnchor.set(position as Pick<IObjectOptions, 'left' | 'top'>);
    moveBox && trackingBBox.set(position as Pick<IObjectOptions, 'left' | 'top'>);
  });

  trackingBBox.on('unselected', () => {
    console.log('trackingBBox unselected');
    trackingBBox.set({
      evented: false,
    });
    // trackingBBox.perPixelTargetFind = true;
  });

  trackingBBox.on('moving', () => {
    pinAnchor.anchorSourceMoved(
      {
        left: trackingBBox.left,
        top: trackingBBox.top,
      },
      true,
      false
    );
  });

  pinAnchor.on('selected', (e) => {
    console.log(e);
  });

  pinAnchor.on('moving', () => {
    pinAnchor.anchorSourceMoved(
      {
        left: pinAnchor.left,
        top: pinAnchor.top,
      },
      false,
      true
    );
  });

  pinAnchor.on('moved', ({ e }) => {
    if (pinAnchor.canvas) {
      console.log('self', pinAnchor, obj);
      const targetObject = findPointerTarget(pinAnchor, e, [obj]);
      console.log('target', targetObject);
      if (targetObject) {
        pinAnchor.setSource(targetObject);

        targetObject.set({
          pinSource: pinAnchor,
        });
      } else {
        console.warn('no target found');
      }
    }
  });

  pinAnchor.on('bbox:edit', () => {
    console.log('bbox:edit');
  });

  pinAnchor.on('scaling', ({ transform }) => {
    if (!transform) {
      return;
    }

    pinAnchor.anchorSourceMoved({
      left: (pinAnchor.left ?? 0) + ((pinAnchor.width ?? 0) * (pinAnchor.scaleX ?? 0)) / 2,
      top: (pinAnchor.top ?? 0) + ((pinAnchor.height ?? 0) * (pinAnchor.scaleY ?? 0)) / 2,
    });

    trackingBBox.set({
      left: pinAnchor.left,
      top: pinAnchor.top,
    });
  });

  obj.on('removed', () => {
    pinAnchor.anchorSource &&
      pinAnchor.anchorSource.set({
        pinSource: null,
      });

    obj.canvas?.remove(pinAnchor, trackingBBox, pinLine);
    obj.canvas?.renderAll();
  });

  pinAnchor.on('removed', () => {
    obj.set({
      pinWatcher: null,
    });
    pinAnchor.anchorSource &&
      pinAnchor.anchorSource.set({
        pinSource: null,
      });
    pinAnchor.canvas?.remove(trackingBBox, pinLine);
    obj.canvas?.renderAll();
  });

  return {
    pinLine,
    trackingBBox,
    pinDot: pinAnchor,
  };
};

export default createPinnedDotObject;
