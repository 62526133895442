import { Middleware } from 'redux';
import { pushHistoryRecord } from '../history/historyActions';

const history: Middleware<any, any> =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const previousState = getState();
    const result = next(action);
    const nextState = getState();

    // Don't log in production
    if (process.env.NODE_ENV !== 'development') {
      console.groupCollapsed(`%c${action.type}`, 'font-weight:bold;font-size:14;color:rgb(23, 162, 184)');
      console.log('%cPrevious state: ', 'font-weight:bold', previousState);
      console.log('%cAction: ', 'font-weight:bold', action);
      console.log('%cNext state: ', 'font-weight:bold', nextState);
      console.groupEnd();
    }

    if (action.type.endsWith('/pending') && 'undoAction' in action.meta) {
      console.log(action.type, action.meta.undoAction.payload);
      dispatch(pushHistoryRecord(action.type, action.meta.undoAction.payload));
    }

    return result;
  };

export default history;
