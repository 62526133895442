import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

import 'react-toastify/dist/ReactToastify.css';

export const Toasts = styled(ToastContainer)`
  position: fixed;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
  margin: 0;
  padding: 0;

  .Toastify__toast {
    min-height: auto;
    max-height: 400px;
    min-width: 300px;
    max-width: 600px;
    margin: 0 0 6px 0;
    padding: 12px 8px 16px 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.gray[90]};
    border-radius: 8px;
    box-shadow: none;
    cursor: auto;

    &.Toastify__toast--error {
      background-color: ${({ theme }) => theme.colors.red[80]};
    }

    &.Toastify__toast--success {
      background-color: ${({ theme }) => theme.colors.green[60]};
    }
  }

  .Toastify__toast-body {
    max-width: none;
    padding: 0;
  }

  .Toastify__progress-bar {
    height: 4px;
    background-color: ${({ theme }) => hexToRGB(theme.colors.gray[90], 0.5)};
    border-radius: 0 6px 6px 0;
  }
`;

export const CloseButton = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -4px 0 0 8px;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-out;

  path {
    fill: ${({ theme }) => hexToRGB(theme.colors.gray[90], 0.5)};
    transition: fill 0.3s ease-out;
  }

  &:hover {
    path {
      fill: ${({ theme }) => hexToRGB(theme.colors.gray[90], 1)};
    }
  }
`;
