import React, { useEffect, useRef, useState } from 'react';

import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import InputText from 'components/InputText';
import Modal from '@reface/ui/Modal';
import ButtonGroup from '@reface/ui/ButtonGroup';

import * as S from './CreateFolderModal.styled';
import { useModalContext } from '@reface/shared/components/ModalManager';
import { useDispatch } from 'react-redux';
import { createContentFolderThunk } from 'store/pages/content/contentActions';
import { AvailableAssetType } from 'api/studio/content';

export const CREATE_FOLDER_MODAL = 'CREATE_FOLDER_MODAL';

const STYLES = {
  modal: {
    width: 512,
  },
};

type CategoryCoverEditModalProps = {
  name: string;
  parent_id: string;
  asset_type: string;
  onConfirm?: (values: Partial<any>) => void;
};

export type ModalPayloadValue = {
  object_id: string;
  title: string;
};

const CreateFolderModal: React.FC<CategoryCoverEditModalProps> = ({ name, parent_id, asset_type }) => {
  const titleInputTextRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { close, isOpen, value } = useModalContext<ModalPayloadValue>(name);
  const [title, setTitle] = useState('');

  const handleConfirm = async () => {
    dispatch(
      createContentFolderThunk({
        title,
        parent_id,
        asset_type: asset_type as AvailableAssetType,
      })
    );
    close();
  };

  const handleClose = () => close();

  useEffect(() => {
    // NOTE: use `setTimeout` to avoid conflict with `focusTrapped` prop of `react-responsive-modal`
    setTimeout(() => titleInputTextRef.current?.focus(), 0);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTitle(value!.title);
    }
  }, [isOpen, value]);

  return (
    <Modal
      styles={STYLES}
      title="Create Folder"
      actions={({ defaultActionRef }) => (
        <ButtonGroup>
          <Button
            ref={defaultActionRef}
            size={ButtonSize.LARGE}
            variant={ButtonVariant.ACTION}
            disabled={!title.length}
            onClick={handleConfirm}
          >
            Create
          </Button>
          <Button size={ButtonSize.LARGE} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <S.InputText>
        <InputText ref={titleInputTextRef} label="Title" value={title} isClearable onChange={setTitle} />
      </S.InputText>
    </Modal>
  );
};

export default CreateFolderModal;
