import React from 'react';
import { AddText, AddSticker, AddMusicSoon, SwapFaceSoon, ChangeBackground } from 'static/images';
import * as S from './ToolsSidebar.styled';

const AVAILABLE_TOOLS = {
  ADD_MEDIA: 'add_media',
  TEXT: 'add_text',
};

type ToolsSidebarProps = {
  onObjectAdd: (alias: string) => void;
};

const ToolsSidebar: React.FC<ToolsSidebarProps> = ({ onObjectAdd }) => {
  const handleToolAdd = (toolAlias: string) => {
    onObjectAdd(toolAlias);
  };
  return (
    <S.ToolsGrid>
      <S.ToolGridItem role={'button'} onClick={() => handleToolAdd(AVAILABLE_TOOLS.ADD_MEDIA)}>
        <AddSticker />
        add sticker
      </S.ToolGridItem>
      <S.ToolGridItem role={'button'} onClick={() => handleToolAdd(AVAILABLE_TOOLS.TEXT)}>
        <AddText />
        place texts
      </S.ToolGridItem>
      <S.ToolGridItem>
        <ChangeBackground />
        change background
      </S.ToolGridItem>
      <S.ToolGridItem>
        <AddMusicSoon />
        add music or sounds
      </S.ToolGridItem>
      <S.ToolGridItem>
        <SwapFaceSoon />
        swap faces change emotions
      </S.ToolGridItem>
    </S.ToolsGrid>
  );
};

export default ToolsSidebar;
