import React, { memo } from 'react';
import SwapProcessingStatusBar from './components/SwapProcessingStatusBar/SwapProcessingStatusBar';
import useSwapQueueCheckInterval from '../SwapQueue/hooks/useSwapQueueCheckInterval';

const SwapProcessingToastBox = () => {
  const items = useSwapQueueCheckInterval();

  if (!items.length) {
    return null;
  }
  console.log('SwapProcessingToastBox', items);
  return <SwapProcessingStatusBar items={items} />;
};

export default memo(SwapProcessingToastBox);
