import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectContentStatistic = createSelector(
  (state: RootState) => state.pages.content.content.data.content || [],
  (entities) => ({
    folders: entities.filter(({ content_type }) => content_type === 'folder').length,
    audios: entities.filter(({ content_type }) => content_type === 'audio').length,
    videos: entities.filter(({ content_type }) => content_type === 'video').length,
    images: entities.filter(({ content_type }) => content_type === 'image').length,
  })
);

export const selectContentEntries = (object_ids?: string[]) =>
  createSelector(
    (state: RootState) => state.pages.content.content.data.content || [],
    (entities) => {
      if (!object_ids) {
        return [];
      }

      return entities
        .filter(({ object_id }) => object_ids.includes(object_id))
        .filter(({ content_type }) => ['video', 'image'].includes(content_type));
    }
  );
