import React, { useCallback, useEffect } from 'react';

const useClickOutside = (onClickOutside: () => void, elementsRefs: React.RefObject<HTMLElement>[]): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (elementsRefs.every((elementRef) => !elementRef.current?.contains(event.target as Node))) {
        onClickOutside();
      }
    },
    [...elementsRefs]
  );

  // NOTE: `useCapture = true` allows to handle click outside first
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);
};

export default useClickOutside;
