import jwtDecode from 'jwt-decode';

import { AccessToken, DecodedAccessToken } from 'types/auth';

const decodeAccessToken = <
  T extends {
    exp: number;
  }
>(
  accessToken: AccessToken
): DecodedAccessToken | Record<string, never> => {
  try {
    const { exp: accessTokenExpirationTimeInSeconds = 0 } = jwtDecode<T>(accessToken);

    return {
      accessToken,
      accessTokenExpirationTime: accessTokenExpirationTimeInSeconds * 1000,
    };
  } catch {
    return {};
  }
};

export default decodeAccessToken;
