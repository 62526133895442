import React, { memo } from 'react';
import * as S from './GridContentItem.styled';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectFolderStatistic } from 'store/pages/content/foldersSlice';
import pluralize from 'pluralize';

const FolderPreviewWrapper = styled.div`
  padding: 15% 16px 0;
`;

const FolderPreview: React.FC<ContentPreviewProps> = ({ object_id }) => {
  const statistic = useSelector(selectFolderStatistic(object_id));

  const entries = Object.entries(statistic)
    .filter(([key, value]) => ['audios', 'folders', 'images', 'videos'].includes(key) && !!value)
    .map(([key, value]) => `${value} ${pluralize(key, value as number)}`)
    .join(' · ');

  return (
    <FolderPreviewWrapper>
      <S.FolderTotals>{entries}</S.FolderTotals>
    </FolderPreviewWrapper>
  );
};

export default memo(FolderPreview);
