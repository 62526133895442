import React, { forwardRef, ReactNode, useEffect, useMemo, useRef } from 'react';
import * as S from './ScratchCanvas.styled';
import { fabric } from 'fabric';
import useScratchAddLayers from './hooks/useScratchAddLayers';
import { PlayerBus } from '../../usePlayerBus';
import useMediaPlayer from './hooks/useMediaPlayer';
import AudioPlayer from 'components/AudioPlayerMini/AudioPlayer';
import ContextMenuItem from 'components/ContextMenuItem';
import ContextMenu from 'components/ContextMenu';
import Divider from '@reface/ui/Divider';
import {
  IconBackground,
  IconEdit,
  IconMoveLayerBack,
  IconMoveLayerFront,
  IconPin,
  IconReview,
  IconUnpin,
} from '@reface/icons/20px';
import BackgroundColorPicker from '../BackgroundColorPicker';
import { createAndAwaitAssetCallback } from 'store/pages/assets/createAsset';
import TextStyles from '../TextStyles';
import { IObjectOptions, IText, ITextOptions } from 'fabric/fabric-impl';
import { createShadowImageElement, createShadowVideoElement } from 'utils/fabric/createShadowMediaElement';
import useScratchCanvas from './hooks/useScratchCanvas';
// import usePopoverStore from '@reface/ui/Popover/usePopover/hooks/usePopoverStore';
import { getObjectTracking } from 'api/studio/idle';
import { EDITOR_PLAYER_ANCHOR_TIME } from '../../editorEvents.constants';
import useEditorLoading from '../../hooks/useEditorLoading';
import PinAnchor from 'utils/fabric/pinDotCircle';
import useToast from 'hooks/useToast';
import { ToastType } from 'types/toast';
import { geObjectTrackOffset } from 'utils/fabric/extensions/objectPin';
import { isFabricImageObjectVideo } from 'utils/fabric/guards';

export type CanvasPlayerRef = {
  // addObject: (source: HTMLVideoElement) => void;
};

export type ScratchCanvasProps = {
  bus: PlayerBus;
  renderModal: (obj: fabric.Object) => JSX.Element;
  children?: ReactNode;
  className?: string;
  width: number;
  height: number;
};

const isTextNode = (obj: IObjectOptions | null): obj is IText => !!obj && obj.tagName === 'TEXT';

const isMediaNode = (obj: IObjectOptions | null): obj is fabric.Image =>
  !!obj && ('VIDEO' === obj.tagName || 'IMG' === obj.tagName) && !!obj.object_id;

const isObjectPinAllowed = (obj: fabric.Object): boolean => !obj.pinSource && obj.tagName !== 'PIN';

const ScratchCanvas = forwardRef<CanvasPlayerRef, ScratchCanvasProps>((props, _ref) => {
  const { bus, renderModal, children, width, height, className } = props;
  const { add: addToast } = useToast();

  const setLoading = useEditorLoading();
  const videosContainer = useRef(null!);

  const { activeObject, canvasNodeRef, canvasRef, canvasWrapper, containerRef } = useScratchCanvas(bus, {
    width,
    height,
  });

  // const { closeAll } = usePopoverStore({ popoverId: '' });
  const playerProps = useMediaPlayer(bus);

  useScratchAddLayers(canvasRef, bus, videosContainer);

  useEffect(() => {
    const handlePlayerAnchor = ({ detail }: any) => {
      const objects = canvasRef.current.getObjects();
      const movingObjects = objects.filter((obj) => !!obj.pinWatcher && !!obj?.pinWatcher?.bboxes?.length);
      for (const obj of movingObjects) {
        const frames = obj?.pinWatcher?.bboxes.length;
        const timePerFrame = detail.duration / frames;
        const frameIndex = Math.ceil(detail.time / timePerFrame);
        const bbox = obj?.pinWatcher?.bboxes[frameIndex];
        if (!bbox) {
          continue;
        }

        const objectTrackOffset = geObjectTrackOffset(obj);
        const position = {
          left: objectTrackOffset.left + (bbox.x + bbox.w / 2) * objectTrackOffset.scaleX,
          top: objectTrackOffset.top + (bbox.y + bbox.h / 2) * objectTrackOffset.scaleY,
        };
        console.log(objectTrackOffset, bbox, position);

        obj.set(position);
      }
    };
    bus.on(EDITOR_PLAYER_ANCHOR_TIME, handlePlayerAnchor);

    return () => {
      bus.off(EDITOR_PLAYER_ANCHOR_TIME, handlePlayerAnchor);
    };
  }, [bus]);

  const handleMoveBackClick = () => {
    if (activeObject) {
      canvasRef.current.sendBackwards(activeObject);
      canvasRef.current.renderAll();
    }
  };
  const handleMoveFrontClick = () => {
    if (activeObject) {
      canvasRef.current.bringToFront(activeObject);
      canvasRef.current.renderAll();
    }
  };

  const handlePinToObject = () => {
    console.log('handlePinToObject', activeObject);
    if (!activeObject) {
      return;
    }

    activeObject.createPin();
    canvasRef.current.renderAll();
    // closeAll();
  };

  const handlePinToModifyBBox = () => {
    console.log(activeObject);
    if (!activeObject || !activeObject.attachments) {
      return;
    }

    activeObject.fire('bbox:edit');

    const attachment = activeObject.attachments[0];
    attachment.set({
      evented: true,
    });
    attachment.perPixelTargetFind = false;

    canvasRef.current.setActiveObject(activeObject.attachments[0]);
    // closeAll();
  };

  const handleObjectTrackPreview = async () => {
    if (!activeObject || !activeObject.attachments) {
      return;
    }

    console.log(activeObject);

    const videoObject = (activeObject as PinAnchor).anchorSource;
    if (!videoObject) {
      return '';
    }

    // closeAll();

    setLoading(true);
    try {
      const element = (videoObject as fabric.Image).getOriginalElement();
      if (!element || !element.getAttribute('src')) {
        addToast({
          type: ToastType.ERROR,
          message: 'No source object found',
        });

        return;
      }

      const attachment = activeObject.attachments[0];
      const box = attachment.getAbsoluteDimensions();

      const boxPosition = attachment.getMainPosition();
      const videoDimentions = videoObject.getMainPosition();

      const response = await getObjectTracking({
        bbox: {
          w: box.width,
          h: box.height,
          x: boxPosition.left - videoDimentions.left,
          y: boxPosition.top - videoDimentions.top,
        },
        frame: 0,
        video_url: element.getAttribute('src') || '',
      });

      activeObject.bboxes = response.bboxes;
    } finally {
      setLoading(false);
    }
  };

  const handleBackgroundRemove = async () => {
    if (isMediaNode(activeObject)) {
      setLoading(true);

      const object_id = activeObject.get('object_id') || '';

      // closeAll();

      try {
        const { tracking: asset, error_message } = await createAndAwaitAssetCallback({
          asset_type: 'segmentation',
          payload: {
            object_id: object_id,
          },
        });

        if (error_message) {
          addToast({
            type: ToastType.ERROR,
            message: error_message,
          });
          setLoading(false);
          return;
        }
        console.log(asset.origin_path);

        const fabricMethod = asset.content_type === 'video' ? createShadowVideoElement : createShadowImageElement;

        const { target } = await fabricMethod(asset.origin_path);

        // const target = activeObject.getOriginalElement();

        if (!target) {
          return;
        }

        const assetWidth = isFabricImageObjectVideo(target) ? target.videoWidth / 2 : target.naturalWidth / 2;
        const sourceTarget = activeObject.getOriginalElement();
        const sourceWidth = isFabricImageObjectVideo(sourceTarget)
          ? sourceTarget.videoWidth
          : sourceTarget?.naturalWidth;

        bus.modifyLayer(object_id, {
          mask: target,
        });

        const fImage = new fabric.MaskedMedia(target);

        if (sourceWidth === assetWidth) {
          activeObject.setElement(target);
          activeObject.set({
            cropX: 0,
            cropY: 0,
            width: assetWidth,
            height: isFabricImageObjectVideo(target) ? target.videoHeight : target.naturalHeight,
          });
          fImage.set({
            left: -assetWidth,
          });
        }

        const mask = new fabric.Image.filters.BlendImage({
          image: fImage,
          mode: 'alpha',
          alpha: 0.5,
        });

        activeObject.filters && activeObject.filters.push(mask);

        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const colors = useMemo(
    () => ['#000000', '#FFFFFF', '#5197E9', '#83BD5F', '#F6CB6E', '#EF9148', '#DC565B', '#D038B8', '#5F38D0'],
    []
  );

  const handleTextColorChange = (color: any) => {
    const activeObjects = canvasRef.current.getActiveObjects();
    activeObjects.forEach((object) => {
      (object as fabric.Text).set('fill', color.hex);
    });

    canvasRef.current.renderAll();
  };

  const handleBackgroundColorChange = (color: any) => {
    console.log('color: ', color);
    const activeObjects = canvasRef.current.getActiveObjects();
    activeObjects.forEach((object) => {
      object.set('backgroundColor', color.hex);
    });

    canvasRef.current.renderAll();
  };

  const handleTextNodeStyleChange = (styles: Partial<ITextOptions>) => {
    activeObject && activeObject.set(styles);
  };

  return (
    <S.CanvasContainer ref={containerRef} className={className} id={'editor-container'}>
      <S.ScratchCanvasWrapper ref={canvasWrapper}>
        <S.VideosContainer ref={videosContainer} />
        <S.Canvas ref={canvasNodeRef} width={720} height={720} />

        {!!bus.duration && <AudioPlayer {...playerProps} />}

        <ContextMenu {...props}>
          {activeObject && renderModal(activeObject)}

          {activeObject && activeObject.tagName && (
            <>
              {isTextNode(activeObject) && (
                <TextStyles
                  onChange={handleTextNodeStyleChange}
                  value={{
                    fontStyle: activeObject.fontStyle,
                    fontWeight: activeObject.fontWeight as number,
                    textAlign: activeObject.textAlign,
                    backgroundColor: activeObject.backgroundColor,
                  }}
                />
              )}

              {isMediaNode(activeObject) && (
                <>
                  <BackgroundColorPicker colors={colors} onChange={handleBackgroundColorChange} />

                  <Divider />
                  <ContextMenuItem onClick={handleBackgroundRemove}>
                    <IconBackground />
                    remove background
                  </ContextMenuItem>
                </>
              )}
              {activeObject.tagName === 'TEXT' && (
                <>
                  <BackgroundColorPicker colors={colors} onChange={handleTextColorChange} />
                  <Divider />
                </>
              )}

              {isObjectPinAllowed(activeObject) && (
                <>
                  <ContextMenuItem onClick={handlePinToObject}>
                    <IconPin />
                    pin object
                  </ContextMenuItem>
                </>
              )}
              {activeObject.tagName === 'PIN' && (
                <>
                  <ContextMenuItem onClick={handlePinToObject}>
                    <IconUnpin />
                    remove pin object
                  </ContextMenuItem>
                  <ContextMenuItem onClick={handlePinToModifyBBox}>
                    <IconEdit />
                    modify bbox
                  </ContextMenuItem>
                  <ContextMenuItem onClick={handleObjectTrackPreview}>
                    <IconReview />
                    request track preview
                  </ContextMenuItem>
                </>
              )}
            </>
          )}
          <Divider />
          <ContextMenuItem onClick={handleMoveFrontClick}>
            <IconMoveLayerFront />
            move to front
          </ContextMenuItem>
          <ContextMenuItem onClick={handleMoveBackClick}>
            <IconMoveLayerBack />
            move to back
          </ContextMenuItem>
        </ContextMenu>
        {children}
      </S.ScratchCanvasWrapper>
    </S.CanvasContainer>
  );
});

export default ScratchCanvas;
