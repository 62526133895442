import React from 'react';

import * as S from './LayoutColumn.styled';

export type LayoutColumnProps = {
  header?: React.ReactElement;
  footer?: React.ReactElement;
  children?: React.ReactNode;
};

const LayoutColumn: React.FC<LayoutColumnProps> = ({ header, footer, children }) => (
  <S.LayoutColumn>
    {header && <S.Header>{header}</S.Header>}

    {children && <S.Children>{children}</S.Children>}

    {footer && <S.Footer>{footer}</S.Footer>}
  </S.LayoutColumn>
);

export default LayoutColumn;
