import styled, { css } from 'styled-components';

import { ButtonColor, ButtonSize, ButtonVariant } from './Button.constants';

export const Button = styled.button<{
  $hasChildren: boolean;
  $hasIcon: boolean;
  $variant: ButtonVariant;
  $color: ButtonColor;
  $size: ButtonSize;
  $isRounded: boolean;
}>`
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;

  &:disabled {
    cursor: auto;
  }

  svg {
    transition: fill 0.3s ease-out;
  }

  ${({ $variant }) =>
    $variant === ButtonVariant.PRIMARY &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[20]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[25]};
      }

      &:active,
      &.active {
        background-color: ${({ theme }) => theme.colors.gray[15]};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }
    `}

  ${({ $variant }) =>
    $variant === ButtonVariant.ACTION &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[100]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.blue[80]};
      }

      &:active,
      &.active {
        background-color: ${({ theme }) => theme.colors.blue[60]};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }
    `}
  
  ${({ $variant }) =>
    $variant === ButtonVariant.SECONDARY &&
    css`
      background-color: transparent;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[15]};
      }

      &:active,
      &.active {
        background-color: ${({ theme }) => theme.colors.gray[20]};
      }
    `}
  
  ${({ $color }) => {
    switch ($color) {
      case ButtonColor.DANGER:
        return css`
          color: ${({ theme }) => theme.palette.error};

          &:disabled {
            color: ${({ theme }) => theme.colors.gray[40]};
          }
        `;

      case ButtonColor.SUCCESS:
        return css`
          color: ${({ theme }) => theme.palette.success};

          &:disabled {
            color: ${({ theme }) => theme.colors.gray[40]};
        `;

      case ButtonColor.DEFAULT:
      default:
        return css`
          color: ${({ theme }) => theme.colors.gray[90]};

          &:disabled {
            color: ${({ theme }) => theme.colors.gray[40]};
          }
        `;
    }
  }}
  
  ${({ $size, $hasChildren, $hasIcon, $isRounded }) =>
    $size === ButtonSize.X_SMALL &&
    css`
      min-height: 20px;
      min-width: 20px;
      padding-top: 0;
      padding-right: ${$hasChildren ? ($hasIcon ? '14px' : '10px') : 0};
      padding-bottom: 0;
      padding-left: ${$hasChildren ? ($hasIcon ? '6px' : '10px') : 0};
      border-radius: ${$isRounded ? '10px' : '6px'};

      ${Icon} + ${Children}, ${Children} + ${Icon} {
        margin-left: 2px;
      }
    `}
  
  ${({ $size, $hasChildren, $hasIcon, $isRounded }) =>
    $size === ButtonSize.SMALL &&
    css`
      min-height: 32px;
      min-width: 32px;
      padding-top: 6px;
      padding-right: ${$hasChildren ? ($hasIcon ? '14px' : '10px') : '6px'};
      padding-bottom: 6px;
      padding-left: ${$hasChildren ? ($hasIcon ? '6px' : '10px') : '6px'};
      border-radius: ${$isRounded ? '16px' : '8px'};

      ${Icon} + ${Children}, ${Children} + ${Icon} {
        margin-left: 2px;
      }
    `}
  
  ${({ $size, $hasChildren, $hasIcon, $isRounded }) =>
    $size === ButtonSize.MEDIUM &&
    css`
      min-height: 36px;
      min-width: 36px;
      padding-top: 8px;
      padding-right: ${$hasChildren ? ($hasIcon ? '16px' : '12px') : '8px'};
      padding-bottom: 8px;
      padding-left: ${$hasChildren ? ($hasIcon ? '8px' : '12px') : '8px'};
      border-radius: ${$isRounded ? '18px' : '8px'};

      ${Icon} + ${Children}, ${Children} + ${Icon} {
        margin-left: 4px;
      }
    `}
  
  ${({ $size, $hasChildren, $hasIcon, $isRounded }) =>
    $size === ButtonSize.LARGE &&
    css`
      min-height: 44px;
      min-width: 44px;
      padding-top: 12px;
      padding-right: ${$hasChildren ? ($hasIcon ? '20px' : '16px') : '12px'};
      padding-bottom: 12px;
      padding-left: ${$hasChildren ? ($hasIcon ? '12px' : '16px') : '12px'};
      border-radius: ${$isRounded ? '22px' : '8px'};

      ${Icon} + ${Children}, ${Children} + ${Icon} {
        margin-left: 6px;
      }
    `}
  
  &:disabled {
    pointer-events: none;
  }
`;

export const Icon = styled.div`
  height: 20px;
  width: 20px;
`;

export const Children = styled.div`
  cursor: inherit;
  white-space: nowrap;
`;
