import { proxyApi } from 'services/http';

export type FoldersStatisticResponse = {
  folders: Record<
    string,
    {
      asset_type: string | null;
      parent_id: string;
      status: 0 | 1 | 2;
      audios: number;
      folders: number;
      images: number;
      videos: number;
      title: string;
    }
  >;
};

export const fetchFoldersTree = async () => {
  const response = await proxyApi.get<FoldersStatisticResponse>(`files/folders`);

  return response.data;
};
