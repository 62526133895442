import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  height: 100%;
  max-height: 100%;
  //overflow-y: hidden;
  justify-content: stretch;
  margin-left: -32px;
  margin-right: -32px;
`;

export const PreviewWrapper = styled.div<{ $variant: string }>`
  align-self: center;
  flex-grow: 1;
  display: grid;
  justify-content: center;
  max-height: 100%;
  align-items: stretch;
  grid-template-rows: 100%;
  padding: 32px;

  ${({ $variant }) => {
    switch ($variant) {
      case 'both':
        return css`
          grid-template-columns: 1fr 1fr;
          gap: 16px;
        `;
      default:
        return css`
          grid-template-columns: 100% 0;
        `;
    }
  }}

  video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const OriginVideo = styled.video`
  margin: 0 0 0 auto;
`;

export const PreviewVideo = styled.video`
  margin: 0 auto 0 0;
`;

export const Timeline = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[0]};
  display: grid;
  grid-template-rows: 48px 16px 64px auto;
  grid-template-columns: 100%;
  background: ${({ theme }) => theme.colors.gray[10]};
  position: relative;
  overflow: hidden;
`;

export const Tools = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

export const PlayerControls = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Ruller = styled.svg`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};
  width: 100%;
  height: 16px;
  //pointer-events: none;
  user-select: none;
  & > g {
    transform: translateX(139px);
  }

  & rect.rulez-rect,
  & text.rulez-text {
    fill: ${({ theme }) => theme.colors.gray[40]};
    font-size: 12px;
    //pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray[10]};
  }
`;

export const PersonsTracksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  flex: 1;
`;

export const FilmstripContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.gray[0]};
  padding: 8px;
  height: 64px;
  display: grid;
  grid-template-columns: 128px auto;
`;

export const TimelineRow = styled.div<{ $size: number }>`
  display: grid;
  height: ${({ $size }) => `${$size + 8}px`};
  align-items: center;
  grid-template-columns: 128px auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }
`;

export const TimelineRowEffects = styled.div``;

export const TimelineRowBBoxWrapper = styled.div`
  padding: 4px 8px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[0]};
`;

export const PersonFaceRanges = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
`;

export const TimelineRowBox = styled.div`
  position: absolute;
  height: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray[25]};
  padding: 2px 4px;
`;

export const FilmstripWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -30px;
  height: calc(48px + 30px);
  display: flex;
  align-items: flex-end;
  cursor: col-resize;
`;

export const FilmstripCanvas = styled.canvas<{ $height: number }>`
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
  visibility: hidden;
  border: 2px solid ${({ theme }) => theme.colors.yellow[90]};
  border-radius: 8px;
  overflow: hidden;

  &.visible {
    visibility: visible;
  }
`;
