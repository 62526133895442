import { authApi } from 'services/http';
import { AccessToken, FirebaseToken } from 'types/auth';

export type SignInPayload = {
  firebaseToken: FirebaseToken;
  isSessionOpen?: boolean;
};

export type SignInResponse = {
  access_token: AccessToken;
  token: string;
};

const signIn = async ({ firebaseToken }: SignInPayload): Promise<SignInResponse> => {
  const response = await authApi.post('/oauth-firebase', { token: firebaseToken }, { withCredentials: true });

  return response.data;
  if (process.env.NODE_ENV === 'development') {
    return {
      token: '590faecf798049feb7e3ddcfcfc6595d',
      access_token: '',
    };
  } else {
    const response = await authApi.post('/oauth-firebase', { token: firebaseToken }, { withCredentials: true });

    return response.data;
  }
};

export default signIn;
