import React, { DOMAttributes } from 'react';

import * as S from './Tab.styled';

export enum TabType {
  NORMAL = 'NORMAL',
  COMPACT = 'COMPACT',
}

export type TabProps = {
  type?: TabType;
  title?: string;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: (label: string) => void;
} & Omit<DOMAttributes<HTMLDivElement>, 'onClick'>;

const Tab: React.FC<TabProps> = ({
  type = TabType.NORMAL,
  title,
  label,
  isActive = false,
  disabled = false,
  onClick,
  ...rest
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick && onClick(label);
  };

  return (
    <S.Tab
      title={title}
      $type={type}
      $isActive={isActive}
      $isClickable={!!onClick && !disabled}
      onClick={handleClick}
      {...rest}
    >
      <S.Label>{label}</S.Label>
    </S.Tab>
  );
};

export default Tab;
