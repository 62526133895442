import React from 'react';
import * as S from './Breadcrumbs.styles';
import { ButtonSize } from '@reface/ui/Button';
import { IconArrowDropDown } from '@reface/icons/20px';
import { Link } from 'react-router-dom';
import ContentItemActionsMenu from '../../pages/Files/components/ContentItemActionsMenu';

type Breadcrumb = {
  label: string;
  to?: string;
  source?: any;
};

export type BreadcrumbsProps = {
  items: Breadcrumb[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const isContextMenuAvailable = items.length > 1;

  const links = items.slice(0, -1);
  const currentItem = items[items.length - 1];

  return (
    <S.Wrapper>
      <S.List>
        {links.map((item, index) =>
          item.to ? (
            <S.Item key={index} as={Link} to={item.to}>
              {item.label}
            </S.Item>
          ) : (
            <S.Item key={index}>{item.label}</S.Item>
          )
        )}

        {!!currentItem && (
          <S.Item>
            <div>{currentItem.label}</div>
          </S.Item>
        )}
      </S.List>

      {isContextMenuAvailable && !!currentItem.source && (
        <ContentItemActionsMenu item={currentItem.source} size={ButtonSize.X_SMALL} icon={<IconArrowDropDown />} />
      )}
    </S.Wrapper>
  );
};

export default Breadcrumbs;
