import React, { DOMAttributes } from 'react';

import * as S from './ButtonGroup.styled';
import { AlignVariants } from './ButtonGroup.styled';

type ButtonGroup = {
  align?: AlignVariants;
} & DOMAttributes<HTMLDivElement>;

const ButtonGroup: React.FC<ButtonGroup> = ({ align = 'flex-start', children, ...rest }) => (
  <S.ButtonGroup $align={align} {...rest}>
    {React.Children.map(children, (child) => child)}
  </S.ButtonGroup>
);

export default ButtonGroup;
