import React, { useMemo } from 'react';
import * as S from './ImageEffectsPreview.styled';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconAdd, IconAudio, IconFace, IconHeadMotion } from '@reface/icons/20px';
import { AppliedMapping } from '../../types';
import { useSelector } from 'react-redux';
import { mediaDetailsSelector } from 'store/pages/files/media/mediaSelectors';
import { TAB_NAMES } from '../AddSwapEffectModal';

type AppliedPersonEffectsRow = {
  person: any;
  onClick: any;
  mapping: AppliedMapping;
  size: number;
};

const AppliedFaceMappingPreview: React.FC<{
  object_id: string;
  onClick: () => void;
}> = ({ object_id, onClick }) => {
  const appliedFace = useSelector(mediaDetailsSelector(object_id));

  return (
    <S.AppliedMappingPreview role={'button'} onClick={onClick}>
      {appliedFace ? <S.AppliedFacePreviewImage src={appliedFace.origin_path} /> : <IconFace className={'fallback'} />}
    </S.AppliedMappingPreview>
  );
};

const AppliedMotionMappingPreview: React.FC<{
  mapping: [string, string];
  onClick: () => void;
}> = ({ mapping, onClick }) => {
  const appliedMotion = useSelector(mediaDetailsSelector(mapping[0]));

  const appliedPerson = useMemo<{ person_id: string; preview_url: string } | null>(() => {
    if (!appliedMotion?.data?.persons) {
      return null;
    }

    return appliedMotion.data.persons.find((person) => person.person_id === mapping[1]);
  }, [appliedMotion]);

  return (
    <S.AppliedMappingPreview role={'button'} onClick={onClick}>
      {appliedPerson ? (
        <>
          <S.AppliedFacePreviewImage src={appliedPerson.preview_url} />
          <S.MiniIconHeadMotion />
        </>
      ) : (
        <IconHeadMotion className={'fallback'} />
      )}
    </S.AppliedMappingPreview>
  );
};

const AppliedPersonEffectsRow: React.FC<AppliedPersonEffectsRow> = ({ person, mapping, size, onClick }) => {
  const appliedEffects: JSX.Element[] = [];

  if (mapping?.facemapping) {
    appliedEffects.push(
      <AppliedFaceMappingPreview
        key={'facemapping'}
        object_id={mapping.facemapping}
        onClick={() => onClick(person.person_id, TAB_NAMES.FACE)}
      />
    );
  }

  if (mapping?.motionmapping) {
    appliedEffects.push(
      <AppliedMotionMappingPreview
        key={'motionmapping'}
        mapping={mapping.motionmapping}
        onClick={() => onClick(person.person_id, TAB_NAMES.ANIMATE)}
      />
    );
  }

  if (mapping?.audiomapping) {
    appliedEffects.push(
      <S.AppliedMappingPreview
        role={'button'}
        key={'audiomapping'}
        onClick={() => onClick(person.person_id, TAB_NAMES.REVOICE)}
      >
        <IconAudio className={'fallback'} />
      </S.AppliedMappingPreview>
    );
  }

  return (
    <S.EffectRow role={'button'} $size={size}>
      <S.PersonPreview src={person.preview_url} onClick={() => onClick(person.person_id, TAB_NAMES.FACE)} />
      {appliedEffects}
      <S.AddEffectWrapper>
        <Button
          icon={<IconAdd />}
          onClick={() => onClick(person.person_id)}
          size={ButtonSize.X_SMALL}
          variant={ButtonVariant.SECONDARY}
        />
      </S.AddEffectWrapper>
    </S.EffectRow>
  );
};

export default AppliedPersonEffectsRow;
