import Typography from '@reface/ui/Typography';
import styled, { css } from 'styled-components';

export const SettingWrapper = styled.div<{ $variant?: 'row' | 'column' }>`
  padding: 8px 12px;
  width: 256px;
  ${({ $variant }) =>
    $variant === 'row' &&
    css`
      display: flex;

      justify-content: space-between;
      align-items: center;
    `}
`;

export const InputLabel = styled(Typography)`
  margin-bottom: 8px;
`;
