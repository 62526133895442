import fetchMe, { FetchMeResponse } from 'api/auth/fetchMe';
import { ApiRequestState, RootState } from 'store';
import createApiRequestSlice from 'store/utils/createApiRequestSlice';

const getState: (state: RootState) => ApiRequestState<FetchMeResponse> = (state) => state.auth.me;

const name = 'auth/api/users/fetchMe';

const [fetchMeThunk, slice] = createApiRequestSlice<undefined, FetchMeResponse>(name, fetchMe);

export { fetchMeThunk, getState as getFetchMeState };
export default slice.reducer;
