import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface CreateHttpOptions {
  config?: AxiosRequestConfig;
  interceptors?: {
    request?: {
      onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
      onRejected?: (error: AxiosError) => Promise<AxiosError>;
    };
    response?: {
      onFulfilled?: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
      onRejected?: (error: AxiosError) => Promise<AxiosError>;
    };
  };
}

export const createHttp: (options?: CreateHttpOptions) => AxiosInstance = (options = {}) => {
  const instance = axios.create(options?.config);

  if (options.interceptors?.request) {
    instance.interceptors.request.use(
      options.interceptors.request?.onFulfilled,
      options.interceptors.request?.onRejected
    );
  }

  if (options.interceptors?.response) {
    instance.interceptors.response.use(
      options.interceptors.response?.onFulfilled,
      options.interceptors.response?.onRejected
    );
  }

  return instance;
};
