import React from 'react';
import pluralize from 'pluralize';

import Button, { ButtonSize } from '@reface/ui/Button';
import Modal from '@reface/ui/Modal';
import { UploadStatus } from 'types/common';

import useUploader from 'App/components/Uploader/hooks/useUploader';
import getItemsByStatuses from 'App/components//Uploader/utils/getItemsByStatuses';

import * as S from './UploaderStatusModal.styled';
import UploaderItem from './components/UploaderItem';
import { useModalContext } from '@reface/shared/components/ModalManager';

export const UPLOADER_STATUS_MODAL = 'UPLOADER_STATUS_MODAL';

const STYLES = {
  modal: {
    width: 600,
  },
};

const UploaderStatusModal: React.FC = () => {
  const { close, isOpen } = useModalContext(UPLOADER_STATUS_MODAL);

  const { items, reset, retry, remove } = useUploader();

  const failedItems = getItemsByStatuses(items, [UploadStatus.UPLOADING_FAILED, UploadStatus.PROCESSING_FAILED]);
  const otherItems = getItemsByStatuses(items, [
    UploadStatus.PENDING,
    UploadStatus.UPLOADING,
    UploadStatus.PROCESSING,
    UploadStatus.PROCESSED,
  ]);

  const numberOfItems = items.length;

  const areItemsUploading = !!getItemsByStatuses(items, [UploadStatus.PENDING, UploadStatus.UPLOADING]).length;
  const areItemsProcessing = !!getItemsByStatuses(items, [UploadStatus.PROCESSING]).length;

  const handleConfirm = async () => {
    await close();
    reset();
  };

  const handleClose = () => close();

  return (
    <Modal
      styles={STYLES}
      title={`${numberOfItems} ${pluralize('Upload', numberOfItems)}`}
      sidePaddings={false}
      open={isOpen}
      actions={({ defaultActionRef }) => (
        <Button
          ref={defaultActionRef}
          size={ButtonSize.LARGE}
          disabled={areItemsUploading || areItemsProcessing}
          onClick={handleConfirm}
        >
          Finish and close
        </Button>
      )}
      onClose={handleClose}
    >
      <S.Wrapper>
        <S.SubgroupCaption variant={'cation'}>Content</S.SubgroupCaption>
        <S.ListWrapper>
          {failedItems.map((item) => (
            <UploaderItem key={item.id} item={item} onRetry={retry} onRemove={remove} />
          ))}

          {otherItems.map((item) => (
            <UploaderItem key={item.id} item={item} onRetry={retry} onRemove={remove} />
          ))}
        </S.ListWrapper>
      </S.Wrapper>
    </Modal>
  );
};

export default UploaderStatusModal;
