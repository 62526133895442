import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as S from './SwapVideoContent.styled';
import { ContentRecord } from 'api/studio/content';
import AppliedPersonEffectsRow from '../ImageEffectsPreview/AppliedPersonEffectsRow';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { ADD_SWAP_EFFECT_MODAL, AddSwapEffectModalPayload, TAB_NAMES } from '../AddSwapEffectModal';
import EmptySwapPreview from '../EmptySwapPreview';
import VideoFilmstrip from './VideoFilmstrip';
import FilmstripRuller from './FilmstripRuller';
import { Spacer } from 'components/LayoutTools';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconCut, IconSettings, IconWarningFilled } from '@reface/icons/20px';
// import VolumeSlider from 'components/VolumeSlider';
import useVideoSyncPlayer from '../../hooks/useVideoSyncPlayer';
import Anchor from '../Anchor';
import Typography from '@reface/ui/Typography';
import { formatDuration } from '../../utils';
import Scrollbar from '@reface/ui/Scrollbar';
import { useMeasure } from 'react-use';
import PanelGroup from 'react-panelgroup';
import PreviewSizeDropdown from '../PreviewSizeDropdown';
import AudioPlayerControls from './AudioPlayerControls';
import { getType } from 'mime/lite';

const PersonTimelineRangesBoxes: React.FC<{ bboxes: any[]; frames: number; children?: React.ReactNode }> = ({
  bboxes,
  frames,
  children,
}) => {
  const personRanges = useMemo(() => {
    if (!bboxes) {
      return [];
    }

    const ranges = [];
    let rangeStart = -1;

    bboxes
      // get rid of invalid positions
      .map((bbox) => {
        const position = bbox.map((pos) => pos.filter((o) => o > 0));
        return position.flat().length ? position : [];
      })
      .forEach((box, i) => {
        if (box.length && rangeStart === -1) {
          rangeStart = i;
        } else if (!box.length && rangeStart > 0) {
          ranges.push([rangeStart, i, i - rangeStart]);
          rangeStart = -1;
        }
      });

    if (rangeStart !== -1) {
      ranges.push([rangeStart, bboxes.length, bboxes.length - rangeStart]);
    }

    return ranges;
  }, [bboxes, frames]);

  if (!frames) {
    return null;
  }

  return (
    <S.PersonFaceRanges>
      {personRanges.map((range, index) => (
        <S.TimelineRowBox
          key={index}
          style={{ left: `${(range[0] / frames) * 100}%`, width: `${(range[2] / frames) * 100}%` }}
        >
          {children}
        </S.TimelineRowBox>
      ))}
    </S.PersonFaceRanges>
  );
};

type SwapVideoContentProps = {
  detailedMedia: ContentRecord;
  appliedMapping: any;
  swapHistoryPreview: any;
};

const SwapVideoContent: React.FC<SwapVideoContentProps> = ({
  detailedMedia,
  swapHistoryPreview,
  appliedMapping = {},
}) => {
  const [wrapperRef, { width }] = useMeasure();
  const [openModal] = useModalManager();
  const [previewSize, setPreviewSize] = useState<number>(48);
  const filmstripRef = useRef(null!);

  const { sourceRef, previewRef, anchorTime, duration, setCurrentTime } = useVideoSyncPlayer<
    HTMLVideoElement,
    HTMLVideoElement
  >();

  const persons = detailedMedia.data?.persons || [];

  const handleAddEffect = (person_id: string) => {
    openModal<AddSwapEffectModalPayload>(ADD_SWAP_EFFECT_MODAL, {
      faces: persons,
      initialApplyTo: [person_id],
      disabled: [TAB_NAMES.TTS, TAB_NAMES.ANIMATE],
      appliedMapping: appliedMapping[person_id],
    });
  };

  const handleAnchorChange = useCallback(
    (value: number) => {
      setCurrentTime(value);
    },
    [setCurrentTime]
  );

  const rullerOffset = useMemo(
    () => ({
      left: 139,
      right: 10,
    }),
    []
  );

  const panelWidths = useMemo(
    () => [
      { minSize: 250, resize: 'stretch' },
      { size: 350, minSize: 350, resize: 'fixed' },
    ],
    []
  );

  const renderSwapPreview = (src: string | null) => {
    if (!src) {
      return (
        <EmptySwapPreview
          sourceRef={sourceRef}
          icon={IconWarningFilled}
          message={swapHistoryPreview.error_message}
          variant={'warning'}
        />
      );
    }

    return <S.PreviewVideo src={swapHistoryPreview?.swap_path} ref={previewRef} muted />;
  };

  return (
    <S.Content ref={wrapperRef}>
      <PanelGroup resize="dynamic" direction="column" borderColor="black" panelWidths={panelWidths} key={'panel'}>
        <S.PreviewWrapper $variant={'both'} key={'preview'}>
          <S.OriginVideo src={detailedMedia.origin_path} ref={sourceRef} muted />
          {swapHistoryPreview ? renderSwapPreview(swapHistoryPreview) : <EmptySwapPreview sourceRef={sourceRef} />}
        </S.PreviewWrapper>

        <S.Timeline key={'timeline'}>
          <S.Tools>
            <Button icon={<IconCut />} variant={ButtonVariant.SECONDARY} disabled />
            <Button icon={<IconSettings />} variant={ButtonVariant.SECONDARY} disabled />
            <Spacer />
            <AudioPlayerControls
              sourceRef={sourceRef}
              previewRef={previewRef}
              key={swapHistoryPreview?.swap_id || 'source'}
            >
              <Typography>
                {formatDuration(anchorTime)} / {formatDuration(duration)}
              </Typography>
            </AudioPlayerControls>
            <Spacer />
          </S.Tools>

          <FilmstripRuller duration={duration} offset={rullerOffset} width={width} />

          <S.FilmstripContainer>
            <PreviewSizeDropdown value={previewSize} onChange={(nextSize) => setPreviewSize(nextSize as number)} />
            <VideoFilmstrip src={detailedMedia.origin_path} width={width} ref={filmstripRef} />
          </S.FilmstripContainer>

          <S.PersonsTracksWrapper>
            <Scrollbar>
              {persons.map((person, index) => (
                <S.TimelineRow key={person.person_id} $size={previewSize}>
                  <S.TimelineRowEffects>
                    <AppliedPersonEffectsRow
                      onClick={handleAddEffect}
                      person={person}
                      mapping={appliedMapping[person.person_id]}
                      size={previewSize}
                    />
                  </S.TimelineRowEffects>
                  <S.TimelineRowBBoxWrapper>
                    <PersonTimelineRangesBoxes bboxes={person.bboxes} frames={detailedMedia.data?.frames_count || 0}>
                      <Typography truncate>Face {index + 1}</Typography>
                    </PersonTimelineRangesBoxes>
                  </S.TimelineRowBBoxWrapper>
                </S.TimelineRow>
              ))}
            </Scrollbar>
          </S.PersonsTracksWrapper>

          <Anchor onChange={handleAnchorChange} value={anchorTime} max={duration} target={filmstripRef} />
        </S.Timeline>
      </PanelGroup>
    </S.Content>
  );
};

export default SwapVideoContent;
