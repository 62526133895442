import React from 'react';

import Image from './components/Image';

import * as S from './EmptyState.styled';

export type EmptyStateProps = {
  title?: string;
  subtitle?: string;
};

const EmptyState: React.FC<EmptyStateProps> = ({ title = 'Nothing to show…', subtitle }) => (
  <S.EmptyState>
    <S.Container>
      <Image />
      <S.Title>{title}</S.Title>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
    </S.Container>
  </S.EmptyState>
);

export default EmptyState;
