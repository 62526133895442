import { createAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const selectSwapProcessingItems = (state: RootState) => state.processing.swap.items;

const initialState: {
  items: string[];
} = {
  items: [],
};

export const addSwapProcessingItems = createAction('processing/swap/addSwapProcessingItems', (swap_ids: string[]) => ({
  payload: swap_ids,
}));

export const removeSwapProcessingItems = createAction(
  'processing/swap/removeSwapProcessingItems',
  (swap_ids: string[]) => ({
    payload: swap_ids,
  })
);

export const clearSwapProcessing = createAction('processing/swap/clearProcessing');

const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(addSwapProcessingItems, (state, action) => {
      state.items = [...state.items.filter((id) => !action.payload.includes(id)), ...action.payload];
    });

    builder.addCase(removeSwapProcessingItems, (state, action) => {
      state.items = state.items.filter((id) => !action.payload.includes(id));
    });

    builder.addCase(clearSwapProcessing, (state) => {
      state.items = [];
    });
  },
});

export default uploaderSlice;
