import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
// import centeringGuidelines from 'fabric/lib/centering_guidelines';
import { useHotkeys } from 'react-hotkeys-hook';
// import { canvasGuidelines } from '@yassidev/fabric-extensions';
import initExtensions from 'utils/fabric/extensions';
import { isFabricImageObjectVideo, isMaskedMediaObject } from 'utils/fabric/guards';
import { PlayerBus } from '../../../usePlayerBus';
import 'utils/fabric/library';
import 'fabric-history';
import getCanvasSize from 'utils/getCanvasSize';
import { initAligningGuidelines } from 'utils/fabric/extensions/aligning_guidelines';

initExtensions(fabric);
// canvasGuidelines(fabric);

// import guideLines from 'utils/fabric/guideLInes';
// guideLines(fabric);
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = '#266EFE';
fabric.Object.prototype.cornerStyle = 'circle';
fabric.Object.prototype.cornerSize = 8;
fabric.Object.prototype.borderColor = '#266EFE';
fabric.Object.prototype.borderScaleFactor = 2;
fabric.Object.prototype.lockUniScaling = true;
// fabric.Object.prototype.lockAspectRatio = true;
// fabric.uniScaleTransform = true;

// basic settings
/*fabric.Object.prototype.customiseCornerIcons({
  tl: {
    borderColor: '#0094dd',
    cornerSize: 25,
    cornerShape: 'rect',
    cornerBackgroundColor: 'black',
  },
});*/

const useScratchCanvas = (bus: PlayerBus, resolution: { width: number; height: number }) => {
  const canvasWrapper = useRef<HTMLDivElement>(null!);
  const canvasNodeRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null!);
  const canvasRef = bus.editor;

  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);

  useEffect(() => {
    canvasRef.current = new fabric.Canvas(canvasNodeRef.current);
    canvasRef.current.preserveObjectStacking = true;
    canvasRef.current.controlsAboveOverlay = true;
    canvasRef.current.selection = false;
    // canvasRef.current.offHistory();
    initAligningGuidelines(canvasRef.current);
    // centeringGuidelines(canvasRef.current);
    canvasRef.current.on('object:selected', (e) => {
      console.log('object:selected', e);
    });

    // canvasRef.current.findTarget()

    const updateCanvas = () => {
      const canvas = canvasRef.current;
      const objects = canvas.getObjects().filter((obj) => isMaskedMediaObject(obj)) as fabric.MaskedMedia[];

      for (const image of objects) {
        const backend = fabric.filterBackend;
        const target = image.getOriginalElement();

        if (isFabricImageObjectVideo(target)) {
          image.videoTime = target.currentTime;
        }

        if (backend && backend.evictCachesForKey) {
          // console.log(backend, backend.gl)
          const cacheKey = image.cacheKey || '';

          backend.gl.blendFunc(backend.gl.SRC_ALPHA, backend.gl.ONE_MINUS_SRC_ALPHA);
          backend.gl.enable(backend.gl.BLEND);
          backend.gl.disable(backend.gl.DEPTH_TEST);
          backend.gl.clear(backend.gl.DEPTH_BUFFER_BIT | backend.gl.COLOR_BUFFER_BIT);

          if (image.filters) {
            for (const filter of image.filters) {
              backend.evictCachesForKey(filter.image.cacheKey);
              backend.evictCachesForKey(filter.image.cacheKey + '_filtered');
            }
          }

          backend.evictCachesForKey(cacheKey);
          backend.evictCachesForKey(cacheKey + '_filtered');
        }
        image.applyFilters();
      }

      canvasRef.current.renderAll();

      fabric.util.requestAnimFrame(updateCanvas);
    };

    fabric.util.requestAnimFrame(updateCanvas);

    const handleContextmenu = (e: any) => {
      // let objectFound = false;
      const clickPoint = new fabric.Point(e.offsetX, e.offsetY);

      e.preventDefault();
      const canvas = canvasRef.current;

      canvas.forEachObject(function (obj) {
        if (obj.tagName && obj.containsPoint(clickPoint)) {
          // objectFound = true;
          setActiveObject(obj);
          canvas.setActiveObject(obj);
          //TODO: whatever you want with the object
        }
      });
    };

    canvasWrapper.current.addEventListener('contextmenu', handleContextmenu);

    const historyKeyupHandler = ({ keyCode, ctrlKey }: KeyboardEvent) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      const canvas = canvasRef.current;
      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        canvas.undo();
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        canvas.redo();
      }
    };

    document.addEventListener('keyup', historyKeyupHandler);

    return () => {
      canvasWrapper.current && canvasWrapper.current.removeEventListener('contextmenu', handleContextmenu);
      document.removeEventListener('keyup', historyKeyupHandler);

      canvasRef.current.clear();
    };
  }, []);

  useEffect(() => {
    const canvasSize = getCanvasSize(containerRef.current, resolution);

    canvasRef.current.setWidth(canvasSize.width);
    canvasRef.current.setHeight(canvasSize.height);
    bus.scaleFactor = canvasSize.factor;
    // canvasRef.current.setZoom(canvasSize.width / resolution.width);
  }, [resolution.width, resolution.height]);

  useHotkeys('Delete, Backspace', () => {
    canvasRef.current.getActiveObjects().forEach((obj) => {
      canvasRef.current.remove(obj);
    });
    canvasRef.current.discardActiveObject().renderAll();
  });

  useHotkeys('Esc', () => {
    canvasRef.current.discardActiveObject().renderAll();
  });

  useHotkeys('Ctrl+Alt+I', () => {
    const canvasExport: any = canvasRef.current.toJSON();
    console.log({
      id: 'test',
      duration: bus.staticState.duration,
      dimensions: {
        width: canvasRef.current.width,
        height: canvasRef.current.height,
      },
      ...canvasExport,
    });
  });

  useHotkeys('Ctrl+Alt+E', () => {
    canvasRef.current
      .getActiveObject()
      .exportPNG()
      .then(
        (blob) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          })
      )
      .then((base64) => {
        console.log(base64);
      });

    // console.log(canvasRef.current.exportToSvg(canvasRef.current.getActiveObject()));
  });

  return {
    canvasWrapper,
    canvasNodeRef,
    containerRef,
    canvasRef,
    activeObject,
  };
};

export default useScratchCanvas;
