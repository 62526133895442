import React, { useCallback, useMemo, useRef } from 'react';
import { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconChevronDown } from '@reface/icons/20px';

import Switch from '@reface/ui/Switch';
import ButtonMenu from 'components/ButtonMenu';
import * as S from './ContentPersonsDropdown.styled';
import ContentItemPersonsPreview from '../../../../Files/components/ContentItemPersonsPreview';

type ContentPersonsDropdownProps = {
  persons: any[];
  selected: string[];
  setSelectedChange: (selected: string[]) => void;
};

const ContentPersonsDropdown: React.FC<ContentPersonsDropdownProps> = ({
  persons,
  selected = [],
  setSelectedChange,
}) => {
  const menuRef = useRef<HTMLButtonElement>(null!);
  const isApplyToAllChecked = useMemo(() => persons.length === selected.length, [persons, selected]);

  const handleToggle = useCallback(() => {
    setSelectedChange(isApplyToAllChecked ? [] : persons.map(({ person_id }) => person_id));
  }, [isApplyToAllChecked, persons]);

  const handleFaceClick = useCallback(
    (person_id) => {
      const nextSelected = selected.includes(person_id)
        ? selected.filter((id) => id !== person_id)
        : [...selected, person_id];

      setSelectedChange(nextSelected);
    },
    [selected]
  );

  const selectedPersons = useMemo(
    () => persons.filter(({ person_id }) => selected.includes(person_id)),
    [persons, selected]
  );

  const handlePersonsClick = useCallback(() => {
    menuRef.current.click();
  }, []);

  return (
    <S.Wrapper>
      <ContentItemPersonsPreview
        persons={selectedPersons}
        variant={'light'}
        direction={'left'}
        size={36}
        onClick={handlePersonsClick}
      />

      <ButtonMenu size={ButtonSize.X_SMALL} variant={ButtonVariant.SECONDARY} icon={<IconChevronDown />} ref={menuRef}>
        <S.MenuContent>
          {persons && (
            <S.PersonsList>
              {persons.map((person, index) => (
                <S.PersonRow
                  key={person.person_id}
                  $isChecked={selected.includes(person.person_id)}
                  role={'button'}
                  onClick={() => handleFaceClick(person.person_id)}
                >
                  <S.Preview src={person.preview_url} /> <S.Title>Face {index + 1}</S.Title> <S.Checked />
                </S.PersonRow>
              ))}
            </S.PersonsList>
          )}

          <S.Toggles>
            <Switch label="Apply to all Faces" checked={isApplyToAllChecked} onChange={handleToggle} />
            {/*<Switch label="Apply to Same" checked={isWithWatermark} onChange={handleToggle} />*/}
          </S.Toggles>
        </S.MenuContent>
      </ButtonMenu>
    </S.Wrapper>
  );
};

export default ContentPersonsDropdown;
