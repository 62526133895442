import styled, { css } from 'styled-components';
import Typography from '@reface/ui/Typography';

export const MotionsGridWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  width: 100%;
  margin: 0;
`;

export const MotionGridItem = styled.li`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid transparent;
  padding: 8px;

  &.active {
    color: white;
    background: ${({ theme }) => theme.colors.gray[10]};
    border: ${({ theme }) => `1px solid ${theme.colors.blue[80]}`};
  }

  & > * + * {
    margin-left: 8px;
  }
`;

export const MotionPreviewImage = styled.video`
  flex-grow: 1;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 8px;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  margin-left: 8px;
`;

export const Duration = styled(Typography)`
  min-width: 64px;
  margin-left: 8px;
  text-align: left;
`;

export const Loader = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const SelectedPersons = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 16px;

  & > * {
    border-radius: 8px;
    width: 28px;
    height: 28px;
  }

  & > :not(:first-child) {
    margin-left: 8px;
  }
`;

export const PersonPreviewItem = styled.li<{ $active: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid transparent;
  box-sizing: border-box;

  ${({ theme, $active }) =>
    $active &&
    css`
      color: white;
      background: ${theme.colors.gray[10]};
      border: ${`1px solid ${theme.colors.blue[80]}`};
    `};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
