import * as jwt from 'jsonwebtoken';
import hash from 'object-hash';
import uaParser from 'ua-parser-js';

const getFingerprint = (): string => {
  const data = uaParser() || { ua: null };

  return jwt.sign(data, hash(data));
};

export default getFingerprint;
