import styled, { css } from 'styled-components';

export const Switch = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div<{ $isDisabled: boolean }>`
  margin-right: 4px;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  transition: 0.3s ease-out color;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          color: ${({ theme }) => theme.colors.gray[30]};
        `
      : css`
          color: ${({ theme }) => theme.colors.gray[40]};
        `}
`;

export const Control = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  width: 32px;
  height: 20px;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray[25]};
  border-radius: 16px;
  user-select: none;
  transition: 0.3s ease-out border-color, 0.3s ease-out background-color;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          background-color: transparent;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[25]};

          &:hover {
            border-color: ${({ theme }) => theme.colors.gray[30]};
            background-color: ${({ theme }) => theme.colors.gray[30]};
          }

          &:active {
            border-color: ${({ theme }) => theme.colors.gray[15]};
            background-color: ${({ theme }) => theme.colors.gray[15]};
          }
        `}
`;

export const Dot = styled.div<{ $isChecked: boolean; $isDisabled: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.success};
  transform: translateX(0);
  transition: 0.3s ease-out background-color, 0.3s ease-out transform;

  ${({ $isChecked, $isDisabled }) =>
    $isChecked
      ? css`
          background-color: ${({ theme }) => ($isDisabled ? theme.colors.green[40] : theme.palette.success)};
          transform: translateX(100%);
        `
      : css`
          background-color: ${({ theme }) => ($isDisabled ? theme.colors.gray[20] : theme.colors.gray[40])};
          transform: translateX(0);
        `}
`;

export const Label = styled.div<{ $isDisabled: boolean }>`
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s ease-out;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          color: ${({ theme }) => theme.colors.gray[40]};
        `
      : css`
          color: ${({ theme }) => theme.colors.gray[90]};
        `}
`;

export const InputCheckbox = styled.input`
  display: none;
`;
