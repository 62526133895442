import React from 'react';
import pluralize from 'pluralize';

import Button, { ButtonSize } from '@reface/ui/Button';
import useHover from 'hooks/useHover';
import { IconLinkOutside } from '@reface/icons/20px';
import { UploadStatus } from 'types/common';

import getItemsByStatuses from '../../../Uploader/utils/getItemsByStatuses';

import UploaderStatusModal, { UPLOADER_STATUS_MODAL } from './components/UploaderStatusModal';

import * as S from './ProcessingStatusBar.styled';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { Item } from '../../../Uploader';
import Spinner from '@reface/ui/Spinner';

const ProcessingStatusBar: React.FC<{ items: Item[] }> = ({ items }) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();

  const isUploaderStatusModalExist = true;

  const [open] = useModalManager();

  const failedItems = getItemsByStatuses(items, [UploadStatus.UPLOADING_FAILED, UploadStatus.PROCESSING_FAILED]);
  const processedItems = getItemsByStatuses(items, [UploadStatus.PROCESSED]);
  const processingItems = getItemsByStatuses(items, [
    UploadStatus.PROCESSING,
    UploadStatus.PENDING,
    UploadStatus.UPLOADING,
  ]);

  const numberOfItems = items.length;
  const numberOfFailedItems = failedItems.length;
  const numberOfProcessedItems = processedItems.length;
  const numberOfProcessingItems = processingItems.length;

  const areItemsUploadingOrProcessing = !!getItemsByStatuses(items, [
    UploadStatus.PENDING,
    UploadStatus.UPLOADING,
    UploadStatus.PROCESSING,
  ]).length;
  const areItemsFailed = !!numberOfFailedItems;
  const areAllItemsProcessed = numberOfProcessedItems === numberOfItems;

  const handleUploaderStatusModalOpen = () => open(UPLOADER_STATUS_MODAL, {});

  return (
    <>
      <S.UploaderStatusBar ref={ref}>
        <S.Message>
          {areItemsFailed && (
            <>
              <S.IconWarningFilled /> {numberOfFailedItems} {pluralize('file', numberOfFailedItems)} failed
            </>
          )}

          {!areItemsFailed && (
            <>
              {areAllItemsProcessed ? (
                <>
                  <S.IconSuccessFilled /> {numberOfItems} {pluralize('file', numberOfItems)} uploaded
                </>
              ) : (
                <>
                  <Spinner /> {numberOfProcessingItems} {pluralize('file', numberOfProcessingItems)} in queue
                </>
              )}
            </>
          )}

          {areItemsUploadingOrProcessing && <>&hellip;</>}
        </S.Message>

        <S.Icon>
          {isHovered && (
            <Button
              size={ButtonSize.SMALL}
              icon={<IconLinkOutside />}
              isRounded
              onClick={handleUploaderStatusModalOpen}
            />
          )}
        </S.Icon>
      </S.UploaderStatusBar>

      {isUploaderStatusModalExist && <UploaderStatusModal />}
    </>
  );
};

export default ProcessingStatusBar;
