export const colors = {
  gray: {
    0: '#000000',
    5: '#0D0D0D',
    8: '#141414',
    10: '#1A1A1A',
    15: '#262626',
    20: '#333333',
    25: '#404040',
    30: '#4D4D4D',
    35: '#595959',
    40: '#666666',
    90: '#E5E5E5',
  },
  blue: {
    60: '#1B3E99',
    80: '#1F58CC',
    100: '#2C67FE',
  },
  red: {
    60: '#990000',
    80: '#CC0000',
    100: '#FF0000',
  },
  green: {
    60: '#089928',
    80: '#0ACC35',
    100: '#0DFF42',
  },
  yellow: {
    90: '#E5CF5C',
    100: '#E5F717',
  },
  white: '#ffffff',
};

export const palette = {
  error: colors.red[80],
  success: colors.green[100],
  warning: colors.yellow[100],
  action: colors.blue[100],
};
