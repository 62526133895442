import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cl from 'clsx';
import PageLayout from 'App/components/PageLayout';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '@reface/ui/Button';
import ButtonGroup from '@reface/ui/ButtonGroup';
import ContentToolbar from './components/ContentToolbar/ContentToolbar';
import GridContentItem from './components/GridContentItem/GridContentItem';

import ContentOrderDropdown from './components/ContentOrderDropdown';
import ContentViewGroup from './components/ContentViewGroup';
import Typography from '@reface/ui/Typography';
import { useDragLayer } from 'react-dnd';
import {
  bulkContentMoveThunk,
  fetchContentThunk,
  fetchUploadedItemsThunk,
  selectContentStatistic,
} from 'store/pages/content/contentActions';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../../components/Grid';
import * as S from './Files.styled';
import ChangeContentTitleModal from './components/ChangeContentTitleModal';
import { RouteComponentProps } from 'react-router-dom';
import { getAssetTypeByRouteAlias } from './utils';
import useUploader from '../../App/components/Uploader/hooks/useUploader';
import { AVAILABLE_MIME_GROUP } from '../../hooks/useContentDropzone';
import { IconBulkSwap, IconFolder, IconTrash } from '@reface/icons/20px';
import Breadcrumbs from '../../components/Breadcrumbs';
import { FilesRouteParams } from './types';
import FilesHeader from './components/FilesHeader';
import { contentSelector } from 'store/pages/content';
import { ContentRecord } from 'api/studio/content';
import { useLocation } from 'react-router';
import { fetchFoldersStatisticThunk } from 'store/pages/content/foldersActions';
import BulkContentMoveModal, {
  MODAL_NAME as BULK_CONTENT_MOVE_MODAL,
} from './components/BulkContentMoveModal/BulkContentMoveModal';
import { useModalManager } from '@reface/shared/components/ModalManager';
import DeleteContentConfirmationModal, {
  DELETE_CONTENT_CONFIRMATION_MODAL,
} from './components/DeleteContentConfirmationModal';
import useFolderBreadcrumbs from './hooks/useFolderBreadcrumbs';
import BulkSwapModal, { BULK_CONTENT_SWAP_MODAL } from './components/BulkSwapModal';
import { useHotkeys } from 'react-hotkeys-hook';
import { UNDO } from 'store/history/historyActions';
import pluralize from 'pluralize';
import useContentSelection from './hooks/useContentSelection';
import { UploaderDropzone } from 'App/components/Uploader';
import { useEvent } from 'react-use';
import { CheckAssetProcessingResponse } from '../../api/studio/processing';
import CreateVideoProductionCopyModal from './components/CreateVideoProductionCopyModal';
import { DropzoneOptions } from 'react-dropzone';
import ContentEmpty from 'components/ContentEmpty';
import { EmptyContentImage, EmptySearchContent } from 'static/images';
import { applyContentOrder, contentOrderSelector } from '../../store/pages/content/searchSlice';
import { DropdownOption } from '../../components/DropdownSelect';
import { SORT_VARIANTS } from '../../store/pages/content/constants';

const isContentList = (content: ReturnType<typeof contentSelector>[0]): content is ContentRecord[] =>
  Array.isArray(content);

const getStatisticCounterLabel = (key: string, asset_type: string) => {
  if (asset_type === 'content' || key === 'folders') {
    return key;
  }
  const assetKeysRewrite = {
    images: 'faces',
    videos: 'motions',
  };

  return assetKeysRewrite[key] || key;
};

const Files: React.FC<RouteComponentProps<FilesRouteParams>> = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null!);
  const [contentEntries, totalEntries, visibleEntries, isLoading] = useSelector(contentSelector);
  const contentSelectedOption = useSelector(contentOrderSelector);
  const contentStatistic = useSelector(selectContentStatistic);
  const breadcrumbs = useFolderBreadcrumbs();

  const { addCreateMedia: addFilesToUploader, setUploaderOptions } = useUploader();
  const location = useLocation();

  const {
    selected,
    setSelected,
    selectableRef,
    handleGridClick,
    onSelectionEnded,
    onSelectionStart,
    onSelectionMove,
    clearSelection,
  } = useContentSelection();

  const [openModal, , isModalsOpened] = useModalManager();

  useHotkeys('ctrl+z, command+z', () => {
    dispatch(UNDO());
  });

  const [gridVariant, gridItems] = useMemo(() => {
    if (isContentList(contentEntries)) {
      return ['grid', contentEntries] as const;
    } else {
      return [
        'sections',
        Object.entries(contentEntries)
          .filter(([, data]) => !!data.length)
          .map(([title, data]) => ({
            title: `${title}s`,
            key: title.toLowerCase(),
            type: 'divider',
            data,
          })),
      ] as const;
    }
  }, [contentEntries]);

  useHotkeys(
    'ctrl+a, command+a',
    () => {
      const content = isContentList(contentEntries) ? contentEntries : Object.values(contentEntries).flat();
      setSelected(new Set(content.map(({ object_id }) => object_id)));
    },
    [contentEntries]
  );

  useHotkeys('esc', () => {
    if (!isModalsOpened) {
      clearSelection();
    }
  });

  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  useEffect(() => {
    selectableRef.current?.cancel();
  }, [isDragging]);

  useEffect(() => {
    selectableRef.current?.cancel();
    clearSelection();
  }, [location.pathname]);

  useEffect(() => {
    dispatch(applyContentOrder(SORT_VARIANTS.MODIFIED));
  }, [params.asset_type]);

  const handleDrop = useCallback(
    async (item, target) => {
      const parent_id = target.object_id as string;
      const items = Array.from(new Set([...selected, item.object_id])).filter((object_id) => object_id !== parent_id);
      await dispatch(
        bulkContentMoveThunk({
          items,
          parent_id,
          prev_parent_id: params.parent_id || 'root',
        })
      );

      clearSelection();
    },
    [selected]
  );

  const handleBulkDeleteClick = useCallback(() => {
    console.log(Array.from(selected));
    openModal(DELETE_CONTENT_CONFIRMATION_MODAL, Array.from(selected));
  }, [selected, clearSelection]);

  useHotkeys(
    'del, backspace',
    () => {
      handleBulkDeleteClick();
    },
    [handleBulkDeleteClick]
  );

  const handleBulkMoveClick = useCallback(() => {
    openModal(BULK_CONTENT_MOVE_MODAL, Array.from(selected));
  }, [selected]);

  const handleBulkSwapClick = useCallback(() => {
    openModal(BULK_CONTENT_SWAP_MODAL, Array.from(selected));
  }, [selected]);

  const loadMoreHandler = () => {
    console.log('load more 3');
  };

  useEffect(() => {
    dispatch(fetchFoldersStatisticThunk());
    dispatch(
      fetchContentThunk({
        folder: params.parent_id || '',
        asset_type: getAssetTypeByRouteAlias(params.asset_type),
      })
    );

    setUploaderOptions({
      uploader: {
        onMediaContentUploaded: (items: any) => {
          console.log('onMediaContentUploaded', items);
        },
        onItemsProcessingCompleted: (items: any) => {
          console.log('onItemsProcessingCompleted', items);
          const object_ids = Object.keys(items);
          dispatch(fetchUploadedItemsThunk(object_ids));
        },
      },
    });
  }, [params.asset_type, params.parent_id]);

  useEvent('uploader:uploaded', ({ detail }) => {
    console.log('onMediaContentUploaded', detail);
  });

  useEvent('uploader:processed', ({ detail }) => {
    console.log('onItemsProcessingCompleted', detail);
    const objects = Object.values(detail as CheckAssetProcessingResponse)
      .map((tracking) => tracking.items)
      .flat()
      .filter(Boolean);

    const object_ids = objects.map(({ object_id }) => object_id);
    dispatch(fetchUploadedItemsThunk(object_ids));
  });

  const statisticString = useMemo(() => {
    const statistic = Object.entries(contentStatistic)
      .filter(([, v]) => !!v)
      .map(([key, value]) => {
        const statLabel = getStatisticCounterLabel(key, params.asset_type);
        return `${value} ${pluralize(statLabel, value as number)}`;
      })
      .join(' · ');

    return statistic || 'The folder is empty';
  }, [contentStatistic, params.asset_type]);

  const dropzoneOptions = useMemo<DropzoneOptions>(() => {
    const availableAssetsType =
      params.asset_type !== 'content'
        ? Object.values(AVAILABLE_MIME_GROUP).flat()
        : [AVAILABLE_MIME_GROUP.video, AVAILABLE_MIME_GROUP.image].flat();

    return {
      disabled: isModalsOpened,
      accept: availableAssetsType,
      noClick: true,
      noKeyboard: true,
      validator: (file) => {
        console.log(file.name, file.type);
        if (!availableAssetsType.includes(file.type)) {
          console.log(`Available MIME types: "${availableAssetsType.join(', ')}"`);
        }

        return null;
      },
      onDrop: (files) => {
        addFilesToUploader(files, {
          parent_id: params.parent_id,
          asset_type: params.asset_type,
        });
      },
    };
  }, [isModalsOpened, params]);

  const onSelectionBeforeStart = useCallback(() => {
    console.log('onSelectionBeforeStart');
  }, []);

  const handleContentOrderChange = (option: DropdownOption['value']) => {
    console.log(option);
    dispatch(applyContentOrder(option));
  };

  return (
    <UploaderDropzone options={dropzoneOptions} position={'fixed'}>
      <PageLayout
        header={<Breadcrumbs items={breadcrumbs} />}
        controls={<FilesHeader />}
        isLoading={isLoading}
        ref={pageRef}
      >
        <S.FilesGridSelectionArea
          className="container"
          ref={selectableRef}
          onBeforeStart={onSelectionBeforeStart}
          onStart={onSelectionStart}
          onStop={onSelectionEnded}
          onMove={onSelectionMove}
          selectables=".selectable"
          onClick={handleGridClick}
          // role="feed"
        >
          <ContentToolbar
            controls={
              <>
                <ContentOrderDropdown value={contentSelectedOption} onChange={handleContentOrderChange} />
                <ContentViewGroup />
              </>
            }
          >
            <ButtonGroup>
              {selected.size ? (
                <>
                  <S.SelectedCounter variant={'body2'}>
                    {selected.size} {pluralize('file', selected.size)} selected
                  </S.SelectedCounter>
                  <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL} onClick={clearSelection}>
                    Unselect
                  </Button>
                  <span> · </span>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.SMALL}
                    icon={<IconFolder />}
                    onClick={handleBulkMoveClick}
                  >
                    Move
                  </Button>

                  {params.asset_type === 'content' && (
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      size={ButtonSize.SMALL}
                      icon={<IconBulkSwap />}
                      onClick={handleBulkSwapClick}
                    >
                      Bulk Swap
                    </Button>
                  )}

                  <Button
                    variant={ButtonVariant.SECONDARY}
                    color={ButtonColor.DANGER}
                    size={ButtonSize.SMALL}
                    icon={<IconTrash />}
                    onClick={handleBulkDeleteClick}
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <Typography variant={'body2'}>{statisticString}</Typography>
              )}
            </ButtonGroup>
          </ContentToolbar>

          {!isLoading && !visibleEntries && (
            <ContentEmpty
              icon={totalEntries === visibleEntries ? EmptyContentImage : EmptySearchContent}
              title={totalEntries === visibleEntries ? 'Nothing Here Yet' : 'We found nothing'}
              subtitle={
                totalEntries === visibleEntries
                  ? 'Simply drag’n’drop image files or choose them from the folder'
                  : 'Sorry, but we don’t have nothing for your request'
              }
            />
          )}

          {!!visibleEntries && (
            <Grid
              items={gridItems}
              hasMore={true}
              isLoadingMore={false}
              onLoadMore={loadMoreHandler}
              variant={gridVariant}
              key={gridVariant}
              role={'feed'}
              style={{ zIndex: 0 }}
              cellRenderer={(file) => (
                <GridContentItem
                  key={file.object_id}
                  item={file}
                  className={cl('selectable', {
                    selected: selected.has(file.object_id),
                  })}
                  onDrop={handleDrop}
                />
              )}
              sectionHeaderRenderer={(section) => <S.SectionHeader key={section.key}>{section.title}</S.SectionHeader>}
            />
          )}
        </S.FilesGridSelectionArea>
      </PageLayout>

      <CreateVideoProductionCopyModal />
      <ChangeContentTitleModal />
      <BulkContentMoveModal onConfirm={clearSelection} />
      <DeleteContentConfirmationModal onConfirm={clearSelection} />
      <BulkSwapModal onConfirm={clearSelection} />
    </UploaderDropzone>
  );
};

export default Files;
