import { createSlice } from '@reduxjs/toolkit';
import { Item } from 'App/components/Uploader';
import { RootState } from '../index';

export const selectProcessingItems = (state: RootState) => state.processing.uploader.items;

type PersistedProcessingItem = Omit<Item, 'file'>;

const initialState: {
  items: PersistedProcessingItem[];
} = {
  items: [],
};

const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    clearProcessing(state) {
      state.items = [];
    },
    removeProcessingItem(state, action) {
      const existedItemIndex = state.items.findIndex(({ id }) => id === action.payload);
      existedItemIndex > -1 && state.items.splice(existedItemIndex, 1);
    },
    addProcessingItems(state, action) {
      const existedItemIds = action.payload.map(({ id }) => id);
      state.items = [...state.items.filter(({ id }) => !existedItemIds.includes(id)), ...action.payload];
    },
    syncProcessingItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const { addProcessingItems, removeProcessingItem, clearProcessing, syncProcessingItems } = uploaderSlice.actions;

export default uploaderSlice;
