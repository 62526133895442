import { proxyApi } from 'services/http';
import { ContentRecord, MediaRecord } from './content';

type CreateAssetPayload = Record<string, unknown> & {
  object_id: string;
  mask_url?: string;
};

export type ProcessContentResponse = {
  object_id: string;
  tracking_id: string;
};

export type ContentType = 'video' | 'image' | 'audio';

export type AssetType = 'media' | 'segmentation' | 'video_motion' | 'audio_lipsync' | 'image_face';

type CheckTrackedProcessingItems = string[];

export const createAssetRecord = async (asset_type: AssetType, payload: CreateAssetPayload) => {
  const response = await proxyApi.post<ProcessContentResponse>(`asset/${asset_type}`, payload);

  return response.data;
};

export type CheckAssetProcessingResponse = Record<
  string,
  {
    error_message: string | null;
    items: MediaRecord[];
    operation_status: string;
    status: number;
    tracking_id: string;
    user_id: string;
  }
>;

export const checkAssetProcessing = async (asset_type: AssetType, ids: CheckTrackedProcessingItems) => {
  const response = await proxyApi.post<CheckAssetProcessingResponse>(`asset/${asset_type}/check-processing`, {
    ids: ids,
  });
  return response.data;
};

export const checkContentProcessing = async (tracking_ids: CheckTrackedProcessingItems) => {
  const response = await proxyApi.post<CheckAssetProcessingResponse>(`processing/checkstatus`, {
    ids: tracking_ids.filter(Boolean),
  });

  return response.data;
};

export const processContentRecord = async (object_id: string, content_type: ContentType) => {
  const response = await proxyApi.post<ProcessContentResponse>(`processing/${content_type}`, {
    object_id,
  });
  return response.data;
};
