import React, { createRef, forwardRef, MutableRefObject, useEffect } from 'react';
import VanillaSelectionArea, { SelectionEvents, SelectionOptions } from '@viselect/vanilla/lib/viselect.esm';
export { default as VanillaSelectionArea } from '@viselect/vanilla/lib/viselect.esm';
export type { SelectionEvent } from '@viselect/vanilla/lib/viselect.esm';

export interface SelectionAreaProps
  extends Omit<Partial<SelectionOptions>, 'boundaries'>,
    React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  onBeforeStart?: SelectionEvents['beforestart'];
  onStart?: SelectionEvents['start'];
  onMove?: SelectionEvents['move'];
  onStop?: SelectionEvents['stop'];
  role?: string;
}

const SelectionArea = forwardRef<VanillaSelectionArea, SelectionAreaProps>((props, ref) => {
  const root = createRef<HTMLDivElement>();

  useEffect(() => {
    const { onBeforeStart, onStart, onMove, onStop, ...opt } = props;
    const areaBoundaries = root.current as HTMLElement;

    console.log('props.container', props.container);

    const selection = new VanillaSelectionArea({
      boundaries: areaBoundaries,
      ...opt,
      // container: root.current,
    });

    onBeforeStart && selection.on('beforestart', onBeforeStart);
    onStart && selection.on('start', onStart);
    onMove && selection.on('move', onMove);
    onStop && selection.on('stop', onStop);

    (ref as MutableRefObject<VanillaSelectionArea>).current = selection;

    return () => selection.destroy();
  }, [props.container]);

  return (
    <section ref={root} className={props.className} role={props.role}>
      {props.children}
    </section>
  );
});

export default SelectionArea;
