import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
`;

export const PreviewContainer = styled.div<{ $variant: string }>`
  flex-grow: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: 16px;
  max-height: 480px;
  overflow: hidden;

  ${({ $variant }) => {
    switch ($variant) {
      case 'both':
        return css`
          grid-template-columns: 50% 50%;
          grid-template-rows: 480px;
          gap: 16px;
        `;
      default:
        return css`
          grid-template-columns: 100% 0;
        `;
    }
  }}

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: 0 auto;
  }

  video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: 0 auto;
  }
`;
