import JSZip from 'jszip';
import download from './download';

const downloadZip = async (files: [string, string?][], zipFileName: string) => {
  if (!files.length) {
    return;
  }

  const zip = new JSZip();

  for (const [url, filename] of files) {
    try {
      const src = await fetch(url);
      const blob = await src.blob();
      zip.file(filename || url, blob, { binary: true });
    } catch (e) {
      console.log(e);
    }
  }

  const content = await zip.generateAsync({ type: 'blob' });

  const url = URL.createObjectURL(content);

  await download(url, zipFileName);
};

export default downloadZip;
