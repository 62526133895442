import React from 'react';

import { IconArrowLeft, IconArrowRight } from '@reface/icons/20px';
import { PageNumber } from 'types/common';
import calculateNumberOfPages from './utils/calculateNumberOfPages';

import * as S from './Pagination.styled';

const NUMBER_OF_BOUNDARY_ITEMS = 1;
const NUMBER_OF_SIBLING_ITEMS = 1;

const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, i) => start + i);
};

enum Types {
  PREVIOUS = 'PREVIOUS',
  ELLIPSIS_START = 'ELLIPSIS_START',
  ELLIPSIS_END = 'ELLIPSIS_END',
  NEXT = 'NEXT',
}

export type PaginationProps = {
  page: PageNumber;
  limit: number;
  total?: number;
  showPreviousButton?: boolean;
  showNextButton?: boolean;
  onChange: (page: PageNumber) => void;
};

// NOTE: for more information:
//       `https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/usePagination/usePagination.js`
const Pagination: React.FC<PaginationProps> = ({
  page,
  limit,
  total,
  showPreviousButton = true,
  showNextButton = true,
  onChange,
}) => {
  const numberOfPages = calculateNumberOfPages(limit, total);

  if (numberOfPages < 2) {
    return null;
  }

  const startPages = range(1, Math.min(NUMBER_OF_BOUNDARY_ITEMS, numberOfPages));
  const endPages = range(
    Math.max(numberOfPages - NUMBER_OF_BOUNDARY_ITEMS + 1, NUMBER_OF_BOUNDARY_ITEMS + 1),
    numberOfPages
  );

  const siblingsStart = Math.max(
    Math.min(
      // natural start
      page - NUMBER_OF_SIBLING_ITEMS,
      // lower boundary when page is high
      numberOfPages - NUMBER_OF_BOUNDARY_ITEMS - NUMBER_OF_SIBLING_ITEMS * 2 - 1
    ),
    // greater than `startPages`
    NUMBER_OF_BOUNDARY_ITEMS + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // natural end
      page + NUMBER_OF_SIBLING_ITEMS,
      // upper boundary when page is low
      NUMBER_OF_BOUNDARY_ITEMS + NUMBER_OF_SIBLING_ITEMS * 2 + 2
    ),
    // less than `endPages`
    endPages.length > 0 ? endPages[0] - 2 : numberOfPages - 1
  );

  const items = [
    ...(showPreviousButton ? [Types.PREVIOUS] : []),
    ...startPages,

    // start ellipsis
    ...(siblingsStart > NUMBER_OF_BOUNDARY_ITEMS + 2
      ? [Types.ELLIPSIS_START]
      : NUMBER_OF_BOUNDARY_ITEMS + 1 < numberOfPages - NUMBER_OF_BOUNDARY_ITEMS
      ? [NUMBER_OF_BOUNDARY_ITEMS + 1]
      : []),

    // sibling pages
    ...range(siblingsStart, siblingsEnd),

    // end ellipsis
    ...(siblingsEnd < numberOfPages - NUMBER_OF_BOUNDARY_ITEMS - 1
      ? [Types.ELLIPSIS_END]
      : numberOfPages - NUMBER_OF_BOUNDARY_ITEMS > NUMBER_OF_BOUNDARY_ITEMS
      ? [numberOfPages - NUMBER_OF_BOUNDARY_ITEMS]
      : []),

    ...endPages,
    ...(showNextButton ? [Types.NEXT] : []),
  ];

  return (
    <S.Pagination>
      {items.map((item) => [
        item === Types.PREVIOUS && (
          <S.Item key={Types.PREVIOUS} $isDisabled={page === 1} onClick={() => onChange(page - 1)}>
            <IconArrowLeft />
          </S.Item>
        ),

        item === Types.ELLIPSIS_START && (
          <S.Item key={Types.ELLIPSIS_START} $isDisabled>
            …
          </S.Item>
        ),

        typeof item === 'number' && (
          <S.Item key={item} $isActive={page === item} onClick={() => onChange(item)}>
            {item}
          </S.Item>
        ),

        item === Types.ELLIPSIS_END && (
          <S.Item key={Types.ELLIPSIS_START} $isDisabled>
            …
          </S.Item>
        ),

        item === Types.NEXT && (
          <S.Item key={Types.NEXT} $isDisabled={page === numberOfPages} onClick={() => onChange(page + 1)}>
            <IconArrowRight />
          </S.Item>
        ),
      ])}
    </S.Pagination>
  );
};

export default Pagination;
