import styled, { css } from 'styled-components';

export type ThemeVariant = 'dark' | 'light';

const getVariantColors = (theme: any, variant: ThemeVariant) =>
  variant === 'dark'
    ? {
        border: 'inherit',
        color: theme.colors.gray[90],
      }
    : {
        border: theme.colors.gray[90],
        color: theme.colors.gray[10],
      };

export const PersonImage = styled.img`
  object-fit: cover;
`;

export const RestCounter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 2;
`;

export const Wrapper = styled.div<{ $variant: ThemeVariant; $direction: 'right' | 'left'; $size: number }>`
  display: flex;
  z-index: 1;

  & > * {
    border-radius: 8px;
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};
  }

  ${({ $direction }) =>
    $direction === 'right'
      ? css`
          flex-direction: row;
          & > :not(:first-child) {
            margin-left: -8px;
          }
        `
      : css`
          flex-direction: row-reverse;
          & > :not(:first-child) {
            margin-right: -8px;
          }
        `}

  ${({ $variant, theme }) => {
    const colors = getVariantColors(theme, $variant);
    return css`
      ${RestCounter} {
        color: ${colors.color};
        background-color: ${colors.border};
      }

      & > * {
        border-style: solid; // seperate line to use inherit color
        border-width: 2px; // seperate line to use inherit color
        border-color: ${colors.border};
      }
    `;
  }}
`;
