import React from 'react';
import styled from 'styled-components';

const ImagePreviewImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const ImagePreview: React.FC<ContentPreviewProps> = ({ src }) => <ImagePreviewImage src={src} />;

export default ImagePreview;
