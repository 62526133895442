import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ModalManagerProvider } from './ModalManagerContext';

const ModalManager: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const modals = useRef(new Map());
  const [openedModals, setOpenedModals] = useState<string[]>([]);

  const openModal = useCallback(<T extends unknown>(name: string, context: T) => {
    modals.current.set(name, context);
    setOpenedModals((prevValue) => [...prevValue, name]);
  }, []);

  const closeModal = useCallback((name: string) => {
    modals.current.delete(name);
    console.log('closeModal', name);
    setOpenedModals((prevState) => prevState.filter((value) => value !== name));
  }, []);

  const value = useMemo(() => {
    console.log('ModalManager value check', openedModals);
    return {
      openModal,
      closeModal,
      modals,
      openedModals,
    };
  }, [openModal, closeModal, modals, openedModals]);

  return <ModalManagerProvider value={value}>{children}</ModalManagerProvider>;
};

export default ModalManager;
