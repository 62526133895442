import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TreeView from 'components/TreeView';
import { selectAssetsFoldersTree } from 'store/pages/assets/assetsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { PARENT_ID_ROOT } from 'store/pages/content/foldersSlice';
import { fetchAssetsThunk } from 'store/pages/assets/assetsActions';
import FolderTreeItem from './FolderTreeItem';
import InputText from 'components/InputText';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconAdd, IconReset } from '@reface/icons/20px';
import * as S from './AddSwapEffectModal.styled';
import MotionsSubNodesList from './MotionsSubNodesList';
import SelectedMotionPersonProvider from './MotionsSubNodesList/SelectedMotionPersonContext';
import useTreeViewRecordActiveKey from '../../hooks/useTreeViewRecordActiveKey';
import useEffectAssetsUploader from './useEffectAssetsUploader';
import dispatchEvent from 'utils/dispatchEvent';

const MOTION_ASSET_TYPE = 'video_motion';

type MotionsTreeViewProps = {
  selected: [string, string];
  onSelectedChanged: (motionmapping: [string, string], isDoubleClick: boolean) => void;
};

const MotionsTreeView: React.FC<MotionsTreeViewProps> = ({ onSelectedChanged, selected }) => {
  const dispatch = useDispatch();
  const statistic = useSelector(selectAssetsFoldersTree(MOTION_ASSET_TYPE, PARENT_ID_ROOT, []));
  const [activePersonId, setActivePersonId] = useState(null);

  useEffectAssetsUploader('motions');

  const treeData = useMemo(() => {
    if (!statistic) {
      return [];
    }
    console.log('statistic', statistic);
    return statistic.nodes!;
  }, [statistic]);

  useEffect(() => {
    dispatch(fetchAssetsThunk({ folder: PARENT_ID_ROOT, asset_type: MOTION_ASSET_TYPE }));
    setActivePersonId(Array.isArray(selected) ? selected[1] : null);
  }, []);

  const handleItemClick = (e, nodeId: string, node: any, selectedPersonId: string | undefined) => {
    console.log('handleItemClick', node);
    setActivePersonId(selectedPersonId || node.data?.persons[0]?.person_id);
    const motionmapping: [string, string] = [node.object_id, selectedPersonId || node.data?.persons[0]?.person_id];
    onSelectedChanged(motionmapping, e.detail === 2);
  };

  const handleSelectionClear = () => {
    onSelectedChanged(null, false);
  };

  const handleNodeOpened = useCallback((id: string, node: any) => {
    console.log('handleNodeOpened', id, node);
    dispatch(fetchAssetsThunk({ folder: id, asset_type: MOTION_ASSET_TYPE }));
  }, []);

  const selectedMotionPersonValue = useMemo(() => ({ activePersonId }), [activePersonId]);

  const [initialActiveKey, initialOpenNodes] = useTreeViewRecordActiveKey(
    Array.isArray(selected) ? selected[0] : '',
    MOTION_ASSET_TYPE
  );

  const uploadClickHandler = useCallback(() => {
    dispatchEvent('uploader:open', {});
  }, []);

  return (
    <SelectedMotionPersonProvider value={selectedMotionPersonValue}>
      <TreeView
        data={treeData}
        hasSearch
        onClickItem={handleItemClick}
        isNodesSelectable={false}
        initialOpenNodes={initialOpenNodes}
        activeKey={initialActiveKey}
        onNodeOpened={handleNodeOpened}
        nodeRenderer={FolderTreeItem}
        groupNodesRenderer={MotionsSubNodesList}
        searchRenderer={(searchProps) => (
          <S.Controls>
            <S.InputText>
              <InputText {...searchProps} />
            </S.InputText>
            <ButtonGroup>
              <Button
                variant={ButtonVariant.SECONDARY}
                icon={<IconReset />}
                onClick={handleSelectionClear}
                disabled={!selected}
              >
                Clear
              </Button>
              <Button variant={ButtonVariant.SECONDARY} icon={<IconAdd />} onClick={uploadClickHandler}>
                Upload New
              </Button>
            </ButtonGroup>
          </S.Controls>
        )}
      />
    </SelectedMotionPersonProvider>
  );
};

export default MotionsTreeView;
