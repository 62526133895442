import React, { ComponentProps, useEffect, useState } from 'react';
import RCTooltip from 'rc-tooltip';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconVolume, IconVolumeMuted } from '@reface/icons/20px';
import styled, { css } from 'styled-components';
import Slider from '@reface/ui/Slider';

const RCTooltipAlias: React.FC<
  ComponentProps<typeof RCTooltip> & {
    className?: string;
    showArrow: boolean;
  }
> = ({ className, ...props }) => <RCTooltip overlayClassName={className} {...props} />;

const SliderContent = styled(RCTooltipAlias)<{ $variant: string }>`
  padding: 0;
  opacity: 1;

  .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-inner {
    width: 32px;
    padding: 12px 6px;
    border-radius: 4px;
    min-height: unset;
    max-width: 280px;
    display: flex;
    justify-content: center;

    ${({ theme, $variant }) =>
      $variant === 'info' &&
      css`
        background-color: ${theme.colors.gray[25]};
      `}
  }
`;

const VolumeButton = styled(Button)<{ $isActive: boolean }>`
  border-radius: 0 0 8px 8px;
  opacity: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[25]};
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.gray[25]};
    `}
`;

type VolumeSliderProps = {
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
};

const DEFAULT_VOLUME_LEVEL = 70;

const VolumeControl: React.FC<VolumeSliderProps> = ({
  min = 0,
  max = 100,
  value: defaultValue = DEFAULT_VOLUME_LEVEL,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  const handleSliderChange = (nextValue: number) => {
    setValue(nextValue);
  };

  const content = <Slider vertical onChange={handleSliderChange} value={value} min={min} max={max} step={1} />;

  const handleMuteToggle = () => {
    setValue((prevValue) => (prevValue === 0 ? DEFAULT_VOLUME_LEVEL : 0));
  };

  return (
    <SliderContent
      mouseEnterDelay={0}
      placement={'top'}
      overlay={content}
      $variant={'info'}
      showArrow={false}
      align={{
        offset: [0, 4],
      }}
      onVisibleChange={setIsActive}
    >
      <VolumeButton
        size={ButtonSize.SMALL}
        icon={value === 0 ? <IconVolumeMuted /> : <IconVolume />}
        variant={ButtonVariant.SECONDARY}
        $isActive={isActive}
        onClick={handleMuteToggle}
      />
    </SliderContent>
  );
};

export default VolumeControl;
