import React from 'react';

import { TabType } from '@reface/ui/Tab';

import * as S from './Tabs.styled';

export type TabsProps = {
  type?: TabType;
  children?: React.ReactNode;
};

const Tabs: React.FC<TabsProps> = ({ children, type = TabType.NORMAL }) => <S.Tabs $type={type}>{children}</S.Tabs>;

export default Tabs;
