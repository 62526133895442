import { proxyApi } from 'services/http';

export async function putCloudContent({ url, content_length_range }: SignedUrlResponse, file: File) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      ...(content_length_range ? { 'x-goog-content-length-range': content_length_range } : {}),
    },
    body: file,
  });

  return response.status;
}

type SignedUrlResponse = {
  url: string;
  content_length_range?: string;
};

export async function fetchSignedUrl(extension: string) {
  const response = await proxyApi.post<SignedUrlResponse>(`signedurl`, {
    extension: extension.toLowerCase(),
  });
  return response.data;
}
