import React from 'react';
import * as S from './ScreenLoader.styled';
import { useTimeout } from 'react-use';

type ScreenLoaderProps = {
  size?: number;
  timeout?: number;
  warningMessage?: string;
};

const ScreenLoader: React.FC<ScreenLoaderProps> = ({ size = 300, timeout = Infinity, warningMessage }) => {
  const [isReady] = useTimeout(timeout);
  console.log('ScreenLoader isReady', isReady());
  return (
    <S.LoaderWrapper className="vertical-centered-box">
      <S.LoaderContent className="content" $size={size}>
        <S.LoaderCircle className="loader-circle" />
        <S.LoaderLineMask className="loader-line-mask">
          <S.LoaderLine className="loader-line" />
        </S.LoaderLineMask>

        <svg width="120" height="120" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.9 41.2C25.9 52.6 29.2 63.4999 34.9 71.1999C36.1 70.5999 37.2 69.7 38.2 68.8C33 61.8 30 51.8 30 41.2H25.9Z"
            fill="white"
          />
          <path
            d="M73.5 12.8C68.3 4.89997 61.2 0.599976 53.6 0.599976C46 0.599976 39 4.89997 33.7 12.8C29.6 19 27 26.8 26.1 35.2H30.2C32.1 17.9 41.8 4.69998 53.5 4.69998C66.5 4.69998 77.1 21.1 77.1 41.2C77.1 61.3 66.5 77.7 53.5 77.7C49.5 77.7 45.5 76.1 42 73L39.3 76.1C43.6 79.8 48.5 81.8 53.5 81.8C61.1 81.8 68.1 77.5 73.4 69.6C78.5 62 81.3 51.9 81.3 41.2C81.4 30.5 78.6 20.4 73.5 12.8Z"
            fill="white"
          />
          <path
            d="M51.3 47.1999C49.5 64.4999 39.7 77.8 28 77.8C15 77.8 4.39999 61.3999 4.39999 41.2999C4.39999 21.1999 15 4.79995 28 4.79995C32 4.79995 36 6.39995 39.5 9.49995L42.2 6.39995C37.9 2.69995 33 0.699951 28 0.699951C20.4 0.699951 13.4 4.99995 8.10001 12.9C3.00001 20.5 0.199997 30.5999 0.199997 41.2999C0.199997 52 3.00001 62.1 8.10001 69.7C13.3 77.6 20.4 81.8999 28 81.8999C35.6 81.8999 42.6 77.6 47.9 69.7C52 63.5 54.6 55.6999 55.5 47.1999H51.3Z"
            fill="white"
          />
          <path
            d="M55.7 41.1999C55.7 29.7999 52.4 18.8999 46.7 11.2999C45.5 11.8999 44.4 12.7999 43.4 13.6999C48.6 20.6999 51.6 30.6999 51.6 41.1999H55.7Z"
            fill="white"
          />
          <path
            d="M63.3 29.1L60.7 31.9L68.8 39.3H12.8L20.9 31.8L18.3 29.1L5.3 41.2L18.3 53.3L20.9 50.6L12.7 43H68.9L60.7 50.6L63.3 53.3L76.3 41.2L63.3 29.1Z"
            fill="white"
          />
        </svg>
        {!!warningMessage && isReady() && <S.PageLoadTimeHint>{warningMessage}</S.PageLoadTimeHint>}
      </S.LoaderContent>
    </S.LoaderWrapper>
  );
};

export default ScreenLoader;
