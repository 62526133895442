import React from 'react';
import * as S from './SwapProcessingStatusBar.styled';
import pluralize from 'pluralize';
import Spinner from '@reface/ui/Spinner';
import useHover from 'hooks/useHover';
import getItemsByStatuses from '../../../Uploader/utils/getItemsByStatuses';
import { UploadStatus } from 'types/common';
import { IconSuccessFilled, IconWarningFilled } from '@reface/icons/20px';

type SwapProcessingStatusBarProps = {
  items: any[];
};

const SwapProcessingStatusBar: React.FC<SwapProcessingStatusBarProps> = ({ items }) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();

  const failedItems = getItemsByStatuses(items, [UploadStatus.UPLOADING_FAILED, UploadStatus.PROCESSING_FAILED]);
  const processedItems = getItemsByStatuses(items, [UploadStatus.PROCESSED]);

  const numberOfItems = items.length;
  const numberOfFailedItems = failedItems.length;
  const numberOfProcessedItems = processedItems.length;

  const areItemsFailed = !!numberOfFailedItems;
  const areAllItemsProcessed = numberOfProcessedItems === numberOfItems;

  return (
    <S.StatusBarWrapper ref={ref}>
      <S.Message $areItemsFailed={areItemsFailed}>
        {areItemsFailed && (
          <>
            {numberOfItems} {pluralize('upload', numberOfItems)}
            &nbsp;&middot;&nbsp;
            {numberOfFailedItems} {pluralize('error', numberOfFailedItems)}
          </>
        )}

        {!areItemsFailed && (
          <>
            {areAllItemsProcessed ? (
              <>
                {numberOfItems} {pluralize('swap', numberOfItems)} completed
              </>
            ) : (
              <>
                <Spinner /> {numberOfItems} {pluralize('swap', numberOfItems)} in queue
              </>
            )}
          </>
        )}
      </S.Message>

      <S.Icon>
        {areItemsFailed && <IconWarningFilled color={'error'} />}
        {!areItemsFailed && areAllItemsProcessed && <IconSuccessFilled color={'success'} />}
        {/* {!isHovered && (
          <>

          </>
        )}

        {isHovered && <Button size={ButtonSize.SMALL} icon={<IconLinkOutside />} isRounded />}*/}
      </S.Icon>
    </S.StatusBarWrapper>
  );
};

export default SwapProcessingStatusBar;
