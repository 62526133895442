import styled from 'styled-components';

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-areas: '. content';
  height: 100%;
  width: 100%;
  transition: all 1s;

  &.compact {
    grid-template-columns: 56px auto;
  }
`;

export const Content = styled.div`
  position: relative;
  max-height: 100vh;
  grid-area: content;
`;
