import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import * as S from './GridContentItem.styled';
import FolderPreview from './FolderPreview';
import VideoPreview from './VideoPreview';
import { useDrag, useDrop } from 'react-dnd';
import ImagePreview from './ImagePreview';
import AudioPreview from './AudioPreview';
import dateFormat from 'utils/dateFormat';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DropTargetHookSpec } from 'react-dnd/dist/hooks/types';
import ContentItemActionsMenu from '../ContentItemActionsMenu';
import Typography from '@reface/ui/Typography';
import { ContentRecord } from 'api/studio/content';
import cl from 'clsx';

type GridContentItemProps = {
  item: ContentRecord;
  className?: string;
  onDrop: any;
};

const getPreviewComponent = (content_type: string) => {
  switch (content_type) {
    case 'folder':
      return FolderPreview;
    case 'video':
      return VideoPreview;
    case 'image':
      return ImagePreview;
    case 'audio':
      return AudioPreview;
    default:
      return FolderPreview;
  }
};

const GridContentItem: React.FC<GridContentItemProps> = ({ item, className, onDrop }) => {
  const ref = useRef<HTMLDivElement>(null!);
  const params = useParams<{ asset_type: string }>();
  const history = useHistory();
  const { object_id, content_type } = item;
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const PreviewComponent = getPreviewComponent(content_type);
  const isFolder = content_type === 'folder';
  const asset_type = params.asset_type || 'content';
  const canDropChecker = useCallback(() => content_type === 'folder', [content_type]);

  const handleOnDrop: DropTargetHookSpec<{ object_id: string }, unknown, unknown>['drop'] = useCallback(
    (item, monitor) => {
      onDrop(item, { object_id }, monitor);
    },
    [onDrop, object_id]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['video', 'image', 'folder', 'audio'],
    drop: handleOnDrop,
    options: {
      object_id,
    },
    canDrop: canDropChecker,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: content_type,
    item: () => ({ object_id }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isNavigateAvailable = asset_type === 'content' || content_type === 'folder';

  const detailsLink = useMemo(() => {
    if (content_type === 'folder') {
      return `/files/${asset_type}/${object_id}`;
    } else {
      return `/swap/${object_id}`;
    }
  }, [content_type, object_id, asset_type]);

  const handleClick = useCallback(
    (e) => {
      // handle doubleclick event
      if (e.detail === 2) {
        e.stopPropagation();
        e.preventDefault();
        isNavigateAvailable && history.push(detailsLink);
      }
    },
    [detailsLink, isNavigateAvailable]
  );
  const titleProps = useMemo(
    () =>
      isNavigateAvailable
        ? {
            as: Link,
            to: detailsLink,
          }
        : {},
    [isNavigateAvailable]
  );

  const contentDuration = useMemo(() => {
    const duration = +item.ffprobe?.format?.duration;

    return duration ? `${duration.toFixed(1)}s` : content_type;
  }, [item]);

  return (
    <S.SelectableWrapper
      $isOver={isOver}
      $canDrop={canDrop}
      className={cl(className, { active: isMenuOpened })}
      data-key={object_id}
      ref={ref}
    >
      <S.Wrapper $folder={isFolder} $isDragging={isDragging} role={'button'} onClick={handleClick}>
        {item.data?.persons && <S.ContentPersons persons={item.data.persons} variant={'light'} />}
        <S.PreviewContainer>
          <PreviewComponent src={item.origin_path} alt={item.title} object_id={object_id} />
        </S.PreviewContainer>
        <S.Details $bg={content_type !== 'folder'}>
          <S.Title>
            <Typography truncate {...titleProps}>
              {item.title}
            </Typography>
            {content_type !== 'folder' && <S.ContentDuration>{contentDuration}</S.ContentDuration>}
          </S.Title>
          <S.DetailsControls>
            <S.CreatedDate truncate>{dateFormat(item.created_date)}</S.CreatedDate>

            {content_type !== 'folder' && <S.ContentDuration>{contentDuration}</S.ContentDuration>}
            <ContentItemActionsMenu item={item} onIsOpenChanged={setIsMenuOpened} />
          </S.DetailsControls>
        </S.Details>
      </S.Wrapper>
    </S.SelectableWrapper>
  );
};

export default memo(GridContentItem);
