import { useDispatch, useSelector } from 'react-redux';
import {
  selectSwapProcessingItems,
  addSwapProcessingItems,
  clearSwapProcessing,
  removeSwapProcessingItems,
} from 'store/processing/swap';
import { useCallback, useEffect } from 'react';

const usePersistedSwapProcessingItems = () => {
  const persistedProcessingItems = useSelector(selectSwapProcessingItems);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('usePersistedProcessingItems', persistedProcessingItems);
  }, [persistedProcessingItems]);

  const dispatchAddProcessingItem = useCallback((swap_ids: string[]) => {
    dispatch(addSwapProcessingItems(swap_ids));
  }, []);

  const dispatchRemoveProcessingItem = useCallback((swap_ids: string[]) => {
    dispatch(removeSwapProcessingItems(swap_ids));
  }, []);

  const dispatchClearProcessing = useCallback(() => {
    dispatch(clearSwapProcessing());
  }, []);

  return [
    persistedProcessingItems,
    { dispatchAddProcessingItem, dispatchRemoveProcessingItem, dispatchClearProcessing },
  ] as const;
};

export default usePersistedSwapProcessingItems;
