import React, { useState } from 'react';
import * as S from './ImageEffectsPreview.styled';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { ADD_SWAP_EFFECT_MODAL, AddSwapEffectModalPayload, TAB_NAMES } from '../AddSwapEffectModal';
import AppliedPersonEffectsRow from './AppliedPersonEffectsRow';
import PreviewSizeDropdown from '../PreviewSizeDropdown';

type ImageEffectsPreviewProps = {
  persons: {
    person_id: string;
    preview_url: string;
  }[];
  appliedMapping: any;
};

const ImageEffectsPreview: React.FC<ImageEffectsPreviewProps> = ({ persons, appliedMapping }) => {
  const [openModal] = useModalManager();
  const [previewSize, setPreviewSize] = useState<number>(48);

  const handleAddEffect = (person_id: string, initialActiveTab: TAB_NAMES = TAB_NAMES.FACE) => {
    openModal<AddSwapEffectModalPayload>(ADD_SWAP_EFFECT_MODAL, {
      faces: persons,
      initialApplyTo: [person_id],
      appliedMapping: appliedMapping[person_id],
      initialActiveTab,
    });
  };

  return (
    <S.Wrapper>
      <S.PreviewSizePicker>
        <PreviewSizeDropdown value={previewSize} onChange={(nextSize) => setPreviewSize(nextSize as number)} />
      </S.PreviewSizePicker>

      {persons.map((person) => (
        <AppliedPersonEffectsRow
          key={person.person_id}
          onClick={handleAddEffect}
          person={person}
          mapping={appliedMapping[person.person_id]}
          size={previewSize}
        />
      ))}
    </S.Wrapper>
  );
};

export default ImageEffectsPreview;
