import { RouteId } from 'types/route';

import SignIn from './SignIn';
import SignOut from './SignOut';

export const AUTH_PAGES_ROUTES_BY_ID = {
  [RouteId.SIGN_IN]: {
    id: RouteId.SIGN_IN,
    path: '/sign-in',
    component: SignIn,
    isExact: true,
  },
  [RouteId.SIGN_OUT]: {
    id: RouteId.SIGN_OUT,
    path: '/sign-out',
    component: SignOut,
    isExact: true,
  },
};
