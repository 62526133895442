import styled from 'styled-components';

export const SignIn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  // NOTE: add 'margin-top' for visual centering
  margin-top: -100px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.gray[90]};

  span {
    margin-left: 4px;
    // NOTE: add 'margin-right' for visual centering
    margin-right: 16px;
  }
`;
