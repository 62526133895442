import React, { memo, useEffect, useRef } from 'react';
import * as S from './SwapVideoContent.styled';
import Rulez from 'utils/rulez';

type FilmstripRullerProps = {
  duration: number;
  width: number;
  offset: {
    left: number;
    right: number;
  };
};

const getNodePosition = (node: Element) => Math.round(parseFloat(node.getAttribute('x')));

const FilmstripRuller: React.FC<FilmstripRullerProps> = ({ duration, offset, width }) => {
  const rullerRef = useRef<SVGSVGElement>(null!);

  useEffect(() => {
    const bounding = rullerRef.current.getBoundingClientRect();

    const renderDuration = duration || 10;
    const width = bounding.width - offset.left - offset.right;
    const pxPerSecond = Math.ceil(width / renderDuration);

    const rulez = new Rulez({
      element: rullerRef.current,
      units: 'px',
      alignment: 'top',
      additionalDivisionsAmount: Math.ceil(139 / pxPerSecond),
      textDefaults: {
        offset: 0,
      },
      divisions: [
        {
          pixelGap: pxPerSecond / 5,
          lineLength: 2,
          renderer(node) {
            node.setAttribute('y', '5');
            // console.log(getNodePosition(node), pxPerSecond * 2);
            const xPos = getNodePosition(node);
            if (xPos % (pxPerSecond * 2) === 0 && xPos > 0) {
              node.setAttribute('visibility', 'hidden');
            }
          },
        },
        {
          pixelGap: pxPerSecond,
          lineLength: 8,
          renderer(node) {
            node.setAttribute('y', '1');
            const xPos = getNodePosition(node);
            if (xPos % (pxPerSecond * 2) == 0 || xPos < 0) {
              node.setAttribute('visibility', 'hidden');
            }
          },
        },
      ],
      texts: [
        {
          pixelGap: pxPerSecond * 2,
          offset: 10,
          renderer(node) {
            const xPos = getNodePosition(node);

            if (xPos < 0) {
              node.setAttribute('visibility', 'hidden');
            }

            node.textContent = `${Math.floor(xPos / pxPerSecond)}`;
          },
        },
      ],
    });

    rulez.render();

    return () => {
      while (rullerRef.current && rullerRef.current.lastChild) {
        rullerRef.current.removeChild(rullerRef.current.lastChild);
      }
    };
  }, [duration, width]);

  return <S.Ruller ref={rullerRef} width={width} />;
};

export default memo(FilmstripRuller);
