import { createSlice } from '@reduxjs/toolkit';
import { pushHistoryRecord, UNDO } from './historyActions';

const initialState: {
  actionType: string;
  payload: any;
}[] = [];

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(pushHistoryRecord, (state, action) => {
      console.log(action.payload);
      state.push(action.payload);
    });

    builder.addCase(UNDO.fulfilled, (state) => {
      state.pop();
    });
  },
});

export default historySlice;
