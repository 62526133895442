import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SwapQueueContext from './SwapQueueContext';
import usePersistedSwapProcessingItems from './hooks/usePersistedSwapProcessingItems';

const SwapQueue: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<string[]>([]);
  const [persistedItems, { dispatchAddProcessingItem, dispatchRemoveProcessingItem, dispatchClearProcessing }] =
    usePersistedSwapProcessingItems();

  useEffect(() => {
    setItems(persistedItems);
  }, []);

  const add = useCallback((...swap_ids: string[]) => {
    if (!swap_ids.length) {
      return;
    }
    console.log('add queue handler', swap_ids);
    dispatchAddProcessingItem(swap_ids);
    setItems((prev) => [...prev, ...swap_ids]);
  }, []);

  const reset = useCallback(() => {
    dispatchClearProcessing();

    setItems([]);
  }, []);

  const remove = useCallback((...swap_ids: string[]) => {
    if (!swap_ids.length) {
      return;
    }
    dispatchRemoveProcessingItem(swap_ids);

    setItems((prev) => prev.filter((swap_id) => !swap_ids.includes(swap_id)));
  }, []);

  const value = useMemo(
    () => ({
      items: items,
      add,
      reset,
      remove,
    }),
    [items, add, reset, remove]
  );

  return <SwapQueueContext.Provider value={value}>{children}</SwapQueueContext.Provider>;
};

export default SwapQueue;
