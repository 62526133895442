import styled from 'styled-components';

export const Button = styled.button<{ $visible: boolean }>`
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  transition: background-color 0.3s ease-out;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[20]};
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;
