import React, { ComponentProps, memo } from 'react';
import ContentPersonsDropdown from '../../../../Swap/components/AddSwapEffectModal/ContentPersonsDropdown';
import { Controller, ControllerProps } from 'react-hook-form';

type ContentPersonsForSwapProps = Pick<ComponentProps<typeof ContentPersonsDropdown>, 'persons'> &
  Pick<ControllerProps, 'control' | 'name' | 'defaultValue'>;

const ContentPersonsForSwap: React.FC<ContentPersonsForSwapProps> = ({ persons, name, control, defaultValue }) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <ContentPersonsDropdown persons={persons} selected={field.value} setSelectedChange={field.onChange} />
    )}
  />
);

export default memo(ContentPersonsForSwap);
