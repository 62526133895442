import React, { useMemo, useRef } from 'react';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconCheck, IconArrowSortDown, IconArrowSortUp } from '@reface/icons/20px';
import ContextMenu from '../ContextMenu';
import * as S from './DropdownSelect.styled';

export type DropdownOption = {
  value: string | number;
  label: string;
  key: string;
  description?: string;
};

type DropdownSelectProps = {
  options: DropdownOption[];
  value: DropdownOption;
  onChange: (resolution: any) => void;
  selectedChecker?: typeof defaultSelectedChecker;
  renderAnchor?: (props: { isOpen: boolean }) => React.ReactNode;
};

type MenuItemProps<T> = { onSelect: (value: T) => void; value: T; children?: React.ReactNode };

const MenuItem: React.FC<MenuItemProps<any>> = ({ onSelect, children, value }) => {
  const onClick = () => {
    onSelect(value);
  };
  return <S.DropdownMenuItem onClick={onClick}>{children}</S.DropdownMenuItem>;
};

const defaultSelectedChecker = (option: DropdownOption, value: DropdownOption) => option.value === value.value;

const DropdownSelect: React.FC<DropdownSelectProps> = (props) => {
  const { selectedChecker = defaultSelectedChecker, renderAnchor } = props;

  const anchorRef = useRef<HTMLDivElement>(null!);

  const handleSelect = (value: any) => {
    props.onChange(value);
  };

  const currentOption = useMemo(() => props.options.find((v) => selectedChecker(v, props.value)), [props.value]);

  return (
    <S.DropdownSelectWrapper>
      <ContextMenu
        {...props}
        renderAnchor={({ isOpen }) => (
          <S.DropdownAnchor ref={anchorRef}>
            {renderAnchor ? (
              renderAnchor({ isOpen })
            ) : (
              <Button variant={ButtonVariant.SECONDARY} endIcon={isOpen ? <IconArrowSortUp /> : <IconArrowSortDown />}>
                {currentOption ? currentOption.label : `Custom`}
              </Button>
            )}
          </S.DropdownAnchor>
        )}
      >
        {({ close }) => (
          <>
            {props.options.map((option) => (
              <MenuItem
                onSelect={(value) => {
                  handleSelect(value);
                  close();
                }}
                value={option.value}
                key={option.key}
              >
                <S.MenuItemLabel>{option.label}</S.MenuItemLabel>
                {option.description && <S.MenuItemRight>{option.description}</S.MenuItemRight>}
                <S.MenuItemSelected>{option === currentOption && <IconCheck />}</S.MenuItemSelected>
              </MenuItem>
            ))}
          </>
        )}
      </ContextMenu>
    </S.DropdownSelectWrapper>
  );
};

export default DropdownSelect;
