import { combineReducers } from '@reduxjs/toolkit';
import loadingSlice from './editor/loading';
import content from './content';
import files from './files';
import assets from './assets/assetsSlice';
import swap from './swap';
import history from './history';

export default combineReducers({
  editor: loadingSlice.reducer,
  content: content,
  files: files,
  swap: swap,
  assets: assets.reducer,
  history: history,
});
