import React from 'react';

import Button, { ButtonSize, ButtonColor } from '@reface/ui/Button';
import Modal from '@reface/ui/Modal';

import { useModalContext } from '@reface/shared/components/ModalManager';
import { useDispatch } from 'react-redux';
import { archiveContentThunk } from 'store/pages/content/contentActions';
import Typography from '@reface/ui/Typography';
import ButtonGroup from '@reface/ui/ButtonGroup';

export const DELETE_CONTENT_CONFIRMATION_MODAL = 'DELETE_CONTENT_CONFIRMATION_MODAL';

const STYLES = {
  modal: {
    width: 512,
  },
};

type ChangeContentTitleModalProps = {
  name?: string;
  onConfirm?: () => void;
};

export type ModalPayloadValue = string[];

const DeleteContentConfirmationModal: React.FC<ChangeContentTitleModalProps> = ({
  name = DELETE_CONTENT_CONFIRMATION_MODAL,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const { close: handleClose, isOpen, value } = useModalContext<ModalPayloadValue>(name);

  const handleConfirm = async () => {
    handleClose();

    dispatch(archiveContentThunk(value!));
    onConfirm && onConfirm();
  };

  return (
    <Modal
      styles={STYLES}
      title="Delete Content?"
      actions={({ defaultActionRef }) => (
        <ButtonGroup>
          <Button ref={defaultActionRef} size={ButtonSize.LARGE} color={ButtonColor.DANGER} onClick={handleConfirm}>
            Delete
          </Button>
          <Button size={ButtonSize.LARGE} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <Typography variant={'body1'}>Are you sure you want to delete {value?.length} items</Typography>
    </Modal>
  );
};

export default DeleteContentConfirmationModal;
