import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SignInResponse } from 'api/auth';
import { proxyApi } from 'services/http';
import { RootState } from 'store';
import { AccessToken, AccessTokenExpirationTime, AuthStatus, FirebaseAuthStatus, FirebaseToken } from 'types/auth';
import decodeAccessToken from 'utils/auth/decodeAccessToken';

import { signInThunk, signOutThunk } from './api/auth';
import { fetchMeThunk } from './api/users';

export type AuthState = {
  firebaseAuthStatus: FirebaseAuthStatus;
  authStatus: AuthStatus;
  firebaseToken: FirebaseToken;
  accessToken: AccessToken;
  token: AccessToken;
  accessTokenExpirationTime: AccessTokenExpirationTime;
  me?: any;
};

const getState: (state: RootState) => AuthState = (state) => state.auth;

const name = 'auth/auth';

const initialState: AuthState = {
  firebaseAuthStatus: FirebaseAuthStatus.PENDING,
  authStatus: AuthStatus.PENDING,
  firebaseToken: '',
  accessToken: '',
  token: '',
  accessTokenExpirationTime: 0,
  me: undefined,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setFirebaseAuthStatus(state, { payload }: PayloadAction<FirebaseAuthStatus>) {
      state.firebaseAuthStatus = payload;
    },

    setFirebaseToken(state, { payload }: PayloadAction<FirebaseToken>) {
      state.firebaseToken = payload;
    },

    setAuthStatus(state, { payload }: PayloadAction<AuthStatus>) {
      state.authStatus = payload;
    },

    setAccessToken(state, { payload }: PayloadAction<AccessToken>) {
      state.accessToken = payload;
    },

    setAndDecodeAccessToken(state, { payload }: PayloadAction<AccessToken>) {
      const { accessToken, accessTokenExpirationTime } = decodeAccessToken(payload);

      return {
        ...state,
        accessToken,
        accessTokenExpirationTime,
      };
    },
  },
  extraReducers: (builder) => {
    // sign-in
    builder.addCase(signInThunk.pending, (state) => {
      state.authStatus = AuthStatus.SIGNING_IN;
    });

    builder.addCase(signInThunk.fulfilled, (state, { payload }: PayloadAction<SignInResponse>) => {
      const { accessToken, accessTokenExpirationTime } = decodeAccessToken(payload.access_token);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      proxyApi.defaults.headers.Authorization = `Bearer ${payload.token}`;

      return {
        ...state,
        authStatus: AuthStatus.SIGNED_IN,
        accessToken,
        accessTokenExpirationTime,
        token: payload.token,
      };
    });

    builder.addCase(signInThunk.rejected, (state) => {
      state.authStatus = AuthStatus.SHOULD_BE_SIGNED_OUT;
    });

    // sign-out
    builder.addCase(signOutThunk.pending, (state) => {
      state.authStatus = AuthStatus.SIGNING_OUT;
    });

    builder.addCase(signOutThunk.fulfilled, (state) => {
      const { accessToken, accessTokenExpirationTime, me } = initialState;
      return {
        ...state,
        authStatus: AuthStatus.SIGNED_OUT,
        accessToken: accessToken,
        accessTokenExpirationTime: accessTokenExpirationTime,
        me,
      };
    });

    builder.addCase(signOutThunk.rejected, (state) => {
      const { accessToken, accessTokenExpirationTime, me } = initialState;

      return {
        ...state,
        authStatus: AuthStatus.SIGNED_OUT,
        accessToken: accessToken,
        accessTokenExpirationTime: accessTokenExpirationTime,
        me,
      };
    });

    // me
    builder.addCase(fetchMeThunk.fulfilled, (state, { payload }: PayloadAction<any>) => {
      state.me = payload;
    });
  },
});

export { getState as getAuthState };

export const { setFirebaseAuthStatus, setFirebaseToken, setAuthStatus, setAccessToken, setAndDecodeAccessToken } =
  slice.actions;

export default slice.reducer;
