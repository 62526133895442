import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlainHistoryThunk, getPlainHistoryEntries, SwapHistoryRecord } from 'store/pages/history/plain';
import useContentSelection from '../Files/hooks/useContentSelection';
import { getType } from 'mime/lite';
import * as S from './History.styled';
import { Column } from 'react-table';
import ContentToolbar from '../Files/components/ContentToolbar/ContentToolbar';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Typography from '@reface/ui/Typography';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '@reface/ui/Button';
import {
  IconBulkSwap,
  IconDownload,
  IconLinkOutside,
  IconSuccessFilled,
  IconTrash,
  IconWarningFilled,
} from '@reface/icons/20px';
import DeleteHistoryConfirmationModal, {
  DELETE_HISTORY_CONFIRMATION_MODAL,
} from './components/DeleteHistoryConfirmationModal';
import Table, { TableHandle } from '../../components/Table/Table';
import { downloadSwapHistoryThunk } from '../../store/pages/history/bulk';
import { Link } from 'react-router-dom';
import dateFormat from '../../utils/dateFormat';
import Pagination from '@reface/ui/Pagination';
import ContentViewGroup, { VIEW_VARIANT } from './components/ContentViewGroup';
import Tooltip from '../../components/Tooltip';
import { WindowScroller } from 'react-virtualized';
import HistoryPreviewGalleryModal from './components/HistoryPreviewGalleryModal';
import {
  HISTORY_PREVIEW_GALLERY_MODAL,
  ModalPayloadValue,
} from './components/HistoryPreviewGalleryModal/HistoryPreviewGalleryModal';
import ContentEmpty from 'components/ContentEmpty';
import { EmptyContentImage } from 'static/images';

const RowActionsCell: React.FC<{
  value: {
    swap_id: string;
    object_id: string;
  };
}> = ({ value: { swap_id, object_id } }) => {
  const dispatch = useDispatch();
  const [openModal] = useModalManager();

  const handleHistoryDeleteClick = (operation_key: string) => {
    openModal(DELETE_HISTORY_CONFIRMATION_MODAL, {
      operation_key,
    });
  };

  const handleBulkDownloadClick = (object_id: string) => {
    dispatch(
      downloadSwapHistoryThunk({
        object_id,
        with_object: true,
      })
    );
  };

  return (
    <S.RowActionsGroup onClick={(e) => e.stopPropagation()}>
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconLinkOutside />}
        component={Link}
        to={`/swap/${object_id}?swap_id=${swap_id}`}
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconDownload />}
        onClick={() => handleBulkDownloadClick(object_id)}
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconTrash />}
        onClick={(e) => {
          handleHistoryDeleteClick(swap_id);
        }}
      />
    </S.RowActionsGroup>
  );
};

const PlainHistoryEmptyScreen = () => (
  <ContentEmpty
    icon={EmptyContentImage}
    title="Nothing available for swap"
    subtitle="Here you’ll find history of all bulk swap operations. Just start using them."
  />
);

const RECORDS_ON_PAGE = 25;

const PlainSwapHistoryTable: React.FC<{
  search?: {
    operation_key?: string;
    object_id?: string;
  };
}> = ({ search }) => {
  const tableRef = useRef<TableHandle>(null!);

  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState<VIEW_VARIANT>(VIEW_VARIANT.TABLE);
  const [openModal] = useModalManager();
  const [page, setPage] = useState(1);

  const [historyEntries, total] = useSelector(getPlainHistoryEntries);

  const { selected, setSelected, selectableRef, onSelectionEnded, onSelectionStart, onSelectionMove, clearSelection } =
    useContentSelection();

  useEffect(() => {
    dispatch(
      fetchPlainHistoryThunk({
        offset: (page - 1) * RECORDS_ON_PAGE,
        limit: RECORDS_ON_PAGE,
        ...search,
      })
    );
  }, [page]);

  useEffect(() => {
    tableRef.current.cache.clearAll();
  }, [viewMode]);

  const columns = useMemo(() => {
    const handlePreviewGalleryOpen = (e) => {
      e.stopPropagation();
      console.log('handlePreviewGalleryOpen');
      openModal<ModalPayloadValue>(HISTORY_PREVIEW_GALLERY_MODAL, {
        history: historyEntries,
        total: total,
      });
    };

    return [
      {
        id: 'preview',
        accessor: ({ swap_path, origin_object }) => ({
          swap_path,
          origin_path: origin_object?.origin_path,
        }),
        Cell: ({ value }: { value: { swap_path: string; origin_path: string } }) => (
          <div role={'button'} onClick={handlePreviewGalleryOpen}>
            {getType(value.origin_path)?.includes('image/') ? (
              <S.ContentPreview src={value.origin_path} $viewMode={viewMode} />
            ) : (
              <S.ContentPreview src={value.origin_path} as={'video'} $viewMode={viewMode} />
            )}
            {getType(value.swap_path)?.includes('image/') ? (
              <S.ContentPreview src={value.swap_path} $viewMode={viewMode} />
            ) : (
              <S.ContentPreview src={value.swap_path} as={'video'} $viewMode={viewMode} />
            )}
          </div>
        ),
        width: viewMode === 'large' ? 578 : 200,
      },
      {
        accessor: 'origin_object',
        Cell: ({ value }) => <Typography truncate>{value?.title}</Typography>,
      },

      {
        accessor: 'status',
        Cell: ({ value }) => (
          <>
            {value === 0 && <IconSuccessFilled color={'success'} />}
            {value === 2 && <IconWarningFilled color={'error'} />}
          </>
        ),
        width: 64,
      },
      {
        accessor: 'created_date',
        Cell: ({ value }) => (
          <Typography truncate variant={'body2'}>
            {dateFormat(value)}
          </Typography>
        ),
      },
      {
        accessor: 'operation_key',
        Cell: ({ value }) =>
          !!value && (
            <Tooltip content={'Show whole bulk swap group'}>
              <Button
                variant={ButtonVariant.SECONDARY}
                icon={<IconBulkSwap />}
                component={Link}
                to={`/history/search?operation_key=${value}`}
              />
            </Tooltip>
          ),
      },
      {
        id: 'actions',
        accessor: ({ swap_id, object_id }) => ({
          swap_id,
          object_id,
        }),
        Cell: RowActionsCell,
        width: 200,
      },
    ] as Column<SwapHistoryRecord>[];
  }, [viewMode, historyEntries]);

  const statisticString = '';

  const handleBulkDeleteClick = async () => {
    openModal(DELETE_HISTORY_CONFIRMATION_MODAL, {
      swap_ids: Array.from(selected),
    });
  };

  return (
    <>
      <ContentToolbar controls={<ContentViewGroup value={viewMode} onChange={setViewMode} />}>
        <ButtonGroup>
          {selected.size ? (
            <>
              <Typography variant={'body2'}>{selected.size} files selected</Typography>
              <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL} onClick={() => clearSelection()}>
                Unselect
              </Button>
              ·
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                color={ButtonColor.DANGER}
                icon={<IconTrash />}
                onClick={handleBulkDeleteClick}
              >
                Delete
              </Button>
            </>
          ) : (
            <>
              <Typography variant={'body2'}>{statisticString}</Typography>
            </>
          )}
        </ButtonGroup>
      </ContentToolbar>
      <WindowScroller>
        {(listProps) => (
          <S.TableWrapper
            ref={selectableRef}
            onStart={onSelectionStart}
            onEnded={onSelectionEnded}
            onMove={onSelectionMove}
            selectables=".selectable"
          >
            <Table
              ref={tableRef}
              columns={columns}
              items={historyEntries}
              hasMore={false}
              isLoadingMore={false}
              noHeader={true}
              getRowProps={(row) => ({
                isSelected: selected.has(row.original.swap_id),
              })}
              getRowDataKey={(row) => row.original.swap_id}
              listProps={listProps}
              renderEmptyScreen={PlainHistoryEmptyScreen}
              footer={
                <Pagination
                  total={total}
                  limit={RECORDS_ON_PAGE}
                  page={page}
                  onChange={(nextPage) => setPage(nextPage)}
                />
              }
            />
          </S.TableWrapper>
        )}
      </WindowScroller>
      <DeleteHistoryConfirmationModal onConfirm={clearSelection} />
      <HistoryPreviewGalleryModal />
    </>
  );
};

export default PlainSwapHistoryTable;
