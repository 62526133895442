import styled, { css } from 'styled-components';

import { TabType } from '../Tab';
import { Tab } from '../Tab/Tab.styled';

import { TabsProps } from './Tabs';

export const Tabs = styled.div<{ $type: TabsProps['type'] }>`
  align-items: center;

  ${({ $type }) =>
    $type === TabType.NORMAL &&
    css`
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[20]}};

      ${Tab} + ${Tab} {
        margin-left: 16px;
      }
    `}

  ${({ $type }) =>
    $type === TabType.COMPACT &&
    css`
      display: inline-flex;
      padding: 5px 12px;
      border: 1px solid ${({ theme }) => theme.colors.gray[20]};
      background: ${({ theme }) => theme.colors.gray[5]};
      border-radius: 6px;
    `}
`;
