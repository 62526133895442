import { createSlice } from '@reduxjs/toolkit';
import { ContentRecord } from 'api/studio/content';
import { fetchDetailedMediaRecordThunk } from './detailedActions';

interface DetailedState {
  data: ContentRecord | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  data: null,
  loading: 'idle',
} as DetailedState;

const detailedSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers(builder) {
    builder.addCase(fetchDetailedMediaRecordThunk.pending, (state) => {
      state.data = null;
      state.loading = 'idle';
    });
    builder.addCase(fetchDetailedMediaRecordThunk.fulfilled, (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
    });
  },
});

export default detailedSlice;
