import styled from 'styled-components';

export const FacesGridWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  width: 100%;
  margin: -8px;
`;

export const FaceGridItem = styled.li`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  margin: 8px;
  overflow: hidden;
  border: 2px solid transparent;

  &.active {
    color: white;
    background: ${({ theme }) => theme.colors.gray[10]};
    border: ${({ theme }) => `2px solid ${theme.colors.blue[80]}`};
  }
`;

export const FacePreviewImage = styled.img`
  flex-grow: 1;
  object-fit: cover;
`;

export const Loader = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
`;
