import qs from 'qs';

import store from 'store';
import { addToast } from 'store/toasts';
import { ToastError, ToastType } from 'types/toast';

import { createHttp } from './createHttp';

export const gcsApi = createHttp();

const authApi = createHttp({
  config: {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `/api`,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
  },
  interceptors: {
    response: {
      onRejected: (error) => {
        const { dispatch } = store;

        dispatch(
          addToast({
            type: ToastType.ERROR,
            url: error.config.url,
            error: error.message,
            message: error.response?.data,
          } as ToastError)
        );

        return Promise.reject(error);
      },
    },
  },
});

const proxyApi = createHttp({
  config: {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `/api`,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
  },
  interceptors: {
    response: {
      onRejected: (error) => {
        const { dispatch } = store;

        dispatch(
          addToast({
            type: ToastType.ERROR,
            url: error.config.url,
            error: error.message,
            message: error.response?.data,
          } as ToastError)
        );

        return Promise.reject(error);
      },
    },
  },
});

export { proxyApi, authApi };
