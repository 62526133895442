import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import iconMenu from '@reface/icons/36px/Menu.svg';
import iconLogo from 'static/images/logo.svg';

export const LogoContainer = styled.div`
  height: 64px;
  margin-bottom: 12px;
  display: inline-flex;
  align-items: center;
  padding: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const LogoImage = styled.div`
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-position: center center;
  background-image: url('${iconLogo}');
  cursor: pointer;
  transition: background 0.3s;
  -webkit-transition: background 0.3s;

  &:hover {
    background-image: url('${iconMenu}');
  }
`;

export const LogoLabel = styled.div`
  margin-left: 8px;
`;

export const SidebarFooter = styled.div`
  height: 64px;
`;

export const MenuItem = styled(NavLink)`
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }

  & > svg {
    margin-right: 4px;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }
`;

export const MenuItemLabel = styled.span``;

export const MenuItems = styled.div`
  padding: 0 8px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > ${MenuItem} {
    margin: 2px 0;
    color: white;
  }
`;

export const NestedMenuItems = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > ${MenuItem} {
    margin: 0;
    padding-left: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[40]};
  }

  & > ${MenuItem}.active {
    color: white;
  }
`;

export const Wrapper = styled.div<{ $compact: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background-color: ${({ theme }) => theme.colors.gray[5]};
  width: 240px;

  ${({ $compact }) =>
    !!$compact &&
    css`
      width: 56px;

      ${LogoContainer} {
        padding: 16px 12px;
      }

      ${LogoLabel}, ${NestedMenuItems}, ${MenuItemLabel} {
        display: none;
      }
    `}
`;
