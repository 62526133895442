export enum FirebaseAuthStatus {
  PENDING = 'PENDING',
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
}

export enum AuthStatus {
  PENDING = 'PENDING',
  SHOULD_BE_SIGNED_IN = 'SHOULD_BE_SIGNED_IN',
  SIGNING_IN = 'SIGNING_IN',
  SIGNED_IN = 'SIGNED_IN',
  SHOULD_BE_SIGNED_OUT = 'SHOULD_BE_SIGNED_OUT',
  SIGNING_OUT = 'SIGNING_OUT',
  SIGNED_OUT = 'SIGNED_OUT',
}

export type FirebaseToken = string;

export type AccessToken = string;

export type AccessTokenExpirationTime = number;

export type DecodedAccessToken = {
  accessToken: AccessToken;
  accessTokenExpirationTime: AccessTokenExpirationTime;
};
