import { AsyncThunk, createAsyncThunk, createSlice, Draft, PayloadAction, Slice } from '@reduxjs/toolkit';
import debounce from 'debounce-promise';

import { ApiRequestState } from 'store';

const createApiRequestSlice = <ApiRequestPayload, ApiRequestResponse>(
  name: string,
  apiRequest: (payload: ApiRequestPayload) => Promise<ApiRequestResponse>,
  debouncedDelay = 0
): [
  asyncThunk: AsyncThunk<ApiRequestResponse, ApiRequestPayload, Record<string, unknown>>,
  slice: Slice<ApiRequestState<ApiRequestResponse>>,
  debouncedDelay?: number
] => {
  const initialState: ApiRequestState<ApiRequestResponse> = {
    isLoading: false,
  };

  const asyncThunk = createAsyncThunk(name, debouncedDelay ? debounce(apiRequest, debouncedDelay) : apiRequest);

  const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(asyncThunk.pending, (state) => {
        state.isLoading = true;
      });

      builder.addCase(asyncThunk.fulfilled, (state, { payload }: PayloadAction<ApiRequestResponse>) => {
        state.isLoading = false;
        state.data = payload as Draft<ApiRequestResponse>;
      });

      builder.addCase(asyncThunk.rejected, (state) => {
        state.isLoading = false;
      });
    },
  });

  return [asyncThunk, slice, 0];
};

export default createApiRequestSlice;
