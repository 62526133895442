import React from 'react';

import { IconLoader } from '@reface/icons/20px';

import * as S from './Spinner.styled';
import { SpinnerArgs } from './Spinner.styled';

export type SpinnerProps = Partial<SpinnerArgs>;

const Spinner: React.FC<SpinnerProps> = ({ size = 20 }) => (
  <S.SpinnerWrapper size={size}>
    <IconLoader width={size} height={size} />
  </S.SpinnerWrapper>
);

export default Spinner;
