import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
// import modals from './modals';
// import sidebarMenu from './sidebarMenu';
import pages from './pages';
import historySlice from './history/historySlice';
import toasts from './toasts';
import auth from './auth';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import history from './middlewares/history';
import processing from './processing';
import { AnyAction } from 'redux';

const reducers = combineReducers({
  auth,
  history: historySlice.reducer,
  // modals,
  pages,
  processing,
  // sidebarMenu,
  toasts,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['pages', 'processing'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type ApiRequestState<ApiRequestResponse> = {
  isLoading: boolean;
  data?: ApiRequestResponse;
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(history),
  enhancers: [sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
