import styled, { css } from 'styled-components';
import hexToRGB from '../../utils/hexToRGB';

export const Hint = styled.div<{ $isDragging: boolean }>`
  @keyframes fade-in {
    0% {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
      visibility: visible;
    }

    100% {
      color: ${({ theme }) => theme.colors.gray[90]};
      background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.7)};
      border-color: ${({ theme }) => theme.colors.blue[100]};
    }
  }

  @keyframes fade-out {
    0% {
      color: ${({ theme }) => theme.colors.gray[90]};
      background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.7)};
      border-color: ${({ theme }) => theme.colors.blue[100]};
      visibility: visible;
    }

    100% {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
    }
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border: 2px dashed transparent;
  border-radius: 8px;
  user-select: none;
  z-index: 999;

  ${({ $isDragging }) =>
    $isDragging
      ? css`
          color: ${({ theme }) => theme.colors.gray[90]};
          background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.7)};
          border-color: ${({ theme }) => theme.colors.blue[100]};
          visibility: visible;
          animation: fade-in 0.3s;
        `
      : css`
          color: transparent;
          background-color: transparent;
          border-color: transparent;
          visibility: hidden;
          animation: fade-out 0.3s;
        `}}
`;
