import React, { useState } from 'react';
import {
  IconAlignLeft,
  IconAlignCenter,
  IconAlignRight,
  IconStyleBold,
  IconStyleItalic,
  IconFontFamily,
} from '@reface/icons/20px';
import Button, { ButtonSize } from '@reface/ui/Button';
import * as S from './TextStyles.styled';

type TextStylesProps = {
  onChange: (styles: any) => void;
  value: {
    fontStyle?: string;
    fontWeight?: number;
    textAlign?: string;
    backgroundColor?: string;
  };
};

const TextStyles: React.FC<TextStylesProps> = ({ value, onChange }) => {
  const [styles, setStyles] = useState<TextStylesProps['value']>(value);
  const handleChange = (patch: Partial<TextStylesProps['value']>) => {
    console.log('text:style', patch);
    setStyles((style) => ({
      ...style,
      ...patch,
    }));
    onChange(patch);
  };
  const handleBackgroundToggleClick = () => {
    handleChange({
      backgroundColor: !styles.backgroundColor ? '#ffffff' : '',
    });
  };

  return (
    <S.TextStylesWrapper>
      <S.ActiveButton $isActive={styles.textAlign === 'left'}>
        <Button onClick={() => handleChange({ textAlign: 'left' })} size={ButtonSize.SMALL} icon={<IconAlignLeft />} />
      </S.ActiveButton>

      <S.ActiveButton $isActive={styles.textAlign === 'center'}>
        <Button
          onClick={() => handleChange({ textAlign: 'center' })}
          size={ButtonSize.SMALL}
          icon={<IconAlignCenter />}
        />
      </S.ActiveButton>

      <S.ActiveButton $isActive={styles.textAlign === 'right'}>
        <Button
          onClick={() => handleChange({ textAlign: 'right' })}
          size={ButtonSize.SMALL}
          icon={<IconAlignRight />}
        />
      </S.ActiveButton>

      <S.ActiveButton $isActive={!!styles.fontWeight && styles.fontWeight > 500}>
        <Button
          onClick={() => handleChange({ fontWeight: !!styles.fontWeight && styles.fontWeight > 500 ? 500 : 700 })}
          size={ButtonSize.SMALL}
          icon={<IconStyleBold />}
        />
      </S.ActiveButton>

      <S.ActiveButton $isActive={styles.fontStyle === 'italic'}>
        <Button
          onClick={() => handleChange({ fontStyle: styles.fontStyle === 'italic' ? 'normal' : 'italic' })}
          size={ButtonSize.SMALL}
          icon={<IconStyleItalic />}
        />
      </S.ActiveButton>

      <S.ActiveButton $isActive={!!styles.backgroundColor}>
        <Button onClick={handleBackgroundToggleClick} size={ButtonSize.SMALL} icon={<IconFontFamily />} />
      </S.ActiveButton>
    </S.TextStylesWrapper>
  );
};

export default TextStyles;
