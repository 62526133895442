import React from 'react';

import Button, { ButtonSize } from '@reface/ui/Button';
import Modal from '@reface/ui/Modal';

import * as S from './MemePreviewSaveModal.styled';
import { getExtension } from '../../../../utils/fileUtils';

const STYLES = {
  modal: {
    width: 988,
  },
};

type MemePreviewSaveModalProps = {
  preview: {
    url: string;
    filename: string;
  };
  onClose: () => void;
  open: boolean;
};

const MemePreviewSaveModal: React.FC<MemePreviewSaveModalProps> = ({ preview, open, onClose }) => {
  const handleDownload = async () => {
    const donloadname = preview.filename;
    const src = await fetch(preview.url);
    const blob = await src.blob();

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = donloadname;
    a.click();
    onClose();
  };

  const extension = getExtension(preview.url);

  return (
    <Modal
      styles={STYLES}
      title="Meme result preview"
      actions={({ defaultActionRef }) => (
        <Button ref={defaultActionRef} size={ButtonSize.LARGE} onClick={handleDownload}>
          Download
        </Button>
      )}
      open={open}
      onClose={onClose}
    >
      <S.CategoryCover>
        <S.Preview>
          {extension === 'mp4' ? <video controls src={preview.url} /> : <img src={preview.url} alt={'preview meme'} />}
        </S.Preview>
      </S.CategoryCover>
    </Modal>
  );
};

export default MemePreviewSaveModal;
