import React, { useRef } from 'react';
import * as S from './SwapImageContent.styled';
import ImageEffectsPreview from '../ImageEffectsPreview';
import EmptySwapPreview from '../EmptySwapPreview';
import { Spacer } from 'components/LayoutTools';
import { ContentRecord } from 'api/studio/content';
import { getType } from 'mime/lite';
import { IconWarningFilled } from '@reface/icons/20px';

type SwapImageContentProps = {
  detailedMedia: ContentRecord;
  swapHistoryPreview: any;
  appliedMapping: any;
};

const SwapImageContent: React.FC<SwapImageContentProps> = ({ detailedMedia, appliedMapping, swapHistoryPreview }) => {
  const sourceRef = useRef<HTMLImageElement>(null!);

  const renderSwapPreview = (src: string | null) => {
    if (!src) {
      return (
        <EmptySwapPreview
          sourceRef={sourceRef}
          icon={IconWarningFilled}
          message={swapHistoryPreview.error_message}
          variant={'warning'}
        />
      );
    }

    const isImagePreview = getType(src)?.includes('image/');

    return isImagePreview ? <S.PreviewImage src={src} /> : <S.PreviewImage as={'video'} src={src} controls />;
  };

  return (
    <S.Content>
      <S.PreviewWrapper>
        {detailedMedia.data && (
          <S.Timeline>
            <ImageEffectsPreview persons={detailedMedia.data.persons} appliedMapping={appliedMapping} />
          </S.Timeline>
        )}

        <S.ImagePreview $variant={'both'}>
          <S.OriginVideo src={detailedMedia.origin_path} ref={sourceRef} />
          {swapHistoryPreview ? (
            renderSwapPreview(swapHistoryPreview?.swap_path)
          ) : (
            <EmptySwapPreview sourceRef={sourceRef} />
          )}
        </S.ImagePreview>
      </S.PreviewWrapper>

      <S.Controls>
        <Spacer />
      </S.Controls>
    </S.Content>
  );
};

export default SwapImageContent;
