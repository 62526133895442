import React, { HTMLAttributes } from 'react';
import * as S from './Typography.styled';
import { TypographyVariants } from './Typography.styled';

type TypographyProps = {
  variant?: TypographyVariants;
  truncate?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Typography: React.FC<TypographyProps> = ({ variant = 'body1', truncate = false, children, ...rest }) => (
  <S.Typography $variant={variant} $truncate={truncate} {...rest}>
    {children}
  </S.Typography>
);

export default Typography;
