import { Item } from '../types';
import { AssetAliasType } from 'pages/Files/utils';

type ItemsAssetGroups = {
  [k: string]: Item[];
};
const groupItemsByMetaAssetType = (items: Item[]): ItemsAssetGroups => {
  const groups: ItemsAssetGroups = {};

  for (const item of items) {
    const asset_type: AssetAliasType = item.meta?.asset_type || 'content';
    if (!groups[asset_type]) {
      groups[asset_type] = [];
    }
    groups[asset_type].push(item);
  }

  return groups;
};

export default groupItemsByMetaAssetType;
