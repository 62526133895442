import { createAction, createSlice } from '@reduxjs/toolkit';
import { SORT_VARIANTS } from './constants';
import { RootState } from '../../index';

export const applySearch = createAction('search/applySearch', (search) => ({
  payload: search.toLowerCase(),
}));

export const applyContentOrder = createAction('search/applyContentOrder', (order) => ({ payload: order }));

export const contentOrderSelector = (state: RootState) => state.pages.content.search.sort.type;

const initialState = {
  search: '',
  sort: {
    type: SORT_VARIANTS.MODIFIED,
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(applySearch, (state, action) => {
      state.search = action.payload;
    });
    builder.addCase(applyContentOrder, (state, action) => {
      state.sort.type = action.payload;
    });
  },
});

export default searchSlice;
