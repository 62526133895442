import React, { useCallback, useEffect, useMemo } from 'react';
import TreeView from 'components/TreeView';
import { selectAssetsFoldersTree } from 'store/pages/assets/assetsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { PARENT_ID_ROOT } from 'store/pages/content/foldersSlice';
import { fetchAssetsThunk } from 'store/pages/assets/assetsActions';
import FolderTreeItem from './FolderTreeItem';
import InputText from 'components/InputText';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconAdd, IconReset } from '@reface/icons/20px';
import * as S from './AddSwapEffectModal.styled';
import AudioSubNodesList from './AudioSubNodesList';
import useTreeViewRecordActiveKey from '../../hooks/useTreeViewRecordActiveKey';
import useEffectAssetsUploader from './useEffectAssetsUploader';
import dispatchEvent from 'utils/dispatchEvent';

const AUDIO_ASSET_TYPE = 'audio_lipsync';

type AudiosTreeViewProps = {
  selected: string;
  onSelectedChanged: (object_id: string, isDoubleClick: boolean) => void;
};

const AudiosTreeView: React.FC<AudiosTreeViewProps> = ({ onSelectedChanged, selected }) => {
  const dispatch = useDispatch();
  const statistic = useSelector(selectAssetsFoldersTree(AUDIO_ASSET_TYPE, PARENT_ID_ROOT, []));

  useEffectAssetsUploader('audios');

  const treeData = useMemo(() => {
    if (!statistic) {
      return [];
    }
    console.log('statistic', statistic);
    return statistic.nodes!;
  }, [statistic]);

  useEffect(() => {
    dispatch(fetchAssetsThunk({ folder: PARENT_ID_ROOT, asset_type: AUDIO_ASSET_TYPE }));
  }, []);

  const handleItemClick = (e, nodeId: string, node: any) => {
    onSelectedChanged(node.object_id, e.detail === 2);
  };

  const handleSelectionClear = () => {
    onSelectedChanged(null, false);
  };

  const handleNodeOpened = useCallback((id: string, node: any) => {
    console.log('handleNodeOpened', id, node);
    dispatch(fetchAssetsThunk({ folder: id, asset_type: AUDIO_ASSET_TYPE }));
  }, []);

  const [initialActiveKey, initialOpenNodes] = useTreeViewRecordActiveKey(selected, AUDIO_ASSET_TYPE);

  const uploadClickHandler = useCallback(() => {
    dispatchEvent('uploader:open', {});
  }, []);

  return (
    <TreeView
      data={treeData}
      hasSearch
      onClickItem={handleItemClick}
      activeKey={initialActiveKey}
      initialOpenNodes={initialOpenNodes}
      onNodeOpened={handleNodeOpened}
      nodeRenderer={FolderTreeItem}
      groupNodesRenderer={AudioSubNodesList}
      searchRenderer={(searchProps) => (
        <S.Controls>
          <S.InputText>
            <InputText {...searchProps} />
          </S.InputText>
          <ButtonGroup>
            <Button
              variant={ButtonVariant.SECONDARY}
              icon={<IconReset />}
              onClick={handleSelectionClear}
              disabled={!selected}
            >
              Clear
            </Button>
            <Button variant={ButtonVariant.SECONDARY} icon={<IconAdd />} onClick={uploadClickHandler}>
              Upload New
            </Button>
          </ButtonGroup>
        </S.Controls>
      )}
    />
  );
};

export default AudiosTreeView;
