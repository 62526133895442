import React from 'react';
import * as S from './ContentEmpty.styled';

type ContentEmptyProps = {
  icon: React.FC;
  title: string;
  subtitle: string;
};

const ContentEmpty: React.FC<ContentEmptyProps> = ({ icon: IconComponent, title, subtitle }) => (
  <S.Wrapper>
    <S.EmptyImage as={IconComponent} />
    <S.Title>{title}</S.Title>
    <S.Subtitle>{subtitle}</S.Subtitle>
  </S.Wrapper>
);

export default ContentEmpty;
