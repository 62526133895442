import React from 'react';
import { IconAudioContent } from '@reface/icons/20px';
import { useSelector } from 'react-redux';
import { mediaDetailsSelector } from 'store/pages/files/media/mediaSelectors';
import * as S from '../BulkSwapModal.styled';

const AppliedAudioMappingPreview: React.FC<{ object_id?: string | null }> = ({ object_id }) => {
  const appliedFace = useSelector(mediaDetailsSelector(object_id || ''));

  return (
    <S.AppliedMappingPreview>
      {appliedFace ? <S.AppliedFacePreviewImage as={IconAudioContent} /> : <S.EmptyPreview as={IconAudioContent} />}
    </S.AppliedMappingPreview>
  );
};

export default AppliedAudioMappingPreview;
