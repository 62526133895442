import { fabric } from 'fabric';
import { extendMethod } from './utils';

interface PinAnchor extends fabric.Circle {
  anchorLine: fabric.Line;
  anchorSource: fabric.Object;
  anchorSourceOffset: Record<string, number>;
  objectPosition: Pick<fabric.Object, 'left' | 'top'>;
  setSource: (object: fabric.Object) => void;
  anchorSourceMoved: (position: Pick<fabric.Object, 'left' | 'top'>, movePin?: boolean, moveBox?: boolean) => void;
  anchorWatcherMoved: (position: Pick<fabric.Object, 'left' | 'top'>) => void;
}

const PinAnchor = fabric.util.createClass(fabric.Circle, {
  inner: null,
  innerOptions: {},
  type: 'pinAnchor',

  anchorLine: null,
  anchorSource: null,
  anchorSourceOffset: {},
  initialize: extendMethod(fabric.Circle, 'initialize', function () {
    // initCenteringGuidelines(this);
    // const { left, top } = this;
    // this.anchorLine = new fabric.Line([0, 0, left || 0, top || 0], {
    //   strokeDashArray: [5, 5],
    //   stroke: this.fill as ILineOptions['stroke'],
    //   selectable: false,
    //   excludeFromExport: true,
    // });
    //
    // console.log(this.anchorLine);
  }),
  _render: function (ctx: CanvasRenderingContext2D) {
    this.set(
      this.radius > 12
        ? {
            strokeWidth: 2,
            stroke: this.fill,
          }
        : {
            fill: this.fill,
          }
    );

    ctx.beginPath();
    ctx.arc(0, 0, this.radius, this.startAngle, this.endAngle, false);
    this._renderPaintInOrder(ctx);

    this.set({
      fill: this.innerOptions.fill,
    });
    // this._initGradient(this.innerOptions.fill, 'fill');
    // this._initGradient(this.innerOptions.stroke, 'stroke');
    ctx.beginPath();
    ctx.arc(0, 0, this.innerOptions.radius, this.startAngle, this.endAngle, false);
    this._renderPaintInOrder(ctx);
  },
  render: function (ctx: CanvasRenderingContext2D) {
    this._transformDone = true;
    this.callSuper('render', ctx);
    // console.log('render', this.anchorLine);
    // this.anchorLine && this.anchorLine.render(ctx);
    this._transformDone = false;
  },
  // setCoords: extendMethod(fabric.Circle, 'setCoords', function () {
  //   console.log(this);
  //   // this.canvas && this.canvas.add(this.anchorLine);
  // }),
  get centerPosition() {
    return {
      left: this.left as number,
    };
  },
  get actualDimentions() {
    return {
      width: this.width * this.scaleX,
      height: this.height * this.scaleY,
    };
  },
  get mainPosition() {
    const dimentions = this.actualDimentions;
    return {
      left: (this.left - dimentions.width) * Math.sin(this.rotate),
      top: (this.top - dimentions.height) * Math.cos(this.rotate),
    };
  },
  getAbsolutePosition(relativePosition: any) {
    console.log(relativePosition);
    return {};
  },
  setSource(object: fabric.Object) {
    this.anchorSource = object;
    const sourcePosition = object.getAbsoluteDimensions();
    const anchorPosition = this.getAbsoluteDimensions();

    this.anchorSourceOffset = {
      left: (anchorPosition.left - sourcePosition.left) / sourcePosition.scaleX,
      top: (anchorPosition.top - sourcePosition.top) / sourcePosition.scaleY,
    };
    console.log(this.anchorSourceOffset);
  },
  anchorWatcherMoved(position: Pick<fabric.Object, 'left' | 'top'>) {
    this.anchorLine.set({ x1: position?.left, y1: position?.top });
    this.anchorLine.setCoords();
  },
  anchorSourceMoved(position: Pick<fabric.Object, 'left' | 'top'>, movePin: true, moveBox: true) {
    this.anchorLine.set({ x2: position?.left, y2: position?.top });
    this.anchorLine.setCoords();

    this.fire('moved:anchor-source', { position, movePin, moveBox });
  },
});

export default PinAnchor;
