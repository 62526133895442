import { useModalContext } from '@reface/shared/components/ModalManager';

import Button, { ButtonSize } from '@reface/ui/Button';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Modal from '@reface/ui/Modal';
import Typography from '@reface/ui/Typography';
import { ContentRecord } from 'api/studio/content';
import useUploader from 'App/components/Uploader/hooks/useUploader';
import React from 'react';
import { fetchAsBlob } from 'utils/download';
import { getRouteAliasByAssetType } from '../../utils';

export const CREATE_PRODUCTION_VIDEO_COPY_MODAL = 'CREATE_PRODUCTION_VIDEO_COPY_MODAL';

const STYLES = {
  modal: {
    width: 512,
  },
};

type CreateVideoProductionCopyModalProps = {
  name?: string;
  onConfirm?: () => void;
};

export type ModalPayloadValue = ContentRecord;

const CreateVideoProductionCopyModal: React.FC<CreateVideoProductionCopyModalProps> = ({
  name = CREATE_PRODUCTION_VIDEO_COPY_MODAL,
  onConfirm,
}) => {
  const { close: handleClose, isOpen, value } = useModalContext<ModalPayloadValue>(name);
  const { addConvertMedia: addFilesToUploader } = useUploader();

  const handleConfirm = async () => {
    handleClose();

    const videoBlob = await fetchAsBlob(value.origin_path);
    const file = new File([videoBlob], `${value.title}.mp4`, { type: 'video/mp4' });

    const files = [file];

    addFilesToUploader(files, {
      parent_id: value.parent_id,
      asset_type: getRouteAliasByAssetType(value.asset_type),
      payload: {
        fps: 20,
        visible: true,
      },
    });

    onConfirm && onConfirm();
  };

  return (
    <Modal
      styles={STYLES}
      title="Create video 20fps copy"
      actions={({ defaultActionRef }) => (
        <ButtonGroup>
          <Button ref={defaultActionRef} size={ButtonSize.LARGE} onClick={handleConfirm}>
            Create Copy
          </Button>
          <Button size={ButtonSize.LARGE} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <Typography variant={'body1'}>
        You are going to create video copy with 20fps. Origin video will still be available in future. You will be
        redirected to video copy after successful processing. This WILL take a while to complete.
      </Typography>
    </Modal>
  );
};

export default CreateVideoProductionCopyModal;
