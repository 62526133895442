import styled from 'styled-components';

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 280px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -148px;
`;

export const Title = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[90]};
  user-select: none;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[40]};
  user-select: none;
`;
