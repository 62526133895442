import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  container?: Element | null;
  children?: React.ReactNode;
};

const Portal: React.FC<PortalProps> = ({ container = document.body, children }) =>
  container ? ReactDOM.createPortal(children, container) : null;

export default Portal;
