import styled, { css } from 'styled-components';

export type TypographyVariants = 'body1' | 'body2' | 'cation' | 'subtitle1';

export const Typography = styled.div<{
  $variant: TypographyVariants;
  $truncate: boolean;
}>`
  ${({ $variant, theme }) => {
    if ($variant === 'body1') {
      return css`
        color: ${theme.colors.gray[90]};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      `;
    }

    if ($variant === 'body2') {
      return css`
        color: ${theme.colors.gray[40]};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      `;
    }

    if ($variant === 'cation') {
      return css`
        color: ${theme.colors.gray[90]};
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
      `;
    }

    if ($variant === 'subtitle1') {
      return css`
        color: ${theme.colors.gray[90]};
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      `;
    }
  }}
  ${({ $truncate }) =>
    !!$truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;
