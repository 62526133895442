import React, { useMemo } from 'react';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { useParams } from 'react-router-dom';
import CreateFolderModal, { CREATE_FOLDER_MODAL } from '../CreateFolderModal';
import SearchInput from '../SearchInput';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconAdd } from '@reface/icons/20px';
import { FilesRouteParams } from '../../types';
import { getAssetTypeByRouteAlias } from '../../utils';
import dispatchEvent from 'utils/dispatchEvent';

const FilesHeader = () => {
  const [openModal] = useModalManager();
  const params = useParams<FilesRouteParams>();

  const uploadClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('click handler');

    dispatchEvent('uploader:open');
  };

  const uploadLabel = useMemo(() => 'Upload Content', [params.asset_type]);

  const createFolderClickHandler = () => {
    openModal(CREATE_FOLDER_MODAL, {
      title: '',
    });
  };

  const isCreateFolderAvailable = !!params.asset_type;
  // const isAudioActionsAvailable = params.asset_type === 'audios';

  return (
    <>
      <SearchInput />
      <ButtonGroup>
        {/*       {isAudioActionsAvailable && (
          <>
            <Button variant={ButtonVariant.PRIMARY} icon={<IconTTS />} disabled />

            <Button variant={ButtonVariant.PRIMARY} icon={<IconRecorder />} disabled />
          </>
        )}
*/}
        {isCreateFolderAvailable && (
          <Button variant={ButtonVariant.PRIMARY} onClick={createFolderClickHandler}>
            Create Folder
          </Button>
        )}

        <Button variant={ButtonVariant.ACTION} onClick={uploadClickHandler} icon={<IconAdd />}>
          {uploadLabel}
        </Button>
      </ButtonGroup>

      <CreateFolderModal
        name={CREATE_FOLDER_MODAL}
        parent_id={params.parent_id || ''}
        asset_type={getAssetTypeByRouteAlias(params.asset_type)}
      />
    </>
  );
};
export default FilesHeader;
