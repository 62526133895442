import React, { useEffect, useRef, useState } from 'react';

import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import InputText from 'components/InputText';
import Modal from '@reface/ui/Modal';
import ButtonGroup from '@reface/ui/ButtonGroup';

import * as S from './ChangeContentTitleModal.styled';
import { useModalContext } from '@reface/shared/components/ModalManager';
import { useDispatch } from 'react-redux';
import { updateContentThunk } from 'store/pages/content/contentActions';

export const CHANGE_CONTENT_TITLE_MODAL = 'CHANGE_CONTENT_TITLE_MODAL';

const STYLES = {
  modal: {
    width: 512,
  },
};

type ChangeContentTitleModalProps = {
  name?: string;
  onConfirm?: (values: Partial<any>) => void;
};

export type ModalPayloadValue = {
  object_id: string;
  title: string;
};

const ChangeContentTitleModal: React.FC<ChangeContentTitleModalProps> = ({ name = CHANGE_CONTENT_TITLE_MODAL }) => {
  const titleInputTextRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { close, isOpen, value } = useModalContext<ModalPayloadValue>(name);
  const [title, setTitle] = useState('');

  const handleConfirm = async () => {
    dispatch(updateContentThunk({ object_id: value!.object_id, title }));
    close();
  };

  const handleClose = () => close();

  useEffect(() => {
    // NOTE: use `setTimeout` to avoid conflict with `focusTrapped` prop of `react-responsive-modal`
    setTimeout(() => titleInputTextRef.current?.focus(), 0);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTitle(value!.title);
    }
  }, [isOpen, value]);

  return (
    <Modal
      styles={STYLES}
      title="Rename content"
      actions={({ defaultActionRef }) => (
        <ButtonGroup>
          <Button
            ref={defaultActionRef}
            size={ButtonSize.LARGE}
            variant={ButtonVariant.ACTION}
            disabled={!title.length}
            onClick={handleConfirm}
          >
            Rename
          </Button>
          <Button size={ButtonSize.LARGE} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <S.InputText>
        <InputText ref={titleInputTextRef} label="Name" value={title} isClearable onChange={setTitle} />
      </S.InputText>
    </Modal>
  );
};

export default ChangeContentTitleModal;
