import styled from 'styled-components';

export const EmptyImage = styled.svg`
  color: ${({ theme }) => theme.colors.gray[15]};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  flex: 1;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin: 8px 0;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[40]};
`;
