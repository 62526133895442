import styled, { css } from 'styled-components';

import { BackdropProps } from './Backdrop';

export const Button = styled.button`
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray[25]};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-out, background-color 0.3s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[30]};
  }
`;

export const Backdrop = styled.div<{
  $isAlwaysActive: BackdropProps['isAlwaysActive'];
  $isActiveOnHover: BackdropProps['isActiveOnHover'];
  $isClickableOnHover: boolean;
}>`
  position: relative;
  z-index: 1;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    right: -8px;
    bottom: -6px;
    left: -8px;
    z-index: -1;
    border-radius: 6px;
    pointer-events: none;
    transition: border-color 0.3s ease-out, background-color 0.3s ease-out;
  }

  ${({ $isClickableOnHover }) =>
    $isClickableOnHover &&
    css`
      &:hover {
        &:before {
          background-color: ${({ theme }) => theme.colors.gray[10]};
        }

        ${Button} {
          opacity: 1;
        }
      }
    `}

  ${({ $isAlwaysActive }) =>
    $isAlwaysActive &&
    css`
      &:before {
        background-color: ${({ theme }) => theme.colors.gray[15]};
      }
    `}

  ${({ $isActiveOnHover }) =>
    $isActiveOnHover &&
    css`
      &:hover {
        &:before {
          background-color: ${({ theme }) => theme.colors.gray[15]};
        }
      }
    `}
`;
