import React, { useEffect, useRef } from 'react';
import { toast as toastify } from 'react-toastify';

import useToast from 'hooks/useToast';
import { IconCloseModal } from '@reface/icons/20px';
import { isToastError, isToastSuccess } from 'types/toast';

import ToastContentError from './components/ToastContentError';
import ToastContentSuccess from './components/ToastContentSuccess';

import * as S from './Toasts.styled';

const AUTO_CLOSE_DELAY = 3 * 1000; /* 60 seconds */

const Toasts: React.FC = () => {
  const { toasts, remove } = useToast();

  const visibleToasts = useRef(new Map());

  useEffect(() => {
    for (const toast of toasts) {
      const isToastVisible = visibleToasts.current.has(toast.id);
      if (isToastVisible) {
        toastify.update(toast.id, {
          render: (
            <>
              {isToastError(toast) && <ToastContentError toast={toast} />}
              {isToastSuccess(toast) && <ToastContentSuccess toast={toast} />}
            </>
          ),
        });
        visibleToasts.current.get(toast.id);
      } else {
        visibleToasts.current.set(
          toast.id,
          toastify(
            <>
              {isToastError(toast) && <ToastContentError toast={toast} />}
              {isToastSuccess(toast) && <ToastContentSuccess toast={toast} />}
            </>,
            {
              toastId: toast.id,
              type: toast.type,
              autoClose: AUTO_CLOSE_DELAY,
              closeOnClick: false,
              draggable: false,
              onClose: () => remove(toast.id),
              icon: false,
            }
          )
        );
      }
    }
  }, [toasts]);

  useEffect(() => {
    const unsubscribe = toastify.onChange((payload: any) => {
      switch (payload.status) {
        case 'added':
          // new toast added
          break;
        case 'updated':
          // toast updated
          break;
        case 'removed':
          // toast has been removed
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <S.Toasts
      closeButton={({ closeToast }) => (
        <S.CloseButton onClick={closeToast}>
          <IconCloseModal />
        </S.CloseButton>
      )}
    />
  );
};

export default Toasts;
