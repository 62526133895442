import { useSelector, useDispatch } from 'react-redux';
import { selectProcessingItems, syncProcessingItems, clearProcessing } from 'store/processing/uploader';
import { useCallback, useEffect } from 'react';
import { Item } from '../types';

const usePersistedProcessingItems = () => {
  const persistedProcessingItems = useSelector(selectProcessingItems);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('usePersistedProcessingItems', persistedProcessingItems);
  }, [persistedProcessingItems]);

  const dispatchSyncProcessingItems = useCallback((...items: Item[]) => {
    dispatch(syncProcessingItems(items));
  }, []);

  const dispatchClearProcessing = useCallback(() => {
    dispatch(clearProcessing());
  }, []);

  return [persistedProcessingItems, { dispatchSyncProcessingItems, dispatchClearProcessing }] as const;
};

export default usePersistedProcessingItems;
