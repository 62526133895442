import React from 'react';

import * as S from './ControlButton.styled';

type ControlButtonProps = {
  disabled?: boolean;
  visible?: boolean;
  children?: React.ReactNode;
} & Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

const ControlButton: React.FC<ControlButtonProps> = ({ onClick, children, disabled, visible = true }) => (
  <S.Button onClick={onClick} disabled={disabled} $visible={visible}>
    {children}
  </S.Button>
);

export default ControlButton;
