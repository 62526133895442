import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { fetchContentRecord } from 'api/studio/content';
import { RootState } from '../../index';

export const fetchDetailedMediaRecordThunk = createAsyncThunk(
  'detailed/fetchDetailedMediaRecordThunk',
  (object_id: string) => fetchContentRecord(object_id)
);

export const selectDetailedMediaRecord = (object_id: string) =>
  createSelector(
    (state: RootState) => state.pages.content.detailed.data,
    (data) => {
      if (!data || data.object_id !== object_id) {
        return null;
      }

      return data;
    }
  );
