import { useEffect, useRef, useState } from 'react';
import isAccessTokenValid from 'utils/auth/isAccessTokenValid';
import { proxyApi } from 'services/http';
import { REFRESH_TOKEN_DEBOUNCE_DELAY, REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM } from 'constants/auth';
import { setAndDecodeAccessToken } from 'store/auth/auth';
import { AuthStatus, FirebaseToken } from 'types/auth';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import debounce from 'debounce-promise';
import { refreshAccessToken } from 'api/auth';

const handleAccessTokenRefresh = async ({ firebaseToken }: { firebaseToken: FirebaseToken }) => {
  const { access_token } = await refreshAccessToken({ firebaseToken });

  return access_token;
};

const debouncedHandleAccessTokenRefresh = debounce(handleAccessTokenRefresh, REFRESH_TOKEN_DEBOUNCE_DELAY);

const useExpiringAuthMethod = () => {
  const dispatch = useDispatch();
  const { firebaseToken, authStatus, token: accessToken } = useAuth();
  const requestInterceptorId = useRef(0);
  const [areRequestInterceptorsPatched, setAreRequestInterceptorsPatched] = useState(false);

  // const isAccessDenied = useMemo(() => !userServicesIds?.includes(ServiceId.VARADERO), [userServicesIds]);
  const isAccessDenied = false;

  useEffect(() => {
    if (isAccessTokenValid(firebaseToken)) {
      // NOTE: eject previous request interceptor (if it exists)
      proxyApi.interceptors.request.eject(requestInterceptorId.current);

      //       ...and add new one
      requestInterceptorId.current = proxyApi.interceptors.request.use(async (request) => {
        // const isTokenExpires = isAccessTokenExpired(accessToken);
        const isTokenExpires = false;
        if (!isTokenExpires) {
          request.headers['Authorization'] = `Bearer ${accessToken}`;

          localStorage.setItem(REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM, new Date().toISOString());
        } else {
          try {
            const refreshedAccessToken = await debouncedHandleAccessTokenRefresh({ firebaseToken });

            request.headers['Authorization'] = `Bearer ${refreshedAccessToken}`;

            localStorage.setItem(REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM, new Date().toISOString());

            dispatch(setAndDecodeAccessToken(refreshedAccessToken));
          } catch (error) {
            // NOTE: we don't need to handle error, 'cause it will be caught in `/services/http/index.ts` anyway
          }
        }

        return request;
      });

      setAreRequestInterceptorsPatched(true);
    }
  }, [accessToken]);

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_OUT) {
      // NOTE: eject previous request interceptor (if it exists)
      proxyApi.interceptors.request.eject(requestInterceptorId.current);

      localStorage.removeItem(REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM);

      setAreRequestInterceptorsPatched(false);
    }
  }, [authStatus]);

  return {
    authStatus,
    isAccessDenied,
    areRequestInterceptorsPatched,
  } as const;
};

export default useExpiringAuthMethod;
