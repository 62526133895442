import React from 'react';
import PageLayout from '../../App/components/PageLayout';
import PlainSwapHistoryTable from './PlainSwapHistoryTable';
import useQuery from 'hooks/useQuery';
import Breadcrumbs from '../../components/Breadcrumbs';
import useHistorySearchBreadcrumbs from './hooks/useHistorySearchBreadcrumbs';

const HistorySearch: React.FC = () => {
  const query = useQuery<{
    object_id?: string;
    operation_key?: string;
  }>();

  const breadcrumbs = useHistorySearchBreadcrumbs(query);

  return (
    <PageLayout header={<Breadcrumbs items={breadcrumbs} />}>
      <PlainSwapHistoryTable search={query} />
    </PageLayout>
  );
};

export default HistorySearch;
