import Typography from '@reface/ui/Typography';
import styled from 'styled-components';
import SelectionArea from 'components/SelectionArea';

export const FilesGridSelectionArea = styled(SelectionArea)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: stretch;
  margin-left: -32px;
  margin-right: -32px;
  padding: 0 32px 32px 32px;
  gap: 32px;
`;

export const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[90]};
  padding: 16px 0;

  &:not(:first-of-type) {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[20]};
  }
`;

export const SelectedCounter = styled(Typography)`
  width: 112px;
`;
