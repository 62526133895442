import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalActions = styled.div`
  display: flex;
`;

export const Controls = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
`;

export const InputText = styled.div`
  flex-grow: 1;
  margin-right: 24px;

  & + & {
    margin-top: 24px;
  }
`;
