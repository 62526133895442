import React, { useRef } from 'react';

import AudioPlayer from './AudioPlayer';
import useAudioPlayerMini from './hooks/useAudioPlayerMini';

export type AudioPlayerMiniProps = {
  src: string;
};

const AudioPlayerMini: React.FC<AudioPlayerMiniProps> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null!);
  const playerProps = useAudioPlayerMini(audioRef.current);

  return (
    <>
      <audio controls ref={audioRef} hidden>
        <source src={src} type="audio/ogg" />
      </audio>
      <AudioPlayer {...playerProps} />
    </>
  );
};

export default AudioPlayerMini;
