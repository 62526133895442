import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { FolderStatistic, selectFoldersTree } from '../content/foldersSlice';
import { ContentRecord } from '../../../api/studio/content';
import sum from 'lodash/sum';
import { TreeNodeInArray } from '../../../components/TreeView/types';
import { SORT_VARIANTS } from '../content/constants';

const contentToTreeItem = (
  isParentExpandable: boolean,
  items: ContentRecord[],
  node: Pick<TreeNodeInArray<FolderStatistic>, 'id' | 'item'>
): TreeNodeInArray<FolderStatistic | ContentRecord>[] => {
  if (!isParentExpandable) {
    return [];
  }

  return [
    {
      ...node,
      nodes: items.map((item) => ({
        id: item.object_id,
        item,
        type: 'node',
        label: item.title,
      })),
      type: 'group',
      label: 'Loading...',
    },
  ];
};

const isFolderNodeExpandable = (statistic: FolderStatistic) =>
  !!sum([statistic.folders, statistic.audios, statistic.images, statistic.videos]);

const sortHandler = (a: ContentRecord, b: ContentRecord) =>
  new Date(b.created_date).getTime() - new Date(a.created_date).getTime();

function deepFoldersTreeWalk(
  folderTreeNode: TreeNodeInArray<FolderStatistic>,
  assets: { [folder_key: string]: ContentRecord[] }
): TreeNodeInArray<ContentRecord | FolderStatistic> {
  const { asset_type, object_id } = folderTreeNode.item as FolderStatistic;
  const folder_key = `${asset_type}_${object_id || 'root'}`;

  const expandable = isFolderNodeExpandable(folderTreeNode.item as FolderStatistic);

  const contentNodes = contentToTreeItem(
    expandable,
    (assets[folder_key] || []).filter((item) => item.content_type !== 'folder').sort(sortHandler),
    {
      id: `${object_id}_content`,
      item: folderTreeNode.item,
    }
  );

  return {
    ...folderTreeNode,
    expandable,
    // nodes: folderTreeNode.nodes.map((node) => deepFoldersTreeWalk(node, assets)),
    nodes: [...folderTreeNode.nodes!.map((node) => deepFoldersTreeWalk(node, assets)), ...contentNodes],
  } as TreeNodeInArray<ContentRecord | FolderStatistic>;
}

export const selectAssetsFoldersTree = (asset_type: string, parent_id?: string, excludeFolders: string[] = []) =>
  createSelector(
    selectFoldersTree(asset_type, parent_id, excludeFolders),
    (state: RootState) => state.pages.assets.entities,
    (folders, assets) => {
      // console.log(folders, assets);
      if (!folders) {
        return null;
      }

      return deepFoldersTreeWalk(folders, assets);
    }
  );
