import { withSvgIcon } from '../components/SvgIcon';

import { ReactComponent as PlainIconAbTest } from './AbTest.svg';
import { ReactComponent as PlainIconAdd } from './Add.svg';
import { ReactComponent as PlainIconApprove } from './Approve.svg';
import { ReactComponent as PlainIconArrowLeft } from './ArrowLeft.svg';
import { ReactComponent as PlainIconArrowRight } from './ArrowRight.svg';
import { ReactComponent as PlainIconAudio } from './Audio.svg';
import { ReactComponent as PlainIconBlock } from './Block.svg';
import { ReactComponent as PlainIconBlockUser } from './BlockUser.svg';
import { ReactComponent as PlainIconCheck } from './Check.svg';
import { ReactComponent as PlainIconCheckboxIndeterminate } from './CheckboxIndeterminate.svg';
import { ReactComponent as PlainIconCheckboxOff } from './CheckboxOff.svg';
import { ReactComponent as PlainIconCheckboxOn } from './CheckboxOn.svg';
import { ReactComponent as PlainIconCloseFilled } from './CloseFilled.svg';
import { ReactComponent as PlainIconCloseModal } from './CloseModal.svg';
import { ReactComponent as PlainIconContent } from './Content.svg';
import { ReactComponent as PlainIconAssets } from './Assets.svg';
import { ReactComponent as PlainIconHistory } from './History.svg';
import { ReactComponent as PlainIconArchive } from './Archive.svg';
import { ReactComponent as PlainIconHelp } from './Help.svg';
import { ReactComponent as PlainIconEdit } from './Edit.svg';
import { ReactComponent as PlainIconFace } from './Face.svg';
import { ReactComponent as PlainIconGoogle } from './Google.svg';
import { ReactComponent as PlainIconHeadMove } from './HeadMove.svg';
import { ReactComponent as PlainIconHide } from './Hide.svg';
import { ReactComponent as PlainIconInfo } from './Info.svg';
import { ReactComponent as PlainIconLinkOutside } from './LinkOutside.svg';
import { ReactComponent as PlainIconLoader } from './Loader.svg';
import { ReactComponent as PlainIconMore } from './More.svg';
import { ReactComponent as PlainIconKebabVertical } from './KebabVertical.svg';
import { ReactComponent as PlainIconPublishing } from './Publishing.svg';
import { ReactComponent as PlainIconRadioButtonOff } from './RadioButtonOff.svg';
import { ReactComponent as PlainIconRadioButtonOn } from './RadioButtonOn.svg';
import { ReactComponent as PlainIconRbac } from './Rbac.svg';
import { ReactComponent as PlainIconReturn } from './Return.svg';
import { ReactComponent as PlainIconReview } from './Review.svg';
import { ReactComponent as PlainIconSkip } from './Skip.svg';
import { ReactComponent as PlainIconSuccessFilled } from './SuccessFilled.svg';
import { ReactComponent as PlainIconTrash } from './Trash.svg';
import { ReactComponent as PlainIconUsers } from './Users.svg';
import { ReactComponent as PlainIconWarningFilled } from './WarningFilled.svg';
import { ReactComponent as PlainIconMoveLayerBack } from './MoveLayerBack.svg';
import { ReactComponent as PlainIconMoveLayerFront } from './MoveLayerFront.svg';
import { ReactComponent as PlainIconBackground } from './Background.svg';
import { ReactComponent as PlainIconAlignLeft } from './AlignLeft.svg';
import { ReactComponent as PlainIconAlignCenter } from './AlignCenter.svg';
import { ReactComponent as PlainIconAlignRight } from './AlignRight.svg';
import { ReactComponent as PlainIconStyleBold } from './StyleBold.svg';
import { ReactComponent as PlainIconStyleItalic } from './StyleItalic.svg';
import { ReactComponent as PlainIconFontFamily } from './FontFamily.svg';
import { ReactComponent as PlainIconPin } from './Pin.svg';
import { ReactComponent as PlainIconUnpin } from './Unpin.svg';
import { ReactComponent as PlainIconUndo } from './Undo.svg';
import { ReactComponent as PlainIconRedo } from './Redo.svg';
import { ReactComponent as PlainIconArrowDown } from './ArrowDown.svg';
import { ReactComponent as PlainIconArrowUp } from './ArrowUp.svg';
import { ReactComponent as PlainIconViewTable } from './ViewTable.svg';
import { ReactComponent as PlainIconViewGrid } from './ViewGrid.svg';
import { ReactComponent as PlainIconViewTiles } from './ViewTiles.svg';
import { ReactComponent as PlainIconFolder } from './Folder.svg';
import { ReactComponent as PlainIconArrowDropDown } from './ArrowDropDown.svg';
import { ReactComponent as PlainIconArrowDropRight } from './ArrowDropRight.svg';
import { ReactComponent as PlainIconFacePicker } from './FacePicker.svg';
import { ReactComponent as PlainIconHeadMotion } from './HeadMotion.svg';
import { ReactComponent as PlainIconBulkSwap } from './BulkSwap.svg';
import { ReactComponent as PlainIconSettings } from './Settings.svg';
import { ReactComponent as PlainIconPreferences } from './Preferences.svg';
import { ReactComponent as PlainIconDownload } from './Download.svg';
import { ReactComponent as PlainIconReset } from './Reset.svg';
import { ReactComponent as PlainIconFullscreen } from './Fullscreen.svg';
import { ReactComponent as PlainIconSmallscreen } from './Smallscreen.svg';
import { ReactComponent as PlainIconReface } from './Reface.svg';
import { ReactComponent as PlainIconChevronDown } from './ChevronDown.svg';
import { ReactComponent as PlainIconLoop } from './Loop.svg';
import { ReactComponent as PlainIconLoopBroken } from './LoopBroken.svg';
import { ReactComponent as PlainIconCut } from './Cut.svg';
import { ReactComponent as PlainIconVolume } from './Volume.svg';
import { ReactComponent as PlainIconVolumeMuted } from './VolumeMuted.svg';
import { ReactComponent as PlainIconZoomMinus } from './ZoomMinuse.svg';
import { ReactComponent as PlainIconZoomPlus } from './ZoomPlus.svg';
import { ReactComponent as PlainIconPlay } from './Play.svg';
import { ReactComponent as PlainIconPause } from './Pause.svg';
import { ReactComponent as PlainIconViewTableBig } from './TableBig.svg';
import { ReactComponent as PlainIconTTS } from './TTS.svg';
import { ReactComponent as PlainIconRecorder } from './Recorder.svg';
import { ReactComponent as PlainIconArrowSortDown } from './ArrowSortDown.svg';
import { ReactComponent as PlainIconArrowSortUp } from './ArrowSortUp.svg';
import { ReactComponent as PlainIconImage } from './Image.svg';
import { ReactComponent as PlainIconAudioContent } from './AudioContent.svg';
import { ReactComponent as PlainIconVideo } from './Video.svg';
import { ReactComponent as PlainIconCopy } from './Copy.svg';
import { ReactComponent as PlainIconMisc } from './Misc.svg';

export const IconAbTest = withSvgIcon(PlainIconAbTest);
export const IconAdd = withSvgIcon(PlainIconAdd);
export const IconApprove = withSvgIcon(PlainIconApprove);
export const IconArrowLeft = withSvgIcon(PlainIconArrowLeft);
export const IconArrowRight = withSvgIcon(PlainIconArrowRight);
export const IconAudio = withSvgIcon(PlainIconAudio);
export const IconBlock = withSvgIcon(PlainIconBlock);
export const IconBlockUser = withSvgIcon(PlainIconBlockUser);
export const IconCheck = withSvgIcon(PlainIconCheck);
export const IconCheckboxIndeterminate = withSvgIcon(PlainIconCheckboxIndeterminate);
export const IconCheckboxOff = withSvgIcon(PlainIconCheckboxOff);
export const IconCheckboxOn = withSvgIcon(PlainIconCheckboxOn);
export const IconCloseFilled = withSvgIcon(PlainIconCloseFilled);
export const IconCloseModal = withSvgIcon(PlainIconCloseModal);
export const IconContent = withSvgIcon(PlainIconContent);
export const IconAssets = withSvgIcon(PlainIconAssets);
export const IconHistory = withSvgIcon(PlainIconHistory);
export const IconArchive = withSvgIcon(PlainIconArchive);
export const IconHelp = withSvgIcon(PlainIconHelp);
export const IconEdit = withSvgIcon(PlainIconEdit);
export const IconFace = withSvgIcon(PlainIconFace);
export const IconGoogle = withSvgIcon(PlainIconGoogle);
export const IconHeadMove = withSvgIcon(PlainIconHeadMove);
export const IconHide = withSvgIcon(PlainIconHide);
export const IconInfo = withSvgIcon(PlainIconInfo);
export const IconLinkOutside = withSvgIcon(PlainIconLinkOutside);
export const IconLoader = withSvgIcon(PlainIconLoader);
export const IconMore = withSvgIcon(PlainIconMore);
export const IconKebabVertical = withSvgIcon(PlainIconKebabVertical);
export const IconPublishing = withSvgIcon(PlainIconPublishing);
export const IconRadioButtonOff = withSvgIcon(PlainIconRadioButtonOff);
export const IconRadioButtonOn = withSvgIcon(PlainIconRadioButtonOn);
export const IconRbac = withSvgIcon(PlainIconRbac);
export const IconReturn = withSvgIcon(PlainIconReturn);
export const IconReview = withSvgIcon(PlainIconReview);
export const IconSkip = withSvgIcon(PlainIconSkip);
export const IconSuccessFilled = withSvgIcon(PlainIconSuccessFilled);
export const IconTrash = withSvgIcon(PlainIconTrash);
export const IconUsers = withSvgIcon(PlainIconUsers);
export const IconWarningFilled = withSvgIcon(PlainIconWarningFilled);
export const IconMoveLayerBack = withSvgIcon(PlainIconMoveLayerBack);
export const IconMoveLayerFront = withSvgIcon(PlainIconMoveLayerFront);
export const IconBackground = withSvgIcon(PlainIconBackground);
export const IconAlignLeft = withSvgIcon(PlainIconAlignLeft);
export const IconAlignCenter = withSvgIcon(PlainIconAlignCenter);
export const IconAlignRight = withSvgIcon(PlainIconAlignRight);
export const IconStyleBold = withSvgIcon(PlainIconStyleBold);
export const IconStyleItalic = withSvgIcon(PlainIconStyleItalic);
export const IconFontFamily = withSvgIcon(PlainIconFontFamily);
export const IconPin = withSvgIcon(PlainIconPin);
export const IconUnpin = withSvgIcon(PlainIconUnpin);
export const IconUndo = withSvgIcon(PlainIconUndo);
export const IconRedo = withSvgIcon(PlainIconRedo);
export const IconArrowDown = withSvgIcon(PlainIconArrowDown);
export const IconArrowUp = withSvgIcon(PlainIconArrowUp);
export const IconViewTable = withSvgIcon(PlainIconViewTable);
export const IconViewGrid = withSvgIcon(PlainIconViewGrid);
export const IconViewTiles = withSvgIcon(PlainIconViewTiles);
export const IconFolder = withSvgIcon(PlainIconFolder);
export const IconArrowDropDown = withSvgIcon(PlainIconArrowDropDown);
export const IconArrowDropRight = withSvgIcon(PlainIconArrowDropRight);
export const IconFacePicker = withSvgIcon(PlainIconFacePicker);
export const IconHeadMotion = withSvgIcon(PlainIconHeadMotion);
export const IconBulkSwap = withSvgIcon(PlainIconBulkSwap);
export const IconSettings = withSvgIcon(PlainIconSettings);
export const IconPreferences = withSvgIcon(PlainIconPreferences);
export const IconDownload = withSvgIcon(PlainIconDownload);
export const IconReset = withSvgIcon(PlainIconReset);
export const IconFullscreen = withSvgIcon(PlainIconFullscreen);
export const IconSmallscreen = withSvgIcon(PlainIconSmallscreen);
export const IconReface = withSvgIcon(PlainIconReface);
export const IconChevronDown = withSvgIcon(PlainIconChevronDown);
export const IconLoop = withSvgIcon(PlainIconLoop);
export const IconLoopBroken = withSvgIcon(PlainIconLoopBroken);
export const IconCut = withSvgIcon(PlainIconCut);
export const IconVolume = withSvgIcon(PlainIconVolume);
export const IconVolumeMuted = withSvgIcon(PlainIconVolumeMuted);
export const IconZoomMinus = withSvgIcon(PlainIconZoomMinus);
export const IconZoomPlus = withSvgIcon(PlainIconZoomPlus);
export const IconPlay = withSvgIcon(PlainIconPlay);
export const IconPause = withSvgIcon(PlainIconPause);
export const IconViewTableBig = withSvgIcon(PlainIconViewTableBig);
export const IconTTS = withSvgIcon(PlainIconTTS);
export const IconRecorder = withSvgIcon(PlainIconRecorder);
export const IconArrowSortDown = withSvgIcon(PlainIconArrowSortDown);
export const IconArrowSortUp = withSvgIcon(PlainIconArrowSortUp);
export const IconImage = withSvgIcon(PlainIconImage);
export const IconAudioContent = withSvgIcon(PlainIconAudioContent);
export const IconVideo = withSvgIcon(PlainIconVideo);
export const IconCopy = withSvgIcon(PlainIconCopy);
export const IconMisc = withSvgIcon(PlainIconMisc);
