import { useCallback, useRef, useState } from 'react';
import { SelectionEvent, VanillaSelectionArea } from 'components/SelectionArea';

const useContentSelection = () => {
  const selectableRef = useRef<VanillaSelectionArea>(null!);
  const [selected, setSelected] = useState<Set<string>>(() => new Set());

  const extractIds = useCallback(
    (els: Element[]): string[] =>
      els
        .map((v) => v.getAttribute('data-key'))
        .filter(Boolean)
        .map(String),
    []
  );

  const onSelectionBeforeStart = useCallback(({ event }) => {
    const eventPath = event.composedPath();

    if (eventPath.some((el) => el.tagName === 'BUTTON')) {
      return false;
    }

    document.body.style.userSelect = 'none';
  }, []);

  const onSelectionStart = useCallback(
    ({ event, selection }: SelectionEvent) => {
      document.body.style.userSelect = 'none';
      // document.body.classList.add('selecting');

      // console.log(selectableRef.current, event, selection, store);
      if (!event?.ctrlKey && !event?.metaKey) {
        selection.clearSelection();
        setSelected(() => new Set());
      }
    },
    [setSelected]
  );

  const onSelectionEnded = useCallback(() => {
    console.log('onSelectionEnded');
    document.body.style.userSelect = 'unset';
    // document.body.classList.remove('selecting');
  }, []);

  const onSelectionMove = useCallback(
    ({
      store: {
        changed: { added, removed },
      },
      selection,
    }: SelectionEvent) => {
      // if (removed.length) {
      //   console.log({ event, selection, added, removed });
      // }

      selection.resolveSelectables();

      setSelected((prev) => {
        const next = new Set(prev);
        extractIds(added).forEach((id) => next.add(id));
        extractIds(removed.filter((el) => !!el.closest('[role=rowgroup]'))).forEach((id) => next.delete(id));
        return next;
      });
    },
    [setSelected]
  );

  const clearSelection = useCallback(() => {
    setSelected(new Set());
  }, []);

  const handleGridClick = useCallback(
    (event) => {
      const eventPath = event.nativeEvent.composedPath();
      console.log(
        eventPath.some((el) => el.classList && el.classList.contains('selectable')),
        document.body.style.userSelect
      );

      if (
        document.body.style.userSelect === 'none' ||
        eventPath.some((el) => el.classList && el.classList.contains('selectable'))
      ) {
        return false;
      }

      clearSelection();
    },
    [clearSelection, selectableRef]
  );

  return {
    selectableRef,
    selected,
    setSelected,
    handleGridClick,
    onSelectionBeforeStart,
    onSelectionStart,
    onSelectionEnded,
    onSelectionMove,
    clearSelection,
  };
};

export default useContentSelection;
