import { remove } from 'lodash';

// TODO: add tests
const bubbleItems = <T extends { value: number | string }>({
  items,
  selectedItems,
}: {
  items: T[];
  selectedItems: T[];
}): T[] => {
  const unselectedItems = [...items];

  return [
    ...selectedItems.reduce((bubbledItems: T[], selectedItem) => {
      bubbledItems = [
        ...bubbledItems,
        ...remove(unselectedItems, (unselectedItem) => unselectedItem.value === selectedItem.value),
      ];

      return bubbledItems;
    }, []),
    ...unselectedItems,
  ];
};

export default bubbleItems;
