import { authApi } from 'services/http';
import { AccessToken, FirebaseToken } from 'types/auth';
import getFingerprint from 'utils/auth/getFingerprint';

export type RefreshAccessTokenPayload = {
  firebaseToken: FirebaseToken;
};

export type RefreshAccessTokenResponse = {
  access_token: AccessToken;
};

const refreshAccessToken = async ({
  firebaseToken,
}: RefreshAccessTokenPayload): Promise<RefreshAccessTokenResponse> => {
  const response = await authApi.post(
    '/refresh-access-token',
    { firebase_token: firebaseToken, fingerprint: getFingerprint() },
    { withCredentials: true }
  );

  return response.data;
};

export default refreshAccessToken;
