import React, { useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import * as S from './AudioSubNodesList.styled';

import useAudioPlayerMini from 'components/AudioPlayerMini/hooks/useAudioPlayerMini';
import ControlButton from 'components/AudioPlayerMini/components/ControlButton';
import { IconPause, IconPlay, IconSoundOff, IconSoundOn } from '@reface/icons/32px';
import Spinner from '@reface/ui/Spinner';

type AudioPlayerProps = {
  src: string;
  children?: React.ReactNode;
};

const AudioNodePlayer: React.FC<AudioPlayerProps> = ({ src, children }) => {
  const audioRef = useRef<HTMLAudioElement>(null!);
  const { isReady, isAudioPaused, isAudioMuted, handleAudioPlayToggle, handleAudioMuteToggle, durationInSeconds } =
    useAudioPlayerMini(audioRef.current, 'picker/preview');

  return (
    <S.AudioNodePlayerWrapper>
      <audio controls ref={audioRef} hidden>
        <source src={src} type="audio/ogg" />
      </audio>

      <ControlButton onClick={handleAudioPlayToggle} disabled={!isReady}>
        {isAudioPaused ? <IconPlay /> : <IconPause />}
      </ControlButton>

      {children}
      <S.Duration>{durationInSeconds ? `${+durationInSeconds.toFixed(1)}s` : ''}</S.Duration>

      <ControlButton onClick={handleAudioMuteToggle} disabled={!isReady} visible={!isAudioPaused}>
        {isAudioMuted ? <IconSoundOff /> : <IconSoundOn />}
      </ControlButton>
    </S.AudioNodePlayerWrapper>
  );
};

type AudioSubNodesListProps = {
  nodes: any[];
  id: string;
  activeKey: string;
  onClick: (e: MouseEvent, id: string, item: any) => void;
};

const AudioSubNodesList: React.FC<AudioSubNodesListProps> = ({ activeKey, nodes, onClick }) => (
  <S.GridWrapper>
    {!nodes.length && (
      <S.Loader>
        <Spinner /> Loading ...
      </S.Loader>
    )}
    {nodes.map(({ id, item }) => (
      <S.MotionGridItem
        role="button"
        key={id}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e, id, item);
        }}
        className={classNames({ active: activeKey === id, focused: false })}
      >
        <AudioNodePlayer src={item.origin_path}>
          <S.Title truncate>{item.title}</S.Title>
        </AudioNodePlayer>
      </S.MotionGridItem>
    ))}
  </S.GridWrapper>
);

export default AudioSubNodesList;
