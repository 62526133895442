import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import { isEmpty } from 'lodash';

import * as S from './Dropdown.styled';

const ROW_HEIGHT = 32;
const LIST_MAX_HEIGHT = 7.5 * ROW_HEIGHT;

export type DropdownItem = {
  title: string;
  value: string | number;
};

export type DropdownProps = {
  items?: DropdownItem[];
  hasMore?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
  onSelect: (item: DropdownItem) => void;
  rowRenderer?: (item: DropdownItem) => React.ReactNode;
};

const Dropdown = (
  { items, hasMore = false, isLoading = false, onLoadMore, onSelect, rowRenderer }: DropdownProps,
  ref: React.Ref<{ list: List }>
): React.ReactElement | null => {
  const listRef = useRef<List>(null);

  useImperativeHandle(ref, () => ({
    list: listRef.current as List,
  }));

  const itemsHeight = (items?.length || 0) * ROW_HEIGHT;
  const listHeight = itemsHeight > LIST_MAX_HEIGHT ? LIST_MAX_HEIGHT : itemsHeight;

  const defaultRowRenderer = (item: DropdownItem) => <>{item.title}</>;

  // NOTE: recalculate row heights when dropdown is resized
  const handleResize = () => {
    listRef.current?.recomputeRowHeights();
  };

  const handleLoadMore = async () => {
    if (onLoadMore && !isLoading) {
      return onLoadMore();
    }
  };

  if (!items) {
    return null;
  }

  return (
    <S.Dropdown>
      {!!items && !isEmpty(items) && (
        <div style={{ height: listHeight }}>
          <AutoSizer onResize={handleResize}>
            {({ height, width }) => (
              <InfiniteLoader
                rowCount={hasMore ? items.length + 1 : items.length}
                isRowLoaded={({ index }) => !!items[index]}
                loadMoreRows={handleLoadMore}
              >
                {({ onRowsRendered }) => (
                  <List
                    ref={listRef}
                    height={height}
                    width={width}
                    rowHeight={ROW_HEIGHT}
                    rowCount={items.length}
                    onRowsRendered={onRowsRendered}
                    rowRenderer={({ key, index, style }) => (
                      <S.Item key={key} style={style} onClick={() => onSelect(items[index])}>
                        {rowRenderer ? rowRenderer(items[index]) : defaultRowRenderer(items[index])}
                      </S.Item>
                    )}
                  />
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      )}

      {!!items && isEmpty(items) && <S.EmptyState>Nothing was found…</S.EmptyState>}
    </S.Dropdown>
  );
};

export default forwardRef(Dropdown);
