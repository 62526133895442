import { generatePath } from 'react-router-dom';

const assetTypeAliases = {
  content: 'content',
  video_segmentation: 'video_asset_segmentation',
  image_segmentation: 'image_asset_segmentation',
  motions: 'video_motion',
  audios: 'audio_lipsync',
  faces: 'image_face',
} as const;

export type AssetAliasType = keyof typeof assetTypeAliases;
export type ContentAssetType = typeof assetTypeAliases[AssetAliasType];

export const getAssetTypeByRouteAlias = (asset_type_alias: AssetAliasType): ContentAssetType =>
  assetTypeAliases[asset_type_alias] || assetTypeAliases.content;

export const getRouteAliasByAssetType = (assetType: ContentAssetType): AssetAliasType => {
  if (!assetType) {
    return 'content';
  }
  const aliases = Object.fromEntries(Object.entries(assetTypeAliases).map(([k, v]) => [v, k]));
  return aliases[assetType] as AssetAliasType;
};

export const getAssetTypeAliasOverride = (asset_type_alias: AssetAliasType, content_type: string) => {
  if (['motions', 'faces', 'audios'].includes(asset_type_alias)) {
    const contentToAsset = {
      video: 'motions',
      image: 'faces',
      audio: 'audios',
    };
    return contentToAsset[content_type];
  } else {
    return asset_type_alias;
  }
};

const folderTitleForAssetTypeAlias = {
  content: 'All Content',
  video_segmentation: 'video_asset_segmentation',
  image_segmentation: 'image_asset_segmentation',
  motions: 'All Motions',
  audios: 'All Audio Tracks',
  faces: 'All Faces',
};

export const getFilesTitle = (asset_type_alias: AssetAliasType) =>
  folderTitleForAssetTypeAlias[asset_type_alias] || folderTitleForAssetTypeAlias.content;

export const generatePathByAssetType = (asset_type: string, parent_id?: string) => {
  const types = Object.fromEntries(Object.entries(assetTypeAliases).map(([key, value]) => [value, key]));
  return generatePath('/files/:asset_type/:parent_id?', {
    asset_type: types[asset_type],
    parent_id,
  });
};
