import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import useClickOutside from 'studio/src/hooks/useClickOutside';
import Portal from '../Portal';

export type PopoverRenderOptions = {
  isOpen?: boolean;
  close?: () => void;
};

export type PopoverProps = {
  popover?: React.ReactNode | ((options: PopoverRenderOptions) => React.ReactNode);
  onIsOpenChanged?: (isOpen: boolean) => void;
  children?: React.ReactNode | ((options: PopoverRenderOptions) => React.ReactNode);
};

const Popover: React.FC<PopoverProps> = ({ popover, onIsOpenChanged, children }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [contentElement, setContentElement] = useState(null);
  const anchorRef = useRef(null);
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [containerElement, setContainerElement] = useState<Element>(document.getElementById('context'));

  const { styles, attributes } = usePopper(anchorElement, contentElement, { placement: 'bottom-start' });

  const handleAnchorClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    anchorRef.current = anchorElement;
    contentRef.current = contentElement;
  }, [anchorElement, contentElement]);

  useClickOutside(() => {
    setIsOpen(false);
  }, [anchorRef, contentRef]);

  useEffect(() => {
    onIsOpenChanged && onIsOpenChanged(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const feedElement = anchorElement?.closest('[role=feed]');
    if (feedElement) {
      setContainerElement(feedElement);
    }
  }, [anchorElement]);

  return (
    <>
      <div ref={setAnchorElement} onMouseDown={handleAnchorClick}>
        {typeof children === 'function' ? children({ isOpen }) : children}
      </div>
      <Portal container={containerElement}>
        {isOpen && (
          <div
            ref={setContentElement}
            role={'feed'}
            style={{ ...{ zIndex: 1 }, ...styles.popper }}
            {...attributes.popper}
          >
            {typeof popover === 'function' ? popover({ isOpen, close: () => setIsOpen(false) }) : popover}
          </div>
        )}
      </Portal>
    </>
  );
};

export default Popover;
