import React, { forwardRef, HTMLProps } from 'react';

import useCombinedRef from 'hooks/useCombinedRef';
import { IconClear } from '@reface/icons/16px';

import * as S from './InputText.styled';

export type InputTextProps = Omit<HTMLProps<HTMLInputElement>, 'value' | 'onChange'> & {
  placeholder?: string;
  label?: string;
  className?: string;
  value: string;
  isClearable?: boolean;
  onFocus?: () => void;
  onChange: (value: string) => void;
};

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({ placeholder, label, value, isClearable = false, onFocus, onChange, className, ...rest }, ref) => {
    const combinedRef = useCombinedRef<HTMLInputElement>(ref);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

    const handleClear = () => {
      onChange('');

      combinedRef.current?.focus();
    };

    return (
      <>
        {label && <S.Label>{label}</S.Label>}

        <S.InputTextWrapper $clearable={isClearable} className={className}>
          <input
            ref={combinedRef}
            type="text"
            placeholder={placeholder}
            value={value}
            onFocus={onFocus}
            onChange={handleChange}
            {...rest}
          />

          {isClearable && value && (
            <button onClick={handleClear}>
              <IconClear />
            </button>
          )}
        </S.InputTextWrapper>
      </>
    );
  }
);

export default InputText;
