import { signOut as firebaseSignOut } from '@firebase/auth';
import { firebaseAuth } from '../../services/firebase';
import { REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM } from '../../constants/auth';

export type SignOutResponse = void | null;

const signOut = async (): Promise<SignOutResponse> => {
  firebaseSignOut(firebaseAuth).then(
    () => {
      localStorage.removeItem(REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM);
    },
    (error) => {
      console.error('Logout fail', error);
    }
  );
};

export default signOut;
