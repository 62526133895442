import styled, { css } from 'styled-components';

import { TabProps, TabType } from './Tab';

export const Tab = styled.div<{
  $type: TabProps['type'];
  $isActive: TabProps['isActive'];
  $isClickable: boolean;
}>`
  user-select: none;

  ${({ $type, $isActive }) =>
    $type === TabType.NORMAL &&
    css`
      border-bottom: 4px solid ${({ theme }) => ($isActive ? theme.colors.gray[90] : 'transparent')};
      transition: border-color 0.3s ease-out;

      ${Label} {
        margin-bottom: 4px;
        padding: 4px 6px;
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.gray[40]};
        background-color: transparent;
        transition: background-color 0.3s ease-out;
      }
    `}

  ${({ $type, $isClickable }) =>
    $type === TabType.NORMAL &&
    $isClickable &&
    css`
      ${Label} {
        color: ${({ theme }) => theme.colors.gray[90]};
      }

      &:hover {
        ${Label} {
          background-color: ${({ theme }) => theme.colors.gray[20]};
          cursor: pointer;
        }
      }
    `}

  ${({ $type, $isActive }) =>
    $type === TabType.COMPACT &&
    css`
      position: relative;

      ${Label} {
        font-size: 12px;
        line-height: 15px;
        color: ${({ theme }) => ($isActive ? theme.colors.gray[90] : theme.colors.gray[40])};
        transition: color 0.3s ease-out;
      }

      &:not(:last-child) {
        margin-right: 21px;

        &:after {
          content: '|';
          position: absolute;
          top: -1px;
          right: -13px;
          font-size: 14px;
          line-height: 15px;
          color: ${({ theme }) => theme.colors.gray[40]};
        }
      }
    `}

  ${({ $type, $isClickable }) =>
    $type === TabType.COMPACT &&
    $isClickable &&
    css`
      &:hover {
        ${Label} {
          color: ${({ theme }) => theme.colors.gray[90]};
          cursor: pointer;
        }
      }
    `};
`;

export const Label = styled.div`
  font-weight: 500;
`;
