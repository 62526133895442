import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../index';
import actionsMapping, { RegisteredAction } from './actionsMapping';
import { AnyAction } from 'redux';

export const pushHistoryRecord = createAction('history/PUSH', (actionType: string, payload: any) => ({
  payload: { actionType, payload },
}));

export const UNDO = createAsyncThunk('history/UNDO', async (action, { getState, dispatch }) => {
  const state = getState() as RootState;
  const lastAction = state.history.at(-1);

  if (!lastAction) {
    return;
  }

  console.log('history/UNDO', lastAction);

  const { actionType, payload } = lastAction;
  const thunk = actionsMapping[actionType as RegisteredAction];
  const undoAction = thunk(payload) as unknown as AnyAction;
  await dispatch(undoAction);
});
