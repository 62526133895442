import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import * as S from './FacesSubNodesList.styled';
import Spinner from '@reface/ui/Spinner';

type FacesSubNodesListProps = {
  nodes: any[];
  id: string;
  hasNodes: boolean;
  activeKey: string;
  onClick: (e: MouseEvent<HTMLLIElement>, id: string, item: any) => void;
  item: any;
};

const FacesSubNodesList: React.FC<FacesSubNodesListProps> = ({ activeKey, nodes, onClick, item, id }) => {
  if (!item.images) {
    return null;
  }

  //TODO: id !== 'root_content' replace this valid condition for assets list
  return (
    <S.FacesGridWrapper>
      {!nodes.length && id !== 'root_content' && (
        <S.Loader>
          <Spinner /> Loading ...
        </S.Loader>
      )}
      {nodes.map(({ id, item }) => (
        <S.FaceGridItem
          role="button"
          key={id}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e, id, item);
          }}
          title={item.title}
          className={classNames({ active: activeKey === id, focused: false })}
        >
          <S.FacePreviewImage src={item.origin_path} alt={item.title} />
        </S.FaceGridItem>
      ))}
    </S.FacesGridWrapper>
  );
};

export default FacesSubNodesList;
