import styled, { css } from 'styled-components';
import { IconCheck } from '@reface/icons/20px';
import Typography from '@reface/ui/Typography';

export const Toggles = styled.div`
  padding: 8px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[20]}`};
  margin-top: 8px;

  & > * + * {
    margin-top: 8px;
  }
`;

export const PersonsList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  width: 100%;
  margin: 0;
`;

export const Checked = styled(IconCheck)`
  visibility: hidden;
`;

export const Preview = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 8px;
  object-fit: cover;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
`;

export const PersonRow = styled.li<{ $isChecked: boolean }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      & ${Checked} {
        visibility: visible;
      }
    `}
`;

export const MenuContent = styled.div`
  width: 192px;
`;

export const SelectedPersons = styled.div`
  display: flex;
  align-items: center;

  & > * {
    border-radius: 8px;
    width: 28px;
    height: 28px;
  }

  & > :not(:first-child) {
    margin-left: -8px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
