import styled from 'styled-components';
import Typography from '@reface/ui/Typography';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const ListWrapper = styled.div`
  width: 100%;
`;

export const SubgroupCaption = styled(Typography)`
  padding: 0 10px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[20]};
`;
