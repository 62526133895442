import styled from 'styled-components';

import { LayoutColumn } from 'components/LayoutColumn/LayoutColumn.styled';

export const LayoutContainer = styled.div<{ $size: number }>`
  display: grid;
  grid-template-columns: ${({ $size }) => `repeat(${$size}, ${100 / $size}%)`};

  & & {
    height: 100%;

    ${LayoutColumn} {
      height: 100%;
      padding: 0;

      &:not(:first-child) {
        padding-left: ${({ theme }) => 0.5 * theme.layout.gutter}px;
      }

      &:not(:last-child) {
        padding-right: ${({ theme }) => 0.5 * theme.layout.gutter}px;
      }
    }
  }
`;
