import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '@reface/ui/Button';
import Modal from '@reface/ui/Modal';
import { useModalContext, useModalManager } from '@reface/shared/components/ModalManager';
import * as S from './BulkSwapModal.styled';
import ButtonGroup from '@reface/ui/ButtonGroup';
import { IconSuccessFilled, IconTrash, IconWarningFilled } from '@reface/icons/20px';
import Typography from '@reface/ui/Typography';
import InputText from 'components/InputText';
import { Spacer } from 'components/LayoutTools';
import SwapSettingsMenu, { DEFAULT_SWAP_SETTINGS } from '../../../Swap/components/SwapSettingsMenu';
import { Column } from 'react-table';
import Table from 'components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { selectContentEntries } from 'store/pages/content/contentActions';
import { ContentRecord } from 'api/studio/content';
import { UploadStatus } from 'types/common';
import AddSwapEffectModal, { ADD_SWAP_EFFECT_MODAL, TAB_NAMES } from '../../../Swap/components/AddSwapEffectModal';
import { prepareSwapMapping } from '../../../Swap/utils';
import { SWAP_EVENTS, useSwapQueueContext } from 'App/components/SwapQueue';
import { swapContentThunk, SwapContentThunkResponse } from 'store/pages/swap/swapRequestSlice';
import { AppDispatch } from 'store';
import { AppliedMapping } from '../../../Swap/types';
import { Link } from 'react-router-dom';
import { getType } from 'mime/lite';
import Spinner from '@reface/ui/Spinner';
import formatDate from 'date-fns/format';
import BulkSwapEmptyScreen from './components/BulkSwapEmptyScreen';
import AppliedFaceMappingPreview from './components/AppliedFaceMappingPreview';
import AppliedAnimateMappingPreview from './components/AppliedAnimateMappingPreview';
import { useForm } from 'react-hook-form';
import ContentPersonsForSwap from './components/ContentPersonsForSwap';
import { useEvent } from 'react-use';
import AppliedAudioMappingPreview from './components/AppliedAudioMappingPreview';

export const BULK_CONTENT_SWAP_MODAL = 'BULK_CONTENT_SWAP_MODAL';

type BulkSwapModalProps = {
  name?: string;
  onConfirm?: () => void;
};

const STYLES = {
  modal: {
    width: 664,
  },
};

const BulkSwapModal: React.FC<BulkSwapModalProps> = ({ name = BULK_CONTENT_SWAP_MODAL }) => {
  const dispatch: AppDispatch = useDispatch();
  const tableRaf = useRef<any>();
  const { close, isOpen, value } = useModalContext<string[]>(name);
  const { control, getValues } = useForm({
    defaultValues: {},
  });

  const [operationKey, setOperationKey] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [awaitingSwapIds, setAwaitingSwapIds] = useState<string[]>([]);
  const [appliedMapping, setAppliedMapping] = useState<AppliedMapping | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const entries = useSelector(selectContentEntries(selected));
  const { add: addSwapInQueue } = useSwapQueueContext();
  const [swapOptions, setSwapOptions] = useState(DEFAULT_SWAP_SETTINGS);

  const [openModal] = useModalManager();

  const [process, setProcess] = useState<Record<string, UploadStatus | null>>({});

  const isSwapAvailable = !isProcessing && appliedMapping && Object.values(appliedMapping).filter(Boolean).length;

  const hasImageOnlyOptions = useMemo(() => entries.some((entry) => entry.content_type === 'image'), [entries]);
  const hasVideoOnlyOptions = useMemo(() => entries.some((entry) => entry.content_type === 'video'), [entries]);

  const isResultsReady = useMemo(
    () =>
      Object.keys(process).length === selected.length &&
      Object.values(process).every((status) => status !== UploadStatus.PROCESSING),
    [process, selected]
  );

  const handleSwapsCompleted = useCallback(
    (e: any) => {
      const completedSwap = (e.detail as any[]).filter(({ swap_id }) => awaitingSwapIds.includes(swap_id));

      console.log(awaitingSwapIds, e.detail);

      if (!completedSwap) {
        return;
      }

      setProcess((prevProcessing) => {
        const nextState = {
          ...prevProcessing,
        };

        for (const swap of completedSwap) {
          nextState[swap.object_id] = swap.status === 0 ? UploadStatus.PROCESSED : UploadStatus.PROCESSING_FAILED;
        }

        return nextState;
      });
    },
    [awaitingSwapIds]
  );

  useEvent(SWAP_EVENTS.SWAP_COMPLETED, handleSwapsCompleted);

  const handleBulkSwap = async (appliedMapping: AppliedMapping) => {
    setIsProcessing(true);
    const selectedFaces = getValues();

    for (const record of entries) {
      const selectedRecordFaces = selectedFaces[record.object_id];

      if (!record.data?.persons || !selectedRecordFaces?.length) {
        continue;
      }
      // const appliedRecordMapping = Object.fromEntries(
      //   record.data?.persons?.map((person: any) => [person.person_id, appliedMapping])
      // );

      const appliedRecordMapping = Object.fromEntries(
        selectedRecordFaces?.map((person_id: string) => [person_id, appliedMapping])
      );

      const mapping = prepareSwapMapping(appliedRecordMapping);

      setProcess((prevProcess) => ({
        ...prevProcess,
        [record.object_id]: UploadStatus.PROCESSING,
      }));

      const response = await dispatch(
        swapContentThunk({
          object_id: record.object_id,
          content_type: record.content_type as 'video' | 'image',
          mapping: mapping,
          options: {
            operation_key: operationKey,
            ...swapOptions,
          },
        })
      );
      console.log(response);

      if (response.payload) {
        const swapResponse = response.payload as SwapContentThunkResponse;

        addSwapInQueue(swapResponse.swap_id);
        setAwaitingSwapIds((swap_ids) => [...swap_ids, swapResponse.swap_id]);
      } else {
        setProcess((prevProcess) => ({
          ...prevProcess,
          [record.object_id]: UploadStatus.PROCESSING_FAILED,
        }));
      }
    }
    setIsProcessing(false);
  };

  const handleConfirm = () => {
    handleBulkSwap(appliedMapping);
  };

  const handleClose = () => close();

  const handleItemDelete = useCallback((object_id: string) => {
    setSelected((prev) => prev.filter((o) => o !== object_id));
  }, []);

  const handleTryMoreClick = useCallback(() => {
    setProcess({});
  }, []);

  const handleOpenEffectPicker = (activeTab: TAB_NAMES) => {
    openModal(ADD_SWAP_EFFECT_MODAL, {
      faces: [],
      initialApplyTo: [],
      initialActiveTab: activeTab,
    });
  };

  const handleAddFaceEffect = () => handleOpenEffectPicker(TAB_NAMES.FACE);
  const handleAddAnimateEffect = () => handleOpenEffectPicker(TAB_NAMES.ANIMATE);
  const handleAddRevoiceEffect = () => handleOpenEffectPicker(TAB_NAMES.REVOICE);

  const handleAddSwapEffectConfirm = (appliedMappingDraft: any, triggerReface: boolean) => {
    console.log({ appliedMappingDraft, triggerReface }, appliedMappingDraft['null']);
    setAppliedMapping(appliedMappingDraft['null']);

    if (triggerReface) {
      handleBulkSwap(appliedMappingDraft['null']);
    }
  };

  useEffect(() => {
    console.log('newAppliedMapping', appliedMapping);
  }, [appliedMapping]);

  useEffect(() => {
    if (isOpen) {
      setSelected(value!);
      setOperationKey(formatDate(new Date(), 'Pp'));
      setProcess({});

      tableRaf.current?.cache?.clearAll();
      tableRaf.current?.list?.recomputeRowHeights();
    }
  }, [isOpen]);

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'origin_path',
          Cell: ({ value }: { value: string }) => {
            const isImagePreview = getType(value)?.includes('image/');
            if (isImagePreview) {
              return <S.RecordPreviewImage src={value} />;
            } else {
              return <S.RecordPreviewImage src={value} as={'video'} />;
            }
          },
          width: 64,
        },
        {
          accessor: 'title',
          Cell: ({ value }: { value: string }) => (
            <Typography variant={'body1'} truncate>
              {value}
            </Typography>
          ),
        },
        {
          accessor: 'content_type',
          Cell: ({ value }: { value: string }) => (
            <Typography variant={'body2'} truncate>
              {value}
            </Typography>
          ),
          width: 64,
        },
        /*
    //TODO: ffprobe unavailable for new content

    {
      id: 'duration',
      accessor: ({ ffprobe, content_type }): any => {
        if (content_type === 'image') {
          return 'Image';
        } else {
          console.log(ffprobe);

          return (+ffprobe?.format?.duration).toFixed(2) || '0';
        }
      },
      Cell: ({ value }: { value: string }) => value,
      width: 64,
    },*/
        {
          id: 'persons',
          accessor: ({ data, object_id }): { persons: any[]; object_id: string } => ({
            persons: data?.persons,
            object_id,
          }),
          Cell: ({ value }: { value: { persons: any[]; object_id: string } }) =>
            !!value.persons && (
              <ContentPersonsForSwap
                persons={value.persons}
                name={value.object_id}
                control={control}
                defaultValue={value.persons?.map(({ person_id }) => person_id)}
              />
            ),
          width: 158,
        },
        {
          id: 'actions',
          accessor: 'object_id',
          Cell: ({ value }: { value: string }) => (
            <S.RowActionsWrapper>
              <S.RowStatus>
                {process[value] === UploadStatus.PROCESSING && (
                  <Button icon={<Spinner />} variant={ButtonVariant.SECONDARY} />
                )}

                {process[value] === UploadStatus.PROCESSED && (
                  <Button icon={<IconSuccessFilled />} variant={ButtonVariant.SECONDARY} color={ButtonColor.SUCCESS} />
                )}

                {process[value] === UploadStatus.PROCESSING_FAILED && (
                  <Button icon={<IconWarningFilled />} variant={ButtonVariant.SECONDARY} color={ButtonColor.DANGER} />
                )}
              </S.RowStatus>
              <S.RowActions>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  icon={<IconTrash />}
                  data-id={value}
                  onClick={() => handleItemDelete(value)}
                />
              </S.RowActions>
            </S.RowActionsWrapper>
          ),
          width: 90,
        },
      ] as Column<ContentRecord>[],
    [handleItemDelete, process]
  );

  if (!value) {
    return null;
  }

  return (
    <>
      <Modal
        styles={STYLES}
        title="Bulk Swap"
        actions={({ defaultActionRef }) => (
          <S.ModalActions>
            {isResultsReady ? (
              <ButtonGroup>
                <Button size={ButtonSize.LARGE} component={Link} to={`/history/search?operation_key=${operationKey}`}>
                  See Results
                </Button>

                <Button size={ButtonSize.LARGE} onClick={handleTryMoreClick}>
                  Try More
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  ref={defaultActionRef}
                  size={ButtonSize.LARGE}
                  variant={ButtonVariant.ACTION}
                  onClick={handleConfirm}
                  disabled={!isSwapAvailable}
                >
                  Swap {value?.length} Items
                </Button>
                <Button size={ButtonSize.LARGE} onClick={handleClose}>
                  Close
                </Button>
              </ButtonGroup>
            )}
            <Spacer />
            <SwapSettingsMenu
              hasImageOnlyOptions={hasImageOnlyOptions}
              hasVideoOnlyOptions={hasVideoOnlyOptions}
              onChange={setSwapOptions}
            />
          </S.ModalActions>
        )}
        open={isOpen}
        onClose={handleClose}
      >
        <S.Wrapper>
          <S.Controls>
            <S.EffectButtonsGroup>
              <Button
                variant={ButtonVariant.SECONDARY}
                icon={<AppliedFaceMappingPreview object_id={appliedMapping?.facemapping} />}
                onClick={handleAddFaceEffect}
                disabled={isProcessing}
              >
                Swap Face
              </Button>
              <Button
                variant={ButtonVariant.SECONDARY}
                icon={<AppliedAnimateMappingPreview object_id={appliedMapping?.motionmapping} />}
                onClick={handleAddAnimateEffect}
                disabled={isProcessing}
              >
                Animate
              </Button>
              <Button
                variant={ButtonVariant.SECONDARY}
                icon={<AppliedAudioMappingPreview object_id={appliedMapping?.audiomapping} />}
                onClick={handleAddRevoiceEffect}
                disabled={isProcessing}
              >
                Revoice
              </Button>
            </S.EffectButtonsGroup>
            <Typography variant={'body2'}>Operation Key</Typography>
            <InputText value={operationKey} onChange={setOperationKey} disabled={isProcessing} />
          </S.Controls>
          <Typography variant={'cation'}>Content</Typography>
          <S.ContentTable>
            <Table
              columns={columns}
              items={entries}
              hasMore={false}
              isLoadingMore={false}
              selectable={false}
              noHeader={true}
              ref={tableRaf}
              renderEmptyScreen={BulkSwapEmptyScreen}
            />
          </S.ContentTable>
        </S.Wrapper>
      </Modal>

      <AddSwapEffectModal onConfirm={handleAddSwapEffectConfirm} />
    </>
  );
};

export default memo(BulkSwapModal);
