import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ScratchCanvas, { CanvasPlayerRef, ScratchCanvasProps } from './components/ScratchCanvas/ScratchCanvas';

import * as S from './Editor.styled';
import ToolsSidebar from './components/ToolsSidebar';
import templatesMock from '__mocks__/data/templates.json';
import layoutTemplatesMock from '__mocks__/data/layoutTemplates.json';
import useQuery from '../../hooks/useQuery';
import usePlayerBus, { PlayerBus } from './usePlayerBus';
import { v4 as uuid } from 'uuid';
import { createShadowImageElement, createShadowVideoElement } from '../../utils/fabric/createShadowMediaElement';
import PageLayout from 'App/components/PageLayout';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import useEditorLoading from './hooks/useEditorLoading';
import MemePreviewSaveModal from './components/MemePreviewSaveModal';
import uploadIdleContent from '../../api/pipelines/uploadIdleContent';
import { createContentRecord, fetchContentRecord } from '../../api/studio/content';
import withDropzone from '../../components/Dropzone/withDropzone';
import useEditorExport from './hooks/useEditorExport';
import useToast from '../../hooks/useToast';
import { ToastType } from '../../types/toast';
import { EDITOR_PLAYER_CHANGE } from './editorEvents.constants';
import { isFabricImageObjectVideo } from '../../utils/fabric/guards';
import getCanvasSize from '../../utils/getCanvasSize';
// import { isFabricImageObjectVideo } from '../../utils/fabric/guards';

const useLayoutTemplateBootstrap = (bus: PlayerBus) => {
  const { layout: templateId } = useQuery<{ layout: string }>();

  const createEditorNode = (template: any) => {
    bus.editor.current.loadFromJSON(template, () => {
      bus.dispatch(EDITOR_PLAYER_CHANGE, {
        duration: template.duration || 0,
      });

      bus.editor.current.renderAll();
    });
  };

  useEffect(() => {
    const template = layoutTemplatesMock.find(({ id }) => id === templateId);

    if (template) {
      createEditorNode(template);
    }
  }, [templateId]);
};

const useVideoTemplateBootstrap = (bus: PlayerBus, { setResolution }: any) => {
  const { template: templateId } = useQuery<{ template: string }>();

  const editor = useRef<CanvasPlayerRef>(null!);

  const createEditorNode = (template: any) => {
    let source: Promise<HTMLVideoElement | HTMLImageElement>;

    if (template.content_type === 'video') {
      source = createShadowVideoElement(template.origin_path).then(({ target }) => target);
    } else {
      source = createShadowImageElement(template.origin_path).then(({ target }) => target);
    }

    source.then((target) => {
      const width = isFabricImageObjectVideo(target) ? target.videoWidth : target.naturalWidth;
      const height = isFabricImageObjectVideo(target) ? target.videoHeight : target.naturalHeight;
      const containerElement = bus.editor.current.getElement().closest('#editor-container') as HTMLDivElement;
      const canvasSize = getCanvasSize(containerElement, { width, height });
      // console.log(containerElement, setResolution, { width, height }, canvasSize);
      bus.editor.current.setDimensions(canvasSize);
      bus.editor.current.renderAll();

      setResolution({
        width,
        height,
      });

      bus.addLayer({
        id: template.object_id,
        source: target,
        url: template.origin_path,
        options: {
          cropX: 0,
          cropY: 0,
          // width: isFabricImageObjectVideo(target) ? target.videoWidth / 2 : target.naturalWidth / 2,
          // height: isFabricImageObjectVideo(target) ? target.videoHeight : target.naturalHeight,
        },
      });
    });
  };

  useEffect(() => {
    const template = templatesMock.find(({ object_id }) => object_id === templateId);

    if (template) {
      createEditorNode(template);

      return;
    }

    templateId && fetchContentRecord(templateId).then((template) => createEditorNode(template));
  }, [templateId]);

  return {
    editor,
  };
};

const EditorDropzone = withDropzone(S.EditorContainer, {
  multiple: false,
  maxFiles: 1,
});

const Editor = () => {
  const [downloadPreview, setDownloadPreview] = useState<null | { url: string; filename: string }>(null);
  const { add: addToast } = useToast();

  const options = useMemo(
    () => [
      { value: { width: 1920, height: 1080 }, label: 'full HD ', description: '1920×1080px', key: '1920×1080px' },
      { value: { width: 1280, height: 720 }, label: 'HDTV 16:9 ', description: '1280×720px', key: '1280×720px' },
      { value: { width: 720, height: 720 }, label: 'HDTV 1:1 ', description: '720×720px', key: '720×720px' },
    ],
    []
  );

  const [resolution, setResolution] = useState(options[2].value);
  const bus = usePlayerBus();
  const setLoading = useEditorLoading();
  const { editorExport } = useEditorExport(bus, resolution);

  const uploadFileCallback = useCallback(
    async ([file]: File[]) => {
      setLoading(true);
      try {
        const mediaPayload = await uploadIdleContent(file, {
          visible: false,
        });

        const uploadResponse = await createContentRecord(mediaPayload);

        let source: HTMLVideoElement | HTMLImageElement;

        if (uploadResponse.content_type === 'video') {
          source = await createShadowVideoElement(uploadResponse.origin_path).then(({ target }) => target);
        } else {
          source = await createShadowImageElement(uploadResponse.origin_path).then(({ target }) => target);
        }

        bus.addLayer({
          id: uploadResponse.object_id,
          source,
          url: uploadResponse.origin_path,
          source_url: uploadResponse.origin_path,
        });
      } finally {
        setLoading(false);
      }
    },
    [bus]
  );

  const [activeTab, setActiveTab] = useState('menu');

  const { editor } = useVideoTemplateBootstrap(bus, { setResolution });

  useLayoutTemplateBootstrap(bus);

  const handleStickerObjectAdd = async ({ media }: any) => {
    setLoading(true);
    setActiveTab('menu');

    try {
      // const source = await createShadowVideoElement(media.mp4).then(({ target }) => target);
      const url = media.url.replace(/(\w+).(giphy.com)/i, 'i.giphy.com').replace(/\?\S+/i, '');
      const source = await createShadowImageElement(url).then(({ target }) => target);

      bus.addLayer({
        source,
        url: url,
        source_url: url,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleMainMenuClick = (alias: string) => {
    switch (alias) {
      case 'add_text': {
        bus.addLayer({
          id: uuid(),
          source: {
            tagName: 'TEXT',
            innerText: 'Sample text',
          },
        });
        break;
      }
      case 'add_media': {
        setActiveTab('giply');
        break;
      }
    }
  };

  const handleVideoExport = async () => {
    setLoading(true);
    const canvas = bus.editor.current;
    if (bus.duration) {
      try {
        const [url, error] = await editorExport();

        if (error) {
          addToast({ type: ToastType.ERROR, message: error });
        } else {
          setDownloadPreview({ url, filename: `${uuid()}.mp4` });
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      const url = canvas.toDataURL({
        format: 'png',
        quality: 0.8,
        multiplier: 2,
      });

      setDownloadPreview({ url, filename: `${uuid()}.png` });
    }

    setLoading(false);
  };

  const renderModal: ScratchCanvasProps['renderModal'] = () => <></>;

  return (
    <PageLayout
      header={
        <>
          <Button onClick={handleVideoExport} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM}>
            save meme
          </Button>
        </>
      }
    >
      <EditorDropzone onFileDropped={uploadFileCallback}>
        <S.EditorSidebar>{activeTab === 'menu' && <ToolsSidebar onObjectAdd={handleMainMenuClick} />}</S.EditorSidebar>
        <ScratchCanvas
          ref={editor}
          bus={bus}
          renderModal={renderModal}
          width={resolution.width}
          height={resolution.height}
        >
          <LoadingOverlay />
        </ScratchCanvas>
      </EditorDropzone>

      {downloadPreview && (
        <MemePreviewSaveModal onClose={() => setDownloadPreview(null)} open={true} preview={downloadPreview} />
      )}
    </PageLayout>
  );
};

export default Editor;
