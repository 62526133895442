import { fabric } from 'fabric';
import { IStyledTextboxOptions } from 'fabric/fabric-impl';

const DEFAULT_TEXT_SHADOW = 'rgba(0, 0, 0, 0.2) 0 1px 2px';
const DEFAULT_PADDING = 10;

fabric.StyledTextbox = fabric.util.createClass(fabric.Textbox, {
  type: 'styledTextbox',
  fontWeight: 700,
  padding: DEFAULT_PADDING,
  bgCornerRadius: DEFAULT_PADDING,
  initialize: function (text: string, options: IStyledTextboxOptions) {
    this.callSuper('initialize', text, {
      shadow: DEFAULT_TEXT_SHADOW,
      ...options,
    });
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      backgroundColor: this.get('backgroundColor'),
      padding: this.get('padding'),
      bgCornerRadius: this.get('bgCornerRadius'),
    });
  },

  _roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
    if (w < 2 * r) {
      r = w / 2;
    }
    if (h < 2 * r) {
      r = h / 2;
    }

    const k = 1 - 0.5522847498,
      rx = r,
      ry = r,
      isRounded = r !== 0;

    ctx.beginPath();

    ctx.moveTo(x + rx, y);

    ctx.lineTo(x + w - rx, y);
    isRounded && ctx.bezierCurveTo(x + w - k * rx, y, x + w, y + k * ry, x + w, y + ry);

    ctx.lineTo(x + w, y + h - ry);
    isRounded && ctx.bezierCurveTo(x + w, y + h - k * ry, x + w - k * rx, y + h, x + w - rx, y + h);

    ctx.lineTo(x + rx, y + h);
    isRounded && ctx.bezierCurveTo(x + k * rx, y + h, x, y + h - k * ry, x, y + h - ry);

    ctx.lineTo(x, y + ry);
    isRounded && ctx.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y);

    ctx.closePath();
    ctx.fill();
  },
  _renderBackground(ctx: CanvasRenderingContext2D) {
    if (!this.backgroundColor) {
      return;
    }

    const dim = this._getNonTransformedDimensions();
    // console.log(dim);
    ctx.fillStyle = this.backgroundColor;
    this._roundRect(ctx, -dim.x / 2, -dim.y / 2, dim.x, dim.y, this.bgCornerRadius / this.scaleX);

    // if there is background color no other shadows
    // should be casted
    this._removeShadow(ctx);
  },
  _getNonTransformedDimensions() {
    // Object dimensions
    return new fabric.Point(this.width, this.height).scalarAdd(2 * this.padding);
  },
  _calculateCurrentDimensions() {
    // Controls dimensions
    return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
  },
});

fabric.StyledTextbox.fromObject = function (object: any, callback?: any) {
  return fabric.Object._fromObject('StyledTextbox', object, callback, 'text') as fabric.StyledTextbox;
};
