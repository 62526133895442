import styled from 'styled-components';

import { IconSuccessFilled as _IconSuccessFilled, IconWarningFilled as _IconWarningFilled } from '@reface/icons/20px';

export const UploaderStatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  min-width: 208px;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  transition: background-color 0.3s ease-out;
`;

export const Message = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[90]};
  user-select: none;
  display: inline-flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 16px;
`;

export const IconSuccessFilled = styled(_IconSuccessFilled)`
  margin-right: 4px;

  path {
    fill: ${({ theme }) => theme.palette.success};
  }
`;

export const IconWarningFilled = styled(_IconWarningFilled)`
  margin-right: 4px;

  path {
    fill: ${({ theme }) => theme.palette.error};
  }
`;
