import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchContentArchive,
  fetchMediaContentSearch,
  removeContentForever,
  restoreContentRecords,
} from 'api/studio/content';

export const restoreContentThunk = createAsyncThunk('archive/restoreContentThunk', async (object_ids: string[]) => {
  await restoreContentRecords(object_ids);

  return fetchMediaContentSearch({
    object_ids,
  });
});

export const removeContentForeverThunk = createAsyncThunk('archive/removeContentForeverThunk', removeContentForever);

export const fetchContentArchiveThunk = createAsyncThunk('archive/fetchContentArchiveThunk', fetchContentArchive);
