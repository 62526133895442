import { useContext } from 'react';

import ModalManagerContext from './ModalManagerContext';
const useModalManager = () => {
  const context = useContext(ModalManagerContext);

  const isModalsOpened = !!context.openedModals.length;

  return [context.openModal, context.closeModal, isModalsOpened] as const;
};

export default useModalManager;
