import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as S from './AddSwapEffectModal.styled';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { Spacer } from 'components/LayoutTools';
import Modal from '@reface/ui/Modal';
import { useModalContext } from '@reface/shared/components/ModalManager';
import Tab, { TabType } from '@reface/ui/Tab';
import Tabs from '@reface/ui/Tabs';
import FacesTreeView from './FacesTreeView';
import { fetchFoldersStatisticThunk } from 'store/pages/content/foldersActions';
import { useDispatch } from 'react-redux';
import MotionsTreeView from './MotionsTreeView';
import AudiosTreeView from './AudiosTreeView';
import ContentPersonsDropdown from './ContentPersonsDropdown';
import { AppliedMapping } from '../../types';
import { UploaderDropzone } from 'App/components/Uploader';
import useUploader from 'App/components/Uploader/hooks/useUploader';
import { AVAILABLE_MIME_GROUP } from 'hooks/useContentDropzone';
import { fetchUploadedAssetsThunk } from 'store/pages/assets/assetsActions';
import { useEvent } from 'react-use';
import { CheckAssetProcessingResponse } from 'api/studio/processing';

export const ADD_SWAP_EFFECT_MODAL = 'ADD_SWAP_EFFECT_MODAL';

const prepareSwapMapping = (
  appliedMapping: AppliedMapping,
  selectedPersons: string[]
): { [face_id: string]: AppliedMapping } => {
  if (!selectedPersons.length) {
    return {
      null: appliedMapping,
    };
  }
  return Object.fromEntries(selectedPersons.map((person_id) => [person_id, appliedMapping]));
};

type BulkSwapModalProps = {
  name?: string;
  onConfirm: (mapping: ReturnType<typeof prepareSwapMapping>, triggerReface: boolean) => void;
};

const STYLES = {
  modal: {
    width: 600,
  },
};

export enum TAB_NAMES {
  FACE = 'Swap Face',
  ANIMATE = 'Animate',
  REVOICE = 'Revoice',
  TTS = 'Text to Speech',
}

const typeToAssetType = {
  [TAB_NAMES.FACE]: 'faces',
  [TAB_NAMES.ANIMATE]: 'motions',
  [TAB_NAMES.REVOICE]: 'audios',
} as const;

export type AddSwapEffectModalPayload = {
  disabled?: string[];
  faces: any[];
  initialApplyTo: string[];
  initialActiveTab?: TAB_NAMES;
  appliedMapping: any;
};

const DEFAULT_DISABLED_TABS = [TAB_NAMES.TTS];

const AddSwapEffectModal: React.FC<BulkSwapModalProps> = ({ name = ADD_SWAP_EFFECT_MODAL, onConfirm }) => {
  const dispatch = useDispatch();
  const { close, isOpen, value } = useModalContext<AddSwapEffectModalPayload>(name);
  const [activeTab, setActiveTab] = useState(TAB_NAMES.FACE);
  const { addCreateMedia: addFilesToUploader } = useUploader();

  const [appliedMapping, setAppliedMapping] = useState<AppliedMapping>({
    motionmapping: null,
    facemapping: null,
    audiomapping: null,
  });
  const [calledWithAutoApply, setCalledWithAutoApply] = useState(false);

  const [applyToFaces, setApplyToFaces] = useState<string[]>([]);

  const [disabledTabs, setDisabled] = useState([]);

  const isApplyDisabled = useMemo(() => {
    const initialAppliedMapping = value?.appliedMapping || {
      motionmapping: null,
      facemapping: null,
      audiomapping: null,
    };

    return JSON.stringify(initialAppliedMapping) === JSON.stringify(appliedMapping);
  }, [appliedMapping, value?.appliedMapping]);

  const handleClose = () => close();

  const handleApplyAndReface = () => {
    const mapping = prepareSwapMapping(appliedMapping, applyToFaces);
    onConfirm(mapping, true);
    close();
  };

  const handleApply = () => {
    const mapping = prepareSwapMapping(appliedMapping, applyToFaces);
    console.log(appliedMapping, mapping);
    onConfirm(mapping, false);
    close();
  };

  useEffect(() => {
    if (calledWithAutoApply) {
      handleApply();
    }
  }, [calledWithAutoApply]);

  const handleTabClick = (label: string) => setActiveTab(label as TAB_NAMES);

  useEffect(() => {
    dispatch(fetchFoldersStatisticThunk());
  }, []);

  useEffect(() => {
    if (isOpen) {
      setApplyToFaces(value!.initialApplyTo);
      setActiveTab(value!.initialActiveTab || TAB_NAMES.FACE);
      value?.appliedMapping && setAppliedMapping(value?.appliedMapping);
      setDisabled(value?.disabled || DEFAULT_DISABLED_TABS);
    }
  }, [isOpen]);

  const handleFaceSelectedChange = useCallback((object_id: string, isDoubleClick: boolean) => {
    setAppliedMapping((selected) => ({
      ...selected,
      facemapping: object_id,
    }));
    setCalledWithAutoApply(isDoubleClick);
  }, []);

  const handleMotionSelectedChange = useCallback((motionmapping: [string, string], isDoubleClick: boolean) => {
    setAppliedMapping((selected) => ({
      ...selected,
      motionmapping,
    }));
    setCalledWithAutoApply(isDoubleClick);
  }, []);

  const handleAudioSelectedChange = useCallback((object_id: string, isDoubleClick: boolean) => {
    setAppliedMapping((selected) => ({
      ...selected,
      audiomapping: object_id,
    }));
    setCalledWithAutoApply(isDoubleClick);
  }, []);

  const handleApplyToFacesChanged = useCallback((selected) => {
    setApplyToFaces(selected);
  }, []);

  const dropzoneOptions = useMemo(() => {
    const availableAssetsType = Object.values(AVAILABLE_MIME_GROUP).flat();
    const asset_type = typeToAssetType[activeTab];

    return {
      accept: availableAssetsType,
      noClick: true,
      noKeyboard: true,
      validator: (file) => {
        console.log(file.name, file.type);
        if (!availableAssetsType.includes(file.type)) {
          console.log(`Available MIME types: "${availableAssetsType.join(', ')}"`);
        }

        return null;
      },
      onDrop: (files) => {
        addFilesToUploader(files, {
          parent_id: '',
          asset_type,
        });
      },
    };
  }, [activeTab]);

  /*useEvent('uploader:uploaded', ({ detail }) => {
    const object_ids = detail.items.map(({ object_id }) => object_id);
    dispatch(fetchUploadedAssetsThunk(object_ids));
  });*/

  useEvent('uploader:processed', ({ detail }) => {
    const objects = Object.values(detail as CheckAssetProcessingResponse)
      .map((tracking) => tracking.items)
      .flat()
      .filter(Boolean);
    console.log(objects);
    const object_ids = objects.map(({ object_id }) => object_id);
    dispatch(fetchUploadedAssetsThunk(object_ids));
  });

  if (!value) {
    return null;
  }

  return (
    <Modal
      styles={STYLES}
      title="Add Effects"
      actions={({ defaultActionRef }) => (
        <S.ModalActions>
          <ButtonGroup>
            <Button
              ref={defaultActionRef}
              variant={ButtonVariant.ACTION}
              size={ButtonSize.LARGE}
              onClick={handleApplyAndReface}
              disabled={isApplyDisabled}
            >
              Apply & Reface
            </Button>
            <Button size={ButtonSize.LARGE} onClick={handleApply} disabled={isApplyDisabled}>
              Apply
            </Button>
            <Button size={ButtonSize.LARGE} onClick={handleClose}>
              Close
            </Button>
          </ButtonGroup>
          <Spacer />
          {!!value.faces.length && (
            <ContentPersonsDropdown
              persons={value.faces}
              selected={applyToFaces}
              setSelectedChange={handleApplyToFacesChanged}
            />
          )}
        </S.ModalActions>
      )}
      open={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
    >
      <S.Wrapper>
        <Tabs type={TabType.NORMAL}>
          {Object.entries(TAB_NAMES).map(([key, tab]) => (
            <Tab
              key={key}
              type={TabType.NORMAL}
              label={tab}
              isActive={activeTab === tab}
              onClick={handleTabClick}
              disabled={disabledTabs.includes(tab)}
            />
          ))}
        </Tabs>
        <UploaderDropzone options={dropzoneOptions}>
          {activeTab === TAB_NAMES.FACE && (
            <FacesTreeView onSelectedChanged={handleFaceSelectedChange} selected={appliedMapping.facemapping} />
          )}
          {activeTab === TAB_NAMES.ANIMATE && (
            <MotionsTreeView onSelectedChanged={handleMotionSelectedChange} selected={appliedMapping.motionmapping} />
          )}
          {activeTab === TAB_NAMES.REVOICE && (
            <AudiosTreeView onSelectedChanged={handleAudioSelectedChange} selected={appliedMapping.audiomapping} />
          )}
        </UploaderDropzone>
      </S.Wrapper>
    </Modal>
  );
};

export default AddSwapEffectModal;
