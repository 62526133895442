import useUploader from 'App/components/Uploader/hooks/useUploader';
import { AVAILABLE_MIME_GROUP } from 'hooks/useContentDropzone';
import { useEffect } from 'react';
import { AssetAliasType } from '../../../Files/utils';

const useEffectAssetsUploader = (asset_type: AssetAliasType) => {
  const { addCreateMedia: addFilesToUploader, setUploaderOptions } = useUploader();

  useEffect(() => {
    const availableAssetsType = Object.values(AVAILABLE_MIME_GROUP).flat();

    setUploaderOptions({
      dropzone: {
        accept: availableAssetsType,
        noClick: true,
        noKeyboard: true,
        validator: (file) => {
          console.log(file.name, file.type);
          if (!availableAssetsType.includes(file.type)) {
            console.log(`Available MIME types: "${availableAssetsType.join(', ')}"`);
          }

          return null;
        },
        onDrop: (files) => {
          addFilesToUploader(files, {
            parent_id: '',
            asset_type,
          });
        },
      },
      uploader: {},
    });
  }, []);
};

export default useEffectAssetsUploader;
