import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import Backdrop from '@reface/ui/Backdrop';
import Checkbox from '@reface/ui/Checkbox';
import Dropdown from '@reface/ui/Dropdown';
import Popover from '@reface/ui/Popover';
import { IconCheck } from '@reface/icons/20px';
import bubbleItems from 'utils/bubbleItems';

import * as S from './Multiselect.styled';

export enum MultiselectMode {
  MULTISELECT = 'MULTISELECT',
  SELECT = 'SELECT',
}

export enum MultiselectView {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum MultiselectOrder {
  DEFAULT = 'DEFAULT',
  BUBBLED = 'BUBBLED',
}

export type MultiselectItem = {
  title: string;
  value: string | number;
};

export type MultiselectProps = {
  mode?: MultiselectMode;
  view?: MultiselectView;
  order?: MultiselectOrder;
  label?: string;
  placeholder?: string;
  items?: MultiselectItem[];
  selectedItems?: MultiselectItem[];
  isDisabled?: boolean;
  onSelect?: (item: MultiselectItem) => void;
  className?: string;
};

const Multiselect: React.FC<MultiselectProps> = ({
  mode = MultiselectMode.MULTISELECT,
  view = MultiselectView.VERTICAL,
  order = MultiselectOrder.DEFAULT,
  label,
  placeholder = 'Select',
  items = [],
  selectedItems = [],
  isDisabled = false,
  onSelect,
  ...rest
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const dropdownItems = useMemo(
    () =>
      order === MultiselectOrder.DEFAULT
        ? items
        : order === MultiselectOrder.BUBBLED
        ? bubbleItems({ items, selectedItems })
        : items,
    [order, items, selectedItems]
  );

  const handleSelect = (item: MultiselectItem) => {
    onSelect && onSelect(item);
  };

  if (isDisabled) {
    return (
      <S.Multiselect $view={view} $isEmpty={isEmpty(selectedItems)} $isDisabled {...rest}>
        <S.LabelAndItems>
          {label && <S.Label>{label}</S.Label>}

          <S.Items>
            {isEmpty(selectedItems) && <S.Placeholder>{placeholder}</S.Placeholder>}

            {!isEmpty(selectedItems) &&
              selectedItems.map((selectedItem, index) => (
                <span key={selectedItem.value}>
                  {selectedItem.title || selectedItem.value}
                  {index !== selectedItems.length - 1 && ', '}
                </span>
              ))}
          </S.Items>
        </S.LabelAndItems>
      </S.Multiselect>
    );
  }

  return (
    <>
      <S.Multiselect $view={view} $isEmpty={isEmpty(selectedItems)} {...rest}>
        <Popover
          onIsOpenChanged={setIsEditable}
          popover={({ close }) => (
            <S.Dropdown>
              <Dropdown
                items={dropdownItems}
                onSelect={(item) => {
                  if (mode === MultiselectMode.SELECT) {
                    close();
                  }
                  handleSelect(item);
                }}
                rowRenderer={(item: MultiselectItem) => (
                  <S.DropdownRow $mode={mode}>
                    {mode === MultiselectMode.MULTISELECT && (
                      <Checkbox
                        label={item.title}
                        isChecked={!!selectedItems.find((selectedItem) => selectedItem.value === item.value)}
                      />
                    )}

                    {mode === MultiselectMode.SELECT && (
                      <>
                        <span>{item.title}</span>
                        {!!selectedItems.find((selectedItem) => selectedItem.value === item.value) && <IconCheck />}
                      </>
                    )}
                  </S.DropdownRow>
                )}
              />
            </S.Dropdown>
          )}
        >
          <Backdrop isAlwaysActive={isEditable} isActiveOnHover={true}>
            <S.LabelAndItems>
              {label && <S.Label>{label}</S.Label>}
              <S.Items>
                {isEmpty(selectedItems) && <S.Placeholder>{placeholder}</S.Placeholder>}

                {!isEmpty(selectedItems) &&
                  selectedItems.map((selectedItem, index) => (
                    <span key={selectedItem.value}>
                      {selectedItem.title || selectedItem.value}
                      {index !== selectedItems.length - 1 && ', '}
                    </span>
                  ))}
              </S.Items>
            </S.LabelAndItems>
          </Backdrop>
        </Popover>
      </S.Multiselect>
    </>
  );
};

export default Multiselect;
