import React from 'react';
import RCSlider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled, { css } from 'styled-components';

const StyledSlider = styled(RCSlider)`
  width: 80px;
  height: 12px;

  ${({ vertical }) =>
    vertical
      ? css`
          width: 20px;
          height: 80px;
        `
      : css`
          width: 80px;
          height: 12px;
        `}

  & .rc-slider-rail {
    background-color: ${({ theme }) => theme.colors.gray[20]};
    height: 2px;
  }

  & .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.gray[40]};
    height: 2px;
  }

  &.rc-slider-vertical .rc-slider-track {
    left: 7px;
  }

  & .rc-slider-handle {
    border: none;
    background-color: ${({ theme }) => theme.colors.gray[90]};
    width: 10px;
    height: 10px;
    margin-top: -4px;
  }

  &.rc-slider-vertical .rc-slider-handle {
    margin-left: -1px;
  }

  & .rc-slider-handle:focus-visible {
    box-shadow: unset;
  }

  & .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: unset;
  }
`;

const Slider: React.FC<SliderProps> = (props) => <StyledSlider {...props} />;

export default Slider;
