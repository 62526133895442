import React, { RefObject, useEffect } from 'react';
import { useAudioPlayerMini } from 'components/AudioPlayerMini';
import Button, { ButtonVariant } from '@reface/ui/Button';
import { IconLoop, IconLoopBroken, IconPause, IconPlay } from '@reface/icons/20px';
import VolumeControl from 'components/VolumeControl';
import ButtonGroup from '@reface/ui/ButtonGroup';
import useCachePersistedValue from 'hooks/useCachePersistedValue';

type AudioPlayerControlsProps = {
  sourceRef: RefObject<HTMLVideoElement>;
  previewRef: RefObject<HTMLVideoElement>;
  children: React.ReactNode;
};

const AudioPlayerControls: React.FC<AudioPlayerControlsProps> = ({ sourceRef, previewRef, children }) => {
  const [isLooped, setIsLooped] = useCachePersistedValue('player/loop', (cacheValue) => cacheValue === 'true' ?? false);

  const { audioVolumeLevel, handleAudioVolumeChange } = useAudioPlayerMini(
    previewRef.current ? previewRef.current : sourceRef.current
  );
  const { isReady, isAudioPaused, handleAudioPlayToggle, handleTimeChangeStart } = useAudioPlayerMini(
    sourceRef.current
  );

  useEffect(() => {
    if (!sourceRef.current) {
      return;
    }

    sourceRef.current.loop = isLooped;
  }, [isLooped]);

  useEffect(() => {
    handleTimeChangeStart();
  }, []);

  return (
    <ButtonGroup>
      <Button
        disabled={!isReady}
        icon={isAudioPaused ? <IconPlay /> : <IconPause />}
        variant={ButtonVariant.SECONDARY}
        onClick={handleAudioPlayToggle}
      />
      <Button
        icon={isLooped ? <IconLoop /> : <IconLoopBroken />}
        variant={ButtonVariant.SECONDARY}
        onClick={() => setIsLooped((prevState) => !prevState)}
      />
      <VolumeControl value={audioVolumeLevel} min={0} max={100} onChange={handleAudioVolumeChange} />
      {children}
    </ButtonGroup>
  );
};

export default AudioPlayerControls;
