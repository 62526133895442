import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { firebaseAuth } from 'services/firebase';

export type SignInFirebaseResponse = void;

const signInFirebase = (): SignInFirebaseResponse => {
  const provider = new GoogleAuthProvider();

  provider.addScope('profile');
  provider.addScope('email');

  signInWithPopup(firebaseAuth, provider);
};

export default signInFirebase;
