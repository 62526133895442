import React, { MouseEventHandler, MouseEvent } from 'react';
import classNames from 'classnames';
import * as S from './TreeView.styled';
import { Item } from './utils';
import { IconArrowDropDown, IconArrowDropRight } from '@reface/icons/20px';
import Button, { ButtonVariant } from '@reface/ui/Button';

export interface TreeMenuItem<D extends any> extends Item<D> {
  active: boolean;
  focused: boolean;
  onClick: (e: MouseEvent, id: string, item: any) => void;
  onToggle?: (id: string) => void;
  itemRenderComponent: React.FC<{ type: 'node' | 'group'; item: D; onClick?: MouseEventHandler<HTMLLIElement> }>;
}

export type TreeMenuChildren = (props: {
  search?: (term: string) => void;
  searchTerm?: string;
  items: TreeMenuItem<any>[];
  resetOpenNodes?: (openNodes?: string[]) => void;
}) => JSX.Element;

type TreeViewItemComponent<D = any> = React.FC<TreeMenuItem<D>>;

const TreeViewItem: TreeViewItemComponent = ({
  hasNodes = false,
  isOpen = false,
  level = 0,
  onClick,
  onToggle,
  active,
  focused,
  id,
  item,
  type,
  itemRenderComponent: ItemLabelComponent,
}) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    onClick(e, id, item);
  };

  return (
    <S.TreeViewItem
      $level={hasNodes ? level : level + 1}
      className={classNames({ active: active, focused: focused })}
      role="button"
      aria-pressed={active}
      onClick={handleClick}
    >
      {hasNodes && (
        <Button
          icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropRight />}
          variant={ButtonVariant.SECONDARY}
          onClick={(e) => {
            hasNodes && onToggle && onToggle(id);
            e.stopPropagation();
          }}
        />
      )}

      <ItemLabelComponent type={type} item={item} />
    </S.TreeViewItem>
  );
};

export default TreeViewItem;
