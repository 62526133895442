import styled from 'styled-components';

export const PreviewWrapper = styled.div<{ $variant: 'default' | 'warning' }>`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, $variant }) => ($variant === 'warning' ? theme.colors.yellow[90] : theme.colors.gray[30])};
  max-height: 100%;
`;

export const PreviewContent = styled.div`
  max-width: 256px;
  text-align: center;
`;
