import React, { forwardRef } from 'react';
import * as S from './PageLayout.styled';
import ScreenLoader from '../ScreenLoader';

type PageLayoutProps = {
  header?: JSX.Element;
  controls?: JSX.Element;
  isLoading?: boolean;
};

const PageLayout = forwardRef<HTMLDivElement, PropsWithChildren<PageLayoutProps>>(function PageLayout(
  { children, header, controls, isLoading },
  ref
) {
  return (
    <>
      <S.Wrapper ref={ref}>
        <S.Header>
          {header}
          <S.Spacer />
          {controls}
        </S.Header>
        <S.ContentMain>{children}</S.ContentMain>
      </S.Wrapper>

      {isLoading && <ScreenLoader />}
    </>
  );
});

export default PageLayout;
