import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  background-color: ${({ theme }) => theme.colors.gray[15]};
  overflow: hidden;
`;

export const TimelineWithControlButtons = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`;

export const Timeline = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0 16px;
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray[15]};
  z-index: 11;
`;

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20px;
`;

export const Bar = styled.div`
  position: relative;
  flex: 1;
  height: 6px;
  background: ${({ theme }) => theme.colors.gray[10]};
  border-radius: 20px;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  background: ${({ theme }) => theme.colors.gray[90]};
  border-radius: 20px;
`;

export const Duration = styled.div`
  width: 75px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.gray[90]};
  text-align: center;
  font-weight: 500;
`;

export const InputWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: -5px;
  z-index: 10;
`;

export const Input = styled.input`
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  appearance: none;
  background-color: transparent;
  outline: none;

  &::-webkit-slider-thumb {
    opacity: 1;
    appearance: none;
    height: 12px;
    width: 12px;
    background: ${({ theme }) => theme.colors.gray[90]};
    border: 2px solid ${({ theme }) => theme.colors.gray[30]};
    border-radius: 50%;
  }
`;
