import React from 'react';

import * as S from './MenuItem.styled';

export enum MenuItemVariant {
  PRIMARY = 'PRIMARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}

type MenuItemProps = {
  variant?: MenuItemVariant;
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
};

const MenuItem: React.FC<MenuItemProps> = ({ variant = MenuItemVariant.PRIMARY, onClick, children, className }) => (
  <S.MenuItem $variant={variant} onClick={onClick} className={className}>
    {children}
  </S.MenuItem>
);

export default MenuItem;
