import jwtDecode from 'jwt-decode';

import { AccessToken } from 'types/auth';

const isAccessTokenValid = (accessToken: AccessToken): boolean => {
  try {
    jwtDecode(accessToken);

    return true;
  } catch {
    return false;
  }
};

export default isAccessTokenValid;
