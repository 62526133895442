import React, { useCallback, useMemo, useState } from 'react';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Button, { ButtonSize } from '@reface/ui/Button';
import Typography from '@reface/ui/Typography';
import Modal from '@reface/ui/Modal';
import { useModalContext } from '@reface/shared/components/ModalManager';
import { IconArrowLeft, IconArrowRight } from '@reface/icons/20px';
import * as S from './HistoryPreviewGalleryModal.styled';
import { Spacer } from 'components/LayoutTools';
import { useHotkeys } from 'react-hotkeys-hook';
import PreviewPlayer from './PreviewPlayer';

export const HISTORY_PREVIEW_GALLERY_MODAL = 'HISTORY_PREVIEW_GALLERY_MODAL';

const STYLES = {
  modal: {
    width: '80%',
  },
};

export type ModalPayloadValue = {
  history: any[];
  total: number;
};

type HistoryPreviewGalleryModalProps = {
  name?: string;
  onConfirm?: () => void;
};

const HistoryPreviewGalleryModal: React.FC<HistoryPreviewGalleryModalProps> = ({
  name = HISTORY_PREVIEW_GALLERY_MODAL,
}) => {
  const { close, isOpen, value } = useModalContext<ModalPayloadValue>(name);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClose = () => close();

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === +value?.history?.length - 1;

  const currentPreview = useMemo(() => {
    if (!value?.history) {
      return null;
    }

    return value.history[currentIndex];
  }, [value?.history, currentIndex]);

  const handlePrevClick = useCallback(() => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));
  }, []);

  useHotkeys(
    'left',
    () => {
      handlePrevClick();
    },
    [handlePrevClick]
  );

  const handleNextClick = useCallback(() => {
    setCurrentIndex((prev) => Math.min(prev + 1, value?.history.length - 1));
  }, [value]);

  useHotkeys(
    'right',
    () => {
      handleNextClick();
    },
    [handleNextClick]
  );

  return (
    <Modal
      styles={STYLES}
      scrollable={false}
      title="Bulk Operations Preview"
      actions={() => (
        <S.ModalActions>
          <ButtonGroup>
            <Button
              size={ButtonSize.LARGE}
              icon={<IconArrowLeft />}
              onClick={handlePrevClick}
              disabled={isPrevDisabled}
            >
              Prev
            </Button>
            <Button
              size={ButtonSize.LARGE}
              endIcon={<IconArrowRight />}
              onClick={handleNextClick}
              disabled={isNextDisabled}
            >
              Next
            </Button>
          </ButtonGroup>
          <Spacer />
          <Typography>
            <strong>
              {currentIndex + 1} of {value?.history?.length}
            </strong>
          </Typography>
        </S.ModalActions>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <S.ContentWrapper>
        {currentPreview && (
          <>
            <Typography variant={'body1'}>
              <strong>{currentPreview.origin_object?.title}</strong>
            </Typography>
            <PreviewPlayer
              key={currentPreview.swap_id}
              origin_path={currentPreview.origin_object?.origin_path}
              swap_path={currentPreview?.swap_path}
            />
          </>
        )}
      </S.ContentWrapper>
    </Modal>
  );
};

export default HistoryPreviewGalleryModal;
