import React from 'react';

import { ToastSuccess } from 'types/toast';

import * as S from './ToastContentSuccess.styled';

type ToastContentSuccessProps = {
  toast: ToastSuccess;
};

const ToastContentSuccess: React.FC<ToastContentSuccessProps> = ({ toast }) => (
  <S.ToastContentSuccess>
    {toast.url && (
      <S.Url>
        <strong>URL: </strong>
        {toast.url}
      </S.Url>
    )}

    {toast.message && (
      <S.Message>
        {typeof toast.message === 'object' ? <pre>{JSON.stringify(toast.message, null, 2)}</pre> : toast.message}
      </S.Message>
    )}
  </S.ToastContentSuccess>
);

export default ToastContentSuccess;
