export const EDITOR_LAYERS_ADD = 'editor:layers:add';
export const EDITOR_LAYERS_MODIFY = 'editor:layers:modify';
export const EDITOR_LAYERS_REMOVE = 'editor:layers:remove';
export const EDITOR_LAYERS_SELECT = 'editor:layers:select';

export const EDITOR_MEDIA_LAYER_VIDEO = 'editor_media_element_editor';

export const EDITOR_PLAYER_PLAY = 'editor:player:play';
export const EDITOR_PLAYER_PAUSE = 'editor:player:pause';
export const EDITOR_PLAYER_SEEK_TIME = 'editor:player:seek';
export const EDITOR_PLAYER_ANCHOR_TIME = 'editor:player:anchor';
export const EDITOR_PLAYER_TOGGLE_MUTE = 'editor:player:toggle_mute';
export const EDITOR_PLAYER_CHANGE = 'editor:player:change';
