import { useState } from 'react';

import { REQUEST_POLLING_DELAY } from 'constants/common';
import useInterval from 'hooks/useInterval';

import useUploader from '../Uploader/hooks/useUploader';
import getItemsByStatuses from '../Uploader/utils/getItemsByStatuses';

import { UploadStatus } from 'types/common';
import groupItemsByMetaAssetType from '../Uploader/utils/groupItemsByMetaAssetType';
import { checkContentProcessing } from 'api/studio/processing';
import dispatchEvent from 'utils/dispatchEvent';

const useUploaderCheckInterval = () => {
  const [isCheckProcessingPending, setIsCheckProcessingPending] = useState(false);
  const { items, update } = useUploader();

  const areItemsProcessing = !!getItemsByStatuses(items, [UploadStatus.PROCESSING]).length;

  useInterval(
    async () => {
      const processingItems = getItemsByStatuses(items, [UploadStatus.PROCESSING]);

      const groups = groupItemsByMetaAssetType(processingItems);
      // console.log('groups', groups);

      for (const [, groupItems] of Object.entries(groups)) {
        const processingItemsIds = groupItems.map((item) => item.tracking_id!).filter(Boolean);

        if (!processingItemsIds.length) {
          continue;
        }

        setIsCheckProcessingPending(true);

        const responseItems = await checkContentProcessing(processingItemsIds);

        const completedTasks = Object.entries(responseItems)
          .filter(([, responseItem]) => responseItem.status === 0 || responseItem.status === 2)
          .map(([id]) => id);

        groupItems.map(({ id, tracking_id }) => {
          const isQueueItemProcessed = completedTasks.includes(tracking_id);

          if (isQueueItemProcessed) {
            const trackedTask = responseItems[tracking_id];

            // Group status set to error if one of items failed
            const groupStatus = trackedTask.status === 2 ? UploadStatus.PROCESSING_FAILED : UploadStatus.PROCESSED;

            console.log('uploader:processed', responseItems);
            dispatchEvent('uploader:processed', responseItems);

            update(id, {
              status: groupStatus,
              items: trackedTask.items,
            });
          }
        });
      }

      setIsCheckProcessingPending(false);
    },
    areItemsProcessing && !isCheckProcessingPending ? REQUEST_POLLING_DELAY : null
  );

  return items;
};

export default useUploaderCheckInterval;
