import React from 'react';
import styled from 'styled-components';
import imageAudioSrc from 'static/images/Audio.svg';
import { Wrapper } from './GridContentItem.styled';
import AudioPlayerMini, { S as AudioPlayerStyles } from 'components/AudioPlayerMini';

export const AudioPreviewContainer = styled.div`
  padding: 16px 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Wrapper}:hover & {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }

  & ${AudioPlayerStyles.Duration} {
    display: none;
  }

  & ${AudioPlayerStyles.TimelineWithControlButtons}.paused {
    visibility: hidden;
  }

  ${Wrapper}:hover & ${AudioPlayerStyles.TimelineWithControlButtons} {
    visibility: visible;
  }
`;

const AudioPreviewImage = styled.img`
  width: 60px;
`;

const AudioPreview: React.FC<ContentPreviewProps> = ({ src }) => (
  <AudioPreviewContainer>
    <AudioPreviewImage src={imageAudioSrc} />
    <AudioPlayerMini src={src} />
  </AudioPreviewContainer>
);

export default AudioPreview;
