import styled, { css } from 'styled-components';

export const Image = styled.img<{ $isLoaded: boolean }>`
  height: 60px;
  width: 60px;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  ${({ $isLoaded }) =>
    $isLoaded &&
    css`
      opacity: 1;
    `}
`;
