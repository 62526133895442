import styled, { css } from 'styled-components';

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
`;

export const IconCheckboxOff = styled.div`
  width: 20px;
  height: 20px;

  svg {
    rect {
      transition: fill 0.3s ease-out, stroke 0.3s ease-out;
    }
  }
`;

export const IconCheckboxOn = styled.div`
  width: 20px;
  height: 20px;

  svg {
    rect {
      transition: fill 0.3s ease-out;
    }

    path {
      transition: stroke 0.3s ease-out;
    }
  }
`;

export const IconCheckboxIndeterminate = styled.div`
  width: 20px;
  height: 20px;

  svg {
    rect {
      transition: fill 0.3s ease-out;
    }

    path {
      transition: stroke 0.3s ease-out;
    }
  }
`;

export const Icon = styled.div<{ $isDisabled: boolean }>`
  width: 20px;
  height: 20px;
  user-select: none;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      cursor: pointer;

      ${IconCheckboxOff} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.gray[20]};
            stroke: ${({ theme }) => theme.colors.gray[40]};
          }
        }
      }

      ${IconCheckboxOn} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.blue[100]};
          }
        }

        path {
          stroke: ${({ theme }) => theme.colors.gray[90]};
        }
      }

      ${IconCheckboxIndeterminate} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.blue[100]};
          }

          path {
            stroke: ${({ theme }) => theme.colors.gray[90]};
          }
        }
      }

      &:hover {
        ${IconCheckboxOff} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.gray[25]};
            }
          }
        }

        ${IconCheckboxOn} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.blue[80]};
            }
          }
        }

        ${IconCheckboxIndeterminate} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.blue[80]};
            }
          }
        }
      }

      &:active {
        ${IconCheckboxOff} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.gray[15]};
            }
          }
        }

        ${IconCheckboxOn} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.blue[60]};
            }
          }
        }

        ${IconCheckboxIndeterminate} {
          svg {
            rect {
              fill: ${({ theme }) => theme.colors.blue[60]};
            }
          }
        }
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default;
      pointer-events: none;

      ${IconCheckboxOff} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.gray[15]};
            stroke: ${({ theme }) => theme.colors.gray[20]};
          }
        }
      }

      ${IconCheckboxOn} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.gray[20]};
          }
        }

        path {
          stroke: ${({ theme }) => theme.colors.gray[40]};
        }
      }

      ${IconCheckboxIndeterminate} {
        svg {
          rect {
            fill: ${({ theme }) => theme.colors.gray[20]};
          }

          path {
            stroke: ${({ theme }) => theme.colors.gray[40]};
          }
        }
      }
    `}
`;

export const Label = styled.div<{ $isDisabled: boolean }>`
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s ease-out;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          color: ${({ theme }) => theme.colors.gray[40]};
        `
      : css`
          color: ${({ theme }) => theme.colors.gray[90]};
        `}
`;

export const InputCheckbox = styled.input`
  display: none;
`;
