import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBulkHistoryRecords, SwapHistoryPayload, deleteSwapHistory, getSwapHistory } from 'api/studio/swapHistory';
import prepareHistoryDownloadItems from 'pages/History/utils/prepareHistoryDownloadItems';
import downloadZip from 'utils/downloadZip';

export const fetchBulkHistoryThunk = createAsyncThunk(
  'history/bulk/fetchHistory',
  // if you type your function argument here
  async (options: Pick<SwapHistoryPayload, 'offset' | 'limit'>) => getBulkHistoryRecords(options)
);

export const deleteSwapHistoryThunk = createAsyncThunk(
  'history/bulk/deleteRecord',
  // if you type your function argument here
  async (options: { operation_key?: string; swap_ids?: string[] }) => deleteSwapHistory(options)
);

export const downloadSwapHistoryThunk = createAsyncThunk(
  'history/bulk/download',
  async (options: SwapHistoryPayload) => {
    /*
      offset: 0,
          limit: limit,
          operation_key: operation_key,
          with_object: true
       */
    try {
      const records = await getSwapHistory(options);

      const items = records.swap_history.map((record) => ({
        ...record,
        object: record.origin_object,
      }));
      const zipFilename = `${options.operation_key || options.object_id}.zip`;
      const downloadItems = prepareHistoryDownloadItems(items);

      await downloadZip(downloadItems, zipFilename);
    } catch (e) {
      console.log(e);
    }
  }
);
