import React, {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { chunk } from 'lodash';
import { useEnsuredForwardedRef } from 'react-use';
import Spinner from '@reface/ui/Spinner';
import theme from 'theme';

import * as S from './Grid.styled';
import useGridColumns from '../../hooks/useGridColumns';
import GridContainer, { GridContainerElement } from './GridContainer';

export const DEFAULT_GRID_OPTIONS = {
  defaultColumns: 4,
  minWidth: 250,
};
export const DEFAULT_NUMBER_OF_ROWS = 1;

export type SectionDivider = { title: string; key: string; type: string };

// eslint-disable-next-line @typescript-eslint/ban-types
export type GridProps<T extends {} = {}, V extends 'grid' | 'sections' = 'grid'> = {
  gridOptions?: {
    defaultColumns: number;
    minWidth: number;
  };
  threshold?: number;
  items: V extends 'grid' ? T[] : (SectionDivider & { data: T[] })[];
  hasMore: boolean;
  isLoadingMore: boolean;
  onLoadMore: () => void;
  variant?: V;
  cellRenderer: (cell: T) => React.ReactElement;
  sectionHeaderRenderer?: (section: SectionDivider) => React.ReactElement;
} & HTMLAttributes<HTMLDivElement>;

const isRowDivider = <T extends Record<string, any>>(row: T[] | SectionDivider): row is SectionDivider =>
  (row as SectionDivider)?.type === 'divider';

const prepareGridRows = <T extends Record<string, any>, V extends 'grid' | 'sections' = 'grid'>(
  variant: GridProps<T, V>['variant'],
  items: GridProps<T, V>['items'],
  numberOfColumns: number
): (SectionDivider | T[])[] => {
  if (variant === 'grid') {
    return chunk(items as T[], numberOfColumns);
  } else {
    const rows: (SectionDivider | T[])[] = [];
    for (const { data, ...section } of items as (SectionDivider & { data: T[] })[]) {
      rows.push(section as SectionDivider);
      rows.push(...chunk(data, numberOfColumns));
    }

    return rows;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const Grid = <T extends {}, V extends 'grid' | 'sections'>(
  {
    gridOptions = DEFAULT_GRID_OPTIONS,
    threshold = DEFAULT_NUMBER_OF_ROWS,
    variant = 'grid' as V,
    items,
    hasMore,
    isLoadingMore,
    onLoadMore,
    cellRenderer,
    sectionHeaderRenderer,
    ...rest
  }: GridProps<T, V>,
  ref: React.ForwardedRef<GridContainerElement>
): React.ReactElement => {
  const [mesureContainerRef, numberOfColumns, containerWidth] = useGridColumns(gridOptions);
  const scrollContainer = useRef<HTMLDivElement>(null!);
  const ensuredForwardRef = useEnsuredForwardedRef(ref as MutableRefObject<GridContainerElement>);

  useLayoutEffect(() => {
    scrollContainer.current = document.getElementById('') as HTMLDivElement;
  }, []);

  const rows = useMemo<(SectionDivider | T[])[]>(
    () => prepareGridRows<T, V>(variant, items, numberOfColumns),
    [variant, items, numberOfColumns]
  );

  const handleLoadMore = async () => {
    if (onLoadMore && !isLoadingMore) {
      return onLoadMore();
    }
  };

  useEffect(() => {
    ensuredForwardRef.current?.refresh();
  }, [variant, numberOfColumns]);

  return (
    <S.Grid ref={mesureContainerRef} {...rest}>
      {!!containerWidth && (
        <GridContainer
          hasMore={hasMore}
          threshold={threshold}
          rowCount={rows.length}
          isRowLoaded={({ index }) => !!rows[index]}
          loadMoreRows={handleLoadMore}
          ref={ensuredForwardRef}
        >
          {({ key, index, style, parent }) => (
            <CellMeasurer
              key={key}
              cache={ensuredForwardRef.current.cache}
              parent={parent}
              rowIndex={index}
              columnIndex={0}
            >
              {({ registerChild }) => {
                const row = rows[index];
                const isSectionDivider = isRowDivider(row);

                return (
                  <div
                    ref={registerChild as React.Ref<HTMLDivElement>}
                    style={{
                      ...style,
                      // height: isSectionDivider ? 45 : style.height,
                      paddingTop: index ? theme.layout.grid.gutter : 0,
                      pointerEvents: 'auto',
                    }}
                  >
                    {isSectionDivider ? (
                      <S.SectionRow>{sectionHeaderRenderer ? sectionHeaderRenderer(row) : row.title}</S.SectionRow>
                    ) : (
                      <S.Row $numberOfColumn={numberOfColumns}>{row.map((cell) => cellRenderer(cell))}</S.Row>
                    )}

                    {index === rows.length - 1 && isLoadingMore && (
                      <S.Loader>
                        <Spinner /> Loading…
                      </S.Loader>
                    )}
                  </div>
                );
              }}
            </CellMeasurer>
          )}
        </GridContainer>
      )}
    </S.Grid>
  );
};

export default forwardRef(Grid);
