import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: calc(100% - 64px) 64px;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  justify-content: stretch;
`;

export const PreviewWrapper = styled.div`
  align-self: center;
  display: flex;
  max-height: 100%;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 156px;
  margin-right: 16px;
`;

export const ImagePreview = styled.div<{ $variant: string }>`
  flex-grow: 1;
  display: grid;
  justify-content: center;
  align-items: stretch;
  grid-template-rows: 100%;
  padding: 32px;

  ${({ $variant }) => {
    switch ($variant) {
      case 'both':
        return css`
          grid-template-columns: 50% 50%;
          gap: 16px;
        `;
      default:
        return css`
          grid-template-columns: 100% 0;
        `;
    }
  }}

  img, video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const OriginVideo = styled.img`
  margin: 0 0 0 auto;
`;

export const PreviewImage = styled.img`
  margin: 0 auto 0 0;
`;
