import React from 'react';
import * as Sentry from '@sentry/react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Editor from './Editor';
import Files from './Files';
import Swap from './Swap';
import { History, HistorySearch } from './History';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const PageRoutes = () => (
  <Switch>
    <SentryRoute component={Editor} path={'/editor'} exact={true} />

    <SentryRoute component={Swap} path={'/swap/:object_id'} />
    <SentryRoute component={HistorySearch} path={'/history/search'} />
    <SentryRoute component={History} path={'/history'} />

    <Redirect exact from="/files" to="/files/content" />
    <SentryRoute component={Files} path={'/files/archive'} />
    <SentryRoute component={Files} path={'/files/:asset_type/:parent_id?'} />

    <Redirect to={'/files'} from={'/sign-in'} />
    <Redirect to={'/files/content'} exact from={'/'} />
  </Switch>
);

export default PageRoutes;
