import { createSlice } from '@reduxjs/toolkit';
import { fetchRecordsListThunk } from './mediaActions';
import { ContentRecord } from 'api/studio/content';
import { fetchAssetsThunk } from '../../assets/assetsActions';

const initialState: {
  [object_id: string]: ContentRecord;
} = {};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchRecordsListThunk.fulfilled, (state, action) => {
      for (const record of action.payload.content) {
        state[record.object_id] = record;
      }
    });

    builder.addCase(fetchAssetsThunk.fulfilled, (state, action) => {
      for (const record of action.payload.content) {
        state[record.object_id] = record;
      }
    });
  },
});

export default mediaSlice;
