import React, { memo } from 'react';
import * as S from './Sidebar.styled';
import { IconContent, IconAssets, IconBulkSwap, IconHelp } from '@reface/icons/20px';
import AppUserInfo from 'App/components/AppUserInfo';
import { Link } from 'react-router-dom';

type SidebarProps = {
  onMenuToggle: () => void;
  compact: boolean;
};

const Sidebar: React.FC<SidebarProps> = memo(function Sidebar({ onMenuToggle, compact }) {
  return (
    <S.Wrapper $compact={compact}>
      <S.LogoContainer>
        <S.LogoImage role={'button'} onClick={onMenuToggle} />
        <S.LogoLabel>reface studio</S.LogoLabel>
      </S.LogoContainer>
      <S.MenuItems>
        <S.MenuItem to={'/files/content'}>
          <IconContent /> <S.MenuItemLabel>Content</S.MenuItemLabel>
        </S.MenuItem>
        <S.MenuItem as={Link} to={'/files/motions'}>
          <IconAssets /> <S.MenuItemLabel>Assets</S.MenuItemLabel>
        </S.MenuItem>
        <S.NestedMenuItems>
          <S.MenuItem to={'/files/motions'}>Motions</S.MenuItem>
          <S.MenuItem to={'/files/audios'}>Audio</S.MenuItem>
          <S.MenuItem to={'/files/faces'}>Faces</S.MenuItem>
        </S.NestedMenuItems>
        <S.MenuItem to={'/history'}>
          <IconBulkSwap /> <S.MenuItemLabel>Bulk Face Swap</S.MenuItemLabel>
        </S.MenuItem>
        {/* <S.MenuItem to={'/files/archive'}>
        <IconArchive /> <S.MenuItemLabel>Archive</S.MenuItemLabel>
      </S.MenuItem>*/}
        <S.MenuItem
          href={'https://www.notion.so/Reface-Studio-Manual-071441f681334ebeb79733d6e8f56ec3'}
          as={'a'}
          target={'_blank'}
        >
          <IconHelp /> <S.MenuItemLabel>How Studio Works</S.MenuItemLabel>
        </S.MenuItem>
      </S.MenuItems>
      <S.SidebarFooter>
        <AppUserInfo />
      </S.SidebarFooter>
    </S.Wrapper>
  );
});

export default Sidebar;
