import styled, { css } from 'styled-components';

export const Item = styled.div<{ $isActive?: boolean; $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[40]};
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;

  path {
    transition: fill 0.3s ease-out;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.colors.gray[90]};
      background-color: ${theme.colors.gray[20]};
    `}

  ${({ $isDisabled, theme }) =>
    $isDisabled
      ? css`
          cursor: default;
          pointer-events: none;
          color: ${theme.colors.gray[25]};

          path {
            fill: ${theme.colors.gray[25]};
          }
        `
      : css`
          &:hover {
            color: ${theme.colors.gray[90]};
            background-color: ${theme.colors.gray[25]};

            path {
              fill: ${theme.colors.gray[90]};
            }
          }
        `}

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;

  ${Item} + ${Item} {
    margin-left: 8px;
  }
`;
