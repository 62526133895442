const MIN = 0;
const MAX =
  23 * 60 * 60 * 1000 /* 23 hours */ +
  59 * 60 * 1000 /* 59 minutes */ +
  59 * 1000 /* 59 seconds */ +
  999; /* 999 milliseconds */

const formatTime = (milliseconds?: number): string => {
  if (milliseconds && milliseconds >= MIN && milliseconds <= MAX) {
    return new Date(milliseconds).toISOString().substr(11, 8);
  }

  return '00:00:00';
};

export default formatTime;
