import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ContentRecord } from 'api/studio/content';
import { BreadcrumbsProps } from '../../../components/Breadcrumbs';
import { selectFolderBreadcrumbs } from 'store/pages/content/breadcrumbsSelectors';
import { generatePathByAssetType } from '../../Files/utils';

const useSwapBreadcrumbs = (mediaRecord: ContentRecord | null) => {
  const folders = useSelector(selectFolderBreadcrumbs(mediaRecord?.parent_id || 'root', 'content'));

  return useMemo(() => {
    const breadcrumbs: BreadcrumbsProps['items'] = [
      {
        label: 'All Content',
        to: `/files/content`,
      },
    ];

    const visibleFolders = folders.slice(-2);

    if (folders.length > visibleFolders.length) {
      breadcrumbs.push({
        label: '...',
      });
    }

    breadcrumbs.push(
      ...visibleFolders.map((item) => ({
        label: item.title,
        to: generatePathByAssetType(item.asset_type, item.object_id),
        source: item,
      }))
    );

    breadcrumbs.push(
      mediaRecord
        ? {
            label: mediaRecord.title,
            source: mediaRecord,
          }
        : {
            label: 'Swap content',
          }
    );

    return breadcrumbs;
  }, [mediaRecord]);
};

export default useSwapBreadcrumbs;
