import React from 'react';
import ProcessingStatusBar from './components/ProcessingStatusBar';
import useUploaderCheckInterval from './useUploaderCheckInterval';

const UploaderProcessingToastBox: React.FC = () => {
  const items = useUploaderCheckInterval();

  if (!items.length) {
    return null;
  }

  return <ProcessingStatusBar items={items} />;
};

export default UploaderProcessingToastBox;
