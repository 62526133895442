import React, { useEffect, useRef } from 'react';

const useCombinedRef = <T>(
  ...refs: (((instance: T | null) => void) | React.MutableRefObject<T | null> | null)[]
): React.MutableRefObject<T | null> => {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export default useCombinedRef;
