import React from 'react';
import { IconFacePicker } from '@reface/icons/20px';
import { useSelector } from 'react-redux';
import { mediaDetailsSelector } from 'store/pages/files/media/mediaSelectors';
import * as S from '../BulkSwapModal.styled';

const AppliedFaceMappingPreview: React.FC<{ object_id?: string | null }> = ({ object_id }) => {
  const appliedFace = useSelector(mediaDetailsSelector(object_id || ''));

  return (
    <S.AppliedMappingPreview>
      {appliedFace ? (
        <S.AppliedFacePreviewImage src={appliedFace.origin_path} />
      ) : (
        <S.EmptyPreview as={IconFacePicker} />
      )}
    </S.AppliedMappingPreview>
  );
};

export default AppliedFaceMappingPreview;
