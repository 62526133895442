import React from 'react';

import { ToastError } from 'types/toast';

import * as S from './ToastContentError.styled';

type ToastContentErrorProps = {
  toast: ToastError;
};

const ToastContentError: React.FC<ToastContentErrorProps> = ({ toast }) => (
  <S.ToastContentError>
    {toast.url && (
      <S.Url>
        <strong>URL: </strong>
        {toast.url}
      </S.Url>
    )}

    {toast.error && (
      <S.Error>
        <strong>Error: </strong>
        {toast.error}
      </S.Error>
    )}

    {toast.message && (
      <S.Message>
        <strong>Message: </strong>
        {typeof toast.message === 'object' ? <pre>{JSON.stringify(toast.message, null, 2)}</pre> : toast.message}
      </S.Message>
    )}
  </S.ToastContentError>
);

export default ToastContentError;
