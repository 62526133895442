import React, { useEffect, useRef } from 'react';

import { IconCheckboxIndeterminate, IconCheckboxOff, IconCheckboxOn } from '@reface/icons/20px';

import * as S from './Checkbox.styled';

export type CheckboxProps = {
  label?: string;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  disabled?: boolean;
  onChange?: () => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isChecked = false,
  isIndeterminate = false,
  disabled = false,
  onChange,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  // NOTE: No browser currently supports indeterminate as an attribute.
  //       It must be set via JavaScript. See Indeterminate state checkboxes for details:
  //       https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = isIndeterminate;
    }
  }, [isIndeterminate]);

  return (
    <S.Checkbox>
      <S.Icon $isDisabled={disabled} onClick={onChange}>
        {!isIndeterminate && (
          <>
            {isChecked ? (
              <S.IconCheckboxOn>
                <IconCheckboxOn />
              </S.IconCheckboxOn>
            ) : (
              <S.IconCheckboxOff>
                <IconCheckboxOff />
              </S.IconCheckboxOff>
            )}
          </>
        )}

        {isIndeterminate && (
          <S.IconCheckboxIndeterminate>
            <IconCheckboxIndeterminate />
          </S.IconCheckboxIndeterminate>
        )}
      </S.Icon>

      {label && <S.Label $isDisabled={disabled}>{label}</S.Label>}

      <S.InputCheckbox ref={ref} type="checkbox" checked={isChecked} disabled={disabled} readOnly />
    </S.Checkbox>
  );
};
export default Checkbox;
