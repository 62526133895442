import styled, { css } from 'styled-components';
import FolderImage from 'static/images/folderSmall.svg';

const NESTED_PADDING = 36;

export const TreeViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TreeViewList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  text-align: left;
  width: 100%;
`;

export const InputText = styled.div`
  width: 100%;

  & + & {
    margin-top: 24px;
  }
`;

export const TreeViewLabel = styled.div`
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0 8px;
`;

export const ActiveIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-self: flex-end;
  visibility: hidden;

  path {
    fill: ${({ theme }) => theme.colors.gray[90]};
  }
`;

export const TreeViewItem = styled.li<{ $level: number; $type?: string }>`
  padding: 0.75rem 1rem;
  cursor: pointer;
  background: none;
  box-shadow: none;
  z-index: unset;
  position: relative;
  padding-left: ${({ $level }) => `${$level * NESTED_PADDING}px`};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[90]};
  border-radius: 8px;
  box-sizing: border-box;

  &:empty {
    padding: 0;
  }

  ${({ $type }) =>
    $type !== 'group' &&
    css`
      height: 48px;
      border: 1px solid transparent;
    `};

  &.active {
    color: white;
    background: ${({ theme }) => theme.colors.gray[10]};
    border: ${({ theme }) => `1px solid ${theme.colors.blue[80]}`};
  }

  &.active ${ActiveIcon} {
    visibility: visible;
  }

  &.focused {
    box-shadow: 0 0 5px 0 #222;
    z-index: 999;
  }
`;

export const Folder = styled.div`
  width: 48px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  background-image: url('${FolderImage}');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
  border-radius: 4px;
`;
