import { fabric } from 'fabric';
import { createShadowImageElement, createShadowVideoElement } from '../createShadowMediaElement';
import { IImageOptions } from 'fabric/fabric-impl';

fabric.MaskedMedia = fabric.util.createClass(fabric.Image, {
  type: 'maskedMedia',
  objectCaching: true,
  statefullCache: true,
  cacheProperties: ['videoTime'],
  initialize(element?: string | HTMLImageElement | HTMLVideoElement, options?: IImageOptions) {
    this.callSuper('initialize', element, options);
  },
  _applyMaskFilter(target: HTMLVideoElement | HTMLImageElement) {
    const fImage = new fabric.MaskedMedia(target, {
      // width: canvas.width / 2,
      // height: canvas.height / 2,
    });

    const mask = new fabric.Image.filters.BlendImage({
      image: fImage,
      mode: 'alpha',
      alpha: 0.5,
    });

    this.filters && this.filters.push(mask);
  },
  async applyVideoMask(path: string) {
    const { target } = await createShadowVideoElement(path);
    this._applyMaskFilter(target);
  },
  async applyImageMask(path: string) {
    const { target } = await createShadowImageElement(path);
    this._applyMaskFilter(target);
  },
});

fabric.MaskedMedia.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object);
  const loadMediaMethod = object.src.slice(-4) === '.mp4' ? fabric.util.loadVideo : fabric.util.loadImage;

  loadMediaMethod(
    object.src,
    function (img, isError) {
      if (isError) {
        callback && callback(null, true);
        return;
      }

      fabric.Image.prototype._initFilters.call(object, object.filters, function (filters: any) {
        object.filters = filters || [];
        fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters: any[]) {
          object.resizeFilter = resizeFilters[0];
          fabric.util.enlivenObjects(
            [object.clipPath],
            function (enlivedProps: any[]) {
              object.clipPath = enlivedProps[0];
              if (img) {
                const image = new fabric.MaskedMedia(img, object);
                callback(image, false);
              } else {
                callback(null, false);
              }
            },
            ''
          );
        });
      });
    },
    null,
    object.crossOrigin
  );
};
