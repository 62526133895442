import React from 'react';

import { IconLinkOutside } from '@reface/icons/20px';

import * as S from './Backdrop.styled';

export type BackdropProps = {
  isAlwaysActive?: boolean;
  isActiveOnHover?: boolean;
  onHoverClick?: () => void;
  children?: React.ReactNode;
};

const Backdrop: React.FC<BackdropProps> = ({
  children,
  isAlwaysActive = false,
  isActiveOnHover = false,
  onHoverClick,
}) => {
  const isClickableOnHover = !!onHoverClick;

  return (
    <S.Backdrop
      $isAlwaysActive={isAlwaysActive}
      $isActiveOnHover={isActiveOnHover}
      $isClickableOnHover={isClickableOnHover}
    >
      {children}

      {isClickableOnHover && (
        <S.Button onClick={onHoverClick}>
          <IconLinkOutside />
        </S.Button>
      )}
    </S.Backdrop>
  );
};

export default Backdrop;
