import React, { forwardRef } from 'react';
import { Modal as ReactResponsiveModal } from 'react-responsive-modal';
import styled, { css } from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

import { ModalProps } from './Modal';

export const Modal = styled(
  forwardRef<HTMLDivElement, ModalProps & { className?: string }>(({ className, ...rest }, ref) => (
    <ReactResponsiveModal
      blockScroll={false}
      {...rest}
      container={document.getElementById('modal')}
      ref={ref}
      classNames={{
        root: className,
        ...{
          overlay: 'overlay',
          modal: 'modal',
          closeButton: 'closeButton',
        },
      }}
    />
  ))
)`
  .overlay {
    animation-fill-mode: forwards !important;
    background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.75)};
  }

  .modal {
    animation-fill-mode: forwards !important;
    min-width: 436px;
    max-width: none;
    margin: 80px 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.gray[15]};
    border-radius: 16px;
  }

  .closeButton {
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.gray[20]};
    outline: none;
    transition: background-color 0.3s ease-out;

    path {
      fill: ${({ theme }) => theme.colors.gray[90]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[25]};
    }
  }
`;

export const Title = styled.div`
  margin: 24px 56px 16px 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const ScrollableContent = styled.div<{ $isScrollable: boolean }>`
  ${({ $isScrollable }) =>
    $isScrollable
      ? css`
          max-height: 586px;
          overflow-y: auto;
        `
      : css`
          max-height: unset;
          overflow-y: auto;
        `}
`;

export const Content = styled.div<{
  $sidePaddings: ModalProps['sidePaddings'];
  $noTitle: boolean;
  $noActions: boolean;
}>`
  color: ${({ theme }) => theme.colors.gray[40]};
  display: flex;

  ${({ $sidePaddings }) =>
    $sidePaddings
      ? css`
          margin-left: 24px;
          margin-right: 24px;
        `
      : css`
          margin-left: 0;
          margin-right: 0;
        `}

  ${({ $noTitle }) =>
    $noTitle &&
    css`
      margin-top: 24px;
    `}

  ${({ $noActions }) =>
    $noActions &&
    css`
      margin-bottom: 24px;
    `}
`;

export const Actions = styled.div`
  margin: 20px 24px 24px;
`;
