import styled from 'styled-components';

export const Label = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const InputTextWrapper = styled.div<{ $clearable: boolean }>`
  position: relative;

  & > input[type='text'] {
    display: block;
    width: 100%;
    padding: ${({ $clearable }) => ($clearable ? '7px 40px 7px 10px' : '7px')};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[90]};
    background-color: ${({ theme }) => theme.colors.gray[8]};
    border: 1px solid ${({ theme }) => theme.colors.gray[15]};
    border-radius: 8px;
    outline: none;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.gray[5]};
      border-color: ${({ theme }) => theme.colors.gray[25]};
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray[40]};
      opacity: 1;
    }
  }

  & > button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    path {
      transition: fill 0.3s ease-out;
    }

    &:hover {
      path {
        fill: ${({ theme }) => theme.colors.gray[90]};
      }
    }
  }
`;
