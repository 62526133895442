import { createContext } from 'react';

export type SwapQueueContextType = {
  items: string[];
  add(...swap_ids: string[]): void;
  reset(): void;
  remove(...swap_ids: string[]): void;
};

const SwapQueueContext = createContext<SwapQueueContextType>({
  items: [],
  add(...swap_ids) {
    console.log('add queue handler', swap_ids);
  },
  reset() {
    console.log('reset queue handler');
  },
  remove(...swap_ids) {
    console.log('remove queue handler', swap_ids);
  },
});

export default SwapQueueContext;
