import React from 'react';

import LayoutColumn from 'components/LayoutColumn';
import LayoutContainer from 'components/LayoutContainer';
import { ReactComponent as IconLogo } from 'static/images/logo.svg';

import SignInWithGoogle from './components/SignInWithGoogle';

import * as S from './SignIn.styled';

const SignInPage: React.FC<{ children?: React.ReactNode }> = () => (
  <LayoutContainer>
    <LayoutColumn>
      <S.SignIn>
        <S.Logo>
          <IconLogo />
          <span>reface studio</span>
        </S.Logo>

        <SignInWithGoogle />
      </S.SignIn>
    </LayoutColumn>
  </LayoutContainer>
);

export default SignInPage;
