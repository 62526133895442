import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { FolderStatistic, PARENT_ID_ROOT } from './foldersSlice';

function findItem(
  items: FolderStatistic[],
  prevItems: FolderStatistic[],
  parent_id: string,
  deepCounter: number
): FolderStatistic[] {
  if (!parent_id || parent_id === 'root' || deepCounter > 10) {
    return prevItems;
  }

  const item = items.find(({ object_id }) => object_id === parent_id);
  if (!item) {
    return [];
  }

  return findItem(items, [...prevItems, item], item.parent_id, deepCounter + 1);
}

export const selectFolderBreadcrumbs = (parent_id: string, asset_type: string) =>
  createSelector(
    (state: RootState) => state.pages.content.folders.entities,
    (entities) => {
      const assetEntries = entities.filter(
        (item) => item.asset_type === asset_type || item.object_id === PARENT_ID_ROOT
      );

      return findItem(assetEntries, [], parent_id, 0).reverse();
    }
  );
