import styled from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  background-color: ${({ theme }) => theme.colors.gray[15]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  //box-shadow: 0 4px 24px ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.5)};
  overflow: hidden;
`;
