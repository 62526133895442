import { useEffect, useMemo, useState } from 'react';
import { cacheInstance } from 'services/cache';

const useCachePersistedValue = <T>(cacheKey, defaultValueCb: (cacheValue: string) => T) => {
  const initialVolume = useMemo<T>(() => defaultValueCb(cacheInstance.getItem(cacheKey)), []);

  const [audioVolumeLevel, setAudioVolumeLevel] = useState<T>(initialVolume);

  useEffect(() => {
    cacheInstance.setItem(cacheKey, `${audioVolumeLevel}`);
  }, [audioVolumeLevel]);

  return [audioVolumeLevel, setAudioVolumeLevel] as const;
};

export default useCachePersistedValue;
