import styled, { css } from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

export const UploaderDropzone = styled.div`
  height: 100%;
`;

const activeHintState = css`
  color: ${({ theme }) => theme.colors.gray[90]};
  background-color: ${({ theme }) => hexToRGB(theme.colors.blue[100], 0.1)};
  border-color: ${({ theme }) => theme.colors.blue[100]};
`;

type HintProps = {
  $isDragging: boolean;
  $position: 'absolute' | 'fixed';
};

export const Hint = styled.div<HintProps>`
  @keyframes fade-in {
    0% {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
      visibility: visible;
    }

    100% {
      ${activeHintState}
    }
  }

  @keyframes fade-out {
    0% {
      ${activeHintState};
      visibility: visible;
    }

    100% {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
    }
  }

  position: ${({ $position }) => $position};
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border: 2px dashed transparent;
  border-radius: 16px;
  user-select: none;
  z-index: 999;

  ${({ $isDragging }) =>
    $isDragging
      ? css`
          ${activeHintState};
          visibility: visible;
          animation: fade-in 0.3s;
        `
      : css`
          color: transparent;
          background-color: transparent;
          border-color: transparent;
          visibility: hidden;
          animation: fade-out 0.3s;
        `}}
`;
