import React, { CSSProperties, memo, RefObject, useEffect, useState } from 'react';
import { IconFace } from '@reface/icons/20px';
import * as S from './EmptySwapPreview.styled';
import Typography from '@reface/ui/Typography';
import SvgIcon from '@reface/icons/components/SvgIcon';

type EmptySwapPreviewProps = {
  sourceRef?: RefObject<HTMLVideoElement | HTMLImageElement>;
  icon?: typeof SvgIcon;
  message?: string;
  variant?: 'default' | 'warning';
};

const EmptySwapPreview: React.FC<EmptySwapPreviewProps> = ({
  sourceRef,
  icon: IconComponent = IconFace,
  message,
  variant,
}) => {
  const [style, setStyle] = useState<CSSProperties>({
    aspectRatio: 'auto',
  });

  useEffect(() => {
    if (!sourceRef) {
      return;
    }

    sourceRef.current?.addEventListener('loadedmetadata', (e) => {
      const target = e.target as HTMLVideoElement;
      setStyle({
        aspectRatio: `${target.videoWidth / target.videoHeight}`,
      });
    });

    sourceRef.current?.addEventListener(
      'load',
      (e) => {
        const target = e.target as HTMLImageElement;
        setStyle({
          aspectRatio: `${target.naturalWidth / target.naturalHeight}`,
        });
      },
      false
    );
  }, []);

  return (
    <S.PreviewWrapper style={style} $variant={variant}>
      <S.PreviewContent>
        <IconComponent width={96} height={96} />
        <Typography variant={'body2'} color={'inherit'}>
          {message ?? 'Nothing here yet. Hit Reface button to generate modified content.'}
        </Typography>
      </S.PreviewContent>
    </S.PreviewWrapper>
  );
};

export default memo(EmptySwapPreview);
