import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import FolderImage from 'static/images/folderLarge.svg';
import { Button } from '@reface/ui/Button/Button.styled';
import ContentItemPersonsPreview from '../ContentItemPersonsPreview';
import Typography from '@reface/ui/Typography';

export const PreviewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;

  & > .video-container {
    flex-grow: 1;
    overflow: hidden;
  }

  & video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const DetailsLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[90]};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[90]};
  display: inline-flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
`;

export const CreatedDate = styled(Typography).attrs({
  variant: 'body2',
})`
  font-size: 12px;
  flex-grow: 1;
`;

export const FolderTotals = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const SelectableWrapper = styled.div<{
  $isOver: boolean;
  $canDrop: boolean;
}>`
  border: 2px solid transparent;
  border-radius: 16px;
  overflow: hidden;

  ${({ $isOver, $canDrop }) => {
    if ($isOver && $canDrop) {
      return css`
        border: 2px solid rebeccapurple;
      `;
    }
  }}

  &:hover, &.active {
    border-color: ${({ theme }) => theme.colors.blue[60]};
  }

  &.selected {
    border-color: ${({ theme }) => theme.colors.blue[100]};
  }

  .selecting &.selectable {
    pointer-events: none;
  }
`;

export const Details = styled.div<{ $bg: boolean }>`
  padding: 12px 16px 16px;
  z-index: 1;

  ${({ $bg }) =>
    $bg &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[10]};
    `};
`;

export const Wrapper = styled.div<{
  $folder: boolean;
  $isDragging: boolean;
}>`
  position: relative;
  aspect-ratio: 1.3;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 48px;
  grid-template-columns: 100%;

  ${SelectableWrapper}.active & ${Details},
  &:hover ${Details} {
    margin-top: -16px;
    height: 68px;
    padding: 12px 8px 16px 16px;

    ${({ $folder, theme }) =>
      !$folder &&
      css`
        background-color: ${theme.colors.gray[15]};
      `}
  }

  ${({ $folder }) =>
    $folder &&
    css`
      background-image: url('${FolderImage}');
      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      opacity: 0.5;
    `}
`;

export const DetailsControls = styled.div`
  display: inline-flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  visibility: hidden;

  ${SelectableWrapper}.active ${Wrapper} &,
  ${Wrapper}:hover & {
    visibility: visible;
  }

  ${Button} {
    padding: 0;
    min-height: 20px;
    min-width: 20px;
    border-radius: 6px;
  }
`;

export const ContentDuration = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-left: 32px;
  justify-self: flex-end;
  padding-right: 8px;

  ${Title} & {
    padding-right: 0;
  }

  ${SelectableWrapper}.active ${Wrapper} ${Title} &,
  ${Wrapper}:hover ${Title} & {
    display: none;
  }
`;

export const ContentPersons = styled(ContentItemPersonsPreview)`
  position: absolute;
  top: 16px;
  left: 16px;
`;
