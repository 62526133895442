import React from 'react';
import useContentDropzone, { UseContentDropzoneOptions } from '../../hooks/useContentDropzone';
import * as S from './withDropzone.styled';

type DropzoneProps = {
  onFileDropped: (files: File[]) => void;
  children?: React.ReactNode;
};

function withDropzone<T>(WrappedComponent: React.ComponentType<T>, options: UseContentDropzoneOptions) {
  const WithDropzone: React.FC<T & DropzoneProps> = ({ onFileDropped, ...props }) => {
    const { getRootProps, getInputProps, isDragActive } = useContentDropzone(onFileDropped, options);

    return (
      <WrappedComponent {...getRootProps()} {...(props as T)}>
        {props.children}

        <input {...getInputProps()} />
        <S.Hint $isDragging={isDragActive} />
      </WrappedComponent>
    );
  };
  return WithDropzone;
}

export default withDropzone;
