import styled from 'styled-components';

export const AnchorContainer = styled.div`
  position: absolute;
  left: 136px;
  right: 8px;
  top: 48px;
  bottom: 0;
  pointer-events: none;
`;

export const TimeAnchor = styled.div`
  display: block;
  position: absolute;
  width: 4px;
  margin-left: -2px;
  border-radius: 3px;
  height: 100%;
  z-index: 0;

  &:before {
    content: ' ';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjM4ODAyIDEuMDk1OTNDMi42ODY0OSAxIDMuMDU0OTYgMSAzLjc5MTkgMUg4SDlIMTMuMjA4MUMxMy45NDUgMSAxNC4zMTM1IDEgMTQuNjEyIDEuMDk1OTNDMTUuMjI1OCAxLjI5MzIgMTUuNzA2OCAxLjc3NDI1IDE1LjkwNDEgMi4zODgwMkMxNiAyLjY4NjQ5IDE2IDMuMDU0OTYgMTYgMy43OTE5QzE2IDQuNDA4NjIgMTYgNC43MTY5NyAxNS45NTU2IDUuMDEyNDJDMTUuODY1MSA1LjYxNDg4IDE1LjYzODIgNi4xODg3MSAxNS4yOTIyIDYuNjkwMTJDMTUuMTIyNSA2LjkzNjAxIDE0LjkxMTYgNy4xNjA5NyAxNC40ODk4IDcuNjEwODlMOSAxMy40NjY3VjE0SDhWMTMuNDY2N0wyLjUxMDIxIDcuNjEwODlDMi4wODg0MSA3LjE2MDk3IDEuODc3NTEgNi45MzYwMSAxLjcwNzgyIDYuNjkwMTJDMS4zNjE4IDYuMTg4NzEgMS4xMzQ4OCA1LjYxNDg4IDEuMDQ0MzggNS4wMTI0MkMxIDQuNzE2OTcgMSA0LjQwODYyIDEgMy43OTE5QzEgMy4wNTQ5NiAxIDIuNjg2NDkgMS4wOTU5MyAyLjM4ODAyQzEuMjkzMiAxLjc3NDI1IDEuNzc0MjUgMS4yOTMyIDIuMzg4MDIgMS4wOTU5M1oiIGZpbGw9IiNFNkNGNUMiLz4KPC9zdmc+Cg==);
    width: 16px;
    height: 15px;
    position: absolute;
    right: 50%;
    margin-right: -8px;
    top: -1px;
    z-index: 1;
  }

  &:after {
    position: absolute;
    height: 100%;
    content: ' ';
    width: 1px;
    background-color: ${({ theme }) => theme.colors.yellow[90]};
    box-shadow: #00000077 0 0 2px 2px;
    margin-left: 2px;
  }
`;
