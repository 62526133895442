import styled from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

export const Dropdown = styled.div`
  padding: 6px 0;
  background-color: ${({ theme }) => theme.colors.gray[15]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  box-shadow: 0 4px 24px ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.5)};
`;

export const Item = styled.div`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.gray[15]};
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[25]};
  }
`;

export const EmptyState = styled.div`
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.gray[15]};
`;
