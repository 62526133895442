import React, { useEffect, useState } from 'react';

import useInterval from 'hooks/useInterval';
import {
  EDITOR_PLAYER_ANCHOR_TIME,
  EDITOR_PLAYER_CHANGE,
  EDITOR_PLAYER_PAUSE,
  EDITOR_PLAYER_PLAY,
  EDITOR_PLAYER_SEEK_TIME,
  EDITOR_PLAYER_TOGGLE_MUTE,
} from '../../../editorEvents.constants';
import { PlayerBus } from '../../../usePlayerBus';

const TIMELINE_BAR_UPDATE_TIME = 50;

type UseAudioPlayerMiniReturn = {
  timeInParts: number;
  durationInSeconds: number;
  isReady: boolean;
  isAudioPaused: boolean;
  isAudioMuted: boolean;
  handleAudioPlayToggle: () => void;
  handleAudioMuteToggle: () => void;
  handleTimeChangeStart: () => void;
  handleTimeChangeEnd: () => void;
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const useMediaPlayer = (bus: PlayerBus): UseAudioPlayerMiniReturn => {
  const [isReady, setIsReady] = useState(false);

  const [timeInParts, setTimeInParts] = useState(0);
  const [durationInSeconds, setDurationInSeconds] = useState(0);

  const [isAudioPaused, setIsAudioPaused] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(false);

  // const [shouldPlayingBeResumed, setShouldPlayingBeResumed] = useState(false);

  const handleAudioPlayToggle = () => {
    if (isAudioPaused) {
      bus.dispatch(EDITOR_PLAYER_PLAY);
    } else {
      bus.dispatch(EDITOR_PLAYER_PAUSE, {
        seek: +timeInParts * durationInSeconds,
      });
    }

    setIsAudioPaused(!isAudioPaused);
  };

  const handleAudioMuteToggle = () => {
    bus.dispatch(EDITOR_PLAYER_TOGGLE_MUTE);
    setIsAudioMuted(!isAudioMuted);
  };

  const handleTimeChangeStart = () => {
    // setShouldPlayingBeResumed(!audioRef.current?.paused);
    // audioRef.current?.pause();
  };

  const handleTimeChangeEnd = () => {
    // if (shouldPlayingBeResumed) {
    //   audioRef.current?.play();
    // }
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeInParts = +event.target.value;

    bus.dispatch(EDITOR_PLAYER_SEEK_TIME, {
      seek: +timeInParts * durationInSeconds,
    });
    //
    // if (audioRef.current) {
    //   audioRef.current.currentTime = timeInParts * audioRef.current.duration;
    // }
    //
    setTimeInParts(timeInParts);
  };

  const handleVisibilityChange = () => {
    // // when user switch to another tab/window audio paused
    // if (!isAudioPaused && !audioRef.current?.paused && document.hidden) {
    //   audioRef.current?.pause();
    // }
    //
    // // when user switch to current page audio continue playing if it was paused by previous condition
    // if (!isAudioPaused && audioRef.current?.paused && !document.hidden) {
    //   audioRef.current?.play();
    // }
  };

  useInterval(
    () => {
      // if (audioRef.current) {
      //   const currentTimeInParts = audioRef.current.currentTime / audioRef.current.duration;
      //
      //   if (currentTimeInParts) {
      //     setTimeInParts(currentTimeInParts);
      //   }
      // }
    },
    !isAudioPaused ? TIMELINE_BAR_UPDATE_TIME : null
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAudioPaused]);

  useEffect(() => {
    const handlePlayerChange = (e: any) => {
      setDurationInSeconds(e.detail.duration);
      setIsReady(true);
    };

    const handlePlayerAnchor = (e: any) => {
      const currentTimeInParts = e.detail.time / e.detail.duration;

      if (currentTimeInParts) {
        setTimeInParts(currentTimeInParts);
      }
    };

    bus.on(EDITOR_PLAYER_CHANGE, handlePlayerChange);
    bus.on(EDITOR_PLAYER_ANCHOR_TIME, handlePlayerAnchor);

    return () => {
      bus.off(EDITOR_PLAYER_CHANGE, handlePlayerChange);
      bus.off(EDITOR_PLAYER_ANCHOR_TIME, handlePlayerAnchor);
    };
  }, []);

  return {
    timeInParts,
    durationInSeconds,
    isReady,
    isAudioPaused,
    isAudioMuted,
    handleAudioPlayToggle,
    handleAudioMuteToggle,
    handleTimeChangeStart,
    handleTimeChangeEnd,
    handleTimeChange,
  };
};

export default useMediaPlayer;
