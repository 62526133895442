import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import useInterval from 'hooks/useInterval';
import useCachePersistedValue from 'hooks/useCachePersistedValue';

const TIMELINE_BAR_UPDATE_TIME = 50;

export type UseAudioPlayerMiniReturn = {
  timeInParts: number;
  durationInSeconds: number;
  isReady: boolean;
  isAudioPaused: boolean;
  isAudioMuted: boolean;
  audioVolumeLevel: number;
  handleAudioPlayToggle: React.MouseEventHandler<HTMLElement>;
  handleAudioMuteToggle: () => void;
  handleTimeChangeStart: (e?: SyntheticEvent<Element>) => void;
  handleTimeChangeEnd: (e?: SyntheticEvent<Element>) => void;
  handleAudioVolumeChange: (volume: number) => void;
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const pauseAllMediaFromSameSection = (node: HTMLMediaElement) => {
  const sectionNode = node.closest('section');
  if (!sectionNode) {
    return;
  }

  const audiosOnPage = sectionNode.getElementsByTagName(node.tagName) as HTMLCollectionOf<HTMLMediaElement>;

  for (const audio of audiosOnPage) {
    audio !== node && audio.pause();
  }
};

const DEFAULT_CACHE_KEY = 'player';
const DEFAULT_VOLUME_LEVEL = 70;

const useAudioPlayerMini = (
  sourceNode: HTMLMediaElement,
  cacheKey: string = DEFAULT_CACHE_KEY
): UseAudioPlayerMiniReturn => {
  // const audioRef = useCombinedRef(sourceRef);
  const audioRef = useRef(sourceNode);
  const [isReady, setIsReady] = useState(false);

  const [timeInParts, setTimeInParts] = useState(0);
  const [durationInSeconds, setDurationInSeconds] = useState(0);

  const [isAudioPaused, setIsAudioPaused] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useCachePersistedValue(
    `${cacheKey}/muted`,
    (cacheValue) => cacheValue === 'true' ?? false
  );

  const [audioVolumeLevel, setAudioVolumeLevel] = useCachePersistedValue(
    `${cacheKey}/volume`,
    (cacheValue) => +cacheValue ?? DEFAULT_VOLUME_LEVEL
  );

  const [shouldPlayingBeResumed, setShouldPlayingBeResumed] = useState(false);

  const handleAudioPlayToggle = useCallback((e) => {
    e.stopPropagation();
    if (audioRef.current?.paused) {
      pauseAllMediaFromSameSection(audioRef.current);

      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, []);

  const handleAudioMuteToggle = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
    }

    setIsAudioMuted(audioRef.current?.muted);
  }, []);

  const handleAudioVolumeChange = useCallback((nextVolume: number) => {
    const volume = Math.max(0, Math.min(nextVolume, 100));
    setAudioVolumeLevel(volume);

    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, []);

  const handleTimeChangeStart = useCallback((e) => {
    e?.stopPropagation();

    setShouldPlayingBeResumed(!audioRef.current?.paused);
    setIsAudioPaused(true);

    audioRef.current?.pause();
  }, []);

  const handleTimeChangeEnd = useCallback(
    (e) => {
      e?.stopPropagation();
      if (shouldPlayingBeResumed) {
        audioRef.current?.play();
      }
    },
    [shouldPlayingBeResumed]
  );

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeInParts = +event.target.value;

    if (audioRef.current) {
      audioRef.current.currentTime = timeInParts * audioRef.current.duration;
    }

    setTimeInParts(timeInParts);
  };

  const handleVisibilityChange = () => {
    // when user switch to another tab/window audio paused
    if (!isAudioPaused && !audioRef.current?.paused && document.hidden) {
      audioRef.current?.pause();
    }

    // when user switch to current page audio continue playing if it was paused by previous condition
    if (!isAudioPaused && audioRef.current?.paused && !document.hidden) {
      audioRef.current?.play();
    }
  };

  useInterval(
    () => {
      if (audioRef.current) {
        const currentTimeInParts = audioRef.current.currentTime / audioRef.current.duration;

        if (currentTimeInParts) {
          setTimeInParts(currentTimeInParts);
        }
      }
    },
    !isAudioPaused ? TIMELINE_BAR_UPDATE_TIME : null
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAudioPaused]);

  useEffect(() => {
    audioRef.current = sourceNode;

    if (audioRef.current) {
      audioRef.current.muted = isAudioMuted;
      audioRef.current.volume = audioVolumeLevel / 100;
    }

    const init = () => {
      console.log('loaded');
      setDurationInSeconds(audioRef.current?.duration || 0);
      setIsReady(true);
    };

    if (audioRef.current?.duration) {
      setDurationInSeconds(audioRef.current?.duration || 0);
      setIsReady(true);
    }

    const onAudioPaused = () => {
      setIsAudioPaused(true);
    };

    const onAudioPlay = (e) => {
      setIsAudioPaused(false);
    };

    audioRef.current?.addEventListener('ended', onAudioPaused);

    audioRef.current?.addEventListener('play', onAudioPlay);

    audioRef.current?.addEventListener('pause', onAudioPaused);

    audioRef.current?.addEventListener('loadeddata', init);

    return () => {
      audioRef.current && (audioRef.current.muted = true);

      audioRef.current?.pause();

      audioRef.current?.removeEventListener('loadeddata', init);

      audioRef.current?.removeEventListener('ended', onAudioPaused);

      audioRef.current?.removeEventListener('play', onAudioPlay);

      audioRef.current?.removeEventListener('pause', onAudioPaused);
    };
  }, [sourceNode]);

  return {
    timeInParts,
    durationInSeconds,
    isReady,
    isAudioPaused,
    isAudioMuted,
    audioVolumeLevel,
    handleAudioPlayToggle,
    handleAudioMuteToggle,
    handleTimeChangeStart,
    handleTimeChangeEnd,
    handleTimeChange,
    handleAudioVolumeChange,
  };
};

export default useAudioPlayerMini;
