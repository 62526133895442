import styled, { css } from 'styled-components';

import { MultiselectMode, MultiselectView } from './Multiselect';

export const LabelAndItems = styled.div`
  display: flex;
`;

export const Label = styled.div`
  transition: color 0.3s ease-out;
`;

export const Items = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.gray[40]};
`;

export const Multiselect = styled.div<{ $view: MultiselectView; $isEmpty: boolean; $isDisabled?: boolean }>`
  min-width: 200px;
  width: 100%;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  padding: 6px 8px;
  border-radius: 8px;

  ${({ $view }) =>
    $view === MultiselectView.HORIZONTAL &&
    css`
      ${LabelAndItems} {
        flex-direction: row;
      }

      ${Label} {
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;
      }
    `}

  ${({ $view }) =>
    $view === MultiselectView.VERTICAL &&
    css`
      ${LabelAndItems} {
        flex-direction: column;
      }

      ${Label} {
        margin-bottom: 1px;
        font-size: 12px;
        line-height: 15px;
      }
    `}


  ${({ $isEmpty }) =>
    css`
      ${Label} {
        color: ${({ theme }) => theme.colors.gray[$isEmpty ? 90 : 40]};
      }
    `}

  ${({ $isDisabled }) => css`
    cursor: ${$isDisabled ? 'default' : 'pointer'};
  `}
`;

export const Dropdown = styled.div`
  min-width: 200px;
`;

export const DropdownRow = styled.div<{ $mode: MultiselectMode }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $mode }) => $mode === MultiselectMode.MULTISELECT && css``}

  ${({ $mode }) =>
    $mode === MultiselectMode.SELECT &&
    css`
      span {
        & + svg {
          margin-left: 12px;
        }
      }

      svg {
        path {
          fill: ${({ theme }) => theme.colors.gray[90]};
        }
      }
    `}
`;
