import React from 'react';
import * as S from './TreeView.styled';
import { IconCheck } from '@reface/icons/20px';

const DefaultItemRenderComponent: React.FC<{ item: any }> = ({ item }) => (
  <>
    <S.Folder />
    <S.TreeViewLabel>{item?.label || 'No label'}</S.TreeViewLabel>
    <S.ActiveIcon>
      <IconCheck />
    </S.ActiveIcon>
  </>
);

export default DefaultItemRenderComponent;
