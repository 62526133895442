import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

const loadingSlice = createSlice({
  name: 'editor/loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setEditorLoading: (state, action: PayloadAction<boolean>) => ({
      isLoading: action.payload,
    }),
  },
});

export const getLoadingState = (state: RootState) => state.pages.editor.isLoading;

export const setEditorLoading = loadingSlice.actions.setEditorLoading;

export default loadingSlice;
