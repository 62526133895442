import React, { ComponentProps, DOMAttributes, forwardRef, PropsWithChildren } from 'react';

import * as S from './Button.styled';
import { ButtonColor, ButtonSize, ButtonVariant } from './Button.constants';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  icon?: React.ReactElement;
  endIcon?: React.ReactElement;
  isRounded?: boolean;
  className?: string;
  disabled?: boolean;
  component?: string | React.ElementType;
} & Pick<DOMAttributes<HTMLButtonElement>, 'onClick'> &
  Partial<Pick<React.ComponentProps<Link>, 'href' | 'to'>> &
  ComponentProps<typeof S.Button>;

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      className,
      variant = ButtonVariant.PRIMARY,
      size = ButtonSize.MEDIUM,
      color = ButtonColor.DEFAULT,
      icon: startIcon,
      endIcon,
      isRounded = false,
      disabled = false,
      onClick,
      component = 'button',
      children,
      ...rest
    },
    ref
  ) => (
    <S.Button
      ref={ref}
      $variant={variant}
      $color={color}
      $size={size}
      $hasChildren={!!children}
      $hasIcon={!!(startIcon || endIcon)}
      $isRounded={isRounded}
      disabled={disabled}
      onClick={onClick}
      className={className}
      as={component}
      {...rest}
    >
      {startIcon && <S.Icon>{startIcon}</S.Icon>}
      {children && <S.Children>{children}</S.Children>}
      {endIcon && <S.Icon>{endIcon}</S.Icon>}
    </S.Button>
  )
);

export default Button;
