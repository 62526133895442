import { createContext, useContext } from 'react';

const SelectedMotionPersonContext = createContext<{
  activePersonId: string | null;
}>({
  activePersonId: null,
});

export const useSelectedMotionActivePerson = () => {
  const context = useContext(SelectedMotionPersonContext);

  return context.activePersonId;
};

export default SelectedMotionPersonContext.Provider;
