import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createContentFolderThunk, updateContentThunk } from './contentActions';
import { RootState } from '../../index';
import { fetchFoldersStatisticThunk } from './foldersActions';
import { TreeNodeInArray } from '../../../components/TreeView/types';

function combineFoldersSubtree(
  object_id: string,
  entries: FolderStatistic[],
  deep: number
): TreeNodeInArray<FolderStatistic>[] {
  if (deep > 100) {
    return [];
  }

  const children = entries.filter(({ parent_id }) => parent_id === object_id);

  return children.map((folder) => ({
    id: folder.object_id,
    item: folder,
    type: 'node',
    label: folder.title,
    nodes: combineFoldersSubtree(folder.object_id, entries, deep + 1),
  }));
}

export const PARENT_ID_ROOT = 'root';

export const selectFoldersTree = (asset_type: string, parent_id?: string, excludeFolders: string[] = []) =>
  createSelector(
    (state: RootState) => state.pages.content.folders.entities,
    (entities) => {
      const assetEntries = entities.filter(
        (item) =>
          item.status === 0 &&
          item.asset_type === asset_type &&
          item.object_id !== PARENT_ID_ROOT &&
          !excludeFolders.includes(item.object_id)
      );

      const parentFolder = entities.find(({ object_id }) => object_id === parent_id);

      if (!parentFolder) {
        return null;
      }

      return {
        id: 'root',
        item: {
          ...parentFolder,
          asset_type,
        },
        type: 'node',
        label: parentFolder.title,
        nodes: combineFoldersSubtree(parentFolder.object_id, assetEntries, 0),
      } as TreeNodeInArray<FolderStatistic>;
    }
  );

export const selectFolderStatistic = (object_id: string) => (state: RootState) => {
  const entries = state.pages.content.folders.entities;
  return entries.find((item) => item.object_id === object_id) || ({} as FolderStatistic);
};

export type FolderStatistic = {
  object_id: string;
  asset_type: string;
  parent_id: string;
  status: 0 | 1 | 2;
  audios: number;
  folders: number;
  images: number;
  videos: number;
  title: string;
};

interface FoldersState {
  entities: FolderStatistic[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  entities: [],
  loading: 'idle',
} as FoldersState;

const foldersSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoldersStatisticThunk.fulfilled, (state, action) => {
      state.entities = Object.entries(action.payload.folders).map(([object_id, folder]) => ({
        object_id,
        ...folder,
        asset_type: folder.asset_type || 'content',
      }));
    });

    // builder.addCase(bulkContentMoveThunk.fulfilled, (state, action) => {
    //   console.log(state, action);
    // });

    // update folder statistic entry if folder renamed
    builder.addCase(updateContentThunk.fulfilled, (state, action) => {
      const payload = action.meta.arg;
      const folder = state.entities.find((entry) => entry.object_id === payload.object_id);

      if (folder && 'title' in payload) {
        folder.title = payload.title!;
      }
    });

    builder.addCase(createContentFolderThunk.fulfilled, (state, action) => {
      const { object_id, status, asset_type, title, parent_id } = action.payload;
      state.entities.push({
        object_id,
        status,
        title,
        asset_type: asset_type || 'content',
        parent_id: parent_id || 'root',
        folders: 0,
        audios: 0,
        images: 0,
        videos: 0,
      });
    });
  },
});

export default foldersSlice;
