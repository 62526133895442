import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectFolderBreadcrumbs } from 'store/pages/content/breadcrumbsSelectors';
import { AssetAliasType, generatePathByAssetType, getAssetTypeByRouteAlias, getFilesTitle } from '../utils';
import { useParams } from 'react-router-dom';
import { BreadcrumbsProps } from '../../../components/Breadcrumbs';

const useFolderBreadcrumbs = () => {
  const params = useParams<{ asset_type: AssetAliasType; parent_id: string }>();
  const assetType = getAssetTypeByRouteAlias(params.asset_type);
  const folders = useSelector(selectFolderBreadcrumbs(params.parent_id || 'root', assetType));

  return useMemo(
    () =>
      [
        {
          label: getFilesTitle(params.asset_type),
          to: `/files/${params.asset_type}`,
        },
        ...folders.map((item) => ({
          label: item.title,
          to: generatePathByAssetType(item.asset_type, item.object_id),
          source: item,
        })),
      ] as BreadcrumbsProps['items'],
    [params.asset_type, params.parent_id, folders]
  );
};

export default useFolderBreadcrumbs;
