export type Limit = number;

export type Offset = number;

export type PageSize = number;

export type PageNumber = number;

export type FilterName = string;
export type FilterValues = string | number | (string | number)[];
export type Filters = Record<FilterName, FilterValues>;

export type Query = {
  page?: PageNumber;
  filters: Filters;
};

export type Point = [x: number, y: number];

export type Bbox = [topLeft: Point, bottomRight: Point];

export type Url = string;

export type FileExtension = string;

export enum FileType {
  VIDEO = 'video',
  IMAGE = 'image',
  AUDIO = 'audio',
}

export enum UploadStatus {
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  UPLOADING_FAILED = 'UPLOADING_FAILED',
  CONVERT_PENDING = 'CONVERT_PENDING',
  CONVERTING = 'CONVERTING',
  CONVERT_FAILED = 'CONVERT_FAILED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
}
