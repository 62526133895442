export enum ButtonVariant {
  PRIMARY = 'PRIMARY',
  ACTION = 'ACTION',
  SECONDARY = 'SECONDARY',
}

export enum ButtonColor {
  DEFAULT = 'default',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}

export enum ButtonSize {
  X_SMALL = 'X_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
