import React from 'react';
import * as S from '../../../../components/TreeView/TreeView.styled';
import { IconCheck } from '@reface/icons/20px';

const FolderTreeItem: React.FC<{ type: 'node' | 'group'; item: any }> = ({ type, item }) => {
  if (type === 'node') {
    return (
      <>
        <S.Folder />
        <S.TreeViewLabel>{item?.title || 'No label'}</S.TreeViewLabel>
        <S.ActiveIcon>
          <IconCheck />
        </S.ActiveIcon>
      </>
    );
  } else {
    console.log(item);
    return (
      <>
        <S.TreeViewLabel>{item?.title || 'No label'}</S.TreeViewLabel>
        <S.ActiveIcon>
          <IconCheck />
        </S.ActiveIcon>
      </>
    );
  }
};

export default FolderTreeItem;
