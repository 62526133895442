export interface DivisionConfig {
  strokeWidth?: number;
  type?: 'rect' | 'line';
  className?: string;
  pixelGap?: number;
  lineLength?: number;
  renderer?: (el: Element) => void;
}
export interface GuideConfig {
  strokeWidth?: number;
  className?: string;
  renderer?: (el: Element) => void;
  getSize?: (guideConfig: GuideConfig) => number;
  position: number;
}
export interface TextConfig {
  pixelGap?: number;
  rotation?: number;
  offset?: number;
  className?: string;
  /**
   * Wherever to show or not to show units alongside text
   */
  showUnits?: boolean;
  centerText?: boolean;
  renderer?: (el: Element) => void;
}

export interface RulezConfig {
  width?: number;
  height?: number;
  element: Element | any;
  layout?: 'horizontal' | 'vertical';
  alignment?: 'top' | 'left' | 'right' | 'bottom';
  units?: 'em' | 'ex' | 'px' | 'pt' | 'pc' | 'cm' | 'mm' | 'in' | '';
  divisionDefaults?: DivisionConfig;
  textDefaults?: TextConfig;
  guideDefaults?: GuideConfig;
  divisions?: DivisionConfig[];
  texts?: TextConfig[];
  guides?: GuideConfig[];
  guideSnapInterval?: number;
  additionalDivisionsAmount?: number;
}

declare global {
  interface SVGTextElement extends SVGTextPositioningElement {
    origPos: any;
    origPosAttribute: any;
  }
}

const Rulez = function (config: RulezConfig) {
  'use strict';
  const svgNS = 'http://www.w3.org/2000/svg';
  const defaultConfig = {
    width: null,
    height: null,
    element: null,
    layout: 'horizontal',
    alignment: 'top',
    units: '', //'em', 'ex', 'px', 'pt', 'pc', 'cm', 'mm', 'in' and ''(user units) :  http://www.w3.org/TR/SVG/coords.html#Units
    divisionDefaults: {
      strokeWidth: 1,
      type: 'rect',
      className: 'rulez-rect',
      renderer: null,
    },
    textDefaults: {
      rotation: 0,
      offset: 25,
      className: 'rulez-text',
      /**
       * Wherever to show or not to show units alongside text
       */
      showUnits: false,
      centerText: true,
      renderer: null,
    },
    guideDefaults: {
      strokeWidth: 1,
      getSize: function () {
        return 5000;
      },
    },
    divisions: [
      {
        pixelGap: 5,
        lineLength: 5,
      },
      {
        pixelGap: 25,
        lineLength: 10,
      },
      {
        pixelGap: 50,
        lineLength: 15,
      },
      {
        pixelGap: 100,
        lineLength: 20,
      },
    ],
    texts: [
      {
        pixelGap: 100,
      },
    ],
    guides: [],
    guideSnapInterval: 10,
    additionalDivisionsAmount: 2,
  };
  const getDefaultConfigCopy = function () {
    const copy = JSON.parse(JSON.stringify(defaultConfig));
    copy.guideDefaults.getSize = defaultConfig.guideDefaults.getSize;
    return copy;
  };

  /**
   * result config
   */
  let c = mergeConfigs(getDefaultConfigCopy(), config);
  if (!c.guideDefaults.className) {
    if (isVertical()) {
      c.guideDefaults.className = 'rulez-guide-vert';
    } else {
      c.guideDefaults.className = 'rulez-guide-horiz';
    }
  }
  c = mergeConfigs(c, c);
  /**
   * amount of additional(redundant) divisions on left and right (top, bottom) side of ruler
   */
  let additionalDivisionsAmount = 2;
  /**
   * main group (g svg element) that contains all divisions and texts
   * @type {SVGGElement}
   */
  let g = createGroup();
  /**
   * Array of arrays of all texts
   * @type {Array.<Array.<SVGTextElement >>}
   */
  const texts = [];
  /**
   * Array of all guides
   * @type {Array.<SVGTextElement>}
   */
  const guides = [];
  /**
   * Current position of ruler
   * @type {number}
   */
  let currentPosition = 0;
  /**
   * Start position of drawing ruler
   * @type {number}
   */
  let startPosition;
  /**
   * End position of drawing ruler
   * @type {number}
   */
  let endPosition;
  /**
   * Scale of ruler
   * @type {number}
   */
  let scale = 1;

  let size;
  let maxDistance = 0;
  let unitConversionRate;

  /**
   * Renders ruler inside svg element
   */
  this.render = function () {
    c.width || (c.width = c.element.getBoundingClientRect().width);
    c.height || (c.height = c.element.getBoundingClientRect().height);
    c.element.appendChild((g = createGroup()));
    size = isVertical() ? c.height : c.width;
    unitConversionRate = getUnitConversionRate();
    additionalDivisionsAmount = c.additionalDivisionsAmount;

    calculateStartEndPosition();
    generateDivisionsAndTexts(startPosition, endPosition);
    generateGuides();
    this.scrollTo(0, false);

    c.element.addEventListener('dblclick', function (e) {
      let position = isVertical() ? e.offsetY : e.offsetX;
      position = (currentPosition + position) * scale;

      const guideConfig = Object.assign(
        {
          position: position,
        },
        c.guideDefaults
      );
      c.guides.push(guideConfig);
      createGuideFromConfig(guideConfig);
    });
  };

  /**
   * Scrolls ruler to specified position.
   * @param {number} pos left(or top for vertical rulers) position to scroll to.
   * @param {boolean} useUnits if true pos will be multiplied by unit conversion rate;
   */
  this.scrollTo = function (pos, useUnits) {
    currentPosition = pos;
    if (useUnits) {
      currentPosition *= unitConversionRate;
    }

    if (isVertical()) {
      g.setAttribute('transform', 'translate(0,' + (-currentPosition % (maxDistance * unitConversionRate)) + ')');
    } else {
      g.setAttribute('transform', 'translate(' + (-currentPosition % (maxDistance * unitConversionRate)) + ',0)');
    }
    const pixelCurrentPosition = currentPosition / unitConversionRate;
    for (let i = 0; i < c.texts.length; i++) {
      const textConfig = c.texts[i];
      const textElements = texts[i];
      const amountPerMaxDistance = maxDistance / textConfig.pixelGap;
      const offset = pixelCurrentPosition % maxDistance;
      const startTextPos = pixelCurrentPosition - offset;
      for (let j = 0; j < textElements.length; j++) {
        const textElement = textElements[j];
        let text =
          Math.round(
            (startTextPos + (j - additionalDivisionsAmount * amountPerMaxDistance) * textConfig.pixelGap) * scale * 100
          ) / 100;
        if (textConfig.showUnits) {
          text = addUnits(text);
        }
        textElement.textContent = text;
        if (textConfig.renderer) {
          textConfig.renderer(textElement);
        }
      }
    }
    for (let i = 0; i < guides.length; i++) {
      moveGuide(guides[i], c.guides[i]);
    }
  };

  /**
   * Scales the ruler's text values by specific value.
   * @param {number} scaleValue
   */
  this.setScale = function (scaleValue) {
    scale = scaleValue;
    this.scrollTo(currentPosition, false);
  };

  /**
   * Updates size with current clientWidth(height) in case it's bigger than previous one.
   * Only appends more divisions and texts if necessary.
   */
  this.resize = function () {
    const oldSize = size;
    const newSize = isVertical() ? c.element.clientHeight : c.element.clientWidth;
    if (oldSize !== newSize) {
      if (oldSize > newSize) {
        //todo remove redundant divisions?
      } else {
        size = newSize;
        const oldEndPosition = endPosition;
        calculateStartEndPosition();
        generateDivisionsAndTexts(oldEndPosition, endPosition);
        this.scrollTo(currentPosition, false);
      }
    }

    //FIXME guide resize
  };

  this.getGuideConfigs = function () {
    return JSON.parse(JSON.stringify(c.guides));
  };

  /**
   * Callback that is called after saving of ruler as image is done
   * @callback saveFinishCallback
   * @param {string} base64 png image string
   */
  /**
   * Saves ruler as image.
   * @param {saveFinishCallback} saveFinishCallback
   */
  this.saveAsImage = function (saveFinishCallback) {
    const svgClone = deepCloneWithCopyingStyle(c.element);
    //http://stackoverflow.com/questions/23514921/problems-calling-drawimage-with-svg-on-a-canvas-context-object-in-firefox
    svgClone.setAttribute('width', c.width);
    svgClone.setAttribute('height', c.height);
    //
    const canvas = window.document.createElement('canvas');
    canvas.setAttribute('width', c.width);
    canvas.setAttribute('height', c.height);
    const ctx = canvas.getContext('2d');

    const URL = window.URL || window.webkitURL;

    const img = new Image();
    img.style.position = 'absolute';
    img.style.top = '-100000px';
    img.style.left = '-100000px';
    img.style.zIndex = '-100000';
    img.setAttribute('width', c.width);
    img.setAttribute('height', c.height);

    const svg = new Blob([svgClone.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svg);

    img.onload = function () {
      setTimeout(function () {
        //workaround for not working width and height.
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        window.document.body.removeChild(img);
        saveFinishCallback(canvas.toDataURL());
      }, 1000);
    };

    window.document.body.appendChild(img);
    img.src = url;
  };

  /**
   * @returns {number} how much pixels are in used unit.
   */
  this.getUnitConversionRate = function () {
    return getUnitConversionRate();
  };

  function deepCloneWithCopyingStyle(node) {
    const clone = node.cloneNode(false);
    let i;
    if (node instanceof Element) {
      const computedStyle = window.getComputedStyle(node);
      if (computedStyle) {
        for (i = 0; i < computedStyle.length; i++) {
          const property = computedStyle[i];
          clone.style.setProperty(property, computedStyle.getPropertyValue(property), '');
        }
      }
    }
    for (i = 0; i < node.childNodes.length; i++) {
      clone.appendChild(deepCloneWithCopyingStyle(node.childNodes[i]));
    }

    return clone;
  }

  function calculateStartEndPosition() {
    if (!maxDistance) {
      c.divisions.forEach(function (entry) {
        if (entry.pixelGap > maxDistance) {
          maxDistance = entry.pixelGap;
        }
      });
    }
    endPosition = size - (size % maxDistance) + maxDistance * additionalDivisionsAmount;
    startPosition = -maxDistance * additionalDivisionsAmount;
  }

  function generateDivisionsAndTexts(startPosition, endPosition) {
    c.divisions.forEach(function (division) {
      generateDivisions(startPosition, endPosition, division);
    });
    let i = 0;
    c.texts.forEach(function (textConfig) {
      const textsArray = generateTexts(startPosition, endPosition, textConfig);
      if (texts[i]) {
        texts[i] = texts[i].concat(textsArray);
      } else {
        texts.push(textsArray);
      }
      i++;
    });
  }

  function generateDivisions(startPosition, endPosition, elementConfig) {
    for (let i = startPosition; i < endPosition; i += elementConfig.pixelGap) {
      const line = createLine(i, elementConfig);
      g.appendChild(line);
      if (elementConfig.renderer) {
        elementConfig.renderer(line);
      }
    }
  }

  function generateGuides() {
    c.guides.forEach(function (guideConfig) {
      createGuideFromConfig(guideConfig);
    });
  }

  function createGuideFromConfig(guideConfig) {
    const guide = generateGuide(guideConfig);
    guides.push(guide);

    g.appendChild(guide);
    if (guideConfig.renderer) {
      guideConfig.renderer(guide);
    }
  }

  function moveGuide(guide, guideConfig) {
    const offset = -currentPosition % (maxDistance * unitConversionRate);
    const position = guideConfig.position / scale - currentPosition - offset;
    guide.setAttribute('transform', isVertical() ? 'translate(0,' + position + ')' : 'translate(' + position + ',0)');
  }

  function generateGuide(guideConfig) {
    return _createGuideRect(guideConfig);
  }

  function generateTexts(startPosition, endPosition, elementConfig) {
    const texts = [];
    for (let i = startPosition; i < endPosition; i += elementConfig.pixelGap) {
      const text = createText(i, elementConfig);
      g.appendChild(text);
      if (elementConfig.renderer) {
        elementConfig.renderer(text);
      }
      texts.push(text);
    }
    return texts;
  }

  function createLine(pos, elementConfig) {
    switch (elementConfig.type) {
      case 'line':
        return _createLine(pos, elementConfig);
      case 'rect':
        return _createRect(pos, elementConfig);
      default:
        return _createRect(pos, elementConfig);
    }
  }

  function _createGuideRect(guideConfig) {
    const guide = _createRectGeneral(
      0,
      guideConfig.className,
      guideConfig.getSize(guideConfig),
      guideConfig.strokeWidth,
      0
    );
    moveGuide(guide, guideConfig);
    return guide;
  }

  function _createLine(pos, elementConfig) {
    return _createLineGeneral(pos, elementConfig.className, elementConfig.lineLength, elementConfig.strokeWidth);
  }

  function _createRect(pos, elementConfig) {
    return _createRectGeneral(pos, elementConfig.className, elementConfig.lineLength, elementConfig.strokeWidth);
  }

  function _createLineGeneral(pos, className, lineLength, strokeWidth) {
    const line = window.document.createElementNS(svgNS, 'line');
    const defaultAlignment = isDefaultAlignment();
    let x1, x2, y1, y2;
    if (isVertical()) {
      x1 = 'y1';
      x2 = 'y2';
      y1 = 'x1';
      y2 = 'x2';
    } else {
      x1 = 'x1';
      x2 = 'x2';
      y1 = 'y1';
      y2 = 'y2';
    }

    line.setAttribute('class', className);
    line.setAttribute(x1, addUnits(pos));
    line.setAttribute(x2, addUnits(pos));
    line.setAttribute(y1, addUnits(defaultAlignment ? '0' : getAlignmentOffset() - lineLength));
    line.setAttribute(y2, addUnits(defaultAlignment ? lineLength : getAlignmentOffset()));
    line.setAttribute('stroke-width', addUnits(strokeWidth));
    return line;
  }

  function _createRectGeneral(pos, className, lineLength, strokeWidth, alignment?) {
    const line = window.document.createElementNS(svgNS, 'rect');
    const defaultAlignment = isDefaultAlignment();
    let x, y, height, width;
    if (isVertical()) {
      x = 'y';
      y = 'x';
      height = 'width';
      width = 'height';
    } else {
      x = 'x';
      y = 'y';
      height = 'height';
      width = 'width';
    }

    const alignmentValue =
      typeof alignment !== 'undefined' ? alignment : defaultAlignment ? '0' : getAlignmentOffset() - lineLength;

    line.setAttribute('class', className);
    line.setAttribute(x, addUnits(pos));
    line.setAttribute(y, addUnits(alignmentValue));
    line.setAttribute(height, addUnits(lineLength));
    line.setAttribute(width, addUnits(strokeWidth));
    return line;
  }

  function createText(pos, elementConfig) {
    const textSvg = window.document.createElementNS(svgNS, 'text');
    const yPos = getTextPosY(elementConfig);
    let x, y;
    textSvg.setAttribute('class', elementConfig.className);
    if (isVertical()) {
      x = 'y';
      y = 'x';
    } else {
      x = 'x';
      y = 'y';
    }

    textSvg.origPos = pos;
    textSvg.origPosAttribute = x;
    textSvg.setAttribute(x, addUnits(pos));
    textSvg.setAttribute(y, addUnits(yPos));
    rotateText(textSvg, elementConfig);
    textSvg.textContent = elementConfig.showUnits ? addUnits(pos) : pos;
    if (elementConfig.centerText) {
      textSvg.setAttribute('text-anchor', 'middle');
    }
    return textSvg;
  }

  function createGroup() {
    return window.document.createElementNS(svgNS, 'g');
  }

  function isVertical() {
    return c.layout === 'vertical';
  }

  function isDefaultAlignment() {
    return !(c.alignment === 'bottom' || c.alignment === 'right');
  }

  function getAlignmentOffset() {
    return isVertical() ? c.width : c.height;
  }

  function mergeConfigs(def, cus, notOverrideDef = false) {
    if (!cus) {
      return def;
    }

    for (const param in cus) {
      // eslint-disable-next-line no-prototype-builtins
      if (cus.hasOwnProperty(param)) {
        switch (param) {
          case 'divisionDefaults':
          case 'textDefaults':
          case 'guideDefaults':
            mergeConfigs(def[param], cus[param]);
            break;
          default:
            if (!(notOverrideDef && def[param])) {
              def[param] = cus[param];
            }
        }
      }
    }
    if (def.divisions) {
      def.divisions.forEach(function (entry) {
        mergeConfigs(entry, def.divisionDefaults, entry);
        if (!entry.className) {
          entry.className = entry.type === 'line' ? 'rulez-line' : 'rulez-rect';
        }
      });
    }
    if (def.texts) {
      def.texts.forEach(function (entry) {
        mergeConfigs(entry, def.textDefaults, entry);
      });
    }
    if (def.guides) {
      def.guides.forEach(function (entry) {
        mergeConfigs(entry, def.guideDefaults, entry);
      });
    }

    return def;
  }

  function addUnits(value) {
    return value + c.units;
  }

  function getUnitConversionRate() {
    if (c.units === '' || c.units === 'px') {
      return 1;
    }
    const dummyEl = window.document.createElement('div');
    dummyEl.style.position = 'absolute';
    dummyEl.style.top = '-100000px';
    dummyEl.style.left = '-100000px';
    dummyEl.style.zIndex = '-100000';
    dummyEl.style.width = dummyEl.style.height = addUnits(1);
    window.document.body.appendChild(dummyEl);
    const width = window.getComputedStyle(dummyEl).width.replace('px', '');
    window.document.body.removeChild(dummyEl);
    return width;
  }

  function rotateText(textElement, elementConfig) {
    let rotate;
    const pos = textElement.origPos;
    const yPos = getTextPosY(elementConfig);
    if (isVertical()) {
      rotate =
        'rotate(' + elementConfig.rotation + ' ' + yPos * unitConversionRate + ' ' + pos * unitConversionRate + ')';
    } else {
      rotate =
        'rotate(' + elementConfig.rotation + ' ' + pos * unitConversionRate + ' ' + yPos * unitConversionRate + ')';
    }
    textElement.setAttribute('transform', rotate);
  }

  function getTextPosY(elementConfig) {
    const defaultAlignment = isDefaultAlignment();
    return defaultAlignment ? elementConfig.offset : getAlignmentOffset() - elementConfig.offset;
  }
};

export default Rulez;
