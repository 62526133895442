import { colors, palette } from './colors';
import { layout } from './layout';
import { typography } from './typography';

const theme = {
  colors,
  palette,
  layout,
  typography,
};

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof colors;
    palette: typeof palette;
    layout: typeof layout;
    typography: typeof typography;
  }
}

export default theme;
