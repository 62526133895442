import { MutableRefObject, Ref, useEffect, useMemo, useRef, useState } from 'react';
import {
  EDITOR_LAYERS_ADD,
  EDITOR_PLAYER_CHANGE,
  EDITOR_PLAYER_PAUSE,
  EDITOR_PLAYER_PLAY,
  EDITOR_PLAYER_SEEK_TIME,
} from './editorEvents.constants';
import { fabric } from 'fabric';
import { isFabricImageObjectVideo, isMaskedMediaObject } from '../../utils/fabric/guards';

type PlayerLayerType = {
  id?: string;
} & any;

type PlayerBusStateType = {
  layers: PlayerLayerType[];
  seek: number;
  scaleFactor: number;
};

export type PlayerBus = {
  state: Ref<PlayerBusStateType>;
  editor: MutableRefObject<fabric.Canvas>;
  on(event: string, callback: any): void;
  off(event: string, callback: any): void;
  dispatch<T>(event: string, data?: T): void;
  addLayer(layer: PlayerLayerType): void;
  modifyLayer(id: string, patch: any): void;
  editorObjects: fabric.Object[];
  duration: number;
  staticState: any;
  seek: number;
  scaleFactor: number;
};

const usePlayerBus = () => {
  const staticState = useRef({
    duration: 0,
  });
  const state = useRef<PlayerBusStateType>({
    layers: [],
    seek: 0,
    scaleFactor: 1,
  });

  const [duration, setDuration] = useState(0);

  const canvasRef = useRef<fabric.Canvas>(null!);

  const bus = useMemo<PlayerBus>(
    () => ({
      state: state,
      editor: canvasRef,
      on(event: string, callback: any) {
        // console.log('on', event);
        document.addEventListener(event, callback, true);
      },
      off(event: string, callback: any) {
        // console.log('off', event);
        document.removeEventListener(event, callback, true);
      },
      dispatch<T>(event: string, data?: T) {
        window.dispatchEvent(new CustomEvent(event, { detail: data }));
      },
      addLayer(layer: PlayerLayerType) {
        this.dispatch(EDITOR_LAYERS_ADD, layer);
      },
      modifyLayer(id: string, patch: any) {
        const layer = state.current.layers.find((layer) => layer.id === id);
        if (!layer) {
          return;
        }

        for (const field in patch) {
          layer[field] = patch[field];
        }
      },
      get editorObjects() {
        return canvasRef.current.getObjectsForExport();
      },
      duration,
      get staticState() {
        return staticState.current;
      },
      get seek() {
        return state.current.seek;
      },
      get scaleFactor() {
        return state.current.scaleFactor;
      },
      set scaleFactor(value) {
        state.current.scaleFactor = value;
      },
    }),
    [duration]
  );

  useEffect(() => {
    const getVideoNodes = (): HTMLVideoElement[] =>
      bus.editorObjects
        .filter((obj) => isMaskedMediaObject(obj) && obj.getOriginalElement()?.tagName === 'VIDEO')
        .map((obj) => [
          obj,
          ...(obj.filters
            ? obj.filters.filter((f) => !!f.image && f.image.getElement().tagName === 'VIDEO').map((f) => f.image)
            : []),
        ])
        .flat()
        .map((obj) => (obj as fabric.Image).getOriginalElement())
        .filter((node) => isFabricImageObjectVideo(node)) as HTMLVideoElement[];

    const handlePlayerPlay = async () => {
      console.log('player:play');

      canvasRef.current.discardActiveObject();
      const objects = canvasRef.current.getObjects().filter((obj: fabric.Object) => !!obj.excludeFromExport);
      for (const obj of objects) {
        obj.set({ opacity: 0 });
      }

      const videoNodes = getVideoNodes(); //.reverse();
      await Promise.all(videoNodes.map(async (node) => node.play()));

      for (const node of videoNodes) {
        node.currentTime = bus.seek;
        console.log('play:currentTime', node.currentTime, node.duration);
      }
    };

    const handlePlayerPause = (e: any) => {
      const objects = canvasRef.current.getObjects().filter((obj: fabric.Object) => !!obj.excludeFromExport);
      for (const obj of objects) {
        obj.set({ opacity: 1 });
      }

      state.current.seek = e.detail.seek;

      const videoNodes = getVideoNodes();

      for (const node of videoNodes) {
        node.pause();
        console.log(node, node.currentTime);
        // node.currentTime = e.detail.seek;
      }
    };

    const handlePlayerSeek = (e: any) => {
      const videoNodes = getVideoNodes();

      for (const node of videoNodes) {
        node.currentTime = e.detail.seek;
      }
    };

    const handlePlayerChange = ({ detail }: any) => {
      console.log('handlePlayerChange', detail.duration);
      setDuration(detail.duration);
      staticState.current.duration = detail.duration;
    };

    bus.on(EDITOR_PLAYER_PLAY, handlePlayerPlay);
    bus.on(EDITOR_PLAYER_PAUSE, handlePlayerPause);
    bus.on(EDITOR_PLAYER_SEEK_TIME, handlePlayerSeek);
    bus.on(EDITOR_PLAYER_CHANGE, handlePlayerChange);

    return () => {
      bus.off(EDITOR_PLAYER_PLAY, handlePlayerPlay);
      bus.off(EDITOR_PLAYER_PAUSE, handlePlayerPause);
      bus.off(EDITOR_PLAYER_SEEK_TIME, handlePlayerSeek);
      bus.off(EDITOR_PLAYER_CHANGE, handlePlayerChange);
    };
  }, [bus]);

  return bus;
};

export default usePlayerBus;
