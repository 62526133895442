import { awaitTranscoderPipeline, bakeEditorTimeline } from 'api/studio/transcoder';
import { isMaskedMediaObject } from 'utils/fabric/guards';
import { PlayerBus } from '../usePlayerBus';

const useEditorExport = (bus: PlayerBus, resolution: any) => {
  const editorExport = async () => {
    const editorObjects = bus.editorObjects;
    const scaleFactor = bus.scaleFactor;

    // console.log(editorObjects);

    const mainTimelineDuration = bus.duration;

    const pipeline_video = await Promise.all(
      editorObjects.map(async (object) => {
        const pipeVideo: any = {
          pipe_type: 'object_placement',
          position_track: object.getPositionTrack(scaleFactor),
          ss: 0,
          to: mainTimelineDuration,
        };

        if (isMaskedMediaObject(object)) {
          // const element = object.getOriginalElement();
          if (object.getOriginalElement()?.tagName === 'VIDEO') {
            // pipeVideo.to = (element as HTMLVideoElement).duration;
            pipeVideo.audio_blending = true;
          }
          const source_url = object.getOriginalElement()?.src;
          const filters = object?.getBlendImageElements();
          if (filters && filters.length) {
            const mask_url = filters[0].src;

            if (mask_url === source_url) {
              pipeVideo.side_by_side_url = source_url;
            } else {
              pipeVideo.source_url = source_url;
              pipeVideo.mask_url = mask_url;
            }
          } else {
            pipeVideo.source_url = source_url;
          }
        } else {
          pipeVideo.blob_base64 = await object.exportPNG().then(
            (blob) =>
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
              })
          );
        }

        return pipeVideo;
      })
    );
    console.log(pipeline_video);
    // return [null, 'erro'];

    const response = await bakeEditorTimeline({
      initial_join: {
        timeline: [
          {
            path: 'blank',
            video: {
              ss: 0,
              to: mainTimelineDuration,
            },
          },
        ],
        resolution: `${resolution.width}:${resolution.height}`,
        scale: 'decrease',
        fps: 20,
      },
      pipeline_video,
    });

    const { tracking, error_message } = await awaitTranscoderPipeline([response.id]);

    return [tracking.url, error_message];
  };
  return {
    editorExport,
  };
};

export default useEditorExport;
