import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-width: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  margin-right: 32px;
  //flex-basis: 100%;
`;

export const List = styled.div`
  display: inline-flex;
  min-width: 0;
`;

export const Item = styled.div`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.gray[35]};
  white-space: nowrap;

  &::after {
    content: ' / ';
    margin-left: 4px;
    margin-right: 4px;
  }

  &:last-child {
    color: ${({ theme }) => theme.colors.white};
    min-width: 0; /* or some value */
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child::after {
    content: '';
    margin-left: 0;
    margin-right: 0;
  }
  
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
`;
