import React, { MouseEventHandler, MouseEvent, useRef } from 'react';
import classNames from 'classnames';
import * as S from './MotionsSubNodesList.styled';
import { useSelectedMotionActivePerson } from './SelectedMotionPersonContext';
import Spinner from '@reface/ui/Spinner';
import useAudioPlayerMini from 'components/AudioPlayerMini/hooks/useAudioPlayerMini';
import { IconPause, IconPlay, IconSoundOff, IconSoundOn } from '@reface/icons/32px';
import ControlButton from 'components/AudioPlayerMini/components/ControlButton';

type MotionsSubNodesListProps = {
  nodes: any[];
  id: string;
  activeKey: string;
  onClick: (e: MouseEvent, id: string, item: any, ...rest: any[]) => void;
};

const MotionsSubNodesListItem: React.FC<{
  id: string;
  item: any;
  isActive: boolean;
  activePersonId: string | null;
  onClick: MotionsSubNodesListProps['onClick'];
}> = ({ id, item, isActive, activePersonId, onClick }) => {
  const sourceRef = useRef<HTMLVideoElement>(null!);
  const { isReady, isAudioPaused, isAudioMuted, handleAudioPlayToggle, handleAudioMuteToggle } = useAudioPlayerMini(
    sourceRef.current,
    'picker/preview'
  );

  const handleRecordClick: MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    onClick(e, id, item);
  };

  const handlePersonClick = (e: MouseEvent<HTMLLIElement>, person_id: string) => {
    e.stopPropagation();
    onClick(e, id, item, person_id);
  };

  return (
    <S.MotionGridItem
      role="button"
      onClick={handleRecordClick}
      className={classNames({ active: isActive, focused: false })}
    >
      <S.MotionPreviewImage src={item.origin_path} ref={sourceRef} />
      <ControlButton onClick={handleAudioPlayToggle} disabled={!isReady}>
        {isAudioPaused ? <IconPlay /> : <IconPause />}
      </ControlButton>
      <S.Title truncate>{item.title}</S.Title>
      <S.SelectedPersons>
        {item.data?.persons?.map((person: any) => (
          <S.PersonPreviewItem
            key={person.person_id}
            role={'button'}
            onClick={(e) => handlePersonClick(e, person.person_id)}
            $active={isActive && activePersonId === person.person_id}
          >
            <img src={person.preview_url} />
          </S.PersonPreviewItem>
        ))}
      </S.SelectedPersons>
      <S.Duration>{`${+item.ffprobe?.format?.duration}s`}</S.Duration>
      <ControlButton onClick={handleAudioMuteToggle} disabled={!isReady} visible={!isAudioPaused}>
        {isAudioMuted ? <IconSoundOff /> : <IconSoundOn />}
      </ControlButton>
    </S.MotionGridItem>
  );
};

const MotionsSubNodesList: React.FC<MotionsSubNodesListProps> = ({ activeKey, nodes, onClick }) => {
  const activePersonId = useSelectedMotionActivePerson();

  return (
    <S.MotionsGridWrapper>
      {!nodes.length && (
        <S.Loader>
          <Spinner /> Loading ...
        </S.Loader>
      )}
      {nodes.map(({ id, item }) => (
        <MotionsSubNodesListItem
          key={id}
          id={id}
          item={item}
          isActive={activeKey === id}
          onClick={onClick}
          activePersonId={activePersonId}
        />
      ))}
    </S.MotionsGridWrapper>
  );
};

export default MotionsSubNodesList;
