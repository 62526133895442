import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToast, getToastsState, removeToast } from 'store/toasts';
import { Toast } from 'types/toast';

type UseToastReturn = {
  toasts: ReturnType<typeof getToastsState>;
  add: (toast: Omit<Toast, 'id'>) => void;
  remove: (toastId: Toast['id']) => void;
};

const useToast = (): UseToastReturn => {
  const dispatch = useDispatch();
  const toasts = useSelector(getToastsState);

  const add = useCallback((toast: Omit<Toast, 'id'>) => dispatch(addToast(toast)), [dispatch]);
  const remove = useCallback((toastId: Toast['id']) => dispatch(removeToast(toastId)), [dispatch]);

  return {
    toasts,
    add,
    remove,
  };
};

export default useToast;
