import React, { HTMLAttributes, useMemo } from 'react';
import * as S from './ContentItemPersonsPreview.styled';
import { ThemeVariant } from './ContentItemPersonsPreview.styled';

type ContentItemPersonsPreviewProps = {
  persons: {
    person_id: string;
    preview_url: string;
  }[];
  visibleAmount?: number;
  variant?: ThemeVariant;
  isRestPersonsHidden?: boolean;
  direction?: 'right' | 'left';
  size?: number;
} & Partial<Pick<HTMLAttributes<HTMLDivElement>, 'onClick'>>;

const DEFAULT_VISIBLE_AMOUNT = 4;

const ContentItemPersonsPreview: React.FC<ContentItemPersonsPreviewProps> = ({
  variant = 'dark',
  persons,
  isRestPersonsHidden = false,
  visibleAmount = DEFAULT_VISIBLE_AMOUNT,
  direction = 'right',
  size = 28,
  ...rest
}) => {
  const visiblePersons = useMemo(() => {
    if (isRestPersonsHidden) {
      return persons.slice(0, visibleAmount);
    }

    if (persons.length > visibleAmount) {
      return persons.slice(0, visibleAmount - 1);
    }

    return persons;
  }, [persons, visibleAmount, isRestPersonsHidden]);

  const restCounter = persons.length - visiblePersons.length;

  return (
    <S.Wrapper $variant={variant} $direction={direction} $size={size} {...rest}>
      {visiblePersons.map((person) => (
        <S.PersonImage key={person.person_id} src={person.preview_url} />
      ))}
      {!!restCounter && !isRestPersonsHidden && <S.RestCounter>+{restCounter}</S.RestCounter>}
    </S.Wrapper>
  );
};

export default ContentItemPersonsPreview;
