import { useMemo } from 'react';
import { BreadcrumbsProps } from 'components/Breadcrumbs';

type SearchParams = {
  operation_key?: string;
  object_id?: string;
};

const useHistorySearchBreadcrumbs = <T extends SearchParams>(search?: T) =>
  useMemo(() => {
    const breadcrumbs: BreadcrumbsProps['items'] = [
      {
        label: 'Bulk Face Swap',
        to: `/history`,
      },
    ];

    if (search?.operation_key) {
      breadcrumbs.push(
        {
          label: 'Bulk Operations',
        },
        {
          label: search.operation_key,
        }
      );
    }
    if (search?.object_id) {
      breadcrumbs.push({
        label: 'Content Operations',
      });
    }

    return breadcrumbs;
  }, [search]);

export default useHistorySearchBreadcrumbs;
