import { AppliedMapping } from './types';
import { SwapMappingOptions } from 'api/studio/swapProcessing';

import { format, addSeconds } from 'date-fns';

export const prepareSwapMapping = (appliedMapping: {
  [face_id: string]: AppliedMapping;
}): SwapMappingOptions<{
  motions: {
    [user_face_id: string]: string;
  };
}> =>
  Object.entries(appliedMapping).reduce(
    (mapping, [face_id, applied]) => {
      if (applied.facemapping) {
        mapping.facemapping[face_id] = {
          user_face_id: applied.facemapping,
        };
      }

      if (Array.isArray(applied.motionmapping)) {
        const [object_id, user_face_id] = applied.motionmapping;
        mapping.motionmapping[face_id] = {
          user_face_id,
        };

        mapping.meta.motions[user_face_id] = object_id;
      }

      if (applied.audiomapping) {
        mapping.audiomapping[face_id] = {
          audio_track_id: applied.audiomapping,
        };
      }

      return mapping;
    },
    {
      motionmapping: {},
      facemapping: {},
      audiomapping: {},
      meta: {
        motions: {},
      },
    } as SwapMappingOptions
  );

export const formatDuration = (duration: number) => {
  const helperDate = addSeconds(new Date(0), duration);
  return format(helperDate, 'mm:ss');
};
