import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cl from 'clsx';
import * as S from './SwapVideoContent.styled';
import useCombinedRef from '../../../../hooks/useCombinedRef';

type VideoFilmstripProps = {
  src: string;
  width?: number;
};

const DENSITY = 3;

const VideoFilmstrip = forwardRef<HTMLDivElement, VideoFilmstripProps>(({ src, width: parentWidth }, ref) => {
  const player = useRef(document.createElement('video'));
  const combinedRef = useCombinedRef<HTMLDivElement>(ref);

  const renderOptions = useRef({
    frameWidth: 48,
    frameHeight: 48,
    totalFrames: 10,
    timePerFrame: 0,
    frame: 0,
    image: null,
  });
  const [[clientWidth, clientHeight], setCanvasSize] = useState([0, 0]);
  const [isCanvasLoading, setIsCanvasLoading] = useState(true);

  const canvasRef = useRef<HTMLCanvasElement>(null!);

  useEffect(() => {
    const width = canvasRef.current.clientWidth;
    const height = canvasRef.current.clientHeight;

    player.current.src = src;

    setCanvasSize([width, height]);

    const render = () => {
      const ar = player.current.videoWidth / player.current.videoHeight;
      const estFrameWidth = height * ar;
      const frameWidth = width / Math.round(width / estFrameWidth);
      const totalFrames = width / frameWidth;
      const timePerFrame = player.current.duration / (totalFrames - 1);

      player.current.currentTime = 0;

      const x = document.createElement('canvas');
      x.width = width * DENSITY;
      x.height = height * DENSITY;

      renderOptions.current = {
        frameWidth,
        frameHeight: frameWidth / ar,
        timePerFrame,
        totalFrames,
        frame: 0,
        image: x,
      };
    };

    player.current.addEventListener('loadedmetadata', render);

    return () => {
      player.current.removeEventListener('loadedmetadata', render);
    };
  }, [src, parentWidth]);

  useEffect(() => {
    const renderFrame = () => {
      const { totalFrames, frameWidth, frameHeight, timePerFrame, image } = renderOptions.current;
      if (!canvasRef.current || !image?.width) {
        return;
      }

      const canvas = canvasRef.current;
      const ctx = image.getContext('2d'); //canvas.getContext('2d')
      ctx.drawImage(
        player.current,
        renderOptions.current.frame * frameWidth * DENSITY,
        0,
        frameWidth * DENSITY,
        frameHeight * DENSITY
      );
      renderOptions.current.frame += 1;

      if (renderOptions.current.frame < totalFrames) {
        player.current.currentTime = player.current.currentTime + timePerFrame;
      } else {
        canvas.width = clientWidth * DENSITY;
        canvas.height = clientHeight * DENSITY;
        canvas.getContext('2d').drawImage(image, 0, 0, clientWidth * DENSITY, clientHeight * DENSITY);
        setIsCanvasLoading(false);
      }
    };

    player.current.addEventListener('timeupdate', renderFrame);
    return () => {
      player.current.removeEventListener('timeupdate', renderFrame);
    };
  }, [src, clientWidth, clientHeight]);

  return (
    <S.FilmstripWrapper ref={combinedRef}>
      {/*{isCanvasLoading && <RectSkeleton style={{ width: '100%', height: 48 }} />}*/}
      <S.FilmstripCanvas $height={48} ref={canvasRef} className={cl({ visible: !isCanvasLoading })} />
    </S.FilmstripWrapper>
  );
});

export default VideoFilmstrip;
