import React, { ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 100%;

  & .simplebar-scrollbar:before {
    background-color: ${({ theme }) => theme.colors.gray[40]};
  }
`;

const Scrollbar: React.FC<{ children?: React.ReactNode }> = forwardRef<SimpleBar, ComponentProps<typeof SimpleBar>>(
  function Scrollbar({ children }, ref) {
    return <StyledSimpleBar ref={ref}>{children}</StyledSimpleBar>;
  }
);

export default Scrollbar;
