import styled from 'styled-components';

export const EditorSidebar = styled.div`
  grid-area: tools;
  display: flex;
  max-height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 48px;
`;

export const EditorCanvasWrapper = styled.div`
  position: relative;
  grid-area: canvas;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EditorContainer = styled.div`
  display: grid;
  grid-template-columns: 496px auto;
  grid-template-areas: 'tools canvas';
  margin: 0 auto;
  width: 100%;
`;

export const FooterCanvasSettings = styled.div`
  display: flex;
  align-items: center;
`;
