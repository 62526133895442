import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
`;

export const LoaderLine = styled.div`
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
`;

export const LoaderCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  // animation: fade 1.2s infinite ease-in-out;
`;

export const LoaderLineMask = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;
`;

export const LoaderContent = styled.div<{ $size: number }>`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 0;

  ${({ $size }) => css`
    & ${LoaderLine} {
      width: ${$size};
      height: ${$size};
    }

    & ${LoaderCircle} {
      width: ${$size};
      margin-left: ${-$size / 2};
      margin-top: ${-$size / 2};
    }

    & ${LoaderLineMask} {
      width: ${$size / 2};
      height: ${-$size};
      margin-left: ${-$size / 2};
      margin-top: ${-$size / 2};
      transform-origin: ${$size / 2} ${$size / 2};
    }
  `}
`;

export const PageLoadTimeHint = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[40]};
`;
