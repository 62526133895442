import { PageSize } from 'types/common';

export const NUMBER_OF_COLUMNS = 6;
export const NUMBER_OF_ROWS = 12;
export const PAGE_SIZE: PageSize = NUMBER_OF_COLUMNS * NUMBER_OF_ROWS;

export const DEBOUNCE_DELAY = 500;
export const ANIMATION_DURATION = 500;

export const REQUEST_POLLING_DELAY = 1500;

export const UUID_REGEXP = /^[a-fA-Z0-9]{8}-[a-fA-Z0-9]{4}-[a-fA-Z0-9]{4}-[a-fA-Z0-9]{4}-[a-fA-Z0-9]{12}$/;

// TODO: unify with backend
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// NOTE: `true` for such values as `undefined`, empty string, empty array
export const EMPTY_OBJECT_PROPERTIES_PREDICATE = (value: unknown): boolean =>
  typeof value === 'undefined' ||
  (typeof value === 'string' && value === '') ||
  (Array.isArray(value) && value.length === 0);

export const DELETED = '[deleted]';

export const NOTHING_TO_SHOW = 'Nothing to show…';
