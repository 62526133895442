import createApiRequestSlice from 'store/utils/createApiRequestSlice';
import { swapContent, swapImage } from 'api/studio/swapProcessing';
import { ApiRequestState, RootState } from 'store';

const getSwapContentResponse: (state: RootState) => ApiRequestState<any> = (state) => state.pages.swap.swapRecord;

const name = 'pages/swap/swapImage';

const [swapContentThunk, slice] = createApiRequestSlice(name, swapContent);

export { swapContentThunk, getSwapContentResponse };

export type SwapContentThunkResponse = Awaited<ReturnType<typeof swapContent>>;

export default slice;
