import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { Router, RouterProps } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import Toasts from 'components/Toasts';
import theme from 'theme';

import store, { persistor } from '../store';
import Layout from './components/Layout';

import * as S from './App.styled';
import Auth from './components/Auth';
import PageRoutes from '../pages';
import DragProvider from 'pages/Files/components/DragProvider';
import ModalManager from '@reface/shared/components/ModalManager';
import Uploader from './components/Uploader';
import UploaderProcessingToastBox from './components/UploaderProcessingToastBox';
import SwapProcessingToastBox from './components/SwapProcessingToastBox';
import SwapQueue from './components/SwapQueue';

const App: React.FC<Pick<RouterProps, 'history'>> = ({ history }) => (
  <PersistGate loading={null} persistor={persistor}>
    <StoreProvider store={store}>
      <S.GlobalStyle />

      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Auth>
            <ModalManager>
              <Layout>
                <SwapQueue>
                  <Uploader>
                    <DragProvider>
                      <PageRoutes />
                    </DragProvider>
                    <S.ProcessingToastBoxWrapper>
                      <SwapProcessingToastBox />
                      <UploaderProcessingToastBox />
                    </S.ProcessingToastBoxWrapper>
                  </Uploader>
                </SwapQueue>
              </Layout>
            </ModalManager>
          </Auth>
          <Toasts />
        </Router>
      </ThemeProvider>
    </StoreProvider>
  </PersistGate>
);

export default App;
