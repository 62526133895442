import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 8px 12px;
  width: 256px;
`;

export const HistoryRow = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: white;
      background: ${theme.colors.gray[10]};
      border-color: ${theme.colors.blue[80]};
    `}
`;

export const PersonImage = styled.img`
  object-fit: cover;
`;

export const SelectedPersons = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  margin-right: 8px;

  & > * {
    border-radius: 8px;
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
  }

  & > :not(:first-child) {
    margin-left: -8px;
  }
`;
