import { proxyApi } from 'services/http';
import { SwapMappingOptions } from './swapProcessing';

type AvailableContentType = 'video' | 'image' | 'audio';

export type SwapHistoryPayload = {
  content_type?: AvailableContentType;
  object_id?: string;
  operation_key?: string;
  offset?: number;
  limit?: number;
  with_object?: boolean;
};

export type SwapHistoryRecord = {
  content_type: string;
  created_date: string;
  object_id: string;
  operation_key: string | null;
  origin_object: any;
  request: SwapMappingOptions;
  status: number;
  swap_error_message: string | null;
  swap_id: string;
  swap_path: string;
  swap_time: number;
};

export type SwapHistoryResponse = {
  swap_history: SwapHistoryRecord[];
  total: number;
};

/**
 *   content_type: Optional[str]
 object_id: Optional[uuid.UUID]
 offset: Optional[int] = 0
 limit: Optional[int] = 100
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSwapHistory = async (options: SwapHistoryPayload) => {
  const urlParams = new URLSearchParams(options as Record<string, string>);

  const response = await proxyApi.get<SwapHistoryResponse>(`/swap-history?${urlParams}`);

  return response.data;
};

export type DeleteSwapHistoryOptions = {
  object_id?: string;
  swap_ids?: string[];
  operation_key?: string;
};

export const deleteSwapHistory = async (options: DeleteSwapHistoryOptions) => {
  proxyApi.delete(`/swap-history`, {
    data: options,
  });
};

export type BulkHistoryItemRecord = {
  created_date: string;
  operation_key: string;
  swap_history_items: {
    swap_path: string;
  }[];
};

export type BulkHistoryResponse = {
  swap_history: BulkHistoryItemRecord[];
  total: number;
};

export const getBulkHistoryRecords = async (options: Pick<SwapHistoryPayload, 'offset' | 'limit'>) => {
  const urlParams = new URLSearchParams(options as Record<string, string>);
  const response = await proxyApi.get<BulkHistoryResponse>(`/swap-history/bulk?${urlParams}`);
  return response.data;
};
