import { withSvgIcon } from '../components/SvgIcon';

import { ReactComponent as PlainIconPause } from './Pause.svg';
import { ReactComponent as PlainIconPlay } from './Play.svg';
import { ReactComponent as PlainIconSoundOff } from './SoundOff.svg';
import { ReactComponent as PlainIconSoundOn } from './SoundOn.svg';

export const IconPause = withSvgIcon(PlainIconPause);
export const IconPlay = withSvgIcon(PlainIconPlay);
export const IconSoundOff = withSvgIcon(PlainIconSoundOff);
export const IconSoundOn = withSvgIcon(PlainIconSoundOn);
