import React, { useEffect, useMemo, useState } from 'react';
import ContentToolbar from '../Files/components/ContentToolbar/ContentToolbar';
import ButtonGroup from '@reface/ui/ButtonGroup';
import Typography from '@reface/ui/Typography';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconDownload, IconLinkOutside, IconSuccessFilled, IconTrash } from '@reface/icons/20px';
import * as S from './History.styled';
import Table from 'components/Table/Table';
import { getType } from 'mime/lite';
import { Column } from 'react-table';
import { BulkHistoryItemRecord } from 'api/studio/swapHistory';
import { useDispatch, useSelector } from 'react-redux';
import { useModalManager } from '@reface/shared/components/ModalManager';
import DeleteHistoryConfirmationModal, {
  DELETE_HISTORY_CONFIRMATION_MODAL,
} from './components/DeleteHistoryConfirmationModal';
import { downloadSwapHistoryThunk, fetchBulkHistoryThunk, getBulkHistoryEntries } from 'store/pages/history/bulk';
import { Link } from 'react-router-dom';
import useContentSelection from '../Files/hooks/useContentSelection';
import { useHotkeys } from 'react-hotkeys-hook';
import Pagination from '@reface/ui/Pagination';
import ContentEmpty from 'components/ContentEmpty';
import { EmptyContentImage } from 'static/images';

const RowActionsCell: React.FC<{ value: string }> = ({ value }) => {
  const dispatch = useDispatch();
  const [openModal] = useModalManager();

  const handleHistoryDeleteClick = (operation_key: string) => {
    openModal(DELETE_HISTORY_CONFIRMATION_MODAL, {
      operation_key,
    });
  };

  const handleBulkDownloadClick = (operation_key: string) => {
    dispatch(
      downloadSwapHistoryThunk({
        operation_key,
        with_object: true,
      })
    );
  };
  return (
    <S.RowActionsGroup onClick={(e) => e.stopPropagation()}>
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconLinkOutside />}
        component={Link}
        to={`/history/search?operation_key=${value}`}
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconDownload />}
        onClick={() => handleBulkDownloadClick(value)}
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        icon={<IconTrash />}
        onClick={(e) => {
          handleHistoryDeleteClick(value);
        }}
      />
    </S.RowActionsGroup>
  );
};

const BulkHistoryEmptyScreen = () => (
  <ContentEmpty
    icon={EmptyContentImage}
    title="Nothing available for swap"
    subtitle="Here you’ll find history of all bulk swap operations. Just start using them."
  />
);

const RECORDS_ON_PAGE = 50;

const BulkSwapsHistoryTable = () => {
  const dispatch = useDispatch();

  const [openModal, , isModalsOpened] = useModalManager();
  const [page, setPage] = useState(1);

  const [historyEntries, total] = useSelector(getBulkHistoryEntries);

  const { selected, setSelected, selectableRef, onSelectionEnded, onSelectionStart, onSelectionMove, clearSelection } =
    useContentSelection();

  const statisticString = '';

  useEffect(() => {
    dispatch(
      fetchBulkHistoryThunk({
        offset: (page - 1) * RECORDS_ON_PAGE,
        limit: RECORDS_ON_PAGE,
      })
    );
  }, [page]);

  const handleBulkDeleteClick = async () => {
    openModal(DELETE_HISTORY_CONFIRMATION_MODAL, {
      operation_key: Array.from(selected),
    });
  };

  useHotkeys('esc', () => {
    if (!isModalsOpened) {
      clearSelection();
    }
  });

  const columns = useMemo(
    () =>
      [
        {
          id: 'preview',
          accessor: ({ swap_history_items }): string[] =>
            swap_history_items.map(({ swap_path }) => swap_path).slice(0, 4),
          Cell: ({ value }: { value: string[] }) => (
            <div>
              {value.map((path, index) =>
                getType(path)?.includes('image/') ? (
                  <S.RecordPreviewImage src={path} key={index} />
                ) : (
                  <S.RecordPreviewImage src={path} as={'video'} key={index} />
                )
              )}
            </div>
          ),
          width: 200,
        },
        {
          accessor: 'operation_key',
        },
        {
          id: 'status',
          Cell: () => <IconSuccessFilled color={'error'} />,
          width: 64,
        },
        {
          accessor: 'total',
          Cell: ({ value }: { value: number }) => <S.TotalCell>{value} items swapped</S.TotalCell>,
        },
        {
          id: 'actions',
          accessor: 'operation_key',
          Cell: RowActionsCell,
          width: 200,
        },
      ] as Column<BulkHistoryItemRecord>[],
    []
  );

  return (
    <>
      <ContentToolbar>
        <ButtonGroup>
          {selected.size ? (
            <>
              <Typography variant={'body2'}>{selected.size} files selected</Typography>
              <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL} onClick={() => clearSelection()}>
                Unselect
              </Button>
              ·
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                color={ButtonColor.DANGER}
                icon={<IconTrash />}
                onClick={handleBulkDeleteClick}
              >
                Delete
              </Button>
            </>
          ) : (
            <>
              <Typography variant={'body2'}>{statisticString}</Typography>
            </>
          )}
        </ButtonGroup>
      </ContentToolbar>

      <S.TableWrapper
        ref={selectableRef}
        onStart={onSelectionStart}
        onEnded={onSelectionEnded}
        onMove={onSelectionMove}
        selectables=".selectable"
      >
        <Table
          columns={columns}
          items={historyEntries}
          hasMore={false}
          isLoadingMore={false}
          noHeader={true}
          getRowProps={(row) => ({
            isSelected: selected.has(row.original.operation_key),
          })}
          getRowDataKey={(row) => row.original.operation_key}
          renderEmptyScreen={BulkHistoryEmptyScreen}
          footer={
            <Pagination total={total} limit={RECORDS_ON_PAGE} page={page} onChange={(nextPage) => setPage(nextPage)} />
          }
        />
      </S.TableWrapper>

      <DeleteHistoryConfirmationModal onConfirm={clearSelection} />
    </>
  );
};

export default BulkSwapsHistoryTable;
