import React, { useMemo } from 'react';
import DropdownSelect, { DropdownOption } from 'components/DropdownSelect';

import { SORT_VARIANTS, sortOptions } from 'store/pages/content/constants';

type ContentOrderDropdownProps = { value: SORT_VARIANTS; onChange: (option: DropdownOption['value']) => void };

const ContentOrderDropdown: React.FC<ContentOrderDropdownProps> = ({ value: selectedOption, onChange }) => {
  const value = useMemo(() => sortOptions.find(({ value }) => value === selectedOption), [selectedOption])!;

  return <DropdownSelect onChange={onChange} options={sortOptions} value={value} />;
};

export default ContentOrderDropdown;
