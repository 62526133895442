import React, { useEffect, useRef, useState } from 'react';

const useHover = <T extends HTMLElement>(): [ref: React.RefObject<T>, isHovered: boolean] => {
  const ref = useRef<T>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => setIsHovered(true);

  const handleMouseOut = () => setIsHovered(false);

  useEffect(() => {
    ref.current?.addEventListener('mouseenter', handleMouseOver);
    ref.current?.addEventListener('mouseleave', handleMouseOut);

    return () => {
      ref.current?.removeEventListener('mouseenter', handleMouseOver);
      ref.current?.removeEventListener('mouseleave', handleMouseOut);
    };
  }, [ref.current]);

  return [ref, isHovered];
};

export default useHover;
