import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { queue as q, QueueObject } from 'async';

import { UploadStatus } from 'types/common';

import { Item, Meta, ProcessingQueueItem, QueueItem } from './types';
import UploaderContext, { UploaderContextInitialState, UploaderContextState } from './UploaderContext';
import uploadIdleContent from 'api/pipelines/uploadIdleContent';
import processMediaRecord from 'api/pipelines/processMediaRecord';
import { useBeforeUnload } from 'react-use';
import { v4 as uuid } from 'uuid';
import usePersistedProcessingItems from './hooks/usePersistedProcessingItems';
import { createContentRecord, updateContentRecord } from 'api/studio/content';
import { createVideoCopy } from 'api/studio/transcoder';
import dispatchEvent from 'utils/dispatchEvent';
import { addUploadedContent } from 'store/pages/content/contentActions';
import { useDispatch } from 'react-redux';
import { checkVideoFormat, getFileMediaInfo } from 'utils/files/mediaDetails';

// import debugMethod from '@reface/shared/utils/debugMethod';

// const uuid = debugMethod(plainUuid);

const Uploader: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const uploadingQueue = useRef<QueueObject<QueueItem>>(null!);
  const convertorProcessingQueue = useRef<QueueObject<ProcessingQueueItem>>(null!);

  const [persistedProcessingItems, { dispatchSyncProcessingItems }] = usePersistedProcessingItems();

  const processingQueues = useRef<Record<string, QueueObject<QueueItem>>>({});

  const [items, setItems] = useState<UploaderContextState['items']>([]);

  const [options, setOptions] = useState<UploaderContextState['options']>(UploaderContextInitialState.options);

  useEffect(() => {
    setItems(
      persistedProcessingItems.map((item) => ({
        ...item,
        file: null,
      }))
    );
  }, []);

  useEffect(() => {
    const processingItems = items
      .filter(({ status, id }) => status === UploadStatus.PROCESSING && !!id)
      .map(({ file, ...rest }) => rest);

    dispatchSyncProcessingItems(...processingItems);
  }, [items]);

  const reset: UploaderContextState['reset'] = useCallback(() => {
    setItems([]);
  }, []);

  const add = useCallback((files: File[], action: QueueItem['action'], meta: QueueItem['meta']) => {
    // const newUniqueFiles = files.filter((file) => !items.find((item) => areFilesEqual(item.file, file)));
    meta.payload ||= {}; // set default payload value

    const queueItems: QueueItem[] = files.map((file) => ({
      id: uuid(),
      action,
      file,
      fileData: {
        name: file.name,
        size: file.size,
        type: file.type,
      },
      meta,
    }));
    uploadingQueue.current.push(queueItems);

    setItems((items) => [
      ...items,
      ...queueItems.map((item) => ({
        ...item,
        status: UploadStatus.PENDING,
      })),
    ]);
  }, []);

  const addCreateMedia: UploaderContextState['addCreateMedia'] = useCallback(
    (files, meta) => add(files, 'create', meta),
    [add]
  );
  const addConvertMedia: UploaderContextState['addConvertMedia'] = useCallback(
    (files, meta) => add(files, 'convert', meta),
    [add]
  );

  const retry: UploaderContextState['retry'] = useCallback(
    (item_id) => {
      const { meta, file, fileData, action } = items.find((item) => item.id !== item_id) || {};

      uploadingQueue.current.push({ file, action, fileData, meta, id: item_id });

      setItems((items) => [
        // drop
        ...items.filter((item) => item.id !== item_id),
        // and re-add this item
        { status: UploadStatus.PENDING, file, fileData, meta, action, id: item_id },
      ]);
    },
    [items]
  );

  const update: UploaderContextState['update'] = useCallback((item_id, changes) => {
    console.log('update', item_id, changes);

    setItems((items) => {
      const patchItem = items.find(({ id }) => id === item_id);

      if (
        changes.status === UploadStatus.PROCESSED &&
        patchItem &&
        patchItem.action === 'convert' &&
        Array.isArray(changes.items)
      ) {
        const { fileData, meta } = patchItem;

        const newProcessingItems = changes.items.map((mediaItem) => ({
          id: uuid(),
          media: mediaItem,
          fileData,
          meta,
          status: UploadStatus.PROCESSING,
          action: 'create' as const,
          tracking_id: null,
        }));

        convertorProcessingQueue.current.push(newProcessingItems);
        // replace prev task with new processing tasks
        return [...items.filter((item) => item.id !== item_id), ...newProcessingItems];
      }

      if (
        changes.status === UploadStatus.PENDING ||
        changes.status === UploadStatus.UPLOADING ||
        changes.status === UploadStatus.PROCESSING ||
        changes.status === UploadStatus.PROCESSED
      ) {
        // just update item
        return items.map((item) => (item.id === item_id ? { ...item, ...changes } : item));
      }

      if (changes.status === UploadStatus.UPLOADING_FAILED || changes.status === UploadStatus.PROCESSING_FAILED) {
        // find item, drop it,
        const { item, filteredItems, lastFailedItemIndex } = items.reduce(
          (result, item, index) => {
            if (item.id === item_id) {
              result.item = item;
            } else {
              result.filteredItems.push(item);
            }

            if (item.status === UploadStatus.UPLOADING_FAILED || item.status === UploadStatus.PROCESSING_FAILED) {
              result.lastFailedItemIndex = index;
            }

            return result;
          },
          {
            item: undefined,
            filteredItems: [],
            lastFailedItemIndex: -1,
          } as { item?: Item; filteredItems: Item[]; lastFailedItemIndex: number }
        );

        // and add this item as the last failed item
        return item
          ? [
              ...filteredItems.slice(0, lastFailedItemIndex + 1),
              { ...item, ...changes },
              ...filteredItems.slice(lastFailedItemIndex + 1),
            ]
          : filteredItems;
      }

      return items;
    });
  }, []);

  const remove: UploaderContextState['remove'] = useCallback((item_id) => {
    setItems((items) => items.filter((item) => item.id !== item_id));
    uploadingQueue.current.remove(({ data }) => data.id === item_id);
  }, []);

  // NOTE: init queue
  useEffect(() => {
    uploadingQueue.current = q(async ({ id, file, meta, action }, callback) => {
      update(id, { status: UploadStatus.UPLOADING });

      try {
        const mediaInfo = await getFileMediaInfo(file);
        const isSupportedCodec = !file.type.includes('video/') || checkVideoFormat(mediaInfo);
        console.log(mediaInfo, isSupportedCodec, meta.payload);

        const actionOverride = !isSupportedCodec ? 'convert' : action;

        const mediaPayload = await uploadIdleContent(file, {
          parent_id: meta.parent_id || null,
          visible: meta.asset_type === 'content',
        });

        if (actionOverride === 'convert') {
          const trackingResponse = await createVideoCopy(mediaPayload.path, {
            ...meta.payload,
            visible: mediaPayload.visible,
          });

          update(id, {
            action: 'convert',
            status: UploadStatus.PROCESSING,
            tracking_id: trackingResponse.tracking_id,
          });
        } else {
          const media = await createContentRecord({
            ...mediaPayload,
            parent_id: meta.asset_type === 'content' && meta.parent_id ? meta.parent_id : null,
          });

          console.log(media);
          dispatch(addUploadedContent([media].filter(({ visible }) => !!visible)));

          const trackingResponse = await processMediaRecord(
            {
              ...media,
              parent_id: meta.parent_id || null,
            },
            meta as Meta
          );
          update(id, {
            status: UploadStatus.PROCESSING,
            tracking_id: trackingResponse.tracking_id,
          });
        }
      } catch {
        update(id, { status: UploadStatus.UPLOADING_FAILED });
      }

      callback();
    });

    convertorProcessingQueue.current = q(async ({ id, media, meta }, callback) => {
      update(id, { status: UploadStatus.PROCESSING });

      try {
        await updateContentRecord(media.object_id, {
          visible: true,
          title: `${media.title} (prod)`,
        });

        const trackingResponse = await processMediaRecord(media, meta as Meta);

        dispatchEvent('uploader:uploaded', {
          items: trackingResponse,
        });

        update(id, {
          status: UploadStatus.PROCESSING,
          tracking_id: trackingResponse.tracking_id,
        });
      } catch {
        update(id, { status: UploadStatus.PROCESSING_FAILED });
      }

      callback();
    });
  }, []);

  const registerProcessingQueue = useCallback((type: string, processingHandler: (file: File) => Promise<void>) => {
    processingQueues.current[type] = q(async ({ file }, callback) => {
      await processingHandler(file);

      callback();
    });
  }, []);

  const isUploadingInProcess = useMemo(
    () => items.some(({ status }) => [UploadStatus.UPLOADING, UploadStatus.PENDING].includes(status)),
    [items]
  );

  useBeforeUnload(isUploadingInProcess, 'You have uncompleted uploading in process! Page reload will interrupt this.');

  const value = useMemo(
    () => ({
      items,
      options,
      addCreateMedia,
      addConvertMedia,
      reset,
      retry,
      remove,
      update,
      setUploaderOptions: setOptions,
      register: registerProcessingQueue,
    }),
    [items, options, addCreateMedia, addConvertMedia, reset, retry, remove, update, registerProcessingQueue]
  );

  return <UploaderContext.Provider value={value}>{children}</UploaderContext.Provider>;
};

export default Uploader;
