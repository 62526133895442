import styled from 'styled-components';

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[20]}; ;
`;
