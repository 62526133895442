import styled from 'styled-components';

export type AlignVariants = 'flex-start' | 'flex-end' | 'center';

export const ButtonGroup = styled.div<{ $align: AlignVariants }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  justify-self: flex-end;

  & > * + *,
  & > * + * {
    margin-left: 8px;
  }
`;
