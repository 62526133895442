import { useCallback, useContext } from 'react';
import ModalManagerContext from './ModalManagerContext';

type UseOptions = {
  keepOnNavigate: boolean;
};

const useModalContext = <V extends unknown>(name: string, _options?: UseOptions) => {
  const context = useContext(ModalManagerContext);

  const close = useCallback(() => {
    context.closeModal(name);
  }, [context.closeModal, name]);

  const value = context.modals.current?.get(name) as V | undefined;

  return {
    isOpen: context.openedModals.includes(name),
    close,
    value,
  };
};

export default useModalContext;
