import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  archiveContentRecords,
  ContentModifiablePayload,
  ContentRecord,
  CreateContentFolderPayload,
  createContentFolderRecord,
  fetchFolderContent,
  fetchMediaContentSearch,
  FolderContentResponse,
  updateContentRecord,
} from 'api/studio/content';
import { fetchFoldersStatisticThunk } from './foldersActions';
import { fetchRecordsListThunk } from '../files/media/mediaActions';
import { ToastType } from '../../../types/toast';
import pluralize from 'pluralize';
import { addToast } from '../../toasts';
export { selectContentStatistic, selectContentEntries } from './contentSelectors';
import { v4 as uuid } from 'uuid';
import { chunkList } from 'utils/chunks';

export const addUploadedContent = createAction('content/addUploadedContent', (items: ContentRecord[]) => ({
  payload: items,
}));

export const fetchContentThunk = createAsyncThunk(
  'content/fetchContent',
  // if you type your function argument here
  async ({ folder, asset_type }: { folder: string; asset_type: string }) =>
    fetchFolderContent(folder, {
      asset_type: asset_type,
    })
);

export const fetchUploadedItemsThunk = createAsyncThunk('content/fetchUploadedItems', async (object_ids: string[]) =>
  fetchMediaContentSearch({
    object_ids,
  })
);

export const archiveContentThunk = createAsyncThunk('content/archiveContent', archiveContentRecords, {
  getPendingMeta({ arg }) {
    return {
      undoAction: {
        payload: arg,
      },
    };
  },
});

type UpdateContentThunkPayload = Pick<ContentRecord, 'object_id'> & ContentModifiablePayload;

export const updateContentThunk = createAsyncThunk(
  'content/updateContent',
  async ({ object_id, ...patch }: UpdateContentThunkPayload) => updateContentRecord(object_id, patch)
);

type BulkContentMoveThunkArgs = {
  items: string[];
  parent_id: string;
  prev_parent_id?: string;
};

export const bulkContentMoveThunk = createAsyncThunk(
  'content/bulkContentMove',
  async ({ items, parent_id, prev_parent_id }: BulkContentMoveThunkArgs, { dispatch }) => {
    if (!items.length) {
      return {
        items: [],
        parent_id,
        prev_parent_id,
      };
    }

    const toastId = uuid();

    const CHUNK_SIZE = 5;

    const chunks = chunkList(items, CHUNK_SIZE);

    for (const [index, object_ids] of chunks.entries()) {
      await dispatch(
        addToast({
          id: toastId,
          type: ToastType.SUCCESS,
          message: `${index * CHUNK_SIZE} of ${items.length} ${pluralize('file', index)} moved`,
        })
      );

      await Promise.allSettled(object_ids.map((object_id) => updateContentRecord(object_id, { parent_id })));
    }

    dispatch(fetchFoldersStatisticThunk());
    const fullfilledAction = await dispatch(fetchRecordsListThunk(items));
    const payload = fullfilledAction.payload as FolderContentResponse;

    dispatch(addUploadedContent(payload.content));

    await dispatch(
      addToast({
        id: toastId,
        type: ToastType.SUCCESS,
        message: `${items.length} ${pluralize('file')} moved succesfully`,
      })
    );

    return {
      items,
      parent_id,
      prev_parent_id,
    };
  },
  {
    getPendingMeta({ arg }) {
      if (typeof arg.prev_parent_id === undefined) {
        return {};
      }

      return {
        undoAction: {
          payload: { parent_id: arg.prev_parent_id, items: arg.items },
        },
      };
    },
  }
);

export const createContentFolderThunk = createAsyncThunk(
  'content/createFolder',
  (options: CreateContentFolderPayload) => createContentFolderRecord(options)
);
