import { MutableRefObject, useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { EDITOR_LAYERS_ADD, EDITOR_PLAYER_ANCHOR_TIME, EDITOR_PLAYER_CHANGE } from '../../../editorEvents.constants';
import { PlayerBus } from '../../../usePlayerBus';
import { isFabricImageObjectVideo } from 'utils/fabric/guards';
import { IEvent } from 'fabric/fabric-impl';
// import createGradientRectangle from 'utils/fabric/createGradientRectangle';

function trackObjectOriginPosition({ target }: IEvent) {
  target &&
    target.set({
      originPosition: {
        left: target.left,
        top: target.top,
      },
    });
}

const useScratchAddLayers = (
  canvasRef: MutableRefObject<fabric.Canvas>,
  bus: PlayerBus,
  videosContainer: MutableRefObject<HTMLDivElement>
) => {
  const transformPinTrack = useCallback((object: fabric.Object) => {
    object.pinWatcher?.anchorWatcherMoved({ left: object?.left, top: object?.top });

    if (object.pinSource) {
      // object.pinSource.setSourceOffset(object);
      const dimentions = object.getAbsoluteDimensions();
      const anchorSourceOffset = object.pinSource.anchorSourceOffset;

      const position = {
        left: anchorSourceOffset.left * dimentions.scaleX + dimentions.left,
        top: anchorSourceOffset.top * dimentions.scaleY + dimentions.top,
      };

      // object.pinSource.set(position);

      object.pinSource?.anchorSourceMoved(position, true, true);
    }
  }, []);

  useEffect(() => {
    const layersAddHandler = async ({ detail }: any) => {
      const canvas = canvasRef.current;
      let object: fabric.Object = null!;

      switch (detail.source.tagName) {
        case 'TEXT': {
          object = new fabric.StyledTextbox(detail.source.innerText, {
            objectCaching: false,
            originX: 'center',
            originY: 'center',
            left: (canvas.width || 0) / 2,
            top: (canvas.height || 0) / 2,
            object_id: detail.id,
            fontFamily: 'Montserrat',
            tagName: detail.source.tagName,
            fill: 'white',
            // backgroundColor: '#ff0000',
            padding: 10,
            // bgCornerRadius: 10,
          });

          object.on('selected', (e) => {
            console.log('object:selected', e.target);
          });

          break;
        }
        case 'VIDEO': {
          object = new fabric.MaskedMedia(detail.source, {
            // objectCaching: false,
            originX: 'center',
            originY: 'center',
            left: (canvas.width || 0) / 2,
            top: (canvas.height || 0) / 2,
          });

          object.perPixelTargetFind = true;

          const options = {
            source_url: detail.source_url || detail.url,
            width: detail.source.videoWidth,
            height: detail.source.videoHeight,
            object_id: detail.id,
            tagName: detail.source.tagName,
            ...(detail.options || {}),
          };

          (object as fabric.MaskedMedia).set(options);

          object.scaleToHeight(canvas.height || 0);

          /*const gradient = new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'pixels', // or 'percentage'
            coords: { x1: 0, y1: 0, x2: 0, y2: 300 },
            colorStops: [
              { offset: 0, color: '#000' },
              { offset: 1, color: '#fff' },
            ],
          });

          object.set({
            fill: gradient,
          });*/

          /*   const gr: IGradientOptions = {
            type: 'linear',
            // gradientUnits: 'pixels', // or 'percentage'
            // coords: { x1: 0, y1: 0, x2: 0, y2: 300 },
            colorStops: [
              { offset: 0, color: '#000' },
              { offset: 1, color: '#fff' },
            ],
          };
          (object as fabric.MaskedMedia).filters?.push(
            new fabric.Image.filters.GradientEffect({
              gradient: gr,
              img: object as fabric.MaskedMedia,
            })
          );*/

          /*const gradientfilter = new fabric.Image.filters.GradientTransparency({
            threshold: 200,
          });

          (object as fabric.MaskedMedia).filters?.push(gradientfilter);*/

          if (isFabricImageObjectVideo(detail.source)) {
            detail.source.addEventListener(
              'ended',
              (e: any) => {
                console.log('ended', e);
              },
              false
            );

            videosContainer.current.append(detail.source);
          }

          if (isFabricImageObjectVideo(detail.source) && detail.source.duration > bus.duration) {
            detail.source.addEventListener('timeupdate', (e: any) => {
              bus.dispatch(EDITOR_PLAYER_ANCHOR_TIME, {
                time: e.target.currentTime,
                duration: e.target.duration,
              });
            });

            bus.dispatch(EDITOR_PLAYER_CHANGE, {
              duration: detail.source.duration,
            });
          }

          break;
        }
        case 'IMG': {
          const isAnimation = detail.source.src.includes('.gif');

          if (isAnimation) {
            object = await fabric.GifSticker.fromURL(detail.url);
          } else {
            object = new fabric.MaskedMedia(detail.source, {
              // objectCaching: false,
              originX: 'center',
              originY: 'center',
              left: (canvas.width || 0) / 2,
              top: (canvas.height || 0) / 2,
              width: detail.source.naturalWidth,
              height: detail.source.naturalHeight,
            });
          }

          (object as fabric.MaskedMedia).set({
            source_url: detail.source_url || detail.url,
            object_id: detail.id,
            tagName: detail.source.tagName,
          });

          // object.scaleToHeight(canvas.height || 0);
          break;
        }
        default: {
          console.log('unsupported object handler');
          break;
        }
      }

      // console.log(object.toObject());

      object.on('moving', () => {
        transformPinTrack(object);
      });

      object.on('moved', trackObjectOriginPosition);

      object.on('scaled', trackObjectOriginPosition);
      object.on('rotated', trackObjectOriginPosition);

      object.on('scaling', ({ transform }) => {
        if (!transform) {
          return;
        }
        transformPinTrack(object);
      });

      object && canvas.add(object);

      /*     const rect = createGradientRectangle(canvas.width, canvas.height, [
        { offset: 0.5, color: '#01010100' },
        { offset: 0.72, color: '#01010177' },
        { offset: 1, color: '#010101BB' },
      ]);

      canvas.add(rect);*/

      detail.callback && detail.callback(object);

      canvas.renderAll();
    };

    bus.on(EDITOR_LAYERS_ADD, layersAddHandler);

    return () => {
      bus.off(EDITOR_LAYERS_ADD, layersAddHandler);
    };
  }, [bus]);
};

export default useScratchAddLayers;
