import React from 'react';
import download from 'utils/download';
import { getExtension } from 'utils/fileUtils';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconDownload } from '@reface/icons/20px';
import { ContentRecord } from 'api/studio/content';
import Tooltip from 'components/Tooltip';

type DownloadCurrentSwapButtonProps = {
  detailedMedia: ContentRecord;
  swapHistoryPreview: any;
};

const DownloadCurrentSwapButton: React.FC<DownloadCurrentSwapButtonProps> = ({ detailedMedia, swapHistoryPreview }) => {
  const handleDownloadClick = () => {
    const fileName = `${detailedMedia.title}-${swapHistoryPreview.swap_id.slice(-12)}.${getExtension(
      swapHistoryPreview.swap_path
    )}`;
    download(swapHistoryPreview.swap_path, fileName, true);
  };

  if (!swapHistoryPreview) {
    return null;
  }

  return (
    <Tooltip content={'Download swapped media'} placement={'bottomRight'}>
      <Button variant={ButtonVariant.SECONDARY} icon={<IconDownload />} onClick={handleDownloadClick} />
    </Tooltip>
  );
};

export default DownloadCurrentSwapButton;
