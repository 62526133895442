import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

console.log(process.env.NODE_ENV, process.env.FAST_REFRESH, process.env.REACT_APP_DOCKER_IMAGE_TAG);

const history = createBrowserHistory();

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_DOCKER_IMAGE_TAG,
  });
}

if ('fonts' in document) {
  Promise.all([
    document.fonts.load('1em Montserrat'),
    document.fonts.load('700 1em Montserrat'),
    document.fonts.load('italic 1em Montserrat'),
    document.fonts.load('italic 700 1em Montserrat'),
  ]).then(() => {
    document.documentElement.classList.add('fonts-loaded');
  });
}

import App from './App';

import reportWebVitals from './reportWebVitals';
import './index.css';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root')!);

root.render(<App history={history} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
