import { useContext, useState } from 'react';
import SwapQueueContext from '../SwapQueueContext';
import useInterval from 'hooks/useInterval';
import { REQUEST_POLLING_DELAY } from 'constants/common';
import { checkSwapStatus } from 'api/studio/swapProcessing';
import { SWAP_EVENTS } from '../types';

const useSwapQueueCheckInterval = () => {
  const { items, remove } = useContext(SwapQueueContext);
  console.log('useSwapQueueCheckInterval', items);
  const [isProcessingPending, setProcessingPending] = useState(false);

  useInterval(
    async () => {
      if (!items || !items.length) {
        return;
      }

      try {
        setProcessingPending(true);

        const response = await checkSwapStatus(items);

        if (!response.data) {
          return;
        }

        const swapResults = Object.values(response.data);
        const completedSwaps = swapResults.filter(({ status }) => status !== 1);

        remove(...completedSwaps.map(({ swap_id }) => swap_id));

        if (completedSwaps.length) {
          window.dispatchEvent(
            new CustomEvent(SWAP_EVENTS.SWAP_COMPLETED, {
              detail: completedSwaps,
            })
          );
        }
      } catch (e) {
        if (e.response.status === 404) {
          window.dispatchEvent(new CustomEvent(SWAP_EVENTS.CLEAR_SWAP_QUEUE));
          remove(...items);
        }
        console.log(e);
      } finally {
        setProcessingPending(false);
      }
    },
    !isProcessingPending ? REQUEST_POLLING_DELAY : null
  );

  return items;
};

export default useSwapQueueCheckInterval;
