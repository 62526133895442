import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../index';

export const mediaDetailsSelector = (object_id: string) =>
  createSelector(
    (state: RootState) => state.pages.files.media,
    (mediaDetails) => mediaDetails[object_id] || null
  );

export const mediaListDetailsSelector = (object_ids: string[]) =>
  createSelector(
    (state: RootState) => state.pages.files.media,
    (mediaDetails) => object_ids.map((object_id) => mediaDetails[object_id] || null).filter(Boolean)
  );
