import mime from 'mime-type/with-db';

export const splitExtension = (name: string) => {
  const nameParts = name.split('.');
  if (nameParts.length < 2) {
    return name;
  }

  const mimeType = mime.lookup(nameParts[nameParts.length - 1].split('_')[0]);

  return mimeType ? nameParts.slice(0, -1).join('.') : name;
};

export const getExtension = (name: string) => {
  const nameParts = name.split('.');
  const extension = nameParts[nameParts.length - 1].split('_')[0];
  const mimeType = mime.lookup(extension);

  return mimeType ? extension : '';
};
