import { getType, getExtension } from 'mime/lite';

export const fetchAsBlob = async (url: string): Promise<Blob> => {
  const audioSource = await fetch(url);
  return audioSource.blob();
};

const download = async (url: string, fileName?: string, downloadBlob = false) => {
  const mimeType = getType(url) || getType(fileName);

  if (!mimeType) {
    console.warn('Invalid download mimeType');
    return null;
  }

  const link = document.createElement('a');

  if (downloadBlob) {
    const blob = await fetchAsBlob(url);
    link.href = URL.createObjectURL(blob);
  } else {
    link.href = url;
  }
  const extension = getExtension(mimeType);
  link.download = `${fileName.replace(`.${extension}`, '') || url}.${extension}`;
  link.click();
};

export default download;
