import React from 'react';
import * as S from './TreeView.styled';

const TreeViewGroup: React.FC<any> = (props) => {
  const { level, label, groupNodesRenderer: GroupNodesComponent } = props;

  const children = GroupNodesComponent ? <GroupNodesComponent {...props} /> : <>{label.label}</>;

  return (
    <S.TreeViewItem $level={level + 1} $type={'group'}>
      {children}
    </S.TreeViewItem>
  );
};

export default TreeViewGroup;
