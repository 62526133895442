import React, { memo } from 'react';
import { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import ButtonMenuItem from '@reface/ui/MenuItem';
import { IconEdit, IconTrash, IconDownload, IconCopy, IconFolder } from '@reface/icons/20px';
import { ButtonMenuItemVariant } from 'components/ButtonMenuItem';
import ButtonMenu, { ButtonMenuProps } from 'components/ButtonMenu';
import { useModalManager } from '@reface/shared/components/ModalManager';
import { ContentRecord } from 'api/studio/content';
import download from 'utils/download';
import { CHANGE_CONTENT_TITLE_MODAL, ModalPayloadValue } from '../ChangeContentTitleModal';
import { CREATE_PRODUCTION_VIDEO_COPY_MODAL } from '../CreateVideoProductionCopyModal';
import { DELETE_CONTENT_CONFIRMATION_MODAL } from '../DeleteContentConfirmationModal';
import { MODAL_NAME as BULK_CONTENT_MOVE_MODAL } from '../BulkContentMoveModal/BulkContentMoveModal';
import copy from 'copy-to-clipboard';

type ContentItemActionsMenuProps = {
  item: Pick<ContentRecord, 'object_id' | 'title'> &
    Partial<Pick<ContentRecord, 'origin_path' | 'content_type' | 'data'>>;
} & ButtonMenuProps;

type ContentSerializeData = {
  object_id: string;
  path: string;
  persons?: { preview_url: string; person_id: string }[];
};

const ContentItemActionsMenu: React.FC<ContentItemActionsMenuProps> = memo(({ item, ...rest }) => {
  const [openModal] = useModalManager();

  const handleRenameClick = () => {
    openModal<ModalPayloadValue>(CHANGE_CONTENT_TITLE_MODAL, {
      title: item.title,
      object_id: item.object_id,
    });
  };

  const handleCopyCreateClick = () => {
    openModal<ModalPayloadValue>(CREATE_PRODUCTION_VIDEO_COPY_MODAL, item);
  };

  const handleMoveClick = () => {
    openModal(BULK_CONTENT_MOVE_MODAL, [item.object_id]);
  };

  const handleContentDownload = () => {
    download(item.origin_path!, item.title, true);
  };

  const handleDeleteClick = () => {
    openModal(DELETE_CONTENT_CONFIRMATION_MODAL, [item.object_id]);
  };

  const handleCopyPersonsDetailsClick = () => {
    const data: ContentSerializeData = {
      object_id: item.object_id,
      path: item.origin_path,
    };

    if (item.data.persons) {
      data.persons = item.data.persons.map(({ person_id, preview_url }) => ({ preview_url, person_id }));
    }

    copy(JSON.stringify(data, null, 2), {
      debug: false,
      message: 'Press #{key} to copy',
    });
  };

  return (
    <ButtonMenu size={ButtonSize.SMALL} variant={ButtonVariant.SECONDARY} {...rest}>
      {({ close }) => (
        <div onClick={close}>
          <ButtonMenuItem onClick={handleRenameClick}>
            <IconEdit /> Rename
          </ButtonMenuItem>

          {!!item.origin_path && (
            <ButtonMenuItem onClick={handleContentDownload}>
              <IconDownload /> Download
            </ButtonMenuItem>
          )}

          {!!item.origin_path && (
            <ButtonMenuItem onClick={handleMoveClick}>
              <IconFolder /> Move to Folder
            </ButtonMenuItem>
          )}

          {item.content_type === 'video' && (
            <ButtonMenuItem onClick={handleCopyCreateClick}>
              <IconCopy /> Create 20fps copy
            </ButtonMenuItem>
          )}

          {'data' in item && (
            <ButtonMenuItem onClick={handleCopyPersonsDetailsClick}>
              <IconCopy /> Copy persons details
            </ButtonMenuItem>
          )}

          <ButtonMenuItem variant={ButtonMenuItemVariant.DANGER} onClick={handleDeleteClick}>
            <IconTrash /> Delete
          </ButtonMenuItem>
        </div>
      )}
    </ButtonMenu>
  );
});

export default ContentItemActionsMenu;
