import React, { useMemo } from 'react';
import DropdownSelect, { DropdownOption } from 'components/DropdownSelect';
import { IconArrowSortDown, IconArrowSortUp, IconMisc } from '@reface/icons/20px';
import Button, { ButtonVariant } from '@reface/ui/Button';

type PreviewSizeDropdownProps = {
  value: DropdownOption['value'];
  onChange: (option: DropdownOption['value']) => void;
};

const sizeOptions = [
  { value: 24, label: 'Smaller Layout', key: 'Smaller' },
  { value: 36, label: 'Medium Layout', key: 'Medium' },
  { value: 48, label: 'Large Layout', key: 'Large' },
];

const PreviewSizeDropdown: React.FC<PreviewSizeDropdownProps> = ({ value: selectedOption, onChange }) => {
  const value = useMemo(() => sizeOptions.find(({ value }) => value === selectedOption), [selectedOption])!;

  return (
    <DropdownSelect
      onChange={onChange}
      options={sizeOptions}
      value={value}
      renderAnchor={({ isOpen }) => (
        <Button
          variant={ButtonVariant.SECONDARY}
          icon={<IconMisc />}
          endIcon={isOpen ? <IconArrowSortUp /> : <IconArrowSortDown />}
        />
      )}
    />
  );
};

export default PreviewSizeDropdown;
