import styled from 'styled-components';

export const ToolsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ToolGridItem = styled.div`
  display: grid;
  height: 72px;
  grid-template-columns: 72px auto;
  gap: 16px;
  align-items: center;
  cursor: pointer;
`;
