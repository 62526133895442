import React, { useMemo } from 'react';

import { IconPause, IconPlay, IconSoundOff, IconSoundOn } from '@reface/icons/32px';
import formatTime from 'utils/formatTime';

import ControlButton from './components/ControlButton';

import * as S from './AudioPlayerMini.styled';
import { UseAudioPlayerMiniReturn } from './hooks/useAudioPlayerMini';
import cl from 'clsx';

type AudioPlayerProps = Pick<
  UseAudioPlayerMiniReturn,
  | 'timeInParts'
  | 'durationInSeconds'
  | 'isReady'
  | 'isAudioPaused'
  | 'isAudioMuted'
  | 'handleAudioPlayToggle'
  | 'handleAudioMuteToggle'
  | 'handleTimeChangeStart'
  | 'handleTimeChangeEnd'
  | 'handleTimeChange'
>;

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  timeInParts,
  durationInSeconds,
  isReady,
  isAudioPaused,
  isAudioMuted,
  handleAudioPlayToggle,
  handleAudioMuteToggle,
  handleTimeChangeStart,
  handleTimeChangeEnd,
  handleTimeChange,
}) => {
  const width = useMemo(() => `${timeInParts * 100}%`, [timeInParts]);
  const duration = useMemo(() => formatTime(durationInSeconds * 1000), [durationInSeconds]);

  return (
    <S.TimelineWithControlButtons
      className={cl({
        paused: isAudioPaused,
      })}
    >
      <ControlButton onClick={handleAudioPlayToggle} disabled={!isReady}>
        {isAudioPaused ? <IconPlay /> : <IconPause />}
      </ControlButton>

      <S.Timeline>
        <S.BarWrapper>
          <S.Bar>
            <S.ProgressBar style={{ width }} />

            <S.InputWrapper>
              <S.Input
                type="range"
                min={0}
                max={1}
                step={0.001}
                value={timeInParts}
                onMouseDown={handleTimeChangeStart}
                onMouseUp={handleTimeChangeEnd}
                onChange={handleTimeChange}
              />
            </S.InputWrapper>
          </S.Bar>

          <S.Duration>{duration}</S.Duration>
        </S.BarWrapper>
      </S.Timeline>

      <ControlButton onClick={handleAudioMuteToggle} disabled={!isReady}>
        {isAudioMuted ? <IconSoundOff /> : <IconSoundOn />}
      </ControlButton>
    </S.TimelineWithControlButtons>
  );
};

export default AudioPlayer;
