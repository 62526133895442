import styled from 'styled-components';

import { IconLogOut } from '@reface/icons/24px';

export const SidebarUserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 12px 8px 12px 16px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Initial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray[40]};
  text-transform: uppercase;
  user-select: none;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.div`
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Position = styled.div`
  max-width: 140px;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.gray[40]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LogOutIcon = styled(IconLogOut)`
  //visibility: hidden;
  //opacity: 1;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  //transition: background-color 0.3s ease-out, opacity 0.3s ease-out, visibility 0.3s ease-out;

  path {
    transition: fill 0.3s ease-out;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray[25]};

    path {
      fill: ${({ theme }) => theme.colors.gray[90]};
    }
  }
`;
