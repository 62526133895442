import { createSlice } from '@reduxjs/toolkit';
import {
  addUploadedContent,
  archiveContentThunk,
  bulkContentMoveThunk,
  createContentFolderThunk,
  fetchContentThunk,
  fetchUploadedItemsThunk,
  updateContentThunk,
} from './contentActions';
import { FolderContentResponse } from 'api/studio/content';
import { restoreContentThunk } from '../archive/archvieActions';

interface ContentState {
  data: FolderContentResponse;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  data: {
    content: [],
  },
  loading: 'idle',
} as ContentState;

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContentThunk.pending, (state) => {
      state.loading = 'pending';
      state.data = initialState.data;
    });

    builder.addCase(fetchContentThunk.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
        asset_type: action.meta.arg.asset_type,
        parent_id: action.meta.arg.folder,
      };
      state.loading = 'succeeded';
    });

    builder.addCase(fetchContentThunk.rejected, (state) => {
      state.loading = 'failed';
    });

    builder.addCase(fetchUploadedItemsThunk.fulfilled, (state, action) => {
      const object_ids = action.payload.content.map(({ object_id }) => object_id);

      const availableNewContent = action.payload.content.filter(
        ({ asset_type, parent_id }) =>
          state.data.parent_id === (parent_id || '') && state.data.asset_type === (asset_type || 'content')
      );

      console.log({ prevState: [state.data.asset_type, state.data.parent_id], availableNewContent });

      if (state.data.content) {
        state.data.content = [
          ...state.data.content.filter(({ object_id }) => !object_ids.includes(object_id)),
          ...availableNewContent,
        ];
      }
    });

    builder.addCase(addUploadedContent, (state, action) => {
      state.data.content.push(...action.payload);
    });

    builder.addCase(archiveContentThunk.pending, (state, action) => {
      const object_ids = action.meta.arg;
      state.data.content = state.data.content.filter((entry) => !object_ids.includes(entry.object_id));
    });

    builder.addCase(archiveContentThunk.fulfilled, (state, action) => {
      const { object_ids } = action.payload;
      state.data.content = state.data.content.filter((entry) => !object_ids.includes(entry.object_id));
    });

    builder.addCase(updateContentThunk.fulfilled, (state, action) => {
      const { object_id, ...patch } = action.meta.arg;
      const entryIndex = state.data.content.findIndex((entry) => entry.object_id === object_id);
      state.data.content[entryIndex] = {
        ...state.data.content[entryIndex],
        ...patch,
      };
    });

    builder.addCase(bulkContentMoveThunk.pending, (state, action) => {
      console.log(action);
      const { items, parent_id } = action.meta.arg;

      if (state.data.parent_id !== parent_id) {
        state.data.content = state.data.content.filter((entry) => !items.includes(entry.object_id));
      }
    });

    builder.addCase(bulkContentMoveThunk.fulfilled, (state, action) => {
      const { items, parent_id } = action.payload;
      if (state.data.parent_id !== parent_id) {
        state.data.content = state.data.content.filter((entry) => !items.includes(entry.object_id));
      }
    });

    builder.addCase(createContentFolderThunk.fulfilled, (state, action) => {
      console.log(action);
      state.data.content.push(action.payload);
    });

    builder.addCase(restoreContentThunk.fulfilled, (state, action) => {
      state.data.content.push(...action.payload.content);
    });
  },
});

export default contentSlice;
