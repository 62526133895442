import localforage from 'localforage';

const CACHE_DB_NAME = 'mockStorage';

export const cacheInstance = (function (config: LocalForageOptions) {
  return {
    getItem(k: string) {
      return localStorage.getItem([config.storeName, config.name, k].join('/'));
    },
    setItem(k: string, v: string) {
      return localStorage.setItem([config.storeName, config.name, k].join('/'), v);
    },
  };
})({
  driver: localforage.LOCALSTORAGE,
  name: CACHE_DB_NAME,
  storeName: 'cache',
});
