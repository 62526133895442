import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import {
  Cell,
  Column,
  ColumnInstance,
  defaultRenderer,
  HeaderGroup,
  Row,
  RowPropGetter,
  useFlexLayout,
  useTable,
} from 'react-table';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List, ListProps } from 'react-virtualized';
import { isEmpty } from 'lodash';

import EmptyState from 'components/EmptyState';
import Spinner from '@reface/ui/Spinner';

import * as S from './Table.styled';
import cl from 'clsx';

const INIT_WIDTH = 0;
const DEFAULT_MIN_WIDTH = 50;
const DEFAULT_WIDTH = 150;

const DEFAULT_COLUMN_CONFIG = {
  minWidth: DEFAULT_MIN_WIDTH,
  width: INIT_WIDTH,
};

export type TableHandle = {
  cache?: CellMeasurerCache;
  list?: List;
  // clearSelection: (value?: boolean) => void;
};

export enum TableColumnAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

// eslint-disable-next-line @typescript-eslint/ban-types
type CellExtended<T extends {}> = Cell<T> & {
  column: ColumnInstance<T> & {
    align?: TableColumnAlign;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
type HeaderGroupExtended<T extends {}> = HeaderGroup<T> & {
  align?: TableColumnAlign;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableProps<T extends {} = {}> = {
  columns: Column<T>[];
  items: T[];
  hasMore?: boolean;
  selectable?: boolean;
  isLoadingMore?: boolean;
  onLoadMore?: () => void;
  onRowClick?: (item: T) => void;
  noHeader?: boolean;
  getRowProps?: (row: Row<T>) => RowPropGetter<T>;
  getRowDataKey?: (row: Row<T>) => string;
  listProps?: Partial<ListProps>;
  footer?: React.ReactNode;
  renderEmptyScreen?: React.FC;
};

const defaultGetRowProps: TableProps<any>['getRowProps'] = (_row) => ({});
const defaultGetRowDataKey: TableProps<any>['getRowDataKey'] = (row) => row.id;

// eslint-disable-next-line @typescript-eslint/ban-types
const Table = <T extends {}>(
  {
    columns,
    items,
    hasMore = false,
    isLoadingMore = false,
    selectable = true,
    onLoadMore,
    onRowClick,
    noHeader,
    getRowProps = defaultGetRowProps,
    getRowDataKey = defaultGetRowDataKey,
    listProps = {},
    footer,
    renderEmptyScreen: EmptyScreenComponent = EmptyState,
  }: TableProps<T>,
  ref: React.Ref<TableHandle>
): React.ReactElement => {
  const cacheRef = useRef<CellMeasurerCache>(
    new CellMeasurerCache({
      fixedWidth: true,
      minWidth: DEFAULT_COLUMN_CONFIG.minWidth,
    })
  );

  const { registerChild, height: windowHeight, isScrolling, onChildScroll, scrollTop, ...restListProps } = listProps;

  const listRef = useRef<List>(null);

  useImperativeHandle(ref, () => ({
    cache: cacheRef.current as CellMeasurerCache,
    list: listRef.current as List,
  }));

  const columnsMemoized = useMemo(() => columns, [columns]);
  const itemsMemoized = useMemo(() => items, [items]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      // NOTE: columns and data should be `memoized`
      // https://react-table.tanstack.com/docs/api/useTable
      columns: columnsMemoized,
      data: itemsMemoized,
      defaultColumn: DEFAULT_COLUMN_CONFIG,
    },
    useFlexLayout
  );

  const minWidth = useMemo(
    () => columns.map((column) => column?.width || DEFAULT_COLUMN_CONFIG.width).reduce((acc: number, v) => acc + +v, 0),
    [columns]
  ) as number;

  // NOTE: recalculate row heights when table is resized
  const handleResize = () => {
    cacheRef.current?.clearAll();
    listRef.current?.recomputeRowHeights();
  };

  const handleLoadMore = async () => {
    if (onLoadMore && !isLoadingMore) {
      return onLoadMore();
    }
  };

  const handleRowClick = (e, item: T) => {
    e.stopPropagation();
    onRowClick && onRowClick(item);
  };

  return (
    <S.Table {...getTableProps()} $scroll={!registerChild}>
      {!noHeader && (
        <S.TableHeader>
          {headerGroups.map((headerGroup) => (
            <S.TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroupExtended<T>) => {
                const { style, ...rest } = column.getHeaderProps();
                const width = column.width !== INIT_WIDTH ? column.width : DEFAULT_WIDTH;
                const maxWidth = column.width !== INIT_WIDTH ? width : 'none';

                return (
                  <S.TableHead
                    {...rest}
                    style={{ ...style, width, maxWidth }}
                    $align={column?.align || TableColumnAlign.LEFT}
                  >
                    {column.render('Header')}
                  </S.TableHead>
                );
              })}
            </S.TableRow>
          ))}
        </S.TableHeader>
      )}

      {isEmpty(rows) && <EmptyScreenComponent />}

      {!isEmpty(rows) && (
        <S.TableBody>
          <AutoSizer onResize={handleResize} ref={registerChild}>
            {({ height, width }) => (
              <div {...getTableBodyProps()}>
                <InfiniteLoader
                  rowCount={hasMore ? rows.length + 1 : rows.length}
                  isRowLoaded={({ index }) => !!rows[index]}
                  loadMoreRows={handleLoadMore}
                >
                  {({ onRowsRendered }) => (
                    <List
                      ref={listRef}
                      deferredMeasurementCache={cacheRef.current}
                      height={windowHeight || height}
                      width={Math.max(width, minWidth)}
                      rowHeight={cacheRef.current?.rowHeight}
                      rowCount={rows.length}
                      onRowsRendered={onRowsRendered}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      autoHeight={!!onChildScroll}
                      rowRenderer={({ key, index, style, parent }) => {
                        const row = rows[index];

                        prepareRow(row);
                        const { isSelected, ...rowProps } = row.getRowProps(getRowProps(row));

                        return (
                          <CellMeasurer
                            key={key}
                            cache={cacheRef.current}
                            parent={parent}
                            rowIndex={index}
                            columnIndex={0}
                          >
                            {({ registerChild }) => (
                              <S.TableRowSelectable
                                ref={registerChild as React.Ref<HTMLDivElement>}
                                style={style}
                                data-key={getRowDataKey(row)}
                                className={cl({
                                  selectable,
                                  selected: isSelected,
                                })}
                              >
                                <S.TableRow
                                  {...rowProps}
                                  $hasHover={!!onRowClick}
                                  onClick={onRowClick ? (e) => handleRowClick(e, row.original) : undefined}
                                >
                                  {row.cells.map((cell: CellExtended<T>) => {
                                    const { style, ...rest } = cell.getCellProps();
                                    const width = cell.column.width !== INIT_WIDTH ? cell.column.width : DEFAULT_WIDTH;
                                    const maxWidth = cell.column.width !== INIT_WIDTH ? width : 'none';
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const isDefaultRenderer = cell.column.Cell === defaultRenderer;

                                    return (
                                      <S.TableCell
                                        {...rest}
                                        style={{ ...style, width, maxWidth }}
                                        title={
                                          isDefaultRenderer && !React.isValidElement(cell.value)
                                            ? cell.value
                                            : undefined
                                        }
                                        $align={cell.column?.align || TableColumnAlign.LEFT}
                                        $isDefaultRenderer={isDefaultRenderer}
                                      >
                                        {cell.render('Cell')}
                                      </S.TableCell>
                                    );
                                  })}
                                </S.TableRow>
                                {index === rows.length - 1 && isLoadingMore && (
                                  <S.Loader>
                                    <Spinner /> Loading…
                                  </S.Loader>
                                )}
                              </S.TableRowSelectable>
                            )}
                          </CellMeasurer>
                        );
                      }}
                    />
                  )}
                </InfiniteLoader>
                {!!footer && <div style={{ width }}>{footer}</div>}
              </div>
            )}
          </AutoSizer>
        </S.TableBody>
      )}
    </S.Table>
  );
};

export default forwardRef(Table);
