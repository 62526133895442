import { createContext } from 'react';
import { DropzoneOptions } from 'react-dropzone';

import { Item, Meta, ProcessingQueueItem, QueueItem } from './types';

export type UploaderContextState = {
  // items
  items: Item[];
  reset: () => void;
  addCreateMedia: (files: File[], meta: Meta) => void;
  addConvertMedia: (files: File[], meta: Meta) => void;
  retry: (id: string) => void;
  update: (id: string, changes: Pick<Item, 'status'> & Partial<Omit<Item, 'status'>>) => void;
  remove: (id: string) => void;
  // dropzone
  options: { dropzone?: DropzoneOptions; uploader: any };
  setUploaderOptions: (options: { dropzone?: DropzoneOptions; uploader: any }) => void;
};

export const UploaderContextInitialState: UploaderContextState = {
  // items
  items: [],
  reset: (): void => {
    console.log('reset');
  },
  addCreateMedia: (): void => {
    console.log('add');
  },
  addConvertMedia: (): void => {
    console.log('add');
  },
  retry: (): void => {
    console.log('retry');
  },
  update: (): void => {
    console.log('update');
  },
  remove: (): void => {
    console.log('remove');
  },
  // dropzone
  options: {
    dropzone: {
      disabled: true,
    },
    uploader: {},
  },
  setUploaderOptions: (): void => {
    console.log('setUploaderOptions');
  },
};

const UploaderContext = createContext<UploaderContextState>(UploaderContextInitialState);

export default UploaderContext;
