import styled, { css } from 'styled-components';

import { MenuItemVariant } from './MenuItem';

export const MenuItem = styled.div<{ $variant: MenuItemVariant }>`
  display: flex;
  align-items: center;
  min-width: 164px;
  padding: 6px 12px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }

  svg {
    margin-right: 6px;
  }

  path {
    fill: currentColor;
    transition: fill 0.3s ease-out;
  }

  ${({ $variant }) =>
    $variant === MenuItemVariant.PRIMARY &&
    css`
      color: ${({ theme }) => theme.colors.gray[90]};

      path {
        fill: currentColor;
      }
    `}

  ${({ $variant }) =>
    $variant === MenuItemVariant.SUCCESS &&
    css`
      color: ${({ theme }) => theme.palette.success};

      path {
        fill: ${({ theme }) => theme.palette.success};
      }
    `}

  ${({ $variant }) =>
    $variant === MenuItemVariant.DANGER &&
    css`
      color: ${({ theme }) => theme.palette.error};

      path {
        fill: ${({ theme }) => theme.palette.error};
      }
    `}
`;
