import styled from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

export const ToastContentSuccess = styled.div`
  & > div + div {
    margin-top: 4px;
  }
`;

export const Url = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Message = styled.div`
  pre {
    max-width: ${600 - 12 - 8 - 20 - 8}px;
    margin: 4px 0 0 0;
    padding: 4px;
    font-size: 10px;
    font-weight: 600;
    background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.15)};
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
