import React, { useEffect, useMemo } from 'react';
import ButtonMenu from 'components/ButtonMenu';
import { IconArrowRight, IconHistory } from '@reface/icons/20px';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import Typography from '@reface/ui/Typography';
import * as S from './SwapHistoryMenu.styled';
import { Divider } from 'components/LayoutTools';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrayElement } from 'global';
import { fetchSwapHistoryThunk, selectContentSwapHistory } from 'store/pages/swap/history/historySlice';
import { mediaListDetailsSelector } from 'store/pages/files/media/mediaSelectors';
import { fetchRecordsListThunk } from 'store/pages/files/media/mediaActions';
import useQuery from 'hooks/useQuery';

type HistoryRecord = ArrayElement<ReturnType<typeof selectContentSwapHistory>>;

type HistoryRecordRowProps = {
  record: HistoryRecord;
  isActive: boolean;
  onClick: (swap_id: string) => void;
};

const HistoryRecordRow: React.FC<HistoryRecordRowProps> = ({ record, isActive, onClick }) => {
  const appliedFace = useSelector(mediaListDetailsSelector(record.appliedAssets));

  const handleHistoryRowClick = () => {
    onClick(record.swap_id);
  };

  return (
    <S.HistoryRow $isActive={isActive} role={'button'} onClick={handleHistoryRowClick}>
      <S.SelectedPersons>
        {appliedFace.slice(0, 2).map((item) => (
          <S.PersonImage src={item.origin_path} key={item.object_id} />
        ))}
      </S.SelectedPersons>
      <Typography truncate>{record.created_date}</Typography>
    </S.HistoryRow>
  );
};

const SwapHistoryMenu: React.FC<{ entities: ReturnType<typeof selectContentSwapHistory>; object_id: string }> = ({
  entities,
  object_id,
}) => {
  const query = useQuery<{ swap_id?: string }>();
  const link = `/history/search?object_id=${object_id}`;
  const history = useHistory();
  const dispatch = useDispatch();

  const appliedFaces = useMemo(() => entities.map((record) => record.appliedAssets).flat(), [entities]);

  useEffect(() => {
    dispatch(fetchRecordsListThunk(appliedFaces));
  }, [appliedFaces]);

  const handleRowClick = (swap_id: string) => {
    history.push({
      search: `swap_id=${swap_id}`,
    });
  };

  if (!entities.length) {
    return null;
  }

  return (
    <ButtonMenu variant={ButtonVariant.SECONDARY} icon={<IconHistory />}>
      <S.Wrapper>
        <Typography variant={'subtitle1'}>History</Typography>
        {entities.map((record) => (
          <HistoryRecordRow
            key={record.swap_id}
            record={record}
            isActive={record.swap_id === query.swap_id}
            onClick={handleRowClick}
          />
        ))}
      </S.Wrapper>
      <Divider />
      <S.Wrapper>
        <Button endIcon={<IconArrowRight />} component={Link} to={link} variant={ButtonVariant.SECONDARY}>
          See All Swaps
        </Button>
      </S.Wrapper>
    </ButtonMenu>
  );
};

export default SwapHistoryMenu;
