export enum SORT_VARIANTS {
  MODIFIED = 'modified',
  TYPE = 'type',
  NAME = 'name',
}

export const sortOptions = [
  { value: SORT_VARIANTS.MODIFIED, label: 'Modified', key: 'modified' },
  { value: SORT_VARIANTS.TYPE, label: 'Type', key: 'type' },
  { value: SORT_VARIANTS.NAME, label: 'Name', key: 'name' },
];
