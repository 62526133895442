import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[15]};
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #141414;
`;

export const ToolbarControls = styled.div`
  justify-self: flex-end;
  display: inline-flex;
`;

export const ToolbarMain = styled.div`
  flex-grow: 1;
`;
