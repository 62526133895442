import React from 'react';
// import { generatePath, useHistory } from 'react-router-dom';
import Button, { ButtonSize } from '@reface/ui/Button';
import Spinner from '@reface/ui/Spinner';
import useHover from 'hooks/useHover';
import {
  IconAudioContent,
  IconCloseFilled,
  IconImage,
  IconLinkOutside,
  IconReturn,
  IconTrash,
  IconVideo,
  IconSuccessFilled,
  IconWarningFilled,
} from '@reface/icons/20px';
import { UploadStatus } from 'types/common';
// import { RoutePath } from 'types/route';
import { Item } from 'App/components/Uploader/types';
import { UPLOADER_STATUS_MODAL } from '../../UploaderStatusModal';

import * as S from './UploaderItem.styled';
import { useModalManager } from '@reface/shared/components/ModalManager';
import Typography from '@reface/ui/Typography';
import ButtonGroup from '@reface/ui/ButtonGroup';
// import { Link } from 'react-router-dom';

const StatusMessages = {
  [UploadStatus.PENDING]: 'Pending…',
  [UploadStatus.UPLOADING]: 'Uploading…',
  [UploadStatus.UPLOADING_FAILED]: 'Uploading failed',
  [UploadStatus.CONVERT_PENDING]: 'Convert pending…',
  [UploadStatus.CONVERTING]: 'Converting…',
  [UploadStatus.CONVERT_FAILED]: 'Convert failed',
  [UploadStatus.PROCESSING]: 'Processing…',
  [UploadStatus.PROCESSING_FAILED]: 'Processing failed',
  [UploadStatus.PROCESSED]: 'Processed',
};

const getStatusTextMessage = (status: UploadStatus | string) => {
  if (StatusMessages[status as UploadStatus]) {
    return StatusMessages[status as UploadStatus];
  }

  return 'Undefined';
};

type UploaderItemProps = {
  item: Item;
  onRetry: (id: string) => void;
  onRemove: (id: string) => void;
};

const UploaderItem: React.FC<UploaderItemProps> = ({ item, onRetry, onRemove }) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();
  const [, closeUploaderStatusModal] = useModalManager();
  // const history = useHistory();

  // const isItemFailed = [UploadingStatus.UPLOADING_FAILED, ProcessingStatus.PROCESSING_FAILED].includes(item.status);

  const handleRetry = () => onRetry(item.id);

  const handleRemove = () => onRemove(item.id);

  const handleNavigateToAudio = () => {
    closeUploaderStatusModal(UPLOADER_STATUS_MODAL);

    // history.push(generatePath(RoutePath.CONTENT__AUDIOS__FILES__FILE, { audioId: item.id! }));
  };

  const message = getStatusTextMessage(item.status);

  return (
    <S.UploaderItem ref={ref}>
      <S.Icon>
        {item.fileData.type.includes('image/') && <IconImage />}
        {item.fileData.type.includes('video/') && <IconVideo />}
        {item.fileData.type.includes('audio/') && <IconAudioContent />}
      </S.Icon>
      <S.FileNameAndStatusMessage>
        <Typography truncate>{item.fileData?.name}</Typography>
      </S.FileNameAndStatusMessage>

      <S.StatusMessage>{item.status !== UploadStatus.PROCESSED && message}</S.StatusMessage>

      <S.Icon>
        {item.status === UploadStatus.PENDING && isHovered && (
          <Button size={ButtonSize.SMALL} icon={<IconCloseFilled />} isRounded onClick={handleRemove} />
        )}

        {item.status === UploadStatus.UPLOADING && <Spinner />}

        {item.status === UploadStatus.UPLOADING_FAILED && !isHovered && <IconWarningFilled color={'error'} />}
        {item.status === UploadStatus.UPLOADING_FAILED && isHovered && (
          <Button size={ButtonSize.SMALL} icon={<IconReturn />} isRounded onClick={handleRetry} />
        )}

        {item.status === UploadStatus.PROCESSING && <Spinner />}

        {item.status === UploadStatus.PROCESSING && isHovered && (
          <ButtonGroup>
            <Button size={ButtonSize.SMALL} icon={<IconTrash />} onClick={handleRemove} />
          </ButtonGroup>
        )}

        {item.status === UploadStatus.PROCESSING_FAILED && <IconWarningFilled color={'error'} />}
        {item.status === UploadStatus.PROCESSING_FAILED && isHovered && (
          <>
            <Button size={ButtonSize.SMALL} icon={<IconTrash />} onClick={handleRemove} />
            <Button size={ButtonSize.SMALL} icon={<IconLinkOutside />} isRounded onClick={handleNavigateToAudio} />
          </>
        )}

        {item.status === UploadStatus.PROCESSED && !isHovered && <IconSuccessFilled color={'success'} />}

        {item.status === UploadStatus.PROCESSED && isHovered && (
          <ButtonGroup>
            <Button size={ButtonSize.SMALL} icon={<IconTrash />} onClick={handleRemove} />
            {/*<Button size={ButtonSize.SMALL} icon={<IconLinkOutside />} as={Link} to={`/swap/${item.object_id}`} />*/}
          </ButtonGroup>
        )}
      </S.Icon>
    </S.UploaderItem>
  );
};

export default UploaderItem;
