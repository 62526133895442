import styled from 'styled-components';

import { IconSuccessFilled as _IconSuccessFilled, IconWarningFilled as _IconWarningFilled } from '@reface/icons/20px';
import Typography from '@reface/ui/Typography';

export const UploaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px;
  background-color: ${({ theme }) => theme.colors.gray[15]};
  width: 100%;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
  }
`;

export const FileNameAndStatusMessage = styled.div`
  //max-width: calc(100% - 16px - 32px);
  flex-grow: 1;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
`;

export const FileName = styled(Typography)<{ $isItemFailed: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusMessage = styled.div`
  user-select: none;
  white-space: nowrap;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.gray[90]};
`;
