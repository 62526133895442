import { createSlice } from '@reduxjs/toolkit';
import { fetchAssetsThunk, fetchUploadedAssetsThunk } from './assetsActions';
import { ContentRecord } from 'api/studio/content';

type AssetsState = {
  entities: Record<string, ContentRecord[]>;
};

const initialState: AssetsState = {
  entities: {},
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssetsThunk.fulfilled, (state, action) => {
      const { asset_type, folder } = action.meta.arg;
      state.entities[`${asset_type}_${folder || 'root'}`] = action.payload.content;
    });

    builder.addCase(fetchUploadedAssetsThunk.fulfilled, (state, action) => {
      const object_ids = action.meta.arg;

      for (const asset of action.payload.content) {
        const prevFolderState = state.entities[`${asset.asset_type}_${asset.parent_id || 'root'}`] || [];
        state.entities[`${asset.asset_type}_${asset.parent_id || 'root'}`] = [
          ...prevFolderState.filter(({ object_id }) => !object_ids.includes(object_id)),
          asset,
        ];
      }
    });
  },
});

export default assetsSlice;
