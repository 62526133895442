import styled, { css } from 'styled-components';

export type SpinnerArgs = {
  size: number;
};

export const SpinnerWrapper = styled.div<SpinnerArgs>`
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  animation: 0.6s linear infinite spinner-border;

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
`;
