import React, { Component, ComponentProps } from 'react';
import styled from 'styled-components';

export enum ColorVariants {
  ACTION = 'action',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INHERIT = 'inherit',
}

type Colors = typeof ColorVariants[keyof typeof ColorVariants];

type IconCustomAttributes = {
  color?: Colors | string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const IconComponent = styled.svg<IconCustomAttributes>`
  color: ${({ theme, color }) => (color in theme.palette ? theme.palette[color] : color)};
`;

export type IconComponentProps = IconCustomAttributes & ComponentProps<typeof IconComponent>;

const getIconDateTestId = (iconComponent: IconComponentProps['as']) => {
  if (!iconComponent || typeof iconComponent === 'string') {
    return 'icon-basic';
  }

  return iconComponent.name || iconComponent.displayName || (iconComponent as unknown as Component).render.name;
};

const SvgIcon: React.FC<IconComponentProps> = ({ color = ColorVariants.INHERIT, as, ...props }) => (
  <IconComponent color={color} as={as} data-testid={getIconDateTestId(as)} {...props} />
);

export default SvgIcon;
