import React from 'react';
import * as S from './LoadingOverlay.styled';
import { LoadingVariant1 } from 'static/images';
import { useSelector } from 'react-redux';
import { getLoadingState } from 'store/pages/editor/loading';

const LoadingOverlay = () => {
  const isVisible = useSelector(getLoadingState);

  if (!isVisible) {
    return null;
  }

  return (
    <S.LoadingOverlayWrapper>
      <S.LoadingContent>
        <LoadingVariant1 />
      </S.LoadingContent>
    </S.LoadingOverlayWrapper>
  );
};

export default LoadingOverlay;
