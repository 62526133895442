import { proxyApi } from 'services/http';

import { EditorTranscoderPayload } from 'types/transcoder';
import { CheckAssetProcessingResponse } from './processing';
import { sleep } from 'store/pages/assets/createAsset';

export const bakeEditorTimeline = async (data: EditorTranscoderPayload) => {
  const response = await proxyApi.post(`transcoder/pipeline`, data);

  return response.data;
};

type CreateVideoCopyOptions = {
  fps?: number;
  visible?: boolean;
};

export const createVideoCopy = async (path: string, options: CreateVideoCopyOptions) => {
  const response = await proxyApi.post<{ tracking_id: string }>('transcoder/video', {
    path,
    output_format: 'mp4',
    ...options,
  });

  return response.data;
};

export const checkPipelineProcessing = async (ids: string[]) => {
  const response = await proxyApi.post<{ data: CheckAssetProcessingResponse }>(`transcoder/check-processing`, {
    ids: ids,
  });

  return response.data;
};

export const awaitTranscoderPipeline = async (ids: string[]) => {
  let isCompleted = false;
  let completedTasks: any[] = [];
  do {
    await sleep(5000, null);
    const checkResponse = await checkPipelineProcessing(ids);

    completedTasks = Object.entries(checkResponse.data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_key, value]) => value.status !== 1)
      .map(([k, v]) => ({
        tracker_id: k,
        ...v,
      }));

    console.log(completedTasks);

    isCompleted = !!completedTasks.length;
  } while (!isCompleted);

  const tracking = completedTasks.map(({ tracking }) => tracking).flat()[0];
  const error_message = completedTasks.map(({ error_message }) => error_message)[0];

  return {
    tracking,
    error_message,
  };
};
