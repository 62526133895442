import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { firebaseAuth } from '../../services/firebase';
import { REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM } from '../../constants/auth';
import { setAuthStatus, setFirebaseAuthStatus, setFirebaseToken } from '../../store/auth/auth';
import { AuthStatus, FirebaseAuthStatus } from '../../types/auth';
import { signInThunk, signOutThunk } from '../../store/auth/api/auth';

const useFirebaseAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const firebaseSubscription = onAuthStateChanged(firebaseAuth, async (user: User | null) => {
      const isSessionOpen = !!localStorage.getItem(REFRESH_TOKEN_IAT_LOCAL_STORAGE_ITEM);

      if (user) {
        const firebaseToken = await user.getIdToken();

        dispatch(setFirebaseAuthStatus(FirebaseAuthStatus.SIGNED_IN));
        dispatch(setFirebaseToken(firebaseToken));
        dispatch(setAuthStatus(AuthStatus.SHOULD_BE_SIGNED_IN));

        dispatch(signInThunk({ firebaseToken, isSessionOpen }));
      } else {
        dispatch(setFirebaseAuthStatus(FirebaseAuthStatus.SIGNED_OUT));
        dispatch(setFirebaseToken(''));

        if (isSessionOpen) {
          dispatch(signOutThunk());
        } else {
          dispatch(setAuthStatus(AuthStatus.SIGNED_OUT));
        }
      }
    });

    return () => {
      firebaseSubscription && firebaseSubscription();
    };
  }, []);
};

export default useFirebaseAuth;
