import React, { forwardRef } from 'react';

import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import Menu from '@reface/ui/Menu';
import Popover, { PopoverRenderOptions } from '@reface/ui/Popover';
import { IconKebabVertical } from '@reface/icons/20px';
import cl from 'clsx';

export type ButtonMenuProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  className?: string;
  isRounded?: boolean;
  icon?: React.ReactElement;
  onIsOpenChanged?: (isOpen: boolean) => void;
  children?: React.ReactNode | ((options: PopoverRenderOptions) => React.ReactNode);
};

const ButtonMenu = forwardRef<HTMLButtonElement, ButtonMenuProps>(
  ({ variant, size, isRounded, className, icon, children, onIsOpenChanged }, ref) => (
    <Popover
      onIsOpenChanged={onIsOpenChanged}
      popover={(options) => <Menu>{typeof children === 'function' ? children(options) : children}</Menu>}
    >
      {({ isOpen }) => (
        <Button
          variant={variant}
          size={size}
          icon={icon || <IconKebabVertical />}
          isRounded={isRounded}
          className={cl(className, { active: isOpen })}
          ref={ref}
        />
      )}
    </Popover>
  )
);

export default ButtonMenu;
