import React from 'react';
import HoverVideoPlayer from 'react-hover-video-player';

const VideoPreview: React.FC<ContentPreviewProps> = ({ src }) => (
  <HoverVideoPlayer
    videoSrc={src}
    className={'video-container'}
    loadingOverlay={
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    }
  />
);

export default VideoPreview;
