const dispatchEvent = <T extends any>(type: string, detail?: T) => {
  const event = new CustomEvent(type, {
    detail,
    bubbles: true,
    cancelable: true,
    composed: false,
  });
  window.dispatchEvent(event);
};

export default dispatchEvent;
