import styled from 'styled-components';
import { TableCell, TableRow } from 'components/Table/Table.styled';
import ContentItemPersonsPreview from '../ContentItemPersonsPreview';

export const Controls = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[20]}`};
  align-items: center;
  margin-bottom: 16px;
`;

export const EffectButtonsGroup = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
`;

export const RowActions = styled.div`
  display: inline-flex;
  visibility: hidden;
`;

export const RowStatus = styled.div`
  display: inline-flex;
  visibility: visible;
`;

export const ContentTable = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  ${TableCell} {
    display: inline-flex;
    align-items: center;
    padding: 8px 8px 8px 8px;
  }

  & ${TableRow}:hover {
    background-color: ${({ theme }) => theme.colors.gray[20]};
    border-color: ${({ theme }) => theme.colors.gray[20]};
  }

  && ${TableRow} {
    border: none;
    border-radius: 8px;
    border-color: ${({ theme }) => theme.colors.gray[15]};

    &:hover ${RowActions} {
      visibility: visible;
    }

    // &:hover ${RowStatus} {
    //   visibility: hidden;
    // }
  }
`;

export const ContentPersonsPreview = styled(ContentItemPersonsPreview)`
  color: ${({ theme }) => theme.colors.gray[15]};

  ${TableRow}:hover & {
    color: ${({ theme }) => theme.colors.gray[20]};
  }
`;

export const RowActionsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const RecordPreviewImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppliedFacePreviewImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;

  border: 2px solid ${({ theme }) => theme.colors.blue[100]};
  background-color: ${({ theme }) => theme.colors.blue[100]};
  box-sizing: border-box;
`;

export const AppliedMappingPreview = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 8px;
  margin-top: -2px;
  margin-left: -2px;
`;

export const EmptyPreview = styled.svg`
  width: 24px;
  height: 24px;
  padding: 2px;
`;
