import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  SwapHistoryPayload,
  deleteSwapHistory,
  getSwapHistory,
  DeleteSwapHistoryOptions,
  SwapHistoryRecord,
} from 'api/studio/swapHistory';
import { RootState } from 'store';

export type { SwapHistoryRecord } from 'api/studio/swapHistory';

export const getPlainHistoryEntries = (state: RootState) =>
  [state.pages.history.plain.entities, state.pages.history.plain.total] as const;

export const fetchPlainHistoryThunk = createAsyncThunk(
  'pages/history/plain/fetchPlainHistory',
  async (options: Pick<SwapHistoryPayload, 'offset' | 'limit'>) => getSwapHistory({ ...options, with_object: true })
);

export const deleteSwapHistoryThunk = createAsyncThunk(
  'history/bulk/deleteRecord',
  async (options: Pick<DeleteSwapHistoryOptions, 'swap_ids'>) => deleteSwapHistory(options)
);

interface PlainHistoryState {
  entities: SwapHistoryRecord[];
  total: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  entities: [],
  total: 0,
  loading: 'idle',
} as PlainHistoryState;

const bulkHistorySlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlainHistoryThunk.pending, (state) => {
      state.entities = [];
      state.total = 0;
    });

    builder.addCase(fetchPlainHistoryThunk.fulfilled, (state, action) => {
      state.entities = action.payload.swap_history;
      state.total = action.payload.total;
    });

    builder.addCase(deleteSwapHistoryThunk.pending, (state, action) => {
      const { swap_ids } = action.meta.arg;
      state.entities = state.entities.filter((entry) => !swap_ids.includes(entry.swap_id));
    });

    // builder.addCase(deleteSwapHistoryThunk.fulfilled, (state, action) => {
    //   state.entities = state.entities.filter((entry) => !object_ids.includes(entry.object_id));
    // });
  },
});

export default bulkHistorySlice;
