import React, { useState, useCallback, useEffect } from 'react';

import * as S from './Layout.styled';
import Sidebar from '../Sidebar/Sidebar';
import cl from 'clsx';

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [compactMenu, setCompactMenu] = useState(false);

  const handleMenuToggle = useCallback(() => {
    setCompactMenu((value) => !value);
  }, []);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('trigger:resize'));
  }, [compactMenu]);

  return (
    <S.Layout
      className={cl({
        compact: compactMenu,
      })}
    >
      <Sidebar onMenuToggle={handleMenuToggle} compact={compactMenu} />
      <S.Content id="content">{children}</S.Content>
    </S.Layout>
  );
};

export default Layout;
