import styled from 'styled-components';
import { IconHeadMotion } from '@reface/icons/20px';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
export const PreviewSizePicker = styled.div`
  margin-bottom: 16px;
`;

export const PersonPreview = styled.img`
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.gray[40]};
`;

export const AppliedMappingPreview = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.blue[100]};
  background-color: ${({ theme }) => theme.colors.blue[100]};
  position: relative;
  cursor: pointer;

  & > svg.fallback {
    width: 100%;
    height: 100%;
  }
`;

export const AppliedFacePreviewImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
`;

export const MiniIconHeadMotion = styled(IconHeadMotion)`
  position: absolute;
  width: 30%;
  height: 30%;
  top: 4px;
  right: 4px;
  color: ${({ theme }) => theme.colors.blue[100]};
`;

export const AddEffectWrapper = styled.div`
  visibility: hidden;
`;

export const EffectRow = styled.div<{ $size: number }>`
  display: flex;
  align-items: center;
  height: ${({ $size }) => `${$size + 8}px`};
  padding: 4px 8px;
  border-radius: 8px;

  & > :not(:first-child) {
    margin-left: ${({ $size }) => ($size < 32 ? '4px' : '-4px')};
  }

  & > :last-child {
    margin-left: 4px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[15]};
  }

  &:hover ${AddEffectWrapper} {
    visibility: visible;
  }

  & ${PersonPreview}, & ${AppliedMappingPreview} {
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};
  }
`;
