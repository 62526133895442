import styled from 'styled-components';

export const LayoutColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  padding: ${({ theme }) => theme.layout.gutter}px;
  background-color: ${({ theme }) => theme.colors.gray[8]};
`;

export const Header = styled.div`
  margin-bottom: 18px;
`;

export const Children = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`;

export const Footer = styled.div`
  margin-top: 24px;
`;
