import { getExtension, splitExtension } from 'utils/fileUtils';
import { fetchSignedUrl, putCloudContent } from '../studio/cloud';

type CreateContentOptions = {
  visible?: boolean;
  parent_id?: string | null;
};

const uploadIdleContent = async (file: File, options: CreateContentOptions) => {
  const fileName = splitExtension(file.name);

  const extension = getExtension(file.name);

  const sighedUrl = await fetchSignedUrl(extension);
  const originPath = sighedUrl.url.slice(0, sighedUrl.url.indexOf('?'));

  await putCloudContent(sighedUrl, file);

  return {
    title: fileName,
    validate_faces: false,
    with_processing: false,
    ...options,
    path: originPath,
  };
};

export default uploadIdleContent;
