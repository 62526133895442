import { RouteProps } from 'react-router-dom';

export enum RouteId {
  // AUTH PAGES

  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',

  // PAGES

  RBAC = 'RBAC',
  RBAC__SERVICES = 'RBAC__SERVICES',
  RBAC__SERVICES__SERVICES = 'RBAC__SERVICES__SERVICES',
  RBAC__PERMISSIONS = 'RBAC__PERMISSIONS',
  RBAC__PERMISSIONS__PERMISSIONS = 'RBAC__PERMISSIONS__PERMISSIONS',
  RBAC__PERMISSIONS__PERMISSION = 'RBAC__PERMISSIONS__PERMISSION',
  RBAC__ROLES = 'RBAC__ROLES',
  RBAC__ROLES__ROLES = 'RBAC__ROLES__ROLES',
  RBAC__ROLES__ROLE = 'RBAC__ROLES__ROLE',

  USERS = 'USERS',
  USERS__APP_USERS = 'USERS__APP_USERS',
  USERS__APP_USERS__APP_USERS = 'USERS__APP_USERS__APP_USERS',
  USERS__APP_USERS__APP_USER = 'USERS__APP_USERS__APP_USER',
  USERS__APP_USERS__APP_USER_CONTENT = 'USERS__APP_USERS__APP_USER_CONTENT',
  USERS__WEB_USERS = 'USERS__WEB_USERS',
  USERS__WEB_USERS__WEB_USERS = 'USERS__WEB_USERS__WEB_USERS',
  USERS__WEB_USERS__WEB_USER = 'USERS__WEB_USERS__WEB_USER',

  CONTENT = 'CONTENT',
  CONTENT__CATEGORIES = 'CONTENT__CATEGORIES',
  CONTENT__CATEGORIES__CATEGORIES = 'CONTENT__CATEGORIES__CATEGORIES',
  CONTENT__CATEGORIES__CATEGORY = 'CONTENT__CATEGORIES__CATEGORY',
  CONTENT__AUDIOS = 'CONTENT__AUDIOS',
  CONTENT__AUDIOS__AUDIOS = 'CONTENT__AUDIOS__AUDIOS',
  CONTENT__AUDIOS__AUDIO = 'CONTENT__AUDIOS__AUDIO',

  CATEGORY_COVERS = 'CATEGORY_COVERS',
  CATEGORY_COVERS__CATEGORY_COVERS = 'CATEGORY_COVERS__CATEGORY_COVERS',

  PUBLISHING = 'PUBLISHING',
  PUBLISHING__LAYOUT_BUCKETS = 'PUBLISHING__LAYOUT_BUCKETS',
  PUBLISHING__LAYOUT_BUCKETS__LAYOUT_BUCKETS = 'PUBLISHING__LAYOUT_BUCKETS__LAYOUT_BUCKETS',
  PUBLISHING__LAYOUT_BUCKETS__LAYOUT_BUCKET = 'PUBLISHING__LAYOUT_BUCKETS__LAYOUT_BUCKET',

  __CONTENTS = '__CONTENTS',
  __CONTENT = '__CONTENT',

  __ASSETS = '__ASSETS',
  __ASSET = '__ASSET',
}

export type Route = {
  component: RouteProps['component'];
  id: RouteId;
  isExact?: RouteProps['exact'];
  path: string;
};
