import React, { useState } from 'react';
import cl from 'clsx';
import { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconViewTable, IconViewTableBig } from '@reface/icons/20px';
import * as S from './ContentViewGroup.styled';

export enum VIEW_VARIANT {
  TABLE = 'small',
  DETAILED = 'large',
}

const ContentViewGroup: React.FC<{ value: VIEW_VARIANT; onChange: (value: VIEW_VARIANT) => void }> = ({
  value,
  onChange,
}) => (
  <S.Wrapper>
    <S.ViewButton
      size={ButtonSize.SMALL}
      variant={ButtonVariant.SECONDARY}
      onClick={() => onChange(VIEW_VARIANT.TABLE)}
      icon={<IconViewTable />}
      className={cl({
        active: VIEW_VARIANT.TABLE === value,
      })}
      disabled={VIEW_VARIANT.TABLE === value}
    />
    <S.ViewButton
      size={ButtonSize.SMALL}
      variant={ButtonVariant.SECONDARY}
      onClick={() => onChange(VIEW_VARIANT.DETAILED)}
      icon={<IconViewTableBig />}
      className={cl({
        active: VIEW_VARIANT.DETAILED === value,
      })}
      disabled={VIEW_VARIANT.DETAILED === value}
    />
  </S.Wrapper>
);

export default ContentViewGroup;
