import React, { useEffect, useRef } from 'react';
import { ModalProps as ResponsiveModalProps } from 'react-responsive-modal';

import { IconCloseModal } from '@reface/icons/20px';

import * as S from './Modal.styled';

import 'react-responsive-modal/styles.css';

export type ModalProps = {
  title?: string;
  actions?:
    | (({ defaultActionRef }: { defaultActionRef: React.Ref<HTMLButtonElement> }) => React.ReactElement)
    | React.ReactElement;
  sidePaddings?: boolean;
  scrollable?: boolean;
  keepOnNavigate?: boolean;
} & ResponsiveModalProps;

const Modal: React.FC<ModalProps> = ({
  title,
  actions,
  sidePaddings = true,
  showCloseIcon = true,
  scrollable = true,
  children,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const defaultActionRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      defaultActionRef.current?.click();
    }
  };

  useEffect(() => {
    modalRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      modalRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalRef.current]);

  if (!title && !children && !actions) {
    return null;
  }

  return (
    <S.Modal center {...rest} ref={modalRef} showCloseIcon={!!title && showCloseIcon} closeIcon={<IconCloseModal />}>
      {title && <S.Title>{title}</S.Title>}

      {children && (
        <S.ScrollableContent $isScrollable={scrollable}>
          <S.Content $noTitle={!title} $noActions={!actions} $sidePaddings={sidePaddings}>
            {children}
          </S.Content>
        </S.ScrollableContent>
      )}

      {actions && <S.Actions>{typeof actions === 'function' ? actions({ defaultActionRef }) : actions}</S.Actions>}
    </S.Modal>
  );
};

export default Modal;
