import React from 'react';
import * as S from './ContentToolbar.styled';

type ContentToolbarProps = {
  controls?: JSX.Element;
  children?: React.ReactNode;
};

const ContentToolbar: React.FC<ContentToolbarProps> = ({ children, controls }) => (
  <S.ToolbarWrapper role={'feed'}>
    <S.ToolbarMain>{children}</S.ToolbarMain>
    <S.ToolbarControls>{controls}</S.ToolbarControls>
  </S.ToolbarWrapper>
);

export default ContentToolbar;
