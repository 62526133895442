import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setEditorLoading } from 'store/pages/editor/loading';

const useEditorLoading = () => {
  const dispatch = useDispatch();

  return useCallback(
    (loading) => {
      dispatch(setEditorLoading(loading));
    },
    [dispatch]
  );
};

export default useEditorLoading;
