import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFolderContent, fetchMediaContentSearch } from 'api/studio/content';

export const fetchAssetsThunk = createAsyncThunk(
  'assets/fetchContent',
  // if you type your function argument here
  async ({ folder, asset_type }: { folder: string; asset_type: string }) =>
    fetchFolderContent(folder, {
      asset_type: asset_type,
    })
);

export const fetchUploadedAssetsThunk = createAsyncThunk(
  'assets/fetchUploadedAssetsThunk',
  async (object_ids: string[]) =>
    fetchMediaContentSearch({
      object_ids,
    })
);
