import React, { useState } from 'react';
import PageLayout from '../../App/components/PageLayout';
import Tabs from '@reface/ui/Tabs';
import Tab, { TabType } from '@reface/ui/Tab';
import BulkSwapsHistoryTable from './BulkSwapsHistoryTable';
import PlainSwapHistoryTable from './PlainSwapHistoryTable';
import Breadcrumbs from 'components/Breadcrumbs';
import useHistorySearchBreadcrumbs from './hooks/useHistorySearchBreadcrumbs';

const TAB_NAMES = {
  BULK: 'Bulk Operations',
  PLAIN: 'All Swaps History',
};

const History: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TAB_NAMES.BULK);
  const breadcrumbs = useHistorySearchBreadcrumbs();

  const handleTabClick = (label: string) => setActiveTab(label);

  return (
    <PageLayout header={<Breadcrumbs items={breadcrumbs} />}>
      <Tabs type={TabType.NORMAL}>
        <Tab
          type={TabType.NORMAL}
          label={TAB_NAMES.BULK}
          isActive={activeTab === TAB_NAMES.BULK}
          onClick={handleTabClick}
        />
        <Tab
          type={TabType.NORMAL}
          label={TAB_NAMES.PLAIN}
          isActive={activeTab === TAB_NAMES.PLAIN}
          onClick={handleTabClick}
        />
      </Tabs>

      {activeTab === TAB_NAMES.BULK && <BulkSwapsHistoryTable />}
      {activeTab === TAB_NAMES.PLAIN && <PlainSwapHistoryTable />}
    </PageLayout>
  );
};

export default History;
