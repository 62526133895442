import React from 'react';
import ContentEmpty from 'components/ContentEmpty';
import { EmptyContentImage } from 'static/images';

const BulkSwapEmptyScreen = () => (
  <ContentEmpty
    icon={EmptyContentImage}
    title="Nothing available for swap"
    subtitle="Select video or image content for swap"
  />
);

export default BulkSwapEmptyScreen;
