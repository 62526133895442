import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { Toast } from 'types/toast';

export type ToastsState = Toast[];

const getState: (state: RootState) => ToastsState = (state) => state.toasts;

const name = 'toasts';

const initialState: ToastsState = [];

const slice = createSlice({
  name,
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<Toast>) {
      const existedIndex = action.payload.id ? state.findIndex(({ id }) => id === action.payload.id) : -1;

      existedIndex !== -1 && state.splice(existedIndex, 1);

      state.push({
        id: Date.now(),
        ...action.payload,
      });
    },
    removeToast(state, action: PayloadAction<Toast['id']>) {
      return state.filter((toast) => toast.id !== action.payload);
    },
  },
});

export { getState as getToastsState };
export const { addToast, removeToast } = slice.actions;
export default slice.reducer;
