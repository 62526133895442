import { fabric } from 'fabric';
import { GifSticker, IGifStickerOptions } from 'fabric/fabric-impl';
import gifToSprite from '../gifToSprite';

type GifStatus = 'Playing' | 'Paused' | 'Stopped';

enum STATUSES {
  PLAY,
  PAUSE,
  STOP,
}

const defaultOptions = { maxWidth: 150, maxHeight: 150 };

const loadGifImageAsSprite = async function (
  url: string,
  callback?: (image: GifSticker, isError?: boolean) => void,
  imgOptions?: IGifStickerOptions
) {
  const maxWidth = imgOptions?.maxWidth ?? defaultOptions.maxWidth;
  const maxHeight = imgOptions?.maxHeight ?? defaultOptions.maxHeight;

  const { dataUrl, delay, frameWidth, frameHeight, framesLength } = await gifToSprite(
    url,
    maxWidth,
    maxHeight,
    imgOptions?.maxDuration
  );

  const width = Math.min(maxWidth, frameWidth);

  const aspectRation = width / frameWidth;

  const height = frameHeight * aspectRation;
  // console.log(dataUrl);
  return new Promise<fabric.GifSticker>((resolve) => {
    fabric.util.loadImage(
      dataUrl,
      function (sprite: HTMLImageElement, isError: boolean) {
        const img = new fabric.GifSticker(sprite, imgOptions);
        let framesIndex = 0;
        let start = performance.now();
        let status: STATUSES;

        img.filters = [];
        img.mode = 'image';

        img.set({
          width,
          height,
        });

        img._render = function (ctx: CanvasRenderingContext2D) {
          if (status === STATUSES.PAUSE || (status === STATUSES.STOP && framesIndex === 0)) {
            return;
          }
          const now = performance.now();
          const delta = now - start;
          if (delta > delay) {
            start = now;
            framesIndex++;
          }
          if (framesIndex === framesLength || status === STATUSES.STOP) {
            framesIndex = 0;
          }
          // console.log(this);
          ctx.drawImage(
            sprite,
            frameWidth * framesIndex,
            0,
            frameWidth,
            sprite.height,
            -(this.width || 0) / 2,
            -(this.height || 0) / 2,
            frameWidth,
            sprite.height
          );
        };

        img.play = function () {
          status = STATUSES.PLAY;
          this.dirty = true;
        };
        img.pause = function () {
          status = STATUSES.PAUSE;
          this.dirty = false;
        };
        img.stop = function () {
          status = STATUSES.STOP;
          this.dirty = false;
        };

        img.getStatus = () => ['Playing', 'Paused', 'Stopped'][status] as GifStatus;

        img.play();

        callback && callback(img, isError);
        resolve(img);
      },
      null,
      imgOptions && imgOptions.crossOrigin
    );
  });
};

fabric.GifSticker = fabric.util.createClass(fabric.Image, {
  type: 'gifSticker',
  source_url: null,
  toObject: function (propertiesToInclude: string[]) {
    console.log(this.source_url);
    return fabric.util.object.extend(this.callSuper('toObject', propertiesToInclude), {
      src: this.source_url,
    });
  },
});

fabric.GifSticker.fromURL = function (
  url: string,
  callback?: (image: fabric.Image, isError?: boolean) => void,
  imgOptions?: IGifStickerOptions
) {
  return loadGifImageAsSprite(url, callback, imgOptions);
};

fabric.GifSticker.fromObject = function (_object: any, callback?: any) {
  const object = fabric.util.object.clone(_object);
  console.log('GifSticker.fromObject', object);

  loadGifImageAsSprite(object.src, callback, _object);
};

/*
fabric.GifSticker.fromObject = function (object: any, callback?: any) {
  return fabric.Object._fromObject('GifSticker', object, callback) as fabric.GifSticker;
};
*/
