import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '@reface/shared/components/ModalManager';
import { bulkContentMoveThunk } from 'store/pages/content/contentActions';
import Modal from '@reface/ui/Modal';
import Button, { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import * as S from './BulkContentMoveModal.styled';
import TreeView from 'components/TreeView';
import { PARENT_ID_ROOT, selectFoldersTree } from 'store/pages/content/foldersSlice';
import { AssetAliasType, getAssetTypeByRouteAlias, getFilesTitle } from '../../utils';
import { useParams } from 'react-router-dom';
import FolderTreeItem from '../../../Swap/components/AddSwapEffectModal/FolderTreeItem';

type BulkContentMoveModalProps = {
  name?: string;
  onConfirm?: () => void;
};

const STYLES = {
  modal: {
    width: 512,
  },
};

export const MODAL_NAME = 'BULK_CONTENT_MOVE_MODAL';

const BulkContentMoveModal: React.FC<BulkContentMoveModalProps> = ({ name = MODAL_NAME, onConfirm }) => {
  const dispatch = useDispatch();
  const { close, isOpen, value } = useModalContext<string[]>(name);
  const params = useParams<{ asset_type: AssetAliasType }>();

  const statistic = useSelector(
    selectFoldersTree(getAssetTypeByRouteAlias(params.asset_type), PARENT_ID_ROOT, value || [])
  );
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);

  const handleConfirm = async () => {
    dispatch(bulkContentMoveThunk({ items: value!, parent_id: selectedFolder! }));
    close();
    onConfirm && onConfirm();
  };

  const handleClose = () => close();

  const handleItemClick = (e, node: string, item: any) => {
    setSelectedFolder(item.object_id);
  };

  const treeData = useMemo(() => {
    if (!statistic) {
      return [];
    }

    const rootItemLabel = getFilesTitle(params.asset_type);

    return [
      {
        ...statistic,
        item: {
          ...statistic.item,
          title: rootItemLabel,
        },
        label: rootItemLabel,
      },
    ];
  }, [statistic]);

  if (!value) {
    return null;
  }

  return (
    <Modal
      styles={STYLES}
      title="Move to Folder"
      actions={({ defaultActionRef }) => (
        <Button
          ref={defaultActionRef}
          size={ButtonSize.LARGE}
          variant={ButtonVariant.ACTION}
          disabled={!selectedFolder}
          onClick={handleConfirm}
        >
          Move {value.length} items
        </Button>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <S.FoldersTreeWrapper>
        <TreeView
          data={treeData}
          hasSearch
          onClickItem={handleItemClick}
          initialOpenNodes={['root']}
          nodeRenderer={FolderTreeItem}
        />
      </S.FoldersTreeWrapper>
    </Modal>
  );
};

export default BulkContentMoveModal;
