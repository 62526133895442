import isEmpty from 'lodash/isEmpty';
import { TreeNodeInArray } from './types';

// import memoize from 'fast-memoize';

interface LocaleFunctionProps {
  label: string;

  [name: string]: any;
}

interface MatchSearchFunctionProps extends LocaleFunctionProps {
  searchTerm: string;
}

export type LocaleFunction = (localeFunctionProps: LocaleFunctionProps) => string;
export type MatchSearchFunction = (matchSearchFunctionProps: MatchSearchFunctionProps) => boolean;

type Data<D extends any> = TreeNodeInArray<D>[];

interface WalkProps<D extends any> {
  data: Data<D> | undefined;
  parent?: string;
  level?: number;
  openNodes: string[];
  searchTerm: string;
  locale?: LocaleFunction;
  matchSearch?: MatchSearchFunction;
}

interface BranchProps<D extends any> {
  parent: string;
  level: number;
  openNodes: string[];
  searchTerm: string;
  node: TreeNodeInArray<D>;
  nodeName: string;
  index?: number;
  locale?: LocaleFunction;
  matchSearch?: MatchSearchFunction;
}

export type Item<D extends any> = Omit<TreeNodeInArray<D>, 'item'> &
  Partial<Pick<TreeNodeInArray<D>, 'item'>> & {
    hasNodes: boolean;
    isOpen: boolean;
    level: number;
  };

const validateData = <D extends any>(nodes: Data<D> | undefined, entries: Data<D> | undefined): boolean =>
  (!!nodes && !isEmpty(nodes)) || (!!entries && !isEmpty(entries));

const getValidatedData = <D extends any>(data: Data<D> | undefined) => (data ? (data as Data<D>) : []);

const walk = <D extends any>({ data, ...props }: WalkProps<D>): Item<D>[] => {
  const validatedData = getValidatedData(data);

  const propsWithDefaultValues = { parent: '', level: 0, ...props };

  const handleArray = (dataAsArray: TreeNodeInArray<D>[]) =>
    dataAsArray.reduce((all: Item<D>[], node: TreeNodeInArray<D>, index) => {
      const branchProps = { node, index, nodeName: node.id, ...propsWithDefaultValues };
      const branch = generateBranch(branchProps);
      // console.log('branch', { node, branch });
      return [...all, ...branch];
    }, []);

  return handleArray(validatedData);
};

const defaultMatchSearch = ({ label, searchTerm }: MatchSearchFunctionProps) => {
  const processString = (text: string): string => text.trim().toLowerCase();
  return processString(label).includes(processString(searchTerm));
};

const defaultLocale = ({ label }: LocaleFunctionProps): string => label;

/*
const createEntriesNodes = <D extends any>(
  node: Pick<Item<D>, 'id' | 'hasNodes' | 'isOpen' | 'level'>,
  entries: TreeEntry<D>[] | undefined
): Item<D>[] => {
  if (!entries) {
    return [];
  }

  const entryItem: Item<D> = {
    ...node,
    id: `${node.id}_entries`,
    item: undefined,
    entries,
  };

  return [entryItem];
};
*/

const generateBranch = <D extends any>({
  node,
  nodeName,
  matchSearch = defaultMatchSearch,
  locale = defaultLocale,
  ...props
}: BranchProps<D>): Item<D>[] => {
  const { parent, level, openNodes, searchTerm } = props;

  const { nodes, entries, label: rawLabel = 'unknown', expandable, type, ...nodeProps } = node;
  const id = [parent, nodeName].filter((x) => x).join('/');
  const hasNodes = expandable || validateData(nodes, entries);
  const isOpen = hasNodes && (openNodes.includes(id) || !!searchTerm);

  const label = locale({ label: rawLabel, ...nodeProps });
  const isVisible = !searchTerm || matchSearch({ label, searchTerm, ...nodeProps });

  if (type === 'group') {
    const data = getValidatedData(nodes);
    const currentItem = {
      ...props,
      ...nodeProps,
      label,
      hasNodes,
      isOpen,
      id,
      type,
      nodes: walk({ data, locale, matchSearch, ...props, parent: id }),
    };

    return isVisible || !!currentItem.nodes.length ? [currentItem] : [];
  } else {
    const currentItem = { ...props, ...nodeProps, label, hasNodes, isOpen, id, type };
    const data = getValidatedData(nodes);
    const nextLevelItems = isOpen ? walk({ data, locale, matchSearch, ...props, parent: id, level: level + 1 }) : [];

    return isVisible ? [currentItem, ...nextLevelItems] : nextLevelItems;
  }
};

// export const fastWalk = memoize(walk);
export const slowWalk = walk;
