import React, { MutableRefObject } from 'react';
import useVideoSyncPlayer from '../../../Swap/hooks/useVideoSyncPlayer';
import * as S from './HistoryPreviewGalleryModal.styled';
import { getType } from 'mime/lite';
import AudioPlayer from 'components/AudioPlayerMini/AudioPlayer';
import { useAudioPlayerMini } from 'components/AudioPlayerMini';

type PreviewPlayerProps = {
  origin_path: string;
  swap_path: string;
};

const PreviewPlayer: React.FC<PreviewPlayerProps> = ({ origin_path, swap_path }) => {
  const { sourceRef, previewRef } = useVideoSyncPlayer<HTMLVideoElement, HTMLVideoElement>();

  const playerProps = useAudioPlayerMini(sourceRef.current);

  const renderPreview = (src: string | null, ref: MutableRefObject<HTMLVideoElement>) => {
    const isImagePreview = getType(src)?.includes('image/');
    if (isImagePreview) {
      return <img src={src} />;
    } else {
      return <video src={src} muted ref={ref} />;
    }
  };

  return (
    <>
      <S.PreviewContainer $variant={'both'}>
        {renderPreview(origin_path, sourceRef)}
        {renderPreview(swap_path, previewRef)}
      </S.PreviewContainer>

      {getType(origin_path)?.includes('video/') && <AudioPlayer {...playerProps} />}
    </>
  );
};

export default PreviewPlayer;
