import styled from 'styled-components';
import ContextMenuItem from 'components/ContextMenuItem';

export const DropdownSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownMenuItem = styled(ContextMenuItem)`
  justify-content: space-between;
`;

export const MenuItemLabel = styled.div`
  flex: 1;
`;

export const MenuItemRight = styled.div`
  color: ${({ theme }) => theme.colors.gray[40]};
`;

export const MenuItemSelected = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  margin: 0 4px;
`;

export const DropdownAnchor = styled.div`
  display: flex;
  align-items: center;
`;
