import React, { useCallback, useEffect, useMemo, useState, MouseEvent } from 'react';

import { slowWalk, Item, LocaleFunction, MatchSearchFunction } from './utils';

import TreeViewItem, { TreeMenuChildren } from './TreeViewItem';
import * as S from './TreeView.styled';
import useKeyDownNavigation from './useKeyDownNavigation';
import InputText from '../InputText';
import { useDebounce } from 'react-use';
import DefaultItemRenderComponent from './DefaultItemRenderComponent';
import { TreeNodeInArray } from './types';
import TreeViewGroup from './TreeViewGroup';

export type TreeViewProps<D extends any> = {
  data: TreeNodeInArray<D>[];
  activeKey?: string;
  focusKey?: string;
  initialActiveKey?: string;
  initialFocusKey?: string;
  initialOpenNodes?: string[];
  openNodes?: string[];
  resetOpenNodesOnDataUpdate?: boolean;
  hasSearch?: boolean;
  cacheSearch?: boolean;
  isNodesSelectable?: boolean;
  onClickItem?: (e: MouseEvent, nodeId: string, item: Item<D>, ...rest: any[]) => void;
  onNodeOpened?: (id: string, item: Item<D>) => void;
  debounceTime?: number;
  children?: TreeMenuChildren;
  locale?: LocaleFunction;
  matchSearch?: MatchSearchFunction;
  disableKeyboard?: boolean;
  nodeRenderer?: React.FC<{ item: D; type: 'node' | 'group' }>;
  groupNodesRenderer?: React.FC<any>;
  searchRenderer?: React.FC<{ value: string; onChange: (value: string) => void }>;
};

type TreeViewComponent<D = any> = React.FC<TreeViewProps<D>>;

export const TreeView: TreeViewComponent = (props) => {
  const ref = useKeyDownNavigation();

  const {
    hasSearch,
    data,
    initialOpenNodes = [],
    activeKey: activeNodeKey = null,
    focusKey,
    locale,
    matchSearch,
    isNodesSelectable = true,
    onClickItem,
    onNodeOpened,
    nodeRenderer = DefaultItemRenderComponent,
    groupNodesRenderer,
    searchRenderer,
  } = props;
  const [openNodes, setOpenedNodes] = useState(initialOpenNodes);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    setActiveKey(activeNodeKey);
  }, [activeNodeKey]);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search]
  );
  const items = useMemo(
    () => (data ? slowWalk({ data, openNodes, searchTerm: debouncedSearch, locale, matchSearch }) : []),
    [data, openNodes, debouncedSearch]
  );

  const handleItemClick: TreeViewProps<any>['onClickItem'] = (
    e: MouseEvent,
    node: string,
    item: any,
    ...rest: any[]
  ) => {
    console.log(isNodesSelectable, { node, item });
    setActiveKey((activeKey) => node || activeKey);

    onClickItem && onClickItem(e, node, item!, ...rest);
  };

  const handleToggleNode = useCallback(
    (node: string) => {
      setOpenedNodes((openNodes) =>
        openNodes.includes(node) ? openNodes.filter((openNode) => openNode !== node) : [...openNodes, node]
      );

      const item = items.find(({ id }) => id === node);
      console.log('handleToggleNode', item);
      onNodeOpened && onNodeOpened(node, item!);
    },
    [onNodeOpened, items]
  );

  const handleNodeItemClick: TreeViewProps<any>['onClickItem'] = (e, node: string, item: any, ...rest: any[]) => {
    if (isNodesSelectable) {
      setActiveKey((activeKey) => node || activeKey);

      onClickItem && onClickItem(e, node, item!, ...rest);
    } else {
      handleToggleNode(node);
    }
  };

  const renderSearchToolbar = () =>
    searchRenderer ? (
      searchRenderer({ value: search, onChange: setSearch })
    ) : (
      <S.InputText>
        <InputText placeholder="Search" value={search} isClearable onChange={setSearch} />
      </S.InputText>
    );

  return (
    <S.TreeViewWrapper ref={ref}>
      {hasSearch && renderSearchToolbar()}
      <S.TreeViewList>
        {items.map((entry) =>
          entry.type === 'node' ? (
            <TreeViewItem
              key={entry.id}
              {...entry}
              active={entry.id === activeKey}
              focused={entry.id === focusKey}
              onClick={handleNodeItemClick}
              onToggle={handleToggleNode}
              itemRenderComponent={nodeRenderer}
            />
          ) : (
            <TreeViewGroup
              key={entry.id}
              {...entry}
              activeKey={activeKey}
              onClick={handleItemClick}
              groupNodesRenderer={groupNodesRenderer}
            />
          )
        )}
      </S.TreeViewList>
    </S.TreeViewWrapper>
  );
};

export default TreeView;
