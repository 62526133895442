import { signIn, signOut, signInFirebase, signOutFirebase } from 'api/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const signInThunk = createAsyncThunk('auth/api/auth/signIn', signIn);

export const signOutThunk = createAsyncThunk('auth/api/auth/signOut', signOut);

export const signInFirebaseThunk = createAsyncThunk('auth/api/auth/signInFirebase', signInFirebase);

export const signOutFirebaseThunk = createAsyncThunk('auth/api/auth/signOutFirebase', signOutFirebase);
