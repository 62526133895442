import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthStatus } from 'types/auth';
import { RouteId } from 'types/route';

import { AUTH_PAGES_ROUTES_BY_ID } from 'App/pages';
import useExpiringAuthMethod from './useExpiringAuthMethod';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';
import ScreenLoader from '../ScreenLoader';

const Auth: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  useFirebaseAuth();

  const { authStatus, isAccessDenied, areRequestInterceptorsPatched } = useExpiringAuthMethod();

  if (
    authStatus === AuthStatus.PENDING ||
    authStatus === AuthStatus.SHOULD_BE_SIGNED_IN ||
    authStatus === AuthStatus.SIGNING_IN
  ) {
    return (
      <ScreenLoader
        timeout={3000}
        warningMessage={'Auth validation takes to long. Please check VPN connection and reload page!'}
      />
    );
  }

  return (
    <Switch>
      {(authStatus === AuthStatus.SIGNED_IN ||
        authStatus === AuthStatus.SHOULD_BE_SIGNED_OUT ||
        authStatus === AuthStatus.SIGNING_OUT) && (
        <>
          <Route
            exact={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_OUT].isExact}
            path={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_OUT].path}
            component={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_OUT].component}
          />

          {authStatus === AuthStatus.SIGNED_IN && isAccessDenied && (
            <Redirect to={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_OUT].path} />
          )}

          {authStatus === AuthStatus.SIGNED_IN && !isAccessDenied && areRequestInterceptorsPatched && (
            <>
              {children}
              {/*<Redirect to={'/'} from={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_IN].path} />*/}
            </>
          )}

          {(authStatus === AuthStatus.SHOULD_BE_SIGNED_OUT || authStatus === AuthStatus.SIGNING_OUT) && (
            <Redirect to={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_OUT].path} />
          )}
        </>
      )}

      {authStatus === AuthStatus.SIGNED_OUT && (
        <>
          <Route
            exact={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_IN].isExact}
            path={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_IN].path}
            component={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_IN].component}
          />

          <Redirect to={AUTH_PAGES_ROUTES_BY_ID[RouteId.SIGN_IN].path} />
        </>
      )}
    </Switch>
  );
};
export default Auth;
