import { proxyApi } from 'services/http';

export type FetchMeResponse = any;

const fetchMe = async (): Promise<FetchMeResponse> => {
  const response = await proxyApi.get('/users/me');

  return response.data;
};

export default fetchMe;
