import { useMemo } from 'react';
import useMeasure from 'react-use/lib/useMeasure';

const useGridColumns = ({ minWidth, defaultColumns }: { minWidth: number; defaultColumns: number }) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const columns = useMemo(() => Math.trunc(width / minWidth) || defaultColumns, [width, minWidth]);
  console.log(width, columns);

  return [ref, columns, width] as const;
};

export default useGridColumns;
