import React from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonSize } from '@reface/ui/Button';
import { IconGoogle } from '@reface/icons/20px';
import { signInFirebaseThunk } from 'store/auth/api/auth';

const SignInWithGoogle: React.FC = () => {
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(signInFirebaseThunk());
  };

  return (
    <Button size={ButtonSize.LARGE} icon={<IconGoogle />} onClick={handleSignIn}>
      Continue with Google
    </Button>
  );
};

export default SignInWithGoogle;
