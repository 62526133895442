export const createShadowVideoElement = (path: string) =>
  new Promise<any>((resolve, reject) => {
    const player = document.createElement('video');
    player.src = path;
    // player.width = 720;
    // player.height = 720;
    player.preload = 'auto';
    player.loop = true;
    player.crossOrigin = 'anonymous';
    player.addEventListener('loadeddata', (e) => {
      const target = e.target as HTMLVideoElement;
      target.setAttribute('width', `${target.videoWidth}`);
      target.setAttribute('height', `${target.videoHeight}`);
      resolve(e);
    });
    player.addEventListener('error', reject);
  });

export const createShadowImageElement = (path: string) =>
  new Promise<any>((resolve) => {
    const player = document.createElement('img');
    player.src = path;
    player.crossOrigin = 'anonymous';

    player.addEventListener('load', (e) => {
      const target = e.target as HTMLImageElement;
      target.setAttribute('width', `${target.naturalWidth}`);
      target.setAttribute('height', `${target.naturalHeight}`);
      resolve(e);
    });
  });
