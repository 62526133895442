import styled from 'styled-components';

import hexToRGB from 'utils/hexToRGB';

export const LoadingOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => hexToRGB(theme.colors.gray[0], 0.75)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  cursor: wait;
`;

export const LoadingContent = styled.div``;
