import React, { useState } from 'react';
import cl from 'clsx';
import { ButtonSize, ButtonVariant } from '@reface/ui/Button';
import { IconViewGrid, IconViewTable, IconViewTiles } from '@reface/icons/20px';
import * as S from './ContentViewGroup.styled';

enum VIEW_VARIANT {
  TABLE = 'TABLE',
  GRID = 'GRID',
  TILES = 'TILES',
}

const ContentViewGroup = () => {
  const [activeView] = useState(VIEW_VARIANT.TILES);
  const clickHandler = () => {
    console.log('view lcick');
  };

  return (
    <S.Wrapper>
      <S.ViewButton
        size={ButtonSize.SMALL}
        variant={ButtonVariant.SECONDARY}
        onClick={clickHandler}
        icon={<IconViewTable />}
        className={cl({
          active: VIEW_VARIANT.TABLE === activeView,
        })}
        disabled={VIEW_VARIANT.TABLE === activeView}
      />
      <S.ViewButton
        size={ButtonSize.SMALL}
        variant={ButtonVariant.SECONDARY}
        onClick={clickHandler}
        icon={<IconViewGrid />}
        className={cl({
          active: VIEW_VARIANT.GRID === activeView,
        })}
        disabled={VIEW_VARIANT.GRID === activeView}
      />
      <S.ViewButton
        size={ButtonSize.SMALL}
        variant={ButtonVariant.SECONDARY}
        onClick={clickHandler}
        icon={<IconViewTiles />}
        className={cl({
          active: VIEW_VARIANT.TILES === activeView,
        })}
        disabled={VIEW_VARIANT.TILES === activeView}
      />
    </S.Wrapper>
  );
};

export default ContentViewGroup;
