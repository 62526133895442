import React, { useEffect, useState } from 'react';

import src from './mods.gif';

import * as S from './Image.styled';

const Image: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => setIsLoaded(true);

  useEffect(() => {
    handleLoad();
  }, []);

  return <S.Image src={src} $isLoaded={isLoaded} />;
};

export default Image;
