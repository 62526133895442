import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = <Params extends { [K in keyof Params]?: string } = { [key: string]: string }>(): Params => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const params: { [key: string]: string } = {};

  query.forEach((value, key) => {
    params[key] = value;
  });

  return useMemo(() => params as Params, [search]);
};

export default useQuery;
