import React from 'react';

import Button, { ButtonSize, ButtonColor } from '@reface/ui/Button';
import Modal from '@reface/ui/Modal';

import { useModalContext } from '@reface/shared/components/ModalManager';
import { useDispatch } from 'react-redux';
import Typography from '@reface/ui/Typography';
import ButtonGroup from '@reface/ui/ButtonGroup';
import { deleteSwapHistoryThunk } from 'store/pages/history/bulk';

export const DELETE_HISTORY_CONFIRMATION_MODAL = 'DELETE_HISTORY_CONFIRMATION_MODAL';

const STYLES = {
  modal: {
    width: 512,
  },
};

type ChangeContentTitleModalProps = {
  name?: string;
  onConfirm?: () => void;
};

export type ModalPayloadValue = {
  operation_key?: string | string[];
  swap_ids?: string[];
};

const DeleteHistoryConfirmationModal: React.FC<ChangeContentTitleModalProps> = ({
  name = DELETE_HISTORY_CONFIRMATION_MODAL,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const { close, isOpen, value } = useModalContext<ModalPayloadValue>(name);

  const handleConfirm = async () => {
    console.log(value);

    if (value!.swap_ids) {
      dispatch(
        deleteSwapHistoryThunk({
          swap_ids: value!.swap_ids,
        })
      );
    } else if (Array.isArray(value!.operation_key)) {
      for (const operation_key of value!.operation_key) {
        dispatch(
          deleteSwapHistoryThunk({
            operation_key,
          })
        );
      }
    } else {
      dispatch(
        deleteSwapHistoryThunk({
          operation_key: value!.operation_key,
        })
      );
    }

    close();
    onConfirm && onConfirm();
  };

  const handleClose = () => close();

  return (
    <Modal
      styles={STYLES}
      title="Remove History Records?"
      actions={({ defaultActionRef }) => (
        <ButtonGroup>
          <Button ref={defaultActionRef} size={ButtonSize.LARGE} color={ButtonColor.DANGER} onClick={handleConfirm}>
            Delete
          </Button>
          <Button size={ButtonSize.LARGE} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
      open={isOpen}
      onClose={handleClose}
    >
      <Typography variant={'body1'}>Are you sure you want to delete history of this operation?</Typography>
    </Modal>
  );
};

export default DeleteHistoryConfirmationModal;
