import styled from 'styled-components';
import Button from '@reface/ui/Button';

export const ViewButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray[40]};
  path {
    fill: ${({ theme }) => theme.colors.gray[40]};
  }

  &:disabled path {
    fill: currentColor;
  }

  &.active {
    color: ${({ theme }) => theme.colors.gray[90]};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
