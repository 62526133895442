import styled from 'styled-components';
import { ProgressBar, Wrapper } from 'components/AudioPlayerMini/AudioPlayerMini.styled';

export const CanvasContainer = styled.div`
  position: relative;
  grid-area: canvas;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ScratchCanvasWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  ${Wrapper} {
    background-color: unset;
    border: none;
  }

  ${ProgressBar} {
    background-color: #e5cf5c;
  }
`;

export const Canvas = styled.canvas`
  border-radius: 16px;
  &:first-child {
    background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/wAALCAARABEBAREA/8QAFwAAAwEAAAAAAAAAAAAAAAAABAUIB//EACYQAAEDAgMJAQAAAAAAAAAAAAEAAgQDBREVNRIhMUJRVHOTstH/2gAIAQEAAD8AskuGyd44LfbNpELwM+QjMR1CDyaB2Mb0t/Fi1xuMulcZjGSq7GNrPDWtquAADjgAMUuzWd30j3PVC8pU8XTVp/md9hLV/9k=');
  }
`;

export const VideosContainer = styled.div`
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
`;
