import { fabric } from 'fabric';
import { extension } from '../utils';
import createPinnedDotObject from '../pinnedDotObject';

export const geObjectTrackOffset = (obj: fabric.Object) => {
  const originPosition = obj.originPosition;
  const anchorSource = obj.pinWatcher.anchorSource;
  const watcherPosition = obj.pinWatcher.getAbsoluteDimensions();
  const anchorDimensions = anchorSource.getAbsoluteDimensions();
  const anchorPosition = anchorSource.getMainPosition();

  const objectOffset = {
    left: anchorPosition.left - watcherPosition.left,
    top: anchorPosition.top - watcherPosition.top,
  };

  return {
    left: (originPosition.left || 0) + objectOffset.left,
    top: (originPosition.top || 0) + objectOffset.top,
    scaleX: anchorDimensions.scaleX,
    scaleY: anchorDimensions.scaleY,
  };
};

export default extension('object.tracking', (fabric) => {
  fabric.util.object.extend(fabric.Object.prototype, {
    pinSource: null,
    pinWatcher: null,
    createPin() {
      const { pinLine, trackingBBox, pinDot } = createPinnedDotObject(this);
      this.set({
        pinWatcher: pinDot,
      });

      if (this.canvas) {
        this.canvas.add(pinLine, trackingBBox, pinDot);
        const objects: fabric.Object[] = this.canvas.getObjects();

        const activeObjectIndex = objects.findIndex((obj) => obj === this);
        pinLine.moveTo(activeObjectIndex);
        trackingBBox.moveTo(activeObjectIndex);
      }

      return { trackingBBox, pinLine };
    },
    getPositionTrack(factor: number) {
      const dimensions = this.getAbsoluteDimensions();
      const position = this.getMainPosition();

      if (!this.pinWatcher) {
        return [
          [
            position.left * factor,
            position.top * factor,
            dimensions.width * factor,
            dimensions.height * factor,
            this.angle,
          ],
        ];
      }

      const objectTrackOffset = geObjectTrackOffset(this);
      const pinnedTrack = this.pinWatcher.bboxes;

      if (pinnedTrack && pinnedTrack?.length) {
        return pinnedTrack.map((box: any) => [
          (objectTrackOffset.left + box.x * objectTrackOffset.scaleX) * factor,
          (objectTrackOffset.top + box.y * objectTrackOffset.scaleY) * factor,
          dimensions.width * factor,
          dimensions.height * factor,
          this.angle,
        ]);
      } else {
        return [
          [
            position.left * factor,
            position.top * factor,
            dimensions.width * factor,
            dimensions.height * factor,
            this.angle,
          ],
        ];
      }
    },
  });
});
