import { fabric } from 'fabric';

const extend = fabric.util.object.extend;

fabric.Image.filters.GradientEffect = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  type: 'GradientEffect',

  initialize: function (options: any) {
    options = options || {};
    this.gradient = options.gradient || {};
    this.img = options.img;
  },

  applyTo: function ({ canvas: canvasEl }: any) {
    const gr = this.gradient;
    const w = this.img._element.naturalWidth;
    const h = this.img._element.naturalHeight;
    const hc = document.createElement('canvas');

    hc.setAttribute('width', w);
    hc.setAttribute('height', h);

    const fhc = new fabric.Canvas(hc);
    const rect = new fabric.Rect({
      fill: 'transparent',
      width: w,
      height: h,
    });

    rect.set('fill', gr);

    fhc.add(rect);
    fhc.renderAll();

    const ifhcContext = fhc.getContext();
    const fhcImageData = ifhcContext.getImageData(0, 0, fhc.width || 0, fhc.height || 0);
    const fhcData = fhcImageData.data;

    const context = canvasEl.getContext('2d');
    if (!context) {
      return;
    }

    const imageData = context.getImageData(0, 0, canvasEl.width, canvasEl.height),
      data = imageData.data;

    for (let i = 0, n = data.length; i < n; i += 4) {
      if (data[i] != 0 && data[i + 1] != 0 && data[i + 2] != 0) {
        data[i] += fhcData[i];
        data[i + 1] += fhcData[i + 1];
        data[i + 2] += fhcData[i + 2];
      }
    }

    context.putImageData(imageData, 0, 0);
  },

  toObject: function () {
    return extend(this.callSuper('toObject'), {
      gradient: this.gradient,
    });
  },
});

fabric.Image.filters.GradientEffect.fromObject = function (object) {
  return new fabric.Image.filters.GradientEffect(object);
};
