import React from 'react';

import * as S from './Switch.styled';

export type SwitchProps = {
  label?: string;
  titles?: string[];
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
};

const Switch: React.FC<SwitchProps> = ({ label, titles, checked = false, disabled = false, onChange }) => (
  <S.Switch>
    {titles && <S.Title $isDisabled={disabled}>{checked ? titles[1] : titles[0]}</S.Title>}

    <S.Control $isDisabled={disabled} onClick={onChange}>
      <S.Dot $isChecked={checked} $isDisabled={disabled} />
    </S.Control>

    {label && <S.Label $isDisabled={disabled}>{label}</S.Label>}

    <S.InputCheckbox type="checkbox" checked={checked} disabled={disabled} readOnly />
  </S.Switch>
);
export default Switch;
