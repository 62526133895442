import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSwapHistoryThunk, selectContentSwapHistory } from 'store/pages/swap/history/historySlice';
import useQuery from 'hooks/useQuery';
import { AppliedMapping } from '../types';
import { SwapMappingOptions } from 'api/studio/swapProcessing';

export const prepareCurrentMapping = (
  appliedMapping: SwapMappingOptions<{
    motions: {
      [motion_face_id: string]: string;
    };
  }>,
  version: 'v1' | 'v2'
): {
  [face_id: string]: AppliedMapping;
} => {
  const mapping = {};

  for (const [face_id, applied] of Object.entries(appliedMapping.facemapping)) {
    mapping[face_id] = mapping[face_id] || {
      motionmapping: null,
      facemapping: null,
      audiomapping: null,
    };
    if (version === 'v1') {
      mapping[face_id].facemapping = applied ? applied[0] : null;
    } else {
      mapping[face_id].facemapping = applied ? applied.user_face_id : null;
    }
  }

  if (appliedMapping.motionmapping) {
    for (const [face_id, applied] of Object.entries(appliedMapping.motionmapping)) {
      mapping[face_id] = mapping[face_id] || {
        motionmapping: null,
        facemapping: null,
        audiomapping: null,
      };

      const { user_face_id, motion_id } = (applied || {}) as { motion_id?: string; user_face_id?: string };

      const appliedUserFaceId = version === 'v1' ? motion_id : user_face_id;

      mapping[face_id].motionmapping = [
        appliedMapping.meta?.motions ? appliedMapping.meta?.motions[appliedUserFaceId] : null,
        appliedUserFaceId,
      ];
    }
  }

  for (const [face_id, applied] of Object.entries(appliedMapping.audiomapping)) {
    mapping[face_id] = mapping[face_id] || {
      motionmapping: null,
      facemapping: null,
      audiomapping: null,
    };

    const audiomapping = applied as { audio_id?: string; audio_track_id?: string };
    mapping[face_id].audiomapping = version === 'v1' ? audiomapping?.audio_id : audiomapping?.audio_track_id || null;
  }

  return mapping;
};

const useContentSwapHistory = (object_id: string) => {
  const query = useQuery<{ swap_id?: string }>();
  const dispatch = useDispatch();
  const swapHistoryEntries = useSelector(selectContentSwapHistory);
  const [appliedMapping, setAppliedMapping] = useState({});

  useEffect(() => {
    dispatch(
      fetchSwapHistoryThunk({
        object_id: object_id,
        offset: 0,
        limit: 5,
      })
    );
  }, [object_id, query.swap_id]);

  useEffect(() => {
    console.log('newAppliedMapping', appliedMapping);
  }, [appliedMapping]);

  const swapHistoryPreview = useMemo(
    () => swapHistoryEntries.find(({ swap_id }) => swap_id === query.swap_id),
    [swapHistoryEntries, query.swap_id]
  );

  useEffect(() => {
    if (!swapHistoryPreview) {
      return;
    }
    console.log(swapHistoryPreview);
    const mapping = prepareCurrentMapping(swapHistoryPreview.mapping, swapHistoryPreview.version);
    console.log(swapHistoryPreview.mapping, mapping);
    setAppliedMapping(mapping);
  }, [swapHistoryPreview]);

  return { swapHistoryEntries, swapHistoryPreview, appliedMapping, setAppliedMapping };
};

export default useContentSwapHistory;
