import { useCallback, useEffect, useRef, useState } from 'react';
import { useEvent } from 'react-use';

const useVideoSyncPlayer = <S extends HTMLMediaElement, P extends HTMLMediaElement>() => {
  const sourceRef = useRef<S>(null!);
  const previewRef = useRef<P | undefined>();
  const [duration, setDuration] = useState(0);
  const [anchorTime, setAnchor] = useState(0);
  const [isSourceReady, setIsSourceReady] = useState(false);

  const isReady = isSourceReady;

  useEffect(() => {
    // const seektimeUpdate = () => {};

    if (isReady) {
      sourceRef.current?.addEventListener('timeupdate', () => {
        // console.log(sourceRef.current.currentTime, sourceRef.current.paused);
        setAnchor(sourceRef.current.currentTime);

        if (!sourceRef.current.paused) {
          return;
        }

        const evt = new Event('timeupdate', { bubbles: true, cancelable: false });
        previewRef.current?.dispatchEvent(evt);

        // update scrubber

        return;
      });

      sourceRef.current?.addEventListener('seeking', () => {
        if (previewRef.current) {
          previewRef.current.currentTime = sourceRef.current.currentTime;
        }
        // console.log(sourceRef.current.currentTime);
        setAnchor(sourceRef.current.currentTime);
      });

      sourceRef.current?.addEventListener('play', () => {
        if (previewRef.current) {
          previewRef.current.play();
          previewRef.current.currentTime = sourceRef.current.currentTime;
        }

        // window.requestAnimationFrame(seektimeUpdate);
      });
      sourceRef.current?.addEventListener('pause', () => {
        previewRef.current?.pause();
      });
    }
  }, [isReady]);

  const handleKeydownEvent = useCallback((e) => {
    if (!sourceRef.current) {
      return;
    }

    const secPerFrame = 1 / 20; // DEFAULT SPF for video

    const videoEl = sourceRef.current;

    if (e.code === 'Space') {
      videoEl.paused ? videoEl.play() : videoEl.pause();
    } else if (e.code === 'ArrowRight') {
      const curFrame = Math.floor(videoEl.currentTime / secPerFrame);
      videoEl.currentTime = (curFrame + 1) * secPerFrame + 0.01;
      videoEl.pause();
    } else if (e.code === 'ArrowLeft') {
      const curFrame = Math.ceil(videoEl.currentTime / secPerFrame);
      videoEl.currentTime = (curFrame - 1) * secPerFrame - 0.01;
      videoEl.pause();
    }
  }, []);

  useEvent('keydown', handleKeydownEvent);

  useEffect(() => {
    sourceRef.current?.addEventListener('canplaythrough', (event) => {
      setIsSourceReady(true);
      setDuration((event.target as HTMLMediaElement).duration);
    });
  }, []);

  const setCurrentTime = useCallback(
    (value: number) => {
      sourceRef.current.currentTime = Math.max(0, Math.min(value, duration));
    },
    [duration]
  );

  return {
    sourceRef,
    previewRef,
    duration,
    anchorTime,
    setCurrentTime,
  };
};

export default useVideoSyncPlayer;
