import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { deleteSwapHistoryThunk, fetchBulkHistoryThunk } from './bulkAtions';

export const getBulkHistoryEntries = (state: RootState) =>
  [state.pages.history.bulk.entities, state.pages.history.bulk.total] as const;

interface ContentState {
  entities: any[];
  total: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  entities: [],
  total: 0,
  loading: 'idle',
} as ContentState;

const bulkHistorySlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBulkHistoryThunk.fulfilled, (state, action) => {
      state.entities = action.payload.swap_history;
      state.total = action.payload.total;
    });

    builder.addCase(deleteSwapHistoryThunk.pending, (state, action) => {
      const { operation_key } = action.meta.arg;
      state.entities = state.entities.filter((entry) => operation_key !== entry.operation_key);
    });

    // builder.addCase(deleteSwapHistoryThunk.fulfilled, (state, action) => {
    //   state.entities = state.entities.filter((entry) => !object_ids.includes(entry.object_id));
    // });
  },
});

export default bulkHistorySlice;
