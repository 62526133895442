import { createAssetRecord, checkAssetProcessing, AssetType } from 'api/studio/processing';
import { ApiRequestState, RootState } from 'store';
import createApiRequestSlice from 'store/utils/createApiRequestSlice';
import { fetchFolderContent } from '../../../api/studio/content';
import templatesSegmentations from '__mocks__/data/templatesSegmentation.json';

export const sleep = async <T extends any | null>(delay: number, resolveValue: T) =>
  new Promise<T>((resolve) => {
    setTimeout(function () {
      resolve(resolveValue);
    }, delay);
  });

const getState: (state: RootState) => ApiRequestState<any> = (state) => state.auth.me;

const name = 'studio/assets/createAndAwaitAsset';

const checkProcessingUntilAllCompleted = async (asset_type: AssetType, ids: string[]) => {
  const checkResponse = await checkAssetProcessing(asset_type, ids);

  const tasks = Object.entries(checkResponse);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const completedTasks = tasks.filter(([_key, value]) => value.status !== 1);

  return completedTasks.map(([k, v]) => ({
    tracker_id: k,
    ...v,
  }));
};

type CreateAndAwaitAssetPayload = { asset_type: AssetType; payload: { object_id: string } };

const checkSegmentationPromise = async (asset_type: AssetType, assetResponse: any) => {
  let isCompleted = false;
  let completedTasks: any[] = [];
  do {
    await sleep(5000, null);
    completedTasks = await checkProcessingUntilAllCompleted(asset_type, [assetResponse.id]);
    isCompleted = !!completedTasks.length;
  } while (!isCompleted);

  console.log(completedTasks);

  const tracking = completedTasks.map(({ items }) => items).flat()[0];
  const error_message = completedTasks.map(({ error_message }) => error_message)[0];

  return {
    tracking,
    error_message,
  };
};

export const createAndAwaitAssetCallback = async ({ asset_type, payload }: CreateAndAwaitAssetPayload) => {
  const templateAsset = templatesSegmentations.find(({ source_id }) => source_id === payload.object_id);

  if (templateAsset) {
    return { tracking: templateAsset, error_message: null };
  }

  try {
    const assetContent = await fetchFolderContent('', {
      source_id: payload.object_id,
    });

    if (assetContent.content.length) {
      return { tracking: assetContent.content[0], error_message: null };
    }
  } catch (e) {
    console.log(e);
  }

  const assetResponse = await createAssetRecord(asset_type, payload);

  const racePromise = sleep(12 * 5000, {
    tracking: null,
    error_message: 'Segmentation timeout',
  });

  const assetPromise = checkSegmentationPromise(asset_type, assetResponse);

  return Promise.race([assetPromise, racePromise]);
};

const [createAndAwaitAssetThunk, slice] = createApiRequestSlice<CreateAndAwaitAssetPayload, any>(
  name,
  createAndAwaitAssetCallback
);

export { getState as getProcessedAssetState, createAndAwaitAssetThunk };
export default slice.reducer;
