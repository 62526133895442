import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useAuth from 'hooks/useAuth';
import * as S from './AppUserInfo.styled';
import { signOutThunk } from 'store/auth/api/auth';

const AppUserInfo: React.FC = () => {
  const dispatch = useDispatch();

  const { me } = useAuth();

  const handleSignOut = useCallback(() => {
    dispatch(signOutThunk());
  }, []);

  return (
    <S.SidebarUserInfo>
      <S.User>
        {me?.name && (
          <>
            <S.Initial>{me.name[0]}</S.Initial>

            <S.Info>
              <S.Name title={me.name}>{me.name}</S.Name>
            </S.Info>
          </>
        )}
      </S.User>

      <S.LogOutIcon onClick={handleSignOut} />
    </S.SidebarUserInfo>
  );
};

export default AppUserInfo;
