import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getSwapHistory, SwapHistoryPayload, SwapHistoryRecord } from 'api/studio/swapHistory';
import { SwapMappingOptions } from 'api/studio/swapProcessing';

type Values = SwapMappingOptions[keyof SwapMappingOptions];

type ResolverValues = Values[keyof Values];

const prepareAppliedAssets = (
  mapping: Values,
  resolver: (value: ResolverValues) => (boolean | string) | (boolean | string)[]
): string[] => {
  if (!mapping) {
    return [];
  }

  return Array.from(
    new Set(
      Object.entries(mapping)
        .map(([, value]) => resolver(value))
        .flat()
        .filter(Boolean) as string[]
    )
  );
};

export const selectContentSwapHistory = createSelector(
  (state: RootState) => state.pages.swap.history.entities,
  (entries) =>
    entries.map(({ request, ...rest }) => ({
      ...rest,
      version: request.source ? ('v1' as const) : ('v2' as const),
      mapping: {
        motionmapping: request.motionmapping,
        facemapping: request.facemapping,
        audiomapping: request.audiomapping,
        meta: request.meta,
      },
      appliedAssets: prepareAppliedAssets(request.facemapping, (value) => Array.isArray(value) && value),
    }))
);

export const fetchSwapHistoryThunk = createAsyncThunk(
  'pages/swap/history/fetchHistory',
  async (options: Pick<SwapHistoryPayload, 'offset' | 'limit' | 'object_id'>) => getSwapHistory(options)
);

interface SwapHistoryState {
  entities: SwapHistoryRecord[];
  total: number;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  entities: [],
  total: 0,
  loading: 'idle',
} as SwapHistoryState;

const bulkHistorySlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSwapHistoryThunk.fulfilled, (state, action) => {
      state.entities = action.payload.swap_history;
      state.total = action.payload.total;
    });
  },
});

export default bulkHistorySlice;
