import styled, { createGlobalStyle } from 'styled-components';

import theme from 'theme';

export const GlobalStyle = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  html,
  #root {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${theme.typography.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-weight: normal;
    //font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.gray[90]};
    background-color: ${theme.colors.gray[8]};
  }

  pre {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  code {
    padding: 2px 4px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 0.9em;
    color: ${theme.colors.gray[90]};
    background-color: ${theme.colors.gray[10]};
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 12px 0;
    padding: 0 0 0 16px;
  }

  p {
    margin: 0;
    padding: 0;

    & + p {
      margin-top: 12px;
    }
  }
`;

export const ProcessingToastBoxWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;
