import { proxyApi } from '../../services/http';

type IdleObjectTrackingBBox = {
  h: number;
  w: number;
  x: number;
  y: number;
};

type IdleObjectTrackingPayload = {
  bbox: IdleObjectTrackingBBox;
  frame: number;
  video_url: string;
};

type IdleObjectTrackingResponse = {
  bboxes: IdleObjectTrackingBBox[];
};

export const getObjectTracking = async (payload: IdleObjectTrackingPayload) => {
  const response = await proxyApi.post<IdleObjectTrackingResponse>(`idle/tracking`, payload);

  return response.data;
};
