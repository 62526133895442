import { useEffect, useRef } from 'react';

const useKeyDownNavigation = () => {
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp': {
          // up();
          break;
        }
        case 'ArrowDown': {
          // down();
          break;
        }
        case 'ArrowLeft': {
          // left();
          break;
        }
        case 'ArrowRight': {
          // right();
          break;
        }

        case 'Enter': {
          // enter();
          break;
        }
      }
    };

    ref.current.addEventListener('keydown', handleKeyDown);
  }, []);

  return ref;
};

export default useKeyDownNavigation;
