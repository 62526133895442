import React, { useEffect, useRef, useState } from 'react';
import * as S from './Anchor.styled';

type AnchorProps = {
  value: number;
  onChange: (value: number) => void;
  max: number;
  target: React.MutableRefObject<any>;
};

const Anchor: React.FC<AnchorProps> = ({ onChange, max, value, target }) => {
  const anchorRef = useRef<HTMLDivElement>(null!);
  const containerRef = useRef<HTMLDivElement>(null!);

  const seeking = useRef(false);
  const rawValue = useRef(0);

  const [position, setPosition] = useState(0);

  useEffect(() => {
    setPosition(Math.max(0, Math.min(100, (value / max) * 100)));
  }, [value]);

  const handleChange = (anchorTime: number) => {
    onChange(anchorTime);
  };

  useEffect(() => {
    const startSeek = (e) => {
      const { left, width } = containerRef.current.getBoundingClientRect();
      rawValue.current = (max * (e.pageX - left)) / width;

      handleChange(Math.min(max, Math.max(0, rawValue.current)));
      seeking.current = true;
      e.stopPropagation();
    };

    const moveSeek = (e) => {
      if (!seeking.current) {
        return;
      }

      const { width } = containerRef.current.getBoundingClientRect();
      rawValue.current = rawValue.current + (max * e.movementX) / width;

      handleChange(Math.max(0, Math.min(max, rawValue.current)));
    };

    const stopSeek = (e) => {
      moveSeek(e);
      seeking.current = false;
    };

    target.current.addEventListener('mousedown', startSeek);

    document.addEventListener('mousemove', moveSeek);
    document.addEventListener('mouseup', stopSeek);

    return () => {
      target.current?.removeEventListener('mousedown', startSeek);
      document.removeEventListener('mousemove', moveSeek);
      document.removeEventListener('mouseup', stopSeek);
    };
  }, [max]);

  return (
    <S.AnchorContainer ref={containerRef}>
      <S.TimeAnchor ref={anchorRef} style={{ left: `${position}%` }} />
    </S.AnchorContainer>
  );
};

export default Anchor;
