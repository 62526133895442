import { getExtension } from 'utils/fileUtils';

const prepareHistoryDownloadItems = (items: any[]) =>
  items
    .filter((item) => !!item.object)
    .map((item) => {
      const download: [string, string][] = [];

      if (item.swap_path) {
        const title = item.object.title;

        download.push([item.swap_path, `${title} - ${item.swap_id.slice(-11)}.${getExtension(item.swap_path)}`]);
        download.push([item.object.origin_path, `${title}.${getExtension(item.object.origin_path)}`]);
      }

      return download;
    })
    .flat()
    .filter(Boolean);

export default prepareHistoryDownloadItems;
