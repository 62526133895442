import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

export const Row = styled.div<{ $numberOfColumn: number }>`
  display: grid;
  grid-template-columns: ${({ $numberOfColumn }) => `repeat(${$numberOfColumn}, minmax(0, 1fr))`};
  grid-gap: ${({ theme }) => theme.layout.grid.gutter}px;
`;

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Loader = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  transform: translateX(-50%);
  display: flex;
  flex: 0 1 auto;
  margin-left: 50%;
  padding: 6px 16px 6px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[40]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  background-color: ${({ theme }) => theme.colors.gray[10]};

  & > div {
    margin-right: 4px;
  }
`;
