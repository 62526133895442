import styled from 'styled-components';
import Typography from '@reface/ui/Typography';

export const GridWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  width: 100%;
  margin: 0;
`;

export const AudioNodePlayerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  flex-grow: 1;
`;

export const MotionGridItem = styled.li`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid transparent;
  padding: 8px;

  &.active {
    color: white;
    background: ${({ theme }) => theme.colors.gray[10]};
    border: ${({ theme }) => `1px solid ${theme.colors.blue[80]}`};
  }
`;

export const Title = styled(Typography)`
  margin-left: 8px;
  flex-grow: 1;
`;

export const Duration = styled(Typography)`
  width: 84px;
`;

export const Loader = styled.div`
  display: inline-flex;
  align-items: center;
`;
