import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { mediaDetailsSelector } from 'store/pages/files/media/mediaSelectors';
import * as S from '../BulkSwapModal.styled';
import { IconHeadMotion } from '@reface/icons/20px';

const AppliedAnimateMappingPreview: React.FC<{ object_id?: [string, string] | null }> = ({ object_id }) => {
  const appliedAnimation = useSelector(mediaDetailsSelector(Array.isArray(object_id) ? object_id[0] : ''));

  const appliedPerson = useMemo(() => {
    if (!appliedAnimation || !appliedAnimation?.data?.persons) {
      return null;
    }

    return appliedAnimation?.data?.persons.find(({ person_id }) => person_id == object_id[1]);
  }, [appliedAnimation, object_id]);

  return (
    <S.AppliedMappingPreview>
      {appliedPerson ? (
        <S.AppliedFacePreviewImage src={appliedPerson.preview_url} />
      ) : (
        <S.EmptyPreview as={IconHeadMotion} />
      )}
    </S.AppliedMappingPreview>
  );
};

export default AppliedAnimateMappingPreview;
