import styled, { css } from 'styled-components';

import { TableColumnAlign } from './Table';

export const Table = styled.div<{ $scroll: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: ${({ $scroll }) => ($scroll ? 'auto' : 'unset')};
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableCell = styled.div<{ $align: TableColumnAlign; $isDefaultRenderer: boolean }>`
  position: relative;
  padding: 10px 20px 10px 0;
  text-align: ${({ $align }) => $align};
  background-color: transparent;
  transition: background-color 0.3s ease-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: transparent;
    pointer-events: none;
    transition: background-color 0.3s ease-out;
  }

  &:first-child {
    padding-left: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    padding-right: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  ${({ $isDefaultRenderer }) =>
    $isDefaultRenderer &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const TableHead = styled.div<{ $align: TableColumnAlign }>`
  padding: 0 20px 6px 0;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray[40]};
  text-align: ${({ $align }) => $align};

  &:first-child {
    padding-left: 6px;
  }

  &:last-child {
    padding-right: 6px;
  }
`;

export const TableRow = styled.div<{ $hasHover?: boolean }>`
  display: flex;

  ${({ $hasHover }) =>
    $hasHover &&
    css`
      cursor: pointer;

      &:hover ${TableCell} {
        &:before {
          background-color: ${({ theme }) => theme.colors.gray[25]};
        }
      }
    `}
`;

export const TableRowSelectable = styled.div`
  &.selectable {
    padding: 4px 0;

    & > ${TableRow} {
      border: 2px solid transparent;
      border-radius: 8px;
    }

    &.selected > ${TableRow} {
      border: 2px solid #174299;
      background-color: ${({ theme }) => theme.colors.gray[15]};
    }
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Loader = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  transform: translateX(-50%);
  display: flex;
  flex: 0 1 auto;
  margin-left: 50%;
  padding: 6px 16px 6px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[40]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  background-color: ${({ theme }) => theme.colors.gray[10]};

  & > div {
    margin-right: 4px;
  }
`;
