import React, { ComponentProps } from 'react';
import RCTooltip from 'rc-tooltip';
import styled, { css } from 'styled-components';
import 'rc-tooltip/assets/bootstrap.css';

type TooltipProps = {
  content: string;
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'rightTop'
    | 'rightBottom'
    | 'leftTop'
    | 'leftBottom';
} & Pick<ComponentProps<typeof RCTooltip>, 'children'>;

const RCTooltipAlias: React.FC<
  ComponentProps<typeof RCTooltip> & {
    className?: string;
    showArrow: boolean;
  }
> = ({ className, ...props }) => <RCTooltip overlayClassName={className} {...props} />;

const TooltipContent = styled(RCTooltipAlias)<{ $variant: string }>`
  .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-inner {
    padding: 2px 4px;
    border-radius: 4px;
    min-height: unset;
    max-width: 280px;

    ${({ theme, $variant }) =>
      $variant === 'info' &&
      css`
        background-color: ${theme.colors.blue[100]};
      `}
  }
`;

const Tooltip: React.FC<TooltipProps> = ({ children, content, placement = 'rightBottom' }) => (
  <TooltipContent mouseEnterDelay={0} placement={placement} overlay={content} $variant={'info'} showArrow={false}>
    {children}
  </TooltipContent>
);

export default Tooltip;
