type CanvasScaleSize = {
  width: number;
  height: number;
  factor: number;
};

const getCanvasSize = (
  containerNode: HTMLDivElement,
  resolution: { width: number; height: number }
): CanvasScaleSize => {
  const resolutionRatio = resolution.width / resolution.height;
  const container = { width: containerNode.clientWidth - 64, height: containerNode.clientHeight - 64 };
  const containerRatio = container.width / container.height;

  if (resolutionRatio <= containerRatio) {
    const height = Math.min(resolution.height, container.height);
    return {
      width: resolutionRatio * height,
      height,
      factor: resolution.height / height,
    };
  } else {
    const width = Math.min(resolution.width, container.width);
    return {
      width,
      height: width / resolutionRatio,
      factor: resolution.width / width,
    };
  }
};

export default getCanvasSize;
